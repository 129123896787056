const VirtualKey = new Map([
  ["KeyA", 0],
  ["KeyB", 1],
  ["KeyC", 2],
  ["KeyD", 3],
  ["KeyE", 4],
  ["KeyF", 5],
  ["KeyG", 6],
  ["KeyH", 7],
  ["KeyI", 8],
  ["KeyJ", 9],
  ["KeyK", 10],
  ["KeyL", 11],
  ["KeyM", 12],
  ["KeyN", 13],
  ["KeyO", 14],
  ["KeyP", 15],
  ["KeyQ", 16],
  ["KeyR", 17],
  ["KeyS", 18],
  ["KeyT", 19],
  ["KeyU", 20],
  ["KeyV", 21],
  ["KeyW", 22],
  ["KeyX", 23],
  ["KeyY", 24],
  ["KeyZ", 25],
  ["Digit0", 26],
  ["Digit1", 27],
  ["Digit2", 28],
  ["Digit3", 29],
  ["Digit4", 30],
  ["Digit5", 31],
  ["Digit6", 32],
  ["Digit7", 33],
  ["Digit8", 34],
  ["Digit9", 35],
  ["Backquote", 36],
  ["AsciiTiled", 37],
  ["Exclam", 38],
  ["At", 39],
  ["NUMBER_SIGN", 40],
  ["Dollar", 41],
  ["Percent", 42],
  ["AsciiCircum", 43],
  ["Ampersand", 44],
  ["Asterisk", 45],
  ["ParentLeft", 46],
  ["ParentRight", 47],
  ["Minus", 48],
  ["UnderScore", 49],
  ["Plus", 50],
  ["Equal", 51],
  ["BraceLeft", 52],
  ["BraceRight", 53],
  ["BracketLeft", 54],
  ["BracketRight", 55],
  ["Bar", 56],
  ["Backslash", 57],
  ["Semicolon", 58],
  ["Colon", 59],
  ["Quote", 60],
  ["QuoteDBL", 61],
  ["Less", 62],
  ["Comma", 63],
  ["Greater", 64],
  ["Period", 65],
  ["Question", 66],
  ["Slash", 67],
  ["Divide", 68],
  ["Multiply", 69],
  ["Add", 70],
  ["Subtract", 71],
  ["Deciml", 72],
  ["F1", 73],
  ["F2", 74],
  ["F3", 75],
  ["F4", 76],
  ["F5", 77],
  ["F6", 78],
  ["F7", 79],
  ["F8", 80],
  ["F9", 81],
  ["F10", 82],
  ["F11", 83],
  ["F12", 84],
  ["Enter", 85],
  ["Cancel", 86],
  ["Backspace", 87],
  ["Tab", 88],
  ["Clear", 89],
  ["Return", 90],
  ["Alt", 91],
  ["AltLeft", 92],
  ["AltRight", 93],
  ["Shift", 94],
  ["ShiftLeft", 95],
  ["ShiftRight", 96],
  ["Control", 97],
  ["ControlLeft", 98],
  ["CcontrolRight", 99],
  ["Win", 100],
  ["WinLeft", 101],
  ["WinRight", 102],
  ["Menu", 103],
  ["MenuLeft", 104],
  ["MenuRight", 105],
  ["Pause", 106],
  ["CapsLock", 107],
  ["Kana", 108],
  ["Hangul", 109],
  ["Junja", 110],
  ["Final", 111],
  ["Hanja", 112],
  ["Kanji", 113],
  ["Escape", 114],
  ["Convert", 115],
  ["Space", 116],
  ["Prior", 117],
  ["Next", 118],
  ["End", 119],
  ["Home", 120],
  ["ArrowLeft", 121],
  ["ArrowUp", 122],
  ["ArrowRight", 123],
  ["ArrowDown", 124],
  ["Select", 125],
  ["Print", 126],
  ["Execute", 127],
  ["Snapshot", 128],
  ["Insert", 129],
  ["Delete", 130],
  ["Help", 131],
  ["Sleep", 132],
  ["Separator", 133],
  ["Numpad0", 26],
  ["Numpad1", 27],
  ["Numpad2", 28],
  ["Numpad3", 29],
  ["Numpad4", 30],
  ["Numpad5", 31],
  ["Numpad6", 32],
  ["Numpad7", 33],
  ["Numpad8", 34],
  ["Numpad9", 35],
  ["Mute", 144],
  ["Shortcut", 145],
  ["Option", 146],
  ["PageDown", 147],
  ["PageUp", 148],
  ["ScrollLock", 149],
  ["NumLock", 150],
  ["VolumeDown", 151],
  ["VolumeUp", 152],
  ["NumpadEnter", 153],
  ["Command", 154],
  ["Apps", 155],
  ["Super", 156],
  ["SuperLeft", 157],
  ["SuperRight", 158],
  ["Hyper", 159],
  ["HyperLeft", 160],
  ["HyperRight", 161],
  ["Meta", 162],
  ["MetaLeft", 163],
  ["MetaRight", 164],
  ["CtrlAltDel", 165],
  ["LockScreen", 166],
]);
/**
 * 按键值与实际要发送值的对应关系
 */
const kcToVirtualKey = {
  27: 114, // Esc
  112: 73, // F1
  113: 74, // F2
  114: 75, // F3
  115: 76, // F4
  116: 77, // F5
  117: 78, // F6
  118: 79, // F7
  119: 80, // F8
  120: 81, // F9
  121: 82, // F10
  122: 83, // F11
  123: 84, // F12
  45: 129, // Ins
  46: 130, // Del
  33: 148, // PgUp
  34: 147, // PaDn
  36: 120, // Home
  35: 119, // End
  192: 36, // `
  49: 27, // 1
  50: 28, // 2
  51: 29, // 3
  52: 30, // 4
  53: 31, // 5
  54: 32, // 6
  55: 33, // 7
  56: 34, // 8
  57: 35, // 9
  48: 26, // 0
  189: 48, // -
  187: 51, // =
  8: 87, // Back
  111: 67, // /
  106: 69, // *
  109: 71, // -
  107: 70, // +
  9: 88, // Tab
  81: 16, // Q
  87: 22, // W
  69: 4, // E
  82: 17, // R
  84: 19, // T
  89: 24, // Y
  85: 20, // U
  73: 8, // I
  79: 14, // O
  80: 15, // P
  219: 54, // [
  221: 55, // ]
  220: 57, // \
  97: 135, // Numpad 1
  98: 136, // Numpad 2
  99: 137, // Numpad 3
  20: 107, // Caps
  65: 0, // A
  83: 18, // S
  68: 3, // D
  70: 5, // F
  71: 6, // G
  72: 7, // H
  74: 9, // J
  75: 10, // K
  76: 11, // L
  186: 58, // ;
  222: 60, // '
  13: 85, // Enter
  100: 138, // Numpad 4
  101: 139, // Numpad 5
  102: 140, // Numpad 6
  108: 153, // Numpad Enter
  //16: 94, // Shift
  16: 96, // Shift (right)
  16: 95, // Shift (left)
  90: 23, // Z
  88: 21, // X
  67: 2, // C
  86: 21, // V
  66: 1, // B
  78: 13, // N
  77: 12, // M
  188: 63, // ,
  190: 65, // .
  191: 67, // /

  38: 122, // Up
  103: 141, // Numpad 7
  104: 142, // Numpad 8
  105: 143, // Numpad 9
  //17: 97, // Ctrl (left) 修饰键
  17: 98, //默认发左Ctrl
  18: 92, // Alt (left)
  32: 116, // Space
  // 17: 99, // Ctrl (right)
  18: 93, // Alt (right)
  37: 121, // Left
  40: 124, // Down
  39: 123, // Right
  96: 134, // Numpad 0
  110: 72, // Numpad .
};
//ascii码值
const asciiControlMap = {
  0: "NUL",
  1: "SOH",
  2: "STX",
  3: "ETX",
  4: "EOT",
  5: "ENQ",
  6: "ACK",
  7: "BEL",
  8: "BS",
  9: "TAB",
  10: "LF",
  11: "VT",
  12: "FF",
  13: "CR",
  14: "SO",
  15: "SI",
  16: "DLE",
  17: "DC1",
  18: "DC2",
  19: "DC3",
  20: "DC4",
  21: "NAK",
  22: "SYN",
  23: "ETB",
  24: "CAN",
  25: "EM",
  26: "SUB",
  27: "ESC",
  28: "FS",
  29: "GS",
  30: "RS",
  31: "US",
  32: "Space",
  33: "!",
  34: '"',
  35: "#",
  36: "$",
  37: "%",
  38: "&",
  39: "'",
  40: "(",
  41: ")",
  42: "*",
  43: "+",
  44: ",",
  45: "-",
  46: ".",
  47: "/",
  48: "0",
  49: "1",
  50: "2",
  51: "3",
  52: "4",
  53: "5",
  54: "6",
  55: "7",
  56: "8",
  57: "9",
  58: ":",
  59: ";",
  60: "<",
  61: "=",
  62: ">",
  63: "?",
  64: "@",
  65: "A",
  66: "B",
  67: "C",
  68: "D",
  69: "E",
  70: "F",
  71: "G",
  72: "H",
  73: "I",
  74: "J",
  75: "K",
  76: "L",
  77: "M",
  78: "N",
  79: "O",
  80: "P",
  81: "Q",
  82: "R",
  83: "S",
  84: "T",
  85: "U",
  86: "V",
  87: "W",
  88: "X",
  89: "Y",
  90: "Z",
  91: "[",
  92: "\\",
  93: "]",
  94: "^",
  95: "_",
  96: "`",
  97: "a",
  98: "b",
  99: "c",
  100: "d",
  101: "e",
  102: "f",
  103: "g",
  104: "h",
  105: "i",
  106: "j",
  107: "k",
  108: "l",
  109: "m",
  110: "n",
  111: "o",
  112: "p",
  113: "q",
  114: "r",
  115: "s",
  116: "t",
  117: "u",
  118: "v",
  119: "w",
  120: "x",
  121: "y",
  122: "z",
  123: "{",
  124: "|",
  125: "}",
  126: "~",
  127: "DEL",
};
//3A手柄数据
let keyboard = [
  {
    top: 220,
    left: 11,
    type: "kb-rock-letter",
    zoom: 70,
    opacity: 80,
    click: 0, //0代表 点击的响应 // 1代表长按的响应
    inputOp: 0,
    width: 90,
    height: 90,
  },
  {
    top: 162,
    left: 49,
    type: "kb-mouse-lt",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 512,
    width: 40,
    height: 40,
  },
  {
    top: 111,
    left: 56,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 77,
    text: "互动菜单",
    width: 30,
    height: 30,
  },
  {
    top: 10,
    left: 101,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 27,
    text: "设置",
    width: 30,
    height: 30,
  },
  {
    top: 11,
    left: 149,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 13,
    text: "确认",
    width: 30,
    height: 30,
  },
  {
    top: 55,
    left: 58,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 9,
    text: "武器菜单",
    width: 30,
    height: 30,
  },
  {
    top: 11,
    left: 231,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 49,
    text: "拳头",
    width: 30,
    height: 30,
  },
  {
    top: 12,
    left: 281,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 50,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 12,
    left: 327,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 51,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 12,
    left: 374,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 52,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 13,
    left: 419,
    type: "kb-round",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 53,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 14,
    left: 465,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 54,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 14,
    left: 509,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 55,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 14,
    left: 552,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 56,
    text: "武器",
    width: 30,
    height: 30,
  },
  {
    top: 262,
    left: 154,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 82,
    text: "换弹",
    width: 30,
    height: 30,
  },
  {
    top: 317,
    left: 155,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 71,
    text: "炸弹",
    width: 30,
    height: 30,
  },
  {
    top: 317,
    left: 206,
    type: "kb-round",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 20,
    text: "能力",
    width: 30,
    height: 30,
  },
  {
    top: 319,
    left: 264,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 18,
    text: "角色选择",
    width: 30,
    height: 30,
  },
  {
    top: 319,
    left: 306,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 116,
    text: "麦克",
    width: 30,
    height: 30,
  },
  {
    top: 319,
    left: 349,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 117,
    text: "富兰克林",
    width: 30,
    height: 30,
  },
  {
    top: 321,
    left: 393,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 118,
    text: "崔佛",
    width: 30,
    height: 30,
  },
  {
    top: 320,
    left: 445,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 72,
    text: "车灯",
    width: 30,
    height: 30,
  },
  {
    top: 321,
    left: 489,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 17,
    text: "潜行",
    width: 30,
    height: 30,
  },
  {
    top: 319,
    left: 531,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 1, //代表是常按的 一直按下的意思
    inputOp: 16,
    text: "奔跑",
    width: 30,
    height: 30,
  },
  {
    top: 269,
    left: 577,
    type: "kb-mouse-lt",
    zoom: 80,
    opacity: 80,
    click: 0,
    inputOp: 512,
    width: 40,
    height: 40,
  },
  {
    top: 217,
    left: 614,
    type: "kb-mouse-rt",
    zoom: 50,
    opacity: 60,
    click: 1,
    inputOp: 514,
    width: 40,
    height: 40,
  },
  {
    top: 242,
    left: 513,
    type: "kb-round",
    zoom: 80,
    opacity: 80,
    click: 0,
    inputOp: 69,
    text: "互动",
    width: 30,
    height: 30,
  },
  {
    top: 189,
    left: 534,
    type: "kb-round",
    zoom: 80,
    opacity: 80,
    click: 0,
    inputOp: 32,
    text: "跳跃闪避",
    width: 30,
    height: 30,
  },
  {
    top: 152,
    left: 594,
    type: "kb-round",
    zoom: 70,
    opacity: 80,
    click: 0,
    inputOp: 70,
    text: "载具",
    width: 30,
    height: 30,
  },
  {
    top: 108,
    left: 540,
    type: "kb-round",
    zoom: 70,
    opacity: 80,
    click: 0,
    inputOp: 81,
    text: "掩体",
    width: 30,
    height: 30,
  },
  {
    top: 57,
    left: 541,
    type: "kb-round",
    zoom: 70,
    opacity: 80,
    click: 0,
    inputOp: 86,
    text: "视角",
    width: 30,
    height: 30,
  },
  {
    top: 61,
    left: 603,
    type: "kb-mouse-up",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 515,
    remark: 1,
    width: 40,
    height: 40,
  },
  {
    top: 107,
    left: 604,
    type: "kb-mouse-down",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 515,
    remark: -1,
    width: 40,
    height: 40,
  },
  {
    top: 261,
    left: 205,
    type: "kb-round",
    zoom: 60,
    opacity: 80,
    click: 0,
    inputOp: 38,
    text: "手机",
    width: 30,
    height: 30,
  },
];
//海马按键示例数据
const hmVirtualKey = [
  { value: "Esc", text: "Esc", kc: 27 },
  { value: "F1", text: "F1", kc: 112 },
  { value: "F2", text: "F2", kc: 113 },
  { value: "F3", text: "F3", kc: 114 },
  { value: "F4", text: "F4", kc: 115 },
  { value: "F5", text: "F5", kc: 116 },
  { value: "F6", text: "F6", kc: 117 },
  { value: "F7", text: "F7", kc: 118 },
  { value: "F8", text: "F8", kc: 119 },
  { value: "F9", text: "F9", kc: 120 },
  { value: "F10", text: "F10", kc: 121 },
  { value: "F11", text: "F11", kc: 122 },
  { value: "F12", text: "F12", kc: 123 },
  { value: "Ins", text: "Ins", kc: 45 },
  { value: "Del", text: "Del", kc: 46 },
  { value: "PgUp", text: "PgUp", kc: 33 },
  { value: "PaDn", text: "PaDn", kc: 34 },
  { value: "Home", text: "Home", kc: 36 },
  { value: "End", text: "End", kc: 35 },
  { value: "`", text: "`", kc: 192 },
  { value: "1", text: "1", kc: 49 },
  { value: "2", text: "2", kc: 50 },
  { value: "3", text: "3", kc: 51 },
  { value: "4", text: "4", kc: 52 },
  { value: "5", text: "5", kc: 53 },
  { value: "6", text: "6", kc: 54 },
  { value: "7", text: "7", kc: 55 },
  { value: "8", text: "8", kc: 56 },
  { value: "9", text: "9", kc: 57 },
  { value: "0", text: "0", kc: 48 },
  { value: "-", text: "-", kc: 189 },
  { value: "=", text: "=", kc: 187 },
  { value: "Back", text: "Back", grid: [14, 16, 2, 3], kc: 8 },
  { value: "/", text: "/", kc: 111 },
  { value: "*", text: "*", kc: 106 },
  { value: "-", text: "-", kc: 109 },
  { value: "+", text: "+", grid: [19, 20, 2, 4], kc: 107 },
  { value: "Tab", text: "Tab", grid: [1, 3, 3, 4], kc: 9 },
  { value: "Q", text: "Q", kc: 81 },
  { value: "W", text: "W", kc: 87 },
  { value: "E", text: "E", kc: 69 },
  { value: "R", text: "R", kc: 82 },
  { value: "T", text: "T", kc: 84 },
  { value: "Y", text: "Y", kc: 89 },
  { value: "U", text: "U", kc: 85 },
  { value: "I", text: "I", kc: 73 },
  { value: "O", text: "O", kc: 79 },
  { value: "P", text: "P", kc: 80 },
  { value: "[", text: "[", kc: 219 },
  { value: "]", text: "]", kc: 221 },
  { value: "\\", text: "\\", kc: 220 },
  { value: "1", text: "1", kc: 97 },
  { value: "2", text: "2", kc: 98 },
  { value: "3", text: "3", kc: 99 },
  { value: "Caps", text: "Caps", grid: [1, 3, 4, 5], kc: 20 },
  { value: "A", text: "A", kc: 65 },
  { value: "S", text: "S", kc: 83 },
  { value: "D", text: "D", kc: 68 },
  { value: "F", text: "F", kc: 70 },
  { value: "G", text: "G", kc: 71 },
  { value: "H", text: "H", kc: 72 },
  { value: "J", text: "J", kc: 74 },
  { value: "K", text: "K", kc: 75 },
  { value: "L", text: "L", kc: 76 },
  { value: ";", text: ";", kc: 186 },
  { value: "'", text: "'", kc: 222 },
  { value: "Enter", text: "Enter", grid: [14, 16, 4, 5], kc: 13 },
  { value: "4", text: "4", kc: 100 },
  { value: "5", text: "5", kc: 101 },
  { value: "6", text: "6", kc: 102 },
  { value: "Enter", text: "Enter", grid: [19, 20, 4, 7], kc: 108 },
  { value: "Shift", text: "Shift", grid: [1, 3, 5, 6], kc: 16 },
  { value: "Z", text: "Z", kc: 90 },
  { value: "X", text: "X", kc: 88 },
  { value: "C", text: "C", kc: 67 },
  { value: "V", text: "V", kc: 86 },
  { value: "B", text: "B", kc: 66 },
  { value: "N", text: "N", kc: 78 },
  { value: "M", text: "M", kc: 77 },
  { value: ",", text: ",", kc: 188 },
  { value: ".", text: ".", kc: 190 },
  { value: "/", text: "/", kc: 191 },
  { value: "Shift", text: "Shift", grid: [13, 15, 5, 6], kc: 16 },
  { value: "Up", text: "↑", kc: 38 },
  { value: "7", text: "7", kc: 103 },
  { value: "8", text: "8", kc: 104 },
  { value: "9", text: "9", kc: 105 },
  { value: "Ctrl", text: "Ctrl", grid: [1, 3, 6, 7], kc: 17 },
  { value: "Alt", text: "Alt", grid: [3, 5, 6, 7], kc: 18 },
  // {value:'Win',text:'Win',grid:[5,7,6,7],kc:91},
  { value: "Space", text: "Space", grid: [5, 10, 6, 7], kc: 32 },
  { value: "Ctrl", text: "Ctrl", grid: [10, 12, 6, 7], kc: 17 },
  { value: "Alt", text: "Alt", grid: [12, 14, 6, 7], kc: 18 },
  { value: "Left", text: "←", kc: 37 },
  { value: "Down", text: "↓", kc: 40 },
  { value: "Right", text: "→", kc: 39 },
  { value: "0", text: "0", kc: 96 },
  { value: ".", text: ".", kc: 110 },
];

// 取3a对应的key的text
const get3aKeyObj = (inputValue) => {
  // 根据 inputValue 在 hmVirtualKey 中查找对应的对象
  const virtualKeyObject = hmVirtualKey.find((key) => key.kc === inputValue);
  // 如果找到了对应的对象，提取其 text 属性并返回
  if (virtualKeyObject) {
    return virtualKeyObject;
  } else {
    console.log("未找到匹配的键值:", inputValue);
    return "";
  }
};
//获取实际应该发送的值
const getVirtualKey = (inputValue) => {
  let r = get3aKeyObj(inputValue);
  if (r.value) {
    let data = valueToKey[r.value];
    return data;
  }
  return false;
};

//根据 text 获取 inputOp
const getInputOpFromText = (text) => {
  // 根据 inputValue 在 hmVirtualKey 中查找对应的对象
  const virtualKeyObject = hmVirtualKey.find((key) => key.value === text);
  // 如果找到了对应的对象，提取其 text 属性并返回
  if (virtualKeyObject) {
    return virtualKeyObject.kc;
  } else {
    console.log("未找到匹配的键值:", text);
    return "";
  }
};

//极速竞技5
const xboxKeyboard = [
  {
    top: 98,
    left: 44,
    type: "xbox-square",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 1025,
    text: "LT",
    width: 50,
    height: 37,
  },
  {
    top: 150,
    left: 82,
    type: "xbox-square",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 256,
    text: "LB",
    width: 50,
    height: 37,
  },
  {
    top: 244,
    left: 25,
    type: "xbox-round-small",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 64,
    text: "LS",
    width: 25,
    height: 25,
  },
  {
    top: 222,
    left: 53,
    type: "xbox-rock-lt",
    zoom: 70,
    opacity: 70,
    click: 0,
    inputOp: 0,
    width: 90,
    height: 90,
  },
  {
    top: 260,
    left: 188,
    type: "xbox-cross",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 0,
    width: 80,
    height: 80,
  },
  {
    top: 16,
    left: 217,
    type: "xbox-elliptic",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 32,
    width: 40,
    height: 25,
  },
  {
    top: 18,
    left: 387,
    type: "xbox-elliptic",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 16,
    width: 40,
    height: 25,
  },
  {
    top: 263,
    left: 381,
    type: "xbox-rock-rt",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 0,
    width: 90,
    height: 90,
  },
  {
    top: 150,
    left: 524,
    type: "xbox-square",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 512,
    text: "RB",
    width: 50,
    height: 37,
  },
  {
    top: 99,
    left: 570,
    type: "xbox-square",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 1026,
    text: "RT",
    width: 50,
    height: 37,
  },
  {
    top: 245,
    left: 612,
    type: "xbox-round-small",
    zoom: 50,
    opacity: 70,
    click: 0,
    inputOp: 128,
    text: "RS",
    width: 25,
    height: 25,
  },
  {
    top: 239,
    left: 525,
    type: "xbox-round-medium",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 32768,
    text: "Y",
    width: 30,
    height: 30,
  },
  {
    top: 278,
    left: 490,
    type: "xbox-round-medium",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 16384,
    text: "X",
    width: 30,
    height: 30,
  },
  {
    top: 278,
    left: 559,
    type: "xbox-round-medium",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 8192,
    text: "B",
    width: 30,
    height: 30,
  },
  {
    top: 318,
    left: 526,
    type: "xbox-round-medium",
    zoom: 60,
    opacity: 70,
    click: 0,
    inputOp: 4096,
    text: "A",
    width: 30,
    height: 30,
  },
];

//兼容海马的 sendMessageToRom
const xboxMap = {
  0: "0",
  1: "D-pad Up",
  2: "D-pad Down",
  4: "D-pad Left",
  8: "D-pad Right",
  9: "D-pad Right Up",
  10: "D-pad Right Down",
  6: "D-pad Left Down",
  5: "D-pad Left Up",
  16: "START",
  32: "SELECT",
  64: "LS",
  128: "RB",
  256: "LT",
  512: "LB",
  4096: "A",
  8192: "B",
  16384: "X",
  32768: "Y",
  // 0: "按键抬起",
  // 1: "十字键上按下",
  // 2: "十字键下按下",
  // 4: "十字键左按下",
  // 8: "十字键右按下",
  // 9: "十字键右上按下",
  // 10: "十字键右下按下",
  // 6: "十字键左下按下",
  // 5: "十字键左上按下",
  // 16: "START键按下",
  // 32: "SELECT键按下",
  // 4096: "A键按下",
  // 8192: "B键按下",
  // 16384: "X键按下",
  // 32768: "Y键按下",
  // 64: "LS键按下",
  // 256: "LB键按下",
  // 128: "RS键按下",
  // 512: "RB键按下",
  // 255: "RT/LT键按下"
};
// 根据 3a 和本身的键盘key 生成对应值
const valueToKey = {
  Esc: "Escape",
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  F11: "F11",
  F12: "F12",
  Ins: "Insert",
  Del: "Delete",
  PgUp: "PageUp",
  PaDn: "PageDown",
  Home: "Home",
  End: "End",
  "`": "Backquote",
  1: "Digit1",
  2: "Digit2",
  3: "Digit3",
  4: "Digit4",
  5: "Digit5",
  6: "Digit6",
  7: "Digit7",
  8: "Digit8",
  9: "Digit9",
  0: "Digit0",
  "-": "Minus",
  "=": "Equal",
  Back: "Backspace",
  "/": "Slash",
  "*": "Asterisk",
  "+": "Add",
  Tab: "Tab",
  Q: "KeyQ",
  W: "KeyW",
  E: "KeyE",
  R: "KeyR",
  T: "KeyT",
  Y: "KeyY",
  U: "KeyU",
  I: "KeyI",
  O: "KeyO",
  P: "KeyP",
  "[": "BracketLeft",
  "]": "BracketRight",
  "\\": "Backslash",
  Caps: "CapsLock",
  A: "KeyA",
  S: "KeyS",
  D: "KeyD",
  F: "KeyF",
  G: "KeyG",
  H: "KeyH",
  J: "KeyJ",
  K: "KeyK",
  L: "KeyL",
  ";": "Semicolon",
  "'": "Quote",
  Enter: "Enter",
  Z: "KeyZ",
  X: "KeyX",
  C: "KeyC",
  V: "KeyV",
  B: "KeyB",
  N: "KeyN",
  M: "KeyM",
  ",": "Comma",
  ".": "Period",
  Shift: "ShiftLeft",
  Up: "ArrowUp",
  Ctrl: "ControlLeft",
  Alt: "AltLeft",
  Space: "Space",
  AltRight: "AltRight",
  CtrlRight: "ControlRight",
  Left: "ArrowLeft",
  Down: "ArrowDown",
  Right: "ArrowRight",
  0: "Numpad0",
  1: "Numpad1",
  2: "Numpad2",
  3: "Numpad3",
  4: "Numpad4",
  5: "Numpad5",
  6: "Numpad6",
  7: "Numpad7",
  8: "Numpad8",
  9: "Numpad9",
  ".": "NumpadDecimal",
  "/": "NumpadDivide",
  "*": "NumpadMultiply",
  "-": "NumpadSubtract",
  "+": "NumpadAdd",
  Enter: "NumpadEnter",
  Win: "MetaLeft",
};

export { kcToVirtualKey, xboxKeyboard, getInputOpFromText, VirtualKey, asciiControlMap, keyboard, valueToKey, hmVirtualKey, get3aKeyObj, getVirtualKey };
