/**
 * AtKit 默认的注册事件
 * @param {*} AtKit
 */
const registerCustomEvents = (AtKit) => {
  const gestureStartHandler = (event) => {
    event.preventDefault();
  };
  const handleVisibilityChange = () => {
    //浏览器不可见
    if (document.visibilityState === "hidden") {
      AtKit.pauseGame("hidden");
    } else {
      //浏览器可见
      AtKit.pauseGame("visible");
    }
  };

  const networkOnline = (event) => {
    AtKit.onSceneChanged("online", { message: "网络连接已恢复" });
  };

  const networkOffline = (event) => {
    console.log("网络连接已断开", event);
    AtKit.onSceneChanged("offline", { message: "网络异常断开" });
  };

  const handleLeaving = async () => {
    if (AtKit.connPeer) {
      AtKit.connPeer.closeConnect();
      AtKit.eventEmitter.removeAllListeners();
    }
    removeEvents();
  };

  const addEvents = () => {
    document.addEventListener("gesturestart", gestureStartHandler);
    window.addEventListener("beforeunload", handleLeaving);
    window.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("offline", networkOffline);
    window.addEventListener("online", networkOnline);
  };

  const removeEvents = () => {
    document.removeEventListener("gesturestart", gestureStartHandler);
    window.removeEventListener("visibilitychange", handleVisibilityChange);
    window.removeEventListener("beforeunload", handleLeaving);
    window.removeEventListener("offline", networkOffline);
    window.removeEventListener("online", networkOnline);
  };

  addEvents();
};

export { registerCustomEvents };
