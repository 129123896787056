import { isIOS, isMobile, getOperatingSystem, getExampleDimensions } from "@/utils/utilit-ties/tools";
import AtKit from "@/lib/AtKit/AtKit";
import logCollector from "@/utils/report-log/LogCollector.js";
const displayVideoTrack = (track, videoElementId, stream) => {
  // 获取视频元素
  let videoElem = document.getElementById(videoElementId);
  // 检查视频元素是否存在
  if (!videoElem) {
    console.log(`Error: Video element with id ${videoElementId} not found`);
    return;
  }
  // 检查是否传入了有效的轨道
  if (!track) {
    console.log("Error: Invalid video track provided");
    return;
  }
  // 向媒体流中添加轨道
  stream.addTrack(track);
  // 将媒体流关联到视频元素
  videoElem.srcObject = stream; //视频流
  videoElem.autoplay = true; //自动播放
  videoElem.playsinline = true; //内联播放 非全屏
  videoElem.muted = true; //静音
  const playVideo = () => {
    try {
      videoElem
        .play()
        .then(() => {
          console.log("Video is playing");
          //videoElem.muted = false; // 取消静音
          if (window.WeixinJSBridge) {
            videoElem.muted = false; // 取消静音
          }
        })
        .catch((error) => {
          console.log("Play error", error);
          AtKit.onSceneChanged("ui", { message: "play 错误:", error });
          AtKit.onSceneChanged("warning", { message: `[playVideo]出错: ${error}` });
        });
    } catch (error) {
      console.log("playVideo: ==error:", error);
      AtKit.onSceneChanged("warning", { message: `[playVideo]出错: ${error}` });
    }
  };

  //如果是微信 ios 的话
  if (window.WeixinJSBridge) {
    // 使用 WeixinJSBridge 来播放视频
    window.WeixinJSBridge.invoke("getNetworkType", {}, function (res) {
      var networkType = res.networkType; // 获取到的网络类型
      console.log("Network Type:", networkType);
      logCollector.log({
        eventType: 25,
        eventName: "network",
        details: `wx平台用户网络是:${networkType}`,
      });
      playVideo();
    });
    return;
  }

  // 等待视频元素加载完成后再播放
  //loadedmetadata  , loadeddata
  videoElem.addEventListener("loadedmetadata", () => {
    playVideo();
  });

  //打印轨道多少条
  console.log("VideoStream tracks:", videoElem.srcObject.getTracks());
};
const displayAudioTrack = (track, audioElementId, stream) => {
  try {
    // 获取音频元素
    let audioElem = document.getElementById(audioElementId);
    // 检查音频元素是否存在
    if (!audioElem) {
      console.log(`Error: Audio element with id ${audioElementId} not found`);
      return;
    }

    // 检查是否传入了有效的轨道
    if (!track) {
      console.log("Error: Invalid audio track provided");
      return;
    }

    // 向媒体流中添加音频轨道
    if (stream.getAudioTracks().length > 0) {
      stream.removeTrack(stream.getAudioTracks()[0]); //移除现有的音频轨道（如果有）
    }
    stream.addTrack(track);
    audioElem.srcObject = stream; //从新设置stream，确保新的轨道被添加到audio元素上
    audioElem.muted = true; //默认静音
    if (window.WeixinJSBridge) {
      // 使用 WeixinJSBridge 来播放视频
      window.WeixinJSBridge.invoke("getNetworkType", {}, function (res) {
        var networkType = res.networkType; // 获取到的网络类型
        console.log("Network Type:", networkType);
        logCollector.log({
          eventType: 25,
          eventName: "network",
          details: `wx平台用户网络是:${networkType}`,
        });
        audioElem
          .play()
          .then(() => {
            audioElem.muted = false;
            console.log("window.WeixinJSBridge 音频播放成功 :", audioElem, stream);
          })
          .catch((err) => {
            // 检测是否需要用户交互
            if (err.name === "NotAllowedError" || err.name === "NotSupportedError") {
              // 只在错误情况下添加一次性触摸和点击事件监听器
              if (getOperatingSystem()) {
                // 添加一次性的触摸事件监听器
                document.addEventListener("touchstart", onTouchOrClick, { once: true });
              } else {
                // 添加一次性的点击事件监听器
                document.addEventListener("click", onTouchOrClick, { once: true });
              }
            }
            console.log("播放声音失败:", err);
          });
      });
      return;
    }

    const onTouchOrClick = (event) => {
      // 阻止事件传播
      // event.stopPropagation();
      // event.stopImmediatePropagation();

      audioElem
        .play()
        .then(() => {
          audioElem.muted = false;
          console.log("用户交互后成功播放声音");
        })
        .catch((err) => {
          console.log("继续播放声音错误:", err);
          if (getOperatingSystem()) {
            // 添加一次性的触摸事件监听器
            //capture: true
            document.addEventListener("touchstart", onTouchOrClick, { once: true, capture: true });
          } else {
            // 添加一次性的点击事件监听器
            document.addEventListener("click", onTouchOrClick, { once: true, capture: true });
          }
          AtKit.onSceneChanged("warning", { message: `[onTouchOrClick]播放声音错误: ${err}` });
        })
        .finally(() => {
          // 只需触发一次，之后移除事件监听器
          document.removeEventListener("touchstart", onTouchOrClick);
          document.removeEventListener("click", onTouchOrClick);
        });
    };
    if (getOperatingSystem()) {
      // 添加一次性的触摸事件监听器
      //capture: true
      document.addEventListener("touchstart", onTouchOrClick, { once: true, capture: true });
    } else {
      // 添加一次性的点击事件监听器
      document.addEventListener("click", onTouchOrClick, { once: true, capture: true });
    }
    console.log("AudioStream tool :", audioElem.srcObject.getTracks());
  } catch (error) {
    console.log("播放音频异常:", error);
  }
};
/**
 * 手动开始播放视频
 * @param {*} videoElementId
 */
const startVideoPlayback = (videoElementId) => {
  let videoElem = document.getElementById(videoElementId);
  if (videoElem) {
    videoElem.play();
  }
};

/**
 * 每次断开需要调用清空轨道
 * 不然就累计叠加,导致画面黑屏
 */
const removeVideoAudioTrack = () => {
  try {
    // 获取音频元素
    let audioElem = document.getElementById("AtKitAudio");
    let videoElem = document.getElementById("AtKitVideo");
    if (audioElem !== null) {
      if (audioElem.srcObject) {
        audioElem.srcObject.getTracks().forEach((track) => {
          track.stop();
          audioElem.srcObject.removeTrack(track);
        });
      }
    }
    if (videoElem !== null) {
      if (videoElem.srcObject) {
        videoElem.srcObject.getTracks().forEach((track) => {
          track.stop();
          videoElem.srcObject.removeTrack(track);
          console.log("track stop", track);
        });
      }
      videoElem.removeAttribute("src");
      videoElem.removeAttribute("srcObject");
    }
  } catch (error) {
    console.log("removeVideoAudioTrack error", error);
  }
};
/**
 * 切割发送相对鼠标的XY 发送的XY任意一个 最大不超过128
 * @param {*} clientX
 * @param {*} clientY
 * @returns 返回一个要发送的数组
 */
const splitCoordinates = (clientX, clientY) => {
  // 确定最大值
  const maxValue = Math.max(Math.abs(clientX), Math.abs(clientY));
  //console.log("maxValue: ", maxValue);
  // 如果最大值小于等于128，直接返回这一对值
  if (maxValue <= 128) {
    return [{ x: clientX, y: clientY }];
  }

  // 计算需要分割的份数
  const parts = Math.ceil(Math.abs(maxValue / 128));
  // console.log("parts:", parts);
  // 计算每份的大小
  const xIncrement = clientX === 0 ? 0 : clientX / parts;
  const yIncrement = clientY === 0 ? 0 : clientY / parts;

  // 生成分割后的坐标数组
  const coordinates = [];
  for (let i = 1; i <= parts; i++) {
    coordinates.push({
      x: xIncrement,
      y: yIncrement,
    });
  }

  return coordinates;
};
/**
 * 处理竖屏时xy坐标
 * @param {*} clientX X
 * @param {*} clientY Y
 * @param {*} bodyRect example 结构的信息
 * 返回处理竖屏的XY
 */
const portraitOrientationCoordinates = (clientX, clientY, bodyRect) => {
  const temp = clientX;
  clientX = clientY;
  clientY = bodyRect.height - temp;
  return { clientX, clientY };
};

export { displayVideoTrack, startVideoPlayback, displayAudioTrack, removeVideoAudioTrack, splitCoordinates, portraitOrientationCoordinates };
