/**
 *
 * @param {*} videoWidth 视频宽
 * @param {*} videoHeight 视频高
 * @param {*} phoneWidth 手机宽
 * @param {*} phoneHeight 手机高
 * @returns 返回缩放比 应该偏移的距离 返回实际视频应该设置的宽高
 */
const calculateDisplaySize = (videoWidth, videoHeight, phoneWidth, phoneHeight) => {
  // 计算手机屏幕的宽高比
  const phoneAspect = phoneWidth / phoneHeight;

  // 计算视频的宽高比
  const videoAspect = videoWidth / videoHeight;

  // 初始化展示的视频宽度和高度
  let displayWidth = phoneWidth;
  let displayHeight = phoneHeight;

  // 如果视频的宽高比大于手机屏幕的宽高比，则根据手机的宽度来调整视频的高度
  if (videoAspect > phoneAspect) {
    displayHeight = phoneWidth / videoAspect;
  } else {
    // 否则，根据手机的高度来调整视频的宽度
    displayWidth = phoneHeight * videoAspect;
  }

  // 计算水平和垂直方向的缩放比例
  let scaleWidth = displayWidth / videoWidth;
  let scaleHeight = displayHeight / videoHeight;
  // 计算视频在水平方向上的定位偏移量，使其居中
  const leftX = (phoneWidth - displayWidth) / 2;
  // 计算视频在垂直方向上的定位偏移量，使其居中
  const topY = (phoneHeight - displayHeight) / 2;
  return {
    displayWidth: displayWidth,
    displayHeight: displayHeight,
    scaleWidth: scaleWidth,
    scaleHeight: scaleHeight,
    leftX: leftX,
    topY: topY,
  };
};
//这个函数目前用于pc浏览器 换算发送的大小
const restoreOriginalCoordinatesPC = (clientX, clientY, videoWidth, localVideoWidth) => {
  // 计算游戏视角与屏幕之间的缩放比例
  const scale = videoWidth / localVideoWidth;
  // 计算缩放后的坐标差异
  const deltaX = clientX * scale;
  const deltaY = clientY * scale;
  // 将差异四舍五入为整数
  const roundedDeltaX = Math.round(deltaX);
  const roundedDeltaY = Math.round(deltaY);
  return { x: roundedDeltaX, y: roundedDeltaY };
};

/**
 * 返回视频信息帧率等
 * @param {*} options
 * @returns
 */
const getVideoQualityData = (stats) => {
  let videoQualityData = {
    videoFps: stats.fps ?? -1, // 视频帧率,取不到为-1
    bitRate: (stats.kbps / 1000).toFixed(2) ?? -1, // 视频码率,取不到为-1
    packetsLostRate: stats.packetsLostRateChange ?? -1, // 丢包率,取不到为-1
    currentDelayMs: -1, // 帧网络耗时,取不到为-1
    decodeDelay: stats.decodedTimeChange ?? -1, // 帧解码耗时,取不到为-1
    renderDelay: -1, // 帧渲染耗时,取不到为-1
    frameSize: -1, // 帧大小,取不到为-1 (没有直接映射)
    jitterBuffer: -1, // 抖动,取不到为-1
    framesReceived: stats.framesReceived ?? -1, // 收到的总帧数,取不到为-1
    receiveFrameSize: stats.bytesReceived ?? -1, // 收到帧的总大小,取不到为-1
    delay: stats.currentRoundTripTime ?? -1, // 网络延迟,取不到为-1
    codeImplementationName: stats.codecId ?? "", // 编解码名称,取不到为空字符串
    codecName: stats.mimeType ?? "", // 解码器名称,取不到为空字符串
    frameWidth: stats.width ?? -1, // 视频宽度,取不到为-1
    frameHeight: stats.height ?? -1, // 视频高度取不到为-1
  };
 
  return videoQualityData;
};
const keyQuality = (stats) => {
  let videoQualityData = {
    bitRate: stats.bitRate,
    videoFps: stats.videoFps,
    packetsLostRate: stats.packetsLostRate,
    decodeDelay: stats.decodeDelay,
    delay: stats.delay,
  };
  return videoQualityData;
};
function logInfo(message, level = "Info") {
  const startTime = new Date();
  const caller = new Error().stack
    .split("\n")[2]
    .trim()
    .replace(/^.*at\s+(.*)\s+\(.*$/, "$1");
  let val = `${startTime.toLocaleString()} [${level}] [:${caller}] 描述: ${message}
   `;
  if (level === "error") {
    console.error(val);
    return;
  }
  if (level === "info") {
    console.info(val);
    return;
  }
  console.log(val);
}
/**
 * 用途打印函数执行花了多少时间
 * @param {*} func
 * @returns
 */
async function executeAndLog(func) {
  const startTime = new Date();
  const functionName = func.name || "Anonymous Function";

  try {
    const result = await func();
    const executionTime = new Date() - startTime;
    console.log(`Function ${functionName} execution time: ${executionTime}ms`);
    return result;
  } catch (error) {
    const executionTime = new Date() - startTime;
    console.error(`Function ${functionName} encountered an error: ${error}`);
  }
}

/**
 * 返回组装日志对象
 * @param {*} eventType 类型
 * @param {*} eventName 事件名
 * @param {*} details 详细
 * @returns
 */
const reEsData = (eventType, eventName, details) => {
  let playToken = window.localStorageUser?.get("current_connection_info")?.playToken;

  let { accessKeyld } = window.localStorageUser.get("start_game");
  let esData = {
    uuid: playToken,
    timestamp: Date.now().valueOf(),
    accessKeyld: accessKeyld,
    eventType: eventType,
    eventName: eventName,
    details: details,
  };
  return esData;
};
const isMobileDevice = () => {
  const threshold = 1366; // 宽度临界值，可以根据实际需求调整
  return window.innerWidth <= threshold;
};
/**
 * 表示当前设备是否为移动设备（true 表示移动设备，false 表示非移动设备）。
 */
const getOperatingSystem = () => {
  let os = /ipad|iphone os|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/i;
  const userAgent = navigator.userAgent.toLowerCase();
  //console.log("是否手机模式:", os.test(userAgent), isMobileDevice());
  if (os.test(userAgent) || isMobileDevice()) {
    return true;
  }
  return false;
};

const getOperatingSystemName = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/windows nt/i.test(userAgent)) {
    return "Windows";
  } else if (/mac os x/i.test(userAgent)) {
    return "Mac";
  } else if (/android/i.test(userAgent)) {
    return "Android";
  } else if (/iphone|ipad/i.test(userAgent)) {
    return "iOS";
  } else if (/linux/i.test(userAgent)) {
    return "Linux";
  } else if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  } else if (/ucweb/i.test(userAgent)) {
    return "UC Browser";
  } else if (/rv:1.2.3.4/i.test(userAgent)) {
    return "Some specific browser or OS";
  } else if (/midp/i.test(userAgent)) {
    return "Mobile Information Device Profile";
  }
  return "Unknown";
};
//是否ios
const isIOS = () => {
  return /iphone|ipad/i.test(navigator.userAgent.toLowerCase());
};
/**
 *
 * @returns true 竖屏 false 横屏
 */
const isScreenOrientationVertical = () => {
  return window.matchMedia("(orientation: portrait)").matches;
};
/**
 * 设置是否忽略sdk内部旋转
 * @param {*} value 默认:false true:忽略 false:不忽略
 */
const setIgnoreResizeEvent = (domId, value = false) => {
  const example = document.getElementById(domId);
  //const example = document.querySelector("body");
  if (!example) {
    console.log("SDK设置是否忽旋转屏幕失败");
    return;
  }
  if (value) {
    example.style.transform = ""; // 清除 transform 样式
  } else {
    applyPortraitStyles(domId, true);
    // 监听 resize 事件
    window.addEventListener("resize", () => {
      applyPortraitStyles(domId, true);
    });
  }
};
/**
 * 旋转屏幕
 * @returns
 */
function applyPortraitStyles(domId, value) {
  const example = document.getElementById(domId);
  //const example = document.querySelector("body");
  if (!example) {
    console.error(" element not found.");
    return;
  }
  //判断屏幕
  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  if (isPortrait) {
    example.classList.add("example-rotate-90");
    //兼容线上css 文件丢失 example-rotate-90
    example.style.transformOrigin = "0 0";
    example.style.transform = "rotateZ(90deg) translateY(-100%)";
    example.style.width = window.innerHeight + "px";
    example.style.height = window.innerWidth + "px";
  } else {
    example.classList.remove("example-rotate-90");
    example.style.transformOrigin = "";
    example.style.transform = "";
    example.style.width = window.innerWidth + "px";
    example.style.height = window.innerHeight + "px";
  }
}

//新一个横定不判断屏幕 直接 旋转或不旋转
function isRotation(domId, value) {
  const example = document.getElementById(domId);
  //const example = document.querySelector("body");
  if (!example) {
    console.error(" element not found.");
    return;
  }
  //游戏的横竖屏属性: true:竖屏 ,false :横屏
  if (value) {
    example.classList.remove("example-rotate-90");
    example.style.transformOrigin = "";
    example.style.transform = "";
    example.style.width = "100vw";
    example.style.height = "100vh";
    return;
  }
  example.classList.add("example-rotate-90");
  example.style.transformOrigin = "0 0";
  example.style.transform = "rotateZ(90deg) translateY(-100%)";
  example.style.width = "100vh";
  example.style.height = "100vw";
}

//返回example的宽高
function getExampleDimensions() {
  var example = document.getElementById("example");
  if (!example) {
    console.error("Element with id 'example' not found.");
    return null;
  }

  return { width: parseInt(example.style.width), height: parseInt(example.style.height) };
}
// 格式化码率
const formatBytes = (a, b = 2) => {
  if (typeof a !== "number" || isNaN(a) || a <= 0) return "0 Bytes";
  var c = 1024,
    e = ["Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
    f = Math.floor(Math.log(a) / Math.log(c) / 1024);
  return parseFloat((a / Math.pow(c, f)).toFixed(b)) + " " + e[f];
};

const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export {
  isMobile,
  formatBytes,
  getExampleDimensions,
  isRotation,
  keyQuality,
  setIgnoreResizeEvent,
  reEsData,
  isScreenOrientationVertical,
  calculateDisplaySize,
  restoreOriginalCoordinatesPC,
  getVideoQualityData,
  isIOS,
  getOperatingSystemName,
  getOperatingSystem,
};
