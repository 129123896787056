/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.geelevel = (function () {
  /**
   * Namespace geelevel.
   * @exports geelevel
   * @namespace
   */
  var geelevel = {};

  geelevel.DeviceInfo = (function () {
    /**
     * Properties of a DeviceInfo.
     * @memberof geelevel
     * @interface IDeviceInfo
     * @property {string|null} [data] DeviceInfo data
     */

    /**
     * Constructs a new DeviceInfo.
     * @memberof geelevel
     * @classdesc Represents a DeviceInfo.
     * @implements IDeviceInfo
     * @constructor
     * @param {geelevel.IDeviceInfo=} [properties] Properties to set
     */
    function DeviceInfo(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * DeviceInfo data.
     * @member {string|null|undefined} data
     * @memberof geelevel.DeviceInfo
     * @instance
     */
    DeviceInfo.prototype.data = null;

    /**
     * Creates a new DeviceInfo instance using the specified properties.
     * @function create
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {geelevel.IDeviceInfo=} [properties] Properties to set
     * @returns {geelevel.DeviceInfo} DeviceInfo instance
     */
    DeviceInfo.create = function create(properties) {
      return new DeviceInfo(properties);
    };

    /**
     * Encodes the specified DeviceInfo message. Does not implicitly {@link geelevel.DeviceInfo.verify|verify} messages.
     * @function encode
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {geelevel.IDeviceInfo} message DeviceInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeviceInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified DeviceInfo message, length delimited. Does not implicitly {@link geelevel.DeviceInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {geelevel.IDeviceInfo} message DeviceInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeviceInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeviceInfo message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.DeviceInfo} DeviceInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeviceInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.DeviceInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a DeviceInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.DeviceInfo} DeviceInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeviceInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeviceInfo message.
     * @function verify
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeviceInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a DeviceInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.DeviceInfo} DeviceInfo
     */
    DeviceInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.DeviceInfo) return object;
      var message = new $root.geelevel.DeviceInfo();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a DeviceInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {geelevel.DeviceInfo} message DeviceInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeviceInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this DeviceInfo to JSON.
     * @function toJSON
     * @memberof geelevel.DeviceInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeviceInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeviceInfo
     * @function getTypeUrl
     * @memberof geelevel.DeviceInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeviceInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.DeviceInfo";
    };

    return DeviceInfo;
  })();

  geelevel.JsonData = (function () {
    /**
     * Properties of a JsonData.
     * @memberof geelevel
     * @interface IJsonData
     * @property {string|null} [data] JsonData data
     */

    /**
     * Constructs a new JsonData.
     * @memberof geelevel
     * @classdesc Represents a JsonData.
     * @implements IJsonData
     * @constructor
     * @param {geelevel.IJsonData=} [properties] Properties to set
     */
    function JsonData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * JsonData data.
     * @member {string|null|undefined} data
     * @memberof geelevel.JsonData
     * @instance
     */
    JsonData.prototype.data = null;

    /**
     * Creates a new JsonData instance using the specified properties.
     * @function create
     * @memberof geelevel.JsonData
     * @static
     * @param {geelevel.IJsonData=} [properties] Properties to set
     * @returns {geelevel.JsonData} JsonData instance
     */
    JsonData.create = function create(properties) {
      return new JsonData(properties);
    };

    /**
     * Encodes the specified JsonData message. Does not implicitly {@link geelevel.JsonData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.JsonData
     * @static
     * @param {geelevel.IJsonData} message JsonData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    JsonData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified JsonData message, length delimited. Does not implicitly {@link geelevel.JsonData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.JsonData
     * @static
     * @param {geelevel.IJsonData} message JsonData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    JsonData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a JsonData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.JsonData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.JsonData} JsonData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    JsonData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.JsonData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a JsonData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.JsonData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.JsonData} JsonData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    JsonData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a JsonData message.
     * @function verify
     * @memberof geelevel.JsonData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    JsonData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a JsonData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.JsonData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.JsonData} JsonData
     */
    JsonData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.JsonData) return object;
      var message = new $root.geelevel.JsonData();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a JsonData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.JsonData
     * @static
     * @param {geelevel.JsonData} message JsonData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    JsonData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this JsonData to JSON.
     * @function toJSON
     * @memberof geelevel.JsonData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    JsonData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for JsonData
     * @function getTypeUrl
     * @memberof geelevel.JsonData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    JsonData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.JsonData";
    };

    return JsonData;
  })();

  geelevel.Call = (function () {
    /**
     * Properties of a Call.
     * @memberof geelevel
     * @interface ICall
     * @property {string|null} [password] Call password
     * @property {number|null} [pwdType] Call pwdType
     * @property {number|null} [authority] Call authority
     */

    /**
     * Constructs a new Call.
     * @memberof geelevel
     * @classdesc Represents a Call.
     * @implements ICall
     * @constructor
     * @param {geelevel.ICall=} [properties] Properties to set
     */
    function Call(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Call password.
     * @member {string|null|undefined} password
     * @memberof geelevel.Call
     * @instance
     */
    Call.prototype.password = null;

    /**
     * Call pwdType.
     * @member {number|null|undefined} pwdType
     * @memberof geelevel.Call
     * @instance
     */
    Call.prototype.pwdType = null;

    /**
     * Call authority.
     * @member {number|null|undefined} authority
     * @memberof geelevel.Call
     * @instance
     */
    Call.prototype.authority = null;

    /**
     * Creates a new Call instance using the specified properties.
     * @function create
     * @memberof geelevel.Call
     * @static
     * @param {geelevel.ICall=} [properties] Properties to set
     * @returns {geelevel.Call} Call instance
     */
    Call.create = function create(properties) {
      return new Call(properties);
    };

    /**
     * Encodes the specified Call message. Does not implicitly {@link geelevel.Call.verify|verify} messages.
     * @function encode
     * @memberof geelevel.Call
     * @static
     * @param {geelevel.ICall} message Call message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Call.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.password != null && Object.hasOwnProperty.call(message, "password")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.password);
      if (message.pwdType != null && Object.hasOwnProperty.call(message, "pwdType")) writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.pwdType);
      if (message.authority != null && Object.hasOwnProperty.call(message, "authority")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.authority);
      return writer;
    };

    /**
     * Encodes the specified Call message, length delimited. Does not implicitly {@link geelevel.Call.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.Call
     * @static
     * @param {geelevel.ICall} message Call message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Call.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Call message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.Call
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.Call} Call
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Call.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.Call();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.password = reader.string();
            break;
          }
          case 2: {
            message.pwdType = reader.int32();
            break;
          }
          case 3: {
            message.authority = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Call message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.Call
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.Call} Call
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Call.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Call message.
     * @function verify
     * @memberof geelevel.Call
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Call.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.password != null && message.hasOwnProperty("password")) if (!$util.isString(message.password)) return "password: string expected";
      if (message.pwdType != null && message.hasOwnProperty("pwdType")) if (!$util.isInteger(message.pwdType)) return "pwdType: integer expected";
      if (message.authority != null && message.hasOwnProperty("authority")) if (!$util.isInteger(message.authority)) return "authority: integer expected";
      return null;
    };

    /**
     * Creates a Call message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.Call
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.Call} Call
     */
    Call.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.Call) return object;
      var message = new $root.geelevel.Call();
      if (object.password != null) message.password = String(object.password);
      if (object.pwdType != null) message.pwdType = object.pwdType | 0;
      if (object.authority != null) message.authority = object.authority >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a Call message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.Call
     * @static
     * @param {geelevel.Call} message Call
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Call.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.password = "";
        object.pwdType = 0;
        object.authority = 0;
      }
      if (message.password != null && message.hasOwnProperty("password")) object.password = message.password;
      if (message.pwdType != null && message.hasOwnProperty("pwdType")) object.pwdType = message.pwdType;
      if (message.authority != null && message.hasOwnProperty("authority")) object.authority = message.authority;
      return object;
    };

    /**
     * Converts this Call to JSON.
     * @function toJSON
     * @memberof geelevel.Call
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Call.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Call
     * @function getTypeUrl
     * @memberof geelevel.Call
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Call.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.Call";
    };

    return Call;
  })();

  geelevel.CallAck = (function () {
    /**
     * Properties of a CallAck.
     * @memberof geelevel
     * @interface ICallAck
     * @property {number|null} [action] CallAck action
     * @property {number|null} [authority] CallAck authority
     * @property {number|null} [reason] CallAck reason
     */

    /**
     * Constructs a new CallAck.
     * @memberof geelevel
     * @classdesc Represents a CallAck.
     * @implements ICallAck
     * @constructor
     * @param {geelevel.ICallAck=} [properties] Properties to set
     */
    function CallAck(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallAck action.
     * @member {number|null|undefined} action
     * @memberof geelevel.CallAck
     * @instance
     */
    CallAck.prototype.action = null;

    /**
     * CallAck authority.
     * @member {number|null|undefined} authority
     * @memberof geelevel.CallAck
     * @instance
     */
    CallAck.prototype.authority = null;

    /**
     * CallAck reason.
     * @member {number|null|undefined} reason
     * @memberof geelevel.CallAck
     * @instance
     */
    CallAck.prototype.reason = null;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * CallAck ActionData.
     * @member {"authority"|"reason"|undefined} ActionData
     * @memberof geelevel.CallAck
     * @instance
     */
    Object.defineProperty(CallAck.prototype, "ActionData", {
      get: $util.oneOfGetter(($oneOfFields = ["authority", "reason"])),
      set: $util.oneOfSetter($oneOfFields),
    });

    /**
     * Creates a new CallAck instance using the specified properties.
     * @function create
     * @memberof geelevel.CallAck
     * @static
     * @param {geelevel.ICallAck=} [properties] Properties to set
     * @returns {geelevel.CallAck} CallAck instance
     */
    CallAck.create = function create(properties) {
      return new CallAck(properties);
    };

    /**
     * Encodes the specified CallAck message. Does not implicitly {@link geelevel.CallAck.verify|verify} messages.
     * @function encode
     * @memberof geelevel.CallAck
     * @static
     * @param {geelevel.ICallAck} message CallAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallAck.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.action != null && Object.hasOwnProperty.call(message, "action")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.action);
      if (message.authority != null && Object.hasOwnProperty.call(message, "authority")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.authority);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.reason);
      return writer;
    };

    /**
     * Encodes the specified CallAck message, length delimited. Does not implicitly {@link geelevel.CallAck.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.CallAck
     * @static
     * @param {geelevel.ICallAck} message CallAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallAck.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CallAck message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.CallAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.CallAck} CallAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallAck.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.CallAck();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.action = reader.uint32();
            break;
          }
          case 2: {
            message.authority = reader.uint32();
            break;
          }
          case 3: {
            message.reason = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CallAck message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.CallAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.CallAck} CallAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallAck.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CallAck message.
     * @function verify
     * @memberof geelevel.CallAck
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CallAck.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      var properties = {};
      if (message.action != null && message.hasOwnProperty("action")) if (!$util.isInteger(message.action)) return "action: integer expected";
      if (message.authority != null && message.hasOwnProperty("authority")) {
        properties.ActionData = 1;
        if (!$util.isInteger(message.authority)) return "authority: integer expected";
      }
      if (message.reason != null && message.hasOwnProperty("reason")) {
        if (properties.ActionData === 1) return "ActionData: multiple values";
        properties.ActionData = 1;
        if (!$util.isInteger(message.reason)) return "reason: integer expected";
      }
      return null;
    };

    /**
     * Creates a CallAck message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.CallAck
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.CallAck} CallAck
     */
    CallAck.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.CallAck) return object;
      var message = new $root.geelevel.CallAck();
      if (object.action != null) message.action = object.action >>> 0;
      if (object.authority != null) message.authority = object.authority >>> 0;
      if (object.reason != null) message.reason = object.reason >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a CallAck message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.CallAck
     * @static
     * @param {geelevel.CallAck} message CallAck
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CallAck.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.action = 0;
      if (message.action != null && message.hasOwnProperty("action")) object.action = message.action;
      if (message.authority != null && message.hasOwnProperty("authority")) {
        object.authority = message.authority;
        if (options.oneofs) object.ActionData = "authority";
      }
      if (message.reason != null && message.hasOwnProperty("reason")) {
        object.reason = message.reason;
        if (options.oneofs) object.ActionData = "reason";
      }
      return object;
    };

    /**
     * Converts this CallAck to JSON.
     * @function toJSON
     * @memberof geelevel.CallAck
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CallAck.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CallAck
     * @function getTypeUrl
     * @memberof geelevel.CallAck
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CallAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.CallAck";
    };

    return CallAck;
  })();

  geelevel.EndCall = (function () {
    /**
     * Properties of an EndCall.
     * @memberof geelevel
     * @interface IEndCall
     * @property {number|null} [action] EndCall action
     * @property {number|null} [reason] EndCall reason
     */

    /**
     * Constructs a new EndCall.
     * @memberof geelevel
     * @classdesc Represents an EndCall.
     * @implements IEndCall
     * @constructor
     * @param {geelevel.IEndCall=} [properties] Properties to set
     */
    function EndCall(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EndCall action.
     * @member {number|null|undefined} action
     * @memberof geelevel.EndCall
     * @instance
     */
    EndCall.prototype.action = null;

    /**
     * EndCall reason.
     * @member {number|null|undefined} reason
     * @memberof geelevel.EndCall
     * @instance
     */
    EndCall.prototype.reason = null;

    /**
     * Creates a new EndCall instance using the specified properties.
     * @function create
     * @memberof geelevel.EndCall
     * @static
     * @param {geelevel.IEndCall=} [properties] Properties to set
     * @returns {geelevel.EndCall} EndCall instance
     */
    EndCall.create = function create(properties) {
      return new EndCall(properties);
    };

    /**
     * Encodes the specified EndCall message. Does not implicitly {@link geelevel.EndCall.verify|verify} messages.
     * @function encode
     * @memberof geelevel.EndCall
     * @static
     * @param {geelevel.IEndCall} message EndCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EndCall.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.action != null && Object.hasOwnProperty.call(message, "action")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.action);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.reason);
      return writer;
    };

    /**
     * Encodes the specified EndCall message, length delimited. Does not implicitly {@link geelevel.EndCall.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.EndCall
     * @static
     * @param {geelevel.IEndCall} message EndCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EndCall.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EndCall message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.EndCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.EndCall} EndCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EndCall.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.EndCall();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.action = reader.uint32();
            break;
          }
          case 2: {
            message.reason = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EndCall message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.EndCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.EndCall} EndCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EndCall.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EndCall message.
     * @function verify
     * @memberof geelevel.EndCall
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EndCall.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.action != null && message.hasOwnProperty("action")) if (!$util.isInteger(message.action)) return "action: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason")) if (!$util.isInteger(message.reason)) return "reason: integer expected";
      return null;
    };

    /**
     * Creates an EndCall message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.EndCall
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.EndCall} EndCall
     */
    EndCall.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.EndCall) return object;
      var message = new $root.geelevel.EndCall();
      if (object.action != null) message.action = object.action >>> 0;
      if (object.reason != null) message.reason = object.reason >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an EndCall message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.EndCall
     * @static
     * @param {geelevel.EndCall} message EndCall
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EndCall.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.action = 0;
        object.reason = 0;
      }
      if (message.action != null && message.hasOwnProperty("action")) object.action = message.action;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      return object;
    };

    /**
     * Converts this EndCall to JSON.
     * @function toJSON
     * @memberof geelevel.EndCall
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EndCall.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EndCall
     * @function getTypeUrl
     * @memberof geelevel.EndCall
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EndCall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.EndCall";
    };

    return EndCall;
  })();

  geelevel.SetConnChannel = (function () {
    /**
     * Properties of a SetConnChannel.
     * @memberof geelevel
     * @interface ISetConnChannel
     * @property {number|null} [value] SetConnChannel value
     */

    /**
     * Constructs a new SetConnChannel.
     * @memberof geelevel
     * @classdesc Represents a SetConnChannel.
     * @implements ISetConnChannel
     * @constructor
     * @param {geelevel.ISetConnChannel=} [properties] Properties to set
     */
    function SetConnChannel(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetConnChannel value.
     * @member {number|null|undefined} value
     * @memberof geelevel.SetConnChannel
     * @instance
     */
    SetConnChannel.prototype.value = null;

    /**
     * Creates a new SetConnChannel instance using the specified properties.
     * @function create
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {geelevel.ISetConnChannel=} [properties] Properties to set
     * @returns {geelevel.SetConnChannel} SetConnChannel instance
     */
    SetConnChannel.create = function create(properties) {
      return new SetConnChannel(properties);
    };

    /**
     * Encodes the specified SetConnChannel message. Does not implicitly {@link geelevel.SetConnChannel.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {geelevel.ISetConnChannel} message SetConnChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetConnChannel.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.value != null && Object.hasOwnProperty.call(message, "value")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.value);
      return writer;
    };

    /**
     * Encodes the specified SetConnChannel message, length delimited. Does not implicitly {@link geelevel.SetConnChannel.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {geelevel.ISetConnChannel} message SetConnChannel message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetConnChannel.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetConnChannel message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetConnChannel} SetConnChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetConnChannel.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetConnChannel();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.value = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetConnChannel message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetConnChannel} SetConnChannel
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetConnChannel.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetConnChannel message.
     * @function verify
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetConnChannel.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.value != null && message.hasOwnProperty("value")) if (!$util.isInteger(message.value)) return "value: integer expected";
      return null;
    };

    /**
     * Creates a SetConnChannel message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetConnChannel} SetConnChannel
     */
    SetConnChannel.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetConnChannel) return object;
      var message = new $root.geelevel.SetConnChannel();
      if (object.value != null) message.value = object.value >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetConnChannel message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {geelevel.SetConnChannel} message SetConnChannel
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetConnChannel.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.value = 0;
      if (message.value != null && message.hasOwnProperty("value")) object.value = message.value;
      return object;
    };

    /**
     * Converts this SetConnChannel to JSON.
     * @function toJSON
     * @memberof geelevel.SetConnChannel
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetConnChannel.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetConnChannel
     * @function getTypeUrl
     * @memberof geelevel.SetConnChannel
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetConnChannel.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetConnChannel";
    };

    return SetConnChannel;
  })();

  geelevel.StartScreenCapture = (function () {
    /**
     * Properties of a StartScreenCapture.
     * @memberof geelevel
     * @interface IStartScreenCapture
     * @property {number|null} [index] StartScreenCapture index
     */

    /**
     * Constructs a new StartScreenCapture.
     * @memberof geelevel
     * @classdesc Represents a StartScreenCapture.
     * @implements IStartScreenCapture
     * @constructor
     * @param {geelevel.IStartScreenCapture=} [properties] Properties to set
     */
    function StartScreenCapture(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StartScreenCapture index.
     * @member {number|null|undefined} index
     * @memberof geelevel.StartScreenCapture
     * @instance
     */
    StartScreenCapture.prototype.index = null;

    /**
     * Creates a new StartScreenCapture instance using the specified properties.
     * @function create
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {geelevel.IStartScreenCapture=} [properties] Properties to set
     * @returns {geelevel.StartScreenCapture} StartScreenCapture instance
     */
    StartScreenCapture.create = function create(properties) {
      return new StartScreenCapture(properties);
    };

    /**
     * Encodes the specified StartScreenCapture message. Does not implicitly {@link geelevel.StartScreenCapture.verify|verify} messages.
     * @function encode
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {geelevel.IStartScreenCapture} message StartScreenCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartScreenCapture.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified StartScreenCapture message, length delimited. Does not implicitly {@link geelevel.StartScreenCapture.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {geelevel.IStartScreenCapture} message StartScreenCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartScreenCapture.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StartScreenCapture message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.StartScreenCapture} StartScreenCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartScreenCapture.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.StartScreenCapture();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StartScreenCapture message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.StartScreenCapture} StartScreenCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartScreenCapture.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StartScreenCapture message.
     * @function verify
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StartScreenCapture.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a StartScreenCapture message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.StartScreenCapture} StartScreenCapture
     */
    StartScreenCapture.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.StartScreenCapture) return object;
      var message = new $root.geelevel.StartScreenCapture();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a StartScreenCapture message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {geelevel.StartScreenCapture} message StartScreenCapture
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StartScreenCapture.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this StartScreenCapture to JSON.
     * @function toJSON
     * @memberof geelevel.StartScreenCapture
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StartScreenCapture.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StartScreenCapture
     * @function getTypeUrl
     * @memberof geelevel.StartScreenCapture
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StartScreenCapture.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.StartScreenCapture";
    };

    return StartScreenCapture;
  })();

  geelevel.StopScreenCapture = (function () {
    /**
     * Properties of a StopScreenCapture.
     * @memberof geelevel
     * @interface IStopScreenCapture
     * @property {number|null} [index] StopScreenCapture index
     */

    /**
     * Constructs a new StopScreenCapture.
     * @memberof geelevel
     * @classdesc Represents a StopScreenCapture.
     * @implements IStopScreenCapture
     * @constructor
     * @param {geelevel.IStopScreenCapture=} [properties] Properties to set
     */
    function StopScreenCapture(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StopScreenCapture index.
     * @member {number|null|undefined} index
     * @memberof geelevel.StopScreenCapture
     * @instance
     */
    StopScreenCapture.prototype.index = null;

    /**
     * Creates a new StopScreenCapture instance using the specified properties.
     * @function create
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {geelevel.IStopScreenCapture=} [properties] Properties to set
     * @returns {geelevel.StopScreenCapture} StopScreenCapture instance
     */
    StopScreenCapture.create = function create(properties) {
      return new StopScreenCapture(properties);
    };

    /**
     * Encodes the specified StopScreenCapture message. Does not implicitly {@link geelevel.StopScreenCapture.verify|verify} messages.
     * @function encode
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {geelevel.IStopScreenCapture} message StopScreenCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopScreenCapture.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified StopScreenCapture message, length delimited. Does not implicitly {@link geelevel.StopScreenCapture.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {geelevel.IStopScreenCapture} message StopScreenCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopScreenCapture.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StopScreenCapture message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.StopScreenCapture} StopScreenCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopScreenCapture.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.StopScreenCapture();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StopScreenCapture message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.StopScreenCapture} StopScreenCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopScreenCapture.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StopScreenCapture message.
     * @function verify
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StopScreenCapture.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a StopScreenCapture message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.StopScreenCapture} StopScreenCapture
     */
    StopScreenCapture.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.StopScreenCapture) return object;
      var message = new $root.geelevel.StopScreenCapture();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a StopScreenCapture message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {geelevel.StopScreenCapture} message StopScreenCapture
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StopScreenCapture.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this StopScreenCapture to JSON.
     * @function toJSON
     * @memberof geelevel.StopScreenCapture
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StopScreenCapture.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StopScreenCapture
     * @function getTypeUrl
     * @memberof geelevel.StopScreenCapture
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StopScreenCapture.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.StopScreenCapture";
    };

    return StopScreenCapture;
  })();

  geelevel.StartCameraCapture = (function () {
    /**
     * Properties of a StartCameraCapture.
     * @memberof geelevel
     * @interface IStartCameraCapture
     * @property {number|null} [index] StartCameraCapture index
     */

    /**
     * Constructs a new StartCameraCapture.
     * @memberof geelevel
     * @classdesc Represents a StartCameraCapture.
     * @implements IStartCameraCapture
     * @constructor
     * @param {geelevel.IStartCameraCapture=} [properties] Properties to set
     */
    function StartCameraCapture(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StartCameraCapture index.
     * @member {number|null|undefined} index
     * @memberof geelevel.StartCameraCapture
     * @instance
     */
    StartCameraCapture.prototype.index = null;

    /**
     * Creates a new StartCameraCapture instance using the specified properties.
     * @function create
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {geelevel.IStartCameraCapture=} [properties] Properties to set
     * @returns {geelevel.StartCameraCapture} StartCameraCapture instance
     */
    StartCameraCapture.create = function create(properties) {
      return new StartCameraCapture(properties);
    };

    /**
     * Encodes the specified StartCameraCapture message. Does not implicitly {@link geelevel.StartCameraCapture.verify|verify} messages.
     * @function encode
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {geelevel.IStartCameraCapture} message StartCameraCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartCameraCapture.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified StartCameraCapture message, length delimited. Does not implicitly {@link geelevel.StartCameraCapture.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {geelevel.IStartCameraCapture} message StartCameraCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StartCameraCapture.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StartCameraCapture message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.StartCameraCapture} StartCameraCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartCameraCapture.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.StartCameraCapture();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StartCameraCapture message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.StartCameraCapture} StartCameraCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StartCameraCapture.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StartCameraCapture message.
     * @function verify
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StartCameraCapture.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a StartCameraCapture message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.StartCameraCapture} StartCameraCapture
     */
    StartCameraCapture.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.StartCameraCapture) return object;
      var message = new $root.geelevel.StartCameraCapture();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a StartCameraCapture message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {geelevel.StartCameraCapture} message StartCameraCapture
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StartCameraCapture.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this StartCameraCapture to JSON.
     * @function toJSON
     * @memberof geelevel.StartCameraCapture
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StartCameraCapture.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StartCameraCapture
     * @function getTypeUrl
     * @memberof geelevel.StartCameraCapture
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StartCameraCapture.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.StartCameraCapture";
    };

    return StartCameraCapture;
  })();

  geelevel.StopCameraCapture = (function () {
    /**
     * Properties of a StopCameraCapture.
     * @memberof geelevel
     * @interface IStopCameraCapture
     * @property {number|null} [index] StopCameraCapture index
     */

    /**
     * Constructs a new StopCameraCapture.
     * @memberof geelevel
     * @classdesc Represents a StopCameraCapture.
     * @implements IStopCameraCapture
     * @constructor
     * @param {geelevel.IStopCameraCapture=} [properties] Properties to set
     */
    function StopCameraCapture(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StopCameraCapture index.
     * @member {number|null|undefined} index
     * @memberof geelevel.StopCameraCapture
     * @instance
     */
    StopCameraCapture.prototype.index = null;

    /**
     * Creates a new StopCameraCapture instance using the specified properties.
     * @function create
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {geelevel.IStopCameraCapture=} [properties] Properties to set
     * @returns {geelevel.StopCameraCapture} StopCameraCapture instance
     */
    StopCameraCapture.create = function create(properties) {
      return new StopCameraCapture(properties);
    };

    /**
     * Encodes the specified StopCameraCapture message. Does not implicitly {@link geelevel.StopCameraCapture.verify|verify} messages.
     * @function encode
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {geelevel.IStopCameraCapture} message StopCameraCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopCameraCapture.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified StopCameraCapture message, length delimited. Does not implicitly {@link geelevel.StopCameraCapture.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {geelevel.IStopCameraCapture} message StopCameraCapture message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopCameraCapture.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StopCameraCapture message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.StopCameraCapture} StopCameraCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopCameraCapture.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.StopCameraCapture();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StopCameraCapture message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.StopCameraCapture} StopCameraCapture
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopCameraCapture.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StopCameraCapture message.
     * @function verify
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StopCameraCapture.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a StopCameraCapture message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.StopCameraCapture} StopCameraCapture
     */
    StopCameraCapture.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.StopCameraCapture) return object;
      var message = new $root.geelevel.StopCameraCapture();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a StopCameraCapture message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {geelevel.StopCameraCapture} message StopCameraCapture
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StopCameraCapture.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this StopCameraCapture to JSON.
     * @function toJSON
     * @memberof geelevel.StopCameraCapture
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StopCameraCapture.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StopCameraCapture
     * @function getTypeUrl
     * @memberof geelevel.StopCameraCapture
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StopCameraCapture.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.StopCameraCapture";
    };

    return StopCameraCapture;
  })();

  geelevel.SetVideoEncoder = (function () {
    /**
     * Properties of a SetVideoEncoder.
     * @memberof geelevel
     * @interface ISetVideoEncoder
     * @property {number|null} [value] SetVideoEncoder value
     * @property {number|null} [index] SetVideoEncoder index
     */

    /**
     * Constructs a new SetVideoEncoder.
     * @memberof geelevel
     * @classdesc Represents a SetVideoEncoder.
     * @implements ISetVideoEncoder
     * @constructor
     * @param {geelevel.ISetVideoEncoder=} [properties] Properties to set
     */
    function SetVideoEncoder(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVideoEncoder value.
     * @member {number|null|undefined} value
     * @memberof geelevel.SetVideoEncoder
     * @instance
     */
    SetVideoEncoder.prototype.value = null;

    /**
     * SetVideoEncoder index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetVideoEncoder
     * @instance
     */
    SetVideoEncoder.prototype.index = null;

    /**
     * Creates a new SetVideoEncoder instance using the specified properties.
     * @function create
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {geelevel.ISetVideoEncoder=} [properties] Properties to set
     * @returns {geelevel.SetVideoEncoder} SetVideoEncoder instance
     */
    SetVideoEncoder.create = function create(properties) {
      return new SetVideoEncoder(properties);
    };

    /**
     * Encodes the specified SetVideoEncoder message. Does not implicitly {@link geelevel.SetVideoEncoder.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {geelevel.ISetVideoEncoder} message SetVideoEncoder message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoEncoder.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.value != null && Object.hasOwnProperty.call(message, "value")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.value);
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetVideoEncoder message, length delimited. Does not implicitly {@link geelevel.SetVideoEncoder.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {geelevel.ISetVideoEncoder} message SetVideoEncoder message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoEncoder.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVideoEncoder message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetVideoEncoder} SetVideoEncoder
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoEncoder.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetVideoEncoder();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.value = reader.uint32();
            break;
          }
          case 2: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVideoEncoder message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetVideoEncoder} SetVideoEncoder
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoEncoder.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVideoEncoder message.
     * @function verify
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVideoEncoder.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.value != null && message.hasOwnProperty("value")) if (!$util.isInteger(message.value)) return "value: integer expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetVideoEncoder message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetVideoEncoder} SetVideoEncoder
     */
    SetVideoEncoder.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetVideoEncoder) return object;
      var message = new $root.geelevel.SetVideoEncoder();
      if (object.value != null) message.value = object.value >>> 0;
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetVideoEncoder message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {geelevel.SetVideoEncoder} message SetVideoEncoder
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVideoEncoder.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.value = 0;
        object.index = 0;
      }
      if (message.value != null && message.hasOwnProperty("value")) object.value = message.value;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetVideoEncoder to JSON.
     * @function toJSON
     * @memberof geelevel.SetVideoEncoder
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVideoEncoder.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVideoEncoder
     * @function getTypeUrl
     * @memberof geelevel.SetVideoEncoder
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVideoEncoder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetVideoEncoder";
    };

    return SetVideoEncoder;
  })();

  geelevel.SetResolution = (function () {
    /**
     * Properties of a SetResolution.
     * @memberof geelevel
     * @interface ISetResolution
     * @property {number|null} [index] SetResolution index
     * @property {number|null} [width] SetResolution width
     * @property {number|null} [height] SetResolution height
     * @property {number|null} [x] SetResolution x
     * @property {number|null} [y] SetResolution y
     * @property {number|null} [multiple] SetResolution multiple
     * @property {number|null} [scale] SetResolution scale
     */

    /**
     * Constructs a new SetResolution.
     * @memberof geelevel
     * @classdesc Represents a SetResolution.
     * @implements ISetResolution
     * @constructor
     * @param {geelevel.ISetResolution=} [properties] Properties to set
     */
    function SetResolution(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetResolution index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.index = null;

    /**
     * SetResolution width.
     * @member {number|null|undefined} width
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.width = null;

    /**
     * SetResolution height.
     * @member {number|null|undefined} height
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.height = null;

    /**
     * SetResolution x.
     * @member {number|null|undefined} x
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.x = null;

    /**
     * SetResolution y.
     * @member {number|null|undefined} y
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.y = null;

    /**
     * SetResolution multiple.
     * @member {number|null|undefined} multiple
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.multiple = null;

    /**
     * SetResolution scale.
     * @member {number|null|undefined} scale
     * @memberof geelevel.SetResolution
     * @instance
     */
    SetResolution.prototype.scale = null;

    /**
     * Creates a new SetResolution instance using the specified properties.
     * @function create
     * @memberof geelevel.SetResolution
     * @static
     * @param {geelevel.ISetResolution=} [properties] Properties to set
     * @returns {geelevel.SetResolution} SetResolution instance
     */
    SetResolution.create = function create(properties) {
      return new SetResolution(properties);
    };

    /**
     * Encodes the specified SetResolution message. Does not implicitly {@link geelevel.SetResolution.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetResolution
     * @static
     * @param {geelevel.ISetResolution} message SetResolution message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetResolution.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      if (message.width != null && Object.hasOwnProperty.call(message, "width")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.width);
      if (message.height != null && Object.hasOwnProperty.call(message, "height")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.height);
      if (message.x != null && Object.hasOwnProperty.call(message, "x")) writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.x);
      if (message.y != null && Object.hasOwnProperty.call(message, "y")) writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.y);
      if (message.multiple != null && Object.hasOwnProperty.call(message, "multiple")) writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.multiple);
      if (message.scale != null && Object.hasOwnProperty.call(message, "scale")) writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.scale);
      return writer;
    };

    /**
     * Encodes the specified SetResolution message, length delimited. Does not implicitly {@link geelevel.SetResolution.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetResolution
     * @static
     * @param {geelevel.ISetResolution} message SetResolution message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetResolution.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetResolution message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetResolution
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetResolution} SetResolution
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetResolution.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetResolution();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          case 2: {
            message.width = reader.uint32();
            break;
          }
          case 3: {
            message.height = reader.uint32();
            break;
          }
          case 4: {
            message.x = reader.int32();
            break;
          }
          case 5: {
            message.y = reader.int32();
            break;
          }
          case 6: {
            message.multiple = reader.int32();
            break;
          }
          case 7: {
            message.scale = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetResolution message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetResolution
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetResolution} SetResolution
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetResolution.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetResolution message.
     * @function verify
     * @memberof geelevel.SetResolution
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetResolution.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      if (message.width != null && message.hasOwnProperty("width")) if (!$util.isInteger(message.width)) return "width: integer expected";
      if (message.height != null && message.hasOwnProperty("height")) if (!$util.isInteger(message.height)) return "height: integer expected";
      if (message.x != null && message.hasOwnProperty("x")) if (!$util.isInteger(message.x)) return "x: integer expected";
      if (message.y != null && message.hasOwnProperty("y")) if (!$util.isInteger(message.y)) return "y: integer expected";
      if (message.multiple != null && message.hasOwnProperty("multiple")) if (!$util.isInteger(message.multiple)) return "multiple: integer expected";
      if (message.scale != null && message.hasOwnProperty("scale")) if (!$util.isInteger(message.scale)) return "scale: integer expected";
      return null;
    };

    /**
     * Creates a SetResolution message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetResolution
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetResolution} SetResolution
     */
    SetResolution.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetResolution) return object;
      var message = new $root.geelevel.SetResolution();
      if (object.index != null) message.index = object.index >>> 0;
      if (object.width != null) message.width = object.width >>> 0;
      if (object.height != null) message.height = object.height >>> 0;
      if (object.x != null) message.x = object.x | 0;
      if (object.y != null) message.y = object.y | 0;
      if (object.multiple != null) message.multiple = object.multiple | 0;
      if (object.scale != null) message.scale = object.scale | 0;
      return message;
    };

    /**
     * Creates a plain object from a SetResolution message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetResolution
     * @static
     * @param {geelevel.SetResolution} message SetResolution
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetResolution.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.index = 0;
        object.width = 0;
        object.height = 0;
        object.x = 0;
        object.y = 0;
        object.multiple = 0;
        object.scale = 0;
      }
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      if (message.width != null && message.hasOwnProperty("width")) object.width = message.width;
      if (message.height != null && message.hasOwnProperty("height")) object.height = message.height;
      if (message.x != null && message.hasOwnProperty("x")) object.x = message.x;
      if (message.y != null && message.hasOwnProperty("y")) object.y = message.y;
      if (message.multiple != null && message.hasOwnProperty("multiple")) object.multiple = message.multiple;
      if (message.scale != null && message.hasOwnProperty("scale")) object.scale = message.scale;
      return object;
    };

    /**
     * Converts this SetResolution to JSON.
     * @function toJSON
     * @memberof geelevel.SetResolution
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetResolution.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetResolution
     * @function getTypeUrl
     * @memberof geelevel.SetResolution
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetResolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetResolution";
    };

    return SetResolution;
  })();

  geelevel.RequestKeyFrame = (function () {
    /**
     * Properties of a RequestKeyFrame.
     * @memberof geelevel
     * @interface IRequestKeyFrame
     * @property {number|null} [index] RequestKeyFrame index
     */

    /**
     * Constructs a new RequestKeyFrame.
     * @memberof geelevel
     * @classdesc Represents a RequestKeyFrame.
     * @implements IRequestKeyFrame
     * @constructor
     * @param {geelevel.IRequestKeyFrame=} [properties] Properties to set
     */
    function RequestKeyFrame(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestKeyFrame index.
     * @member {number|null|undefined} index
     * @memberof geelevel.RequestKeyFrame
     * @instance
     */
    RequestKeyFrame.prototype.index = null;

    /**
     * Creates a new RequestKeyFrame instance using the specified properties.
     * @function create
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {geelevel.IRequestKeyFrame=} [properties] Properties to set
     * @returns {geelevel.RequestKeyFrame} RequestKeyFrame instance
     */
    RequestKeyFrame.create = function create(properties) {
      return new RequestKeyFrame(properties);
    };

    /**
     * Encodes the specified RequestKeyFrame message. Does not implicitly {@link geelevel.RequestKeyFrame.verify|verify} messages.
     * @function encode
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {geelevel.IRequestKeyFrame} message RequestKeyFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestKeyFrame.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified RequestKeyFrame message, length delimited. Does not implicitly {@link geelevel.RequestKeyFrame.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {geelevel.IRequestKeyFrame} message RequestKeyFrame message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestKeyFrame.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestKeyFrame message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.RequestKeyFrame} RequestKeyFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestKeyFrame.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.RequestKeyFrame();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestKeyFrame message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.RequestKeyFrame} RequestKeyFrame
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestKeyFrame.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestKeyFrame message.
     * @function verify
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestKeyFrame.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a RequestKeyFrame message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.RequestKeyFrame} RequestKeyFrame
     */
    RequestKeyFrame.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.RequestKeyFrame) return object;
      var message = new $root.geelevel.RequestKeyFrame();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestKeyFrame message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {geelevel.RequestKeyFrame} message RequestKeyFrame
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestKeyFrame.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this RequestKeyFrame to JSON.
     * @function toJSON
     * @memberof geelevel.RequestKeyFrame
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestKeyFrame.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestKeyFrame
     * @function getTypeUrl
     * @memberof geelevel.RequestKeyFrame
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestKeyFrame.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.RequestKeyFrame";
    };

    return RequestKeyFrame;
  })();

  geelevel.SetVideoBitrate = (function () {
    /**
     * Properties of a SetVideoBitrate.
     * @memberof geelevel
     * @interface ISetVideoBitrate
     * @property {geelevel.SetVideoBitrate.BitrateType|null} [value] SetVideoBitrate value
     * @property {number|null} [index] SetVideoBitrate index
     */

    /**
     * Constructs a new SetVideoBitrate.
     * @memberof geelevel
     * @classdesc Represents a SetVideoBitrate.
     * @implements ISetVideoBitrate
     * @constructor
     * @param {geelevel.ISetVideoBitrate=} [properties] Properties to set
     */
    function SetVideoBitrate(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVideoBitrate value.
     * @member {geelevel.SetVideoBitrate.BitrateType|null|undefined} value
     * @memberof geelevel.SetVideoBitrate
     * @instance
     */
    SetVideoBitrate.prototype.value = null;

    /**
     * SetVideoBitrate index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetVideoBitrate
     * @instance
     */
    SetVideoBitrate.prototype.index = null;

    /**
     * Creates a new SetVideoBitrate instance using the specified properties.
     * @function create
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {geelevel.ISetVideoBitrate=} [properties] Properties to set
     * @returns {geelevel.SetVideoBitrate} SetVideoBitrate instance
     */
    SetVideoBitrate.create = function create(properties) {
      return new SetVideoBitrate(properties);
    };

    /**
     * Encodes the specified SetVideoBitrate message. Does not implicitly {@link geelevel.SetVideoBitrate.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {geelevel.ISetVideoBitrate} message SetVideoBitrate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoBitrate.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.value != null && Object.hasOwnProperty.call(message, "value")) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.value);
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetVideoBitrate message, length delimited. Does not implicitly {@link geelevel.SetVideoBitrate.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {geelevel.ISetVideoBitrate} message SetVideoBitrate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoBitrate.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVideoBitrate message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetVideoBitrate} SetVideoBitrate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoBitrate.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetVideoBitrate();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.value = reader.int32();
            break;
          }
          case 2: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVideoBitrate message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetVideoBitrate} SetVideoBitrate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoBitrate.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVideoBitrate message.
     * @function verify
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVideoBitrate.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.value != null && message.hasOwnProperty("value"))
        switch (message.value) {
          default:
            return "value: enum value expected";
          case 0:
          case 1:
          case 2:
            break;
        }
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetVideoBitrate message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetVideoBitrate} SetVideoBitrate
     */
    SetVideoBitrate.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetVideoBitrate) return object;
      var message = new $root.geelevel.SetVideoBitrate();
      switch (object.value) {
        default:
          if (typeof object.value === "number") {
            message.value = object.value;
            break;
          }
          break;
        case "AUTO":
        case 0:
          message.value = 0;
          break;
        case "SPEED":
        case 1:
          message.value = 1;
          break;
        case "QUALITY":
        case 2:
          message.value = 2;
          break;
      }
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetVideoBitrate message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {geelevel.SetVideoBitrate} message SetVideoBitrate
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVideoBitrate.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.value = options.enums === String ? "AUTO" : 0;
        object.index = 0;
      }
      if (message.value != null && message.hasOwnProperty("value"))
        object.value =
          options.enums === String
            ? $root.geelevel.SetVideoBitrate.BitrateType[message.value] === undefined
              ? message.value
              : $root.geelevel.SetVideoBitrate.BitrateType[message.value]
            : message.value;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetVideoBitrate to JSON.
     * @function toJSON
     * @memberof geelevel.SetVideoBitrate
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVideoBitrate.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVideoBitrate
     * @function getTypeUrl
     * @memberof geelevel.SetVideoBitrate
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVideoBitrate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetVideoBitrate";
    };

    /**
     * BitrateType enum.
     * @name geelevel.SetVideoBitrate.BitrateType
     * @enum {number}
     * @property {number} AUTO=0 AUTO value
     * @property {number} SPEED=1 SPEED value
     * @property {number} QUALITY=2 QUALITY value
     */
    SetVideoBitrate.BitrateType = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "AUTO")] = 0;
      values[(valuesById[1] = "SPEED")] = 1;
      values[(valuesById[2] = "QUALITY")] = 2;
      return values;
    })();

    return SetVideoBitrate;
  })();

  geelevel.SetVideoContent = (function () {
    /**
     * Properties of a SetVideoContent.
     * @memberof geelevel
     * @interface ISetVideoContent
     * @property {geelevel.SetVideoContent.ContentHint|null} [value] SetVideoContent value
     * @property {number|null} [index] SetVideoContent index
     */

    /**
     * Constructs a new SetVideoContent.
     * @memberof geelevel
     * @classdesc Represents a SetVideoContent.
     * @implements ISetVideoContent
     * @constructor
     * @param {geelevel.ISetVideoContent=} [properties] Properties to set
     */
    function SetVideoContent(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVideoContent value.
     * @member {geelevel.SetVideoContent.ContentHint|null|undefined} value
     * @memberof geelevel.SetVideoContent
     * @instance
     */
    SetVideoContent.prototype.value = null;

    /**
     * SetVideoContent index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetVideoContent
     * @instance
     */
    SetVideoContent.prototype.index = null;

    /**
     * Creates a new SetVideoContent instance using the specified properties.
     * @function create
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {geelevel.ISetVideoContent=} [properties] Properties to set
     * @returns {geelevel.SetVideoContent} SetVideoContent instance
     */
    SetVideoContent.create = function create(properties) {
      return new SetVideoContent(properties);
    };

    /**
     * Encodes the specified SetVideoContent message. Does not implicitly {@link geelevel.SetVideoContent.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {geelevel.ISetVideoContent} message SetVideoContent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoContent.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.value != null && Object.hasOwnProperty.call(message, "value")) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.value);
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetVideoContent message, length delimited. Does not implicitly {@link geelevel.SetVideoContent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {geelevel.ISetVideoContent} message SetVideoContent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoContent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVideoContent message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetVideoContent} SetVideoContent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoContent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetVideoContent();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.value = reader.int32();
            break;
          }
          case 2: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVideoContent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetVideoContent} SetVideoContent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoContent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVideoContent message.
     * @function verify
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVideoContent.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.value != null && message.hasOwnProperty("value"))
        switch (message.value) {
          default:
            return "value: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
            break;
        }
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetVideoContent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetVideoContent} SetVideoContent
     */
    SetVideoContent.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetVideoContent) return object;
      var message = new $root.geelevel.SetVideoContent();
      switch (object.value) {
        default:
          if (typeof object.value === "number") {
            message.value = object.value;
            break;
          }
          break;
        case "NONE":
        case 0:
          message.value = 0;
          break;
        case "FLUID":
        case 1:
          message.value = 1;
          break;
        case "DETAILED":
        case 2:
          message.value = 2;
          break;
        case "TEXT":
        case 3:
          message.value = 3;
          break;
      }
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetVideoContent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {geelevel.SetVideoContent} message SetVideoContent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVideoContent.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.value = options.enums === String ? "NONE" : 0;
        object.index = 0;
      }
      if (message.value != null && message.hasOwnProperty("value"))
        object.value =
          options.enums === String
            ? $root.geelevel.SetVideoContent.ContentHint[message.value] === undefined
              ? message.value
              : $root.geelevel.SetVideoContent.ContentHint[message.value]
            : message.value;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetVideoContent to JSON.
     * @function toJSON
     * @memberof geelevel.SetVideoContent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVideoContent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVideoContent
     * @function getTypeUrl
     * @memberof geelevel.SetVideoContent
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVideoContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetVideoContent";
    };

    /**
     * ContentHint enum.
     * @name geelevel.SetVideoContent.ContentHint
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} FLUID=1 FLUID value
     * @property {number} DETAILED=2 DETAILED value
     * @property {number} TEXT=3 TEXT value
     */
    SetVideoContent.ContentHint = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "NONE")] = 0;
      values[(valuesById[1] = "FLUID")] = 1;
      values[(valuesById[2] = "DETAILED")] = 2;
      values[(valuesById[3] = "TEXT")] = 3;
      return values;
    })();

    return SetVideoContent;
  })();

  geelevel.SetVideoFps = (function () {
    /**
     * Properties of a SetVideoFps.
     * @memberof geelevel
     * @interface ISetVideoFps
     * @property {number|null} [fps] SetVideoFps fps
     * @property {number|null} [index] SetVideoFps index
     */

    /**
     * Constructs a new SetVideoFps.
     * @memberof geelevel
     * @classdesc Represents a SetVideoFps.
     * @implements ISetVideoFps
     * @constructor
     * @param {geelevel.ISetVideoFps=} [properties] Properties to set
     */
    function SetVideoFps(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVideoFps fps.
     * @member {number|null|undefined} fps
     * @memberof geelevel.SetVideoFps
     * @instance
     */
    SetVideoFps.prototype.fps = null;

    /**
     * SetVideoFps index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetVideoFps
     * @instance
     */
    SetVideoFps.prototype.index = null;

    /**
     * Creates a new SetVideoFps instance using the specified properties.
     * @function create
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {geelevel.ISetVideoFps=} [properties] Properties to set
     * @returns {geelevel.SetVideoFps} SetVideoFps instance
     */
    SetVideoFps.create = function create(properties) {
      return new SetVideoFps(properties);
    };

    /**
     * Encodes the specified SetVideoFps message. Does not implicitly {@link geelevel.SetVideoFps.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {geelevel.ISetVideoFps} message SetVideoFps message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoFps.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.fps != null && Object.hasOwnProperty.call(message, "fps")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.fps);
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetVideoFps message, length delimited. Does not implicitly {@link geelevel.SetVideoFps.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {geelevel.ISetVideoFps} message SetVideoFps message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoFps.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVideoFps message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetVideoFps} SetVideoFps
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoFps.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetVideoFps();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.fps = reader.uint32();
            break;
          }
          case 2: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVideoFps message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetVideoFps} SetVideoFps
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoFps.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVideoFps message.
     * @function verify
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVideoFps.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.fps != null && message.hasOwnProperty("fps")) if (!$util.isInteger(message.fps)) return "fps: integer expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetVideoFps message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetVideoFps} SetVideoFps
     */
    SetVideoFps.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetVideoFps) return object;
      var message = new $root.geelevel.SetVideoFps();
      if (object.fps != null) message.fps = object.fps >>> 0;
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetVideoFps message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {geelevel.SetVideoFps} message SetVideoFps
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVideoFps.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.fps = 0;
        object.index = 0;
      }
      if (message.fps != null && message.hasOwnProperty("fps")) object.fps = message.fps;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetVideoFps to JSON.
     * @function toJSON
     * @memberof geelevel.SetVideoFps
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVideoFps.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVideoFps
     * @function getTypeUrl
     * @memberof geelevel.SetVideoFps
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVideoFps.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetVideoFps";
    };

    return SetVideoFps;
  })();

  geelevel.SetVideoHardwareAccelerated = (function () {
    /**
     * Properties of a SetVideoHardwareAccelerated.
     * @memberof geelevel
     * @interface ISetVideoHardwareAccelerated
     * @property {boolean|null} [enable] SetVideoHardwareAccelerated enable
     */

    /**
     * Constructs a new SetVideoHardwareAccelerated.
     * @memberof geelevel
     * @classdesc Represents a SetVideoHardwareAccelerated.
     * @implements ISetVideoHardwareAccelerated
     * @constructor
     * @param {geelevel.ISetVideoHardwareAccelerated=} [properties] Properties to set
     */
    function SetVideoHardwareAccelerated(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVideoHardwareAccelerated enable.
     * @member {boolean|null|undefined} enable
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @instance
     */
    SetVideoHardwareAccelerated.prototype.enable = null;

    /**
     * Creates a new SetVideoHardwareAccelerated instance using the specified properties.
     * @function create
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {geelevel.ISetVideoHardwareAccelerated=} [properties] Properties to set
     * @returns {geelevel.SetVideoHardwareAccelerated} SetVideoHardwareAccelerated instance
     */
    SetVideoHardwareAccelerated.create = function create(properties) {
      return new SetVideoHardwareAccelerated(properties);
    };

    /**
     * Encodes the specified SetVideoHardwareAccelerated message. Does not implicitly {@link geelevel.SetVideoHardwareAccelerated.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {geelevel.ISetVideoHardwareAccelerated} message SetVideoHardwareAccelerated message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoHardwareAccelerated.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.enable != null && Object.hasOwnProperty.call(message, "enable")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.enable);
      return writer;
    };

    /**
     * Encodes the specified SetVideoHardwareAccelerated message, length delimited. Does not implicitly {@link geelevel.SetVideoHardwareAccelerated.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {geelevel.ISetVideoHardwareAccelerated} message SetVideoHardwareAccelerated message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVideoHardwareAccelerated.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVideoHardwareAccelerated message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetVideoHardwareAccelerated} SetVideoHardwareAccelerated
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoHardwareAccelerated.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetVideoHardwareAccelerated();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.enable = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVideoHardwareAccelerated message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetVideoHardwareAccelerated} SetVideoHardwareAccelerated
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVideoHardwareAccelerated.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVideoHardwareAccelerated message.
     * @function verify
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVideoHardwareAccelerated.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.enable != null && message.hasOwnProperty("enable")) if (typeof message.enable !== "boolean") return "enable: boolean expected";
      return null;
    };

    /**
     * Creates a SetVideoHardwareAccelerated message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetVideoHardwareAccelerated} SetVideoHardwareAccelerated
     */
    SetVideoHardwareAccelerated.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetVideoHardwareAccelerated) return object;
      var message = new $root.geelevel.SetVideoHardwareAccelerated();
      if (object.enable != null) message.enable = Boolean(object.enable);
      return message;
    };

    /**
     * Creates a plain object from a SetVideoHardwareAccelerated message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {geelevel.SetVideoHardwareAccelerated} message SetVideoHardwareAccelerated
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVideoHardwareAccelerated.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.enable = false;
      if (message.enable != null && message.hasOwnProperty("enable")) object.enable = message.enable;
      return object;
    };

    /**
     * Converts this SetVideoHardwareAccelerated to JSON.
     * @function toJSON
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVideoHardwareAccelerated.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVideoHardwareAccelerated
     * @function getTypeUrl
     * @memberof geelevel.SetVideoHardwareAccelerated
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVideoHardwareAccelerated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetVideoHardwareAccelerated";
    };

    return SetVideoHardwareAccelerated;
  })();

  geelevel.EnableAudio = (function () {
    /**
     * Properties of an EnableAudio.
     * @memberof geelevel
     * @interface IEnableAudio
     * @property {boolean|null} [enable] EnableAudio enable
     * @property {boolean|null} [remote] EnableAudio remote
     * @property {boolean|null} [echoCancellation] EnableAudio echoCancellation
     * @property {number|null} [recordDevice] EnableAudio recordDevice
     * @property {number|null} [playerDevice] EnableAudio playerDevice
     */

    /**
     * Constructs a new EnableAudio.
     * @memberof geelevel
     * @classdesc Represents an EnableAudio.
     * @implements IEnableAudio
     * @constructor
     * @param {geelevel.IEnableAudio=} [properties] Properties to set
     */
    function EnableAudio(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnableAudio enable.
     * @member {boolean|null|undefined} enable
     * @memberof geelevel.EnableAudio
     * @instance
     */
    EnableAudio.prototype.enable = null;

    /**
     * EnableAudio remote.
     * @member {boolean|null|undefined} remote
     * @memberof geelevel.EnableAudio
     * @instance
     */
    EnableAudio.prototype.remote = null;

    /**
     * EnableAudio echoCancellation.
     * @member {boolean|null|undefined} echoCancellation
     * @memberof geelevel.EnableAudio
     * @instance
     */
    EnableAudio.prototype.echoCancellation = null;

    /**
     * EnableAudio recordDevice.
     * @member {number|null|undefined} recordDevice
     * @memberof geelevel.EnableAudio
     * @instance
     */
    EnableAudio.prototype.recordDevice = null;

    /**
     * EnableAudio playerDevice.
     * @member {number|null|undefined} playerDevice
     * @memberof geelevel.EnableAudio
     * @instance
     */
    EnableAudio.prototype.playerDevice = null;

    /**
     * Creates a new EnableAudio instance using the specified properties.
     * @function create
     * @memberof geelevel.EnableAudio
     * @static
     * @param {geelevel.IEnableAudio=} [properties] Properties to set
     * @returns {geelevel.EnableAudio} EnableAudio instance
     */
    EnableAudio.create = function create(properties) {
      return new EnableAudio(properties);
    };

    /**
     * Encodes the specified EnableAudio message. Does not implicitly {@link geelevel.EnableAudio.verify|verify} messages.
     * @function encode
     * @memberof geelevel.EnableAudio
     * @static
     * @param {geelevel.IEnableAudio} message EnableAudio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableAudio.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.enable != null && Object.hasOwnProperty.call(message, "enable")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.enable);
      if (message.remote != null && Object.hasOwnProperty.call(message, "remote")) writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.remote);
      if (message.echoCancellation != null && Object.hasOwnProperty.call(message, "echoCancellation")) writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.echoCancellation);
      if (message.recordDevice != null && Object.hasOwnProperty.call(message, "recordDevice")) writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.recordDevice);
      if (message.playerDevice != null && Object.hasOwnProperty.call(message, "playerDevice")) writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.playerDevice);
      return writer;
    };

    /**
     * Encodes the specified EnableAudio message, length delimited. Does not implicitly {@link geelevel.EnableAudio.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.EnableAudio
     * @static
     * @param {geelevel.IEnableAudio} message EnableAudio message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableAudio.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnableAudio message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.EnableAudio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.EnableAudio} EnableAudio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableAudio.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.EnableAudio();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.enable = reader.bool();
            break;
          }
          case 2: {
            message.remote = reader.bool();
            break;
          }
          case 3: {
            message.echoCancellation = reader.bool();
            break;
          }
          case 4: {
            message.recordDevice = reader.int32();
            break;
          }
          case 5: {
            message.playerDevice = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnableAudio message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.EnableAudio
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.EnableAudio} EnableAudio
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableAudio.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnableAudio message.
     * @function verify
     * @memberof geelevel.EnableAudio
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnableAudio.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.enable != null && message.hasOwnProperty("enable")) if (typeof message.enable !== "boolean") return "enable: boolean expected";
      if (message.remote != null && message.hasOwnProperty("remote")) if (typeof message.remote !== "boolean") return "remote: boolean expected";
      if (message.echoCancellation != null && message.hasOwnProperty("echoCancellation")) if (typeof message.echoCancellation !== "boolean") return "echoCancellation: boolean expected";
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) if (!$util.isInteger(message.recordDevice)) return "recordDevice: integer expected";
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) if (!$util.isInteger(message.playerDevice)) return "playerDevice: integer expected";
      return null;
    };

    /**
     * Creates an EnableAudio message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.EnableAudio
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.EnableAudio} EnableAudio
     */
    EnableAudio.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.EnableAudio) return object;
      var message = new $root.geelevel.EnableAudio();
      if (object.enable != null) message.enable = Boolean(object.enable);
      if (object.remote != null) message.remote = Boolean(object.remote);
      if (object.echoCancellation != null) message.echoCancellation = Boolean(object.echoCancellation);
      if (object.recordDevice != null) message.recordDevice = object.recordDevice | 0;
      if (object.playerDevice != null) message.playerDevice = object.playerDevice | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnableAudio message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.EnableAudio
     * @static
     * @param {geelevel.EnableAudio} message EnableAudio
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnableAudio.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.enable = false;
        object.remote = false;
        object.echoCancellation = false;
        object.recordDevice = 0;
        object.playerDevice = 0;
      }
      if (message.enable != null && message.hasOwnProperty("enable")) object.enable = message.enable;
      if (message.remote != null && message.hasOwnProperty("remote")) object.remote = message.remote;
      if (message.echoCancellation != null && message.hasOwnProperty("echoCancellation")) object.echoCancellation = message.echoCancellation;
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) object.recordDevice = message.recordDevice;
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) object.playerDevice = message.playerDevice;
      return object;
    };

    /**
     * Converts this EnableAudio to JSON.
     * @function toJSON
     * @memberof geelevel.EnableAudio
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnableAudio.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnableAudio
     * @function getTypeUrl
     * @memberof geelevel.EnableAudio
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnableAudio.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.EnableAudio";
    };

    return EnableAudio;
  })();

  geelevel.EnableTalk = (function () {
    /**
     * Properties of an EnableTalk.
     * @memberof geelevel
     * @interface IEnableTalk
     * @property {boolean|null} [enable] EnableTalk enable
     * @property {boolean|null} [echoCancellation] EnableTalk echoCancellation
     * @property {number|null} [recordDevice] EnableTalk recordDevice
     * @property {number|null} [playerDevice] EnableTalk playerDevice
     */

    /**
     * Constructs a new EnableTalk.
     * @memberof geelevel
     * @classdesc Represents an EnableTalk.
     * @implements IEnableTalk
     * @constructor
     * @param {geelevel.IEnableTalk=} [properties] Properties to set
     */
    function EnableTalk(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnableTalk enable.
     * @member {boolean|null|undefined} enable
     * @memberof geelevel.EnableTalk
     * @instance
     */
    EnableTalk.prototype.enable = null;

    /**
     * EnableTalk echoCancellation.
     * @member {boolean|null|undefined} echoCancellation
     * @memberof geelevel.EnableTalk
     * @instance
     */
    EnableTalk.prototype.echoCancellation = null;

    /**
     * EnableTalk recordDevice.
     * @member {number|null|undefined} recordDevice
     * @memberof geelevel.EnableTalk
     * @instance
     */
    EnableTalk.prototype.recordDevice = null;

    /**
     * EnableTalk playerDevice.
     * @member {number|null|undefined} playerDevice
     * @memberof geelevel.EnableTalk
     * @instance
     */
    EnableTalk.prototype.playerDevice = null;

    /**
     * Creates a new EnableTalk instance using the specified properties.
     * @function create
     * @memberof geelevel.EnableTalk
     * @static
     * @param {geelevel.IEnableTalk=} [properties] Properties to set
     * @returns {geelevel.EnableTalk} EnableTalk instance
     */
    EnableTalk.create = function create(properties) {
      return new EnableTalk(properties);
    };

    /**
     * Encodes the specified EnableTalk message. Does not implicitly {@link geelevel.EnableTalk.verify|verify} messages.
     * @function encode
     * @memberof geelevel.EnableTalk
     * @static
     * @param {geelevel.IEnableTalk} message EnableTalk message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableTalk.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.enable != null && Object.hasOwnProperty.call(message, "enable")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.enable);
      if (message.echoCancellation != null && Object.hasOwnProperty.call(message, "echoCancellation")) writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.echoCancellation);
      if (message.recordDevice != null && Object.hasOwnProperty.call(message, "recordDevice")) writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.recordDevice);
      if (message.playerDevice != null && Object.hasOwnProperty.call(message, "playerDevice")) writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.playerDevice);
      return writer;
    };

    /**
     * Encodes the specified EnableTalk message, length delimited. Does not implicitly {@link geelevel.EnableTalk.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.EnableTalk
     * @static
     * @param {geelevel.IEnableTalk} message EnableTalk message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnableTalk.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnableTalk message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.EnableTalk
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.EnableTalk} EnableTalk
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableTalk.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.EnableTalk();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.enable = reader.bool();
            break;
          }
          case 2: {
            message.echoCancellation = reader.bool();
            break;
          }
          case 3: {
            message.recordDevice = reader.int32();
            break;
          }
          case 4: {
            message.playerDevice = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnableTalk message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.EnableTalk
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.EnableTalk} EnableTalk
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnableTalk.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnableTalk message.
     * @function verify
     * @memberof geelevel.EnableTalk
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnableTalk.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.enable != null && message.hasOwnProperty("enable")) if (typeof message.enable !== "boolean") return "enable: boolean expected";
      if (message.echoCancellation != null && message.hasOwnProperty("echoCancellation")) if (typeof message.echoCancellation !== "boolean") return "echoCancellation: boolean expected";
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) if (!$util.isInteger(message.recordDevice)) return "recordDevice: integer expected";
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) if (!$util.isInteger(message.playerDevice)) return "playerDevice: integer expected";
      return null;
    };

    /**
     * Creates an EnableTalk message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.EnableTalk
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.EnableTalk} EnableTalk
     */
    EnableTalk.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.EnableTalk) return object;
      var message = new $root.geelevel.EnableTalk();
      if (object.enable != null) message.enable = Boolean(object.enable);
      if (object.echoCancellation != null) message.echoCancellation = Boolean(object.echoCancellation);
      if (object.recordDevice != null) message.recordDevice = object.recordDevice | 0;
      if (object.playerDevice != null) message.playerDevice = object.playerDevice | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnableTalk message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.EnableTalk
     * @static
     * @param {geelevel.EnableTalk} message EnableTalk
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnableTalk.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.enable = false;
        object.echoCancellation = false;
        object.recordDevice = 0;
        object.playerDevice = 0;
      }
      if (message.enable != null && message.hasOwnProperty("enable")) object.enable = message.enable;
      if (message.echoCancellation != null && message.hasOwnProperty("echoCancellation")) object.echoCancellation = message.echoCancellation;
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) object.recordDevice = message.recordDevice;
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) object.playerDevice = message.playerDevice;
      return object;
    };

    /**
     * Converts this EnableTalk to JSON.
     * @function toJSON
     * @memberof geelevel.EnableTalk
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnableTalk.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnableTalk
     * @function getTypeUrl
     * @memberof geelevel.EnableTalk
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnableTalk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.EnableTalk";
    };

    return EnableTalk;
  })();

  geelevel.SetAudioDeviceIndex = (function () {
    /**
     * Properties of a SetAudioDeviceIndex.
     * @memberof geelevel
     * @interface ISetAudioDeviceIndex
     * @property {number|null} [recordDevice] SetAudioDeviceIndex recordDevice
     * @property {number|null} [playerDevice] SetAudioDeviceIndex playerDevice
     */

    /**
     * Constructs a new SetAudioDeviceIndex.
     * @memberof geelevel
     * @classdesc Represents a SetAudioDeviceIndex.
     * @implements ISetAudioDeviceIndex
     * @constructor
     * @param {geelevel.ISetAudioDeviceIndex=} [properties] Properties to set
     */
    function SetAudioDeviceIndex(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetAudioDeviceIndex recordDevice.
     * @member {number|null|undefined} recordDevice
     * @memberof geelevel.SetAudioDeviceIndex
     * @instance
     */
    SetAudioDeviceIndex.prototype.recordDevice = null;

    /**
     * SetAudioDeviceIndex playerDevice.
     * @member {number|null|undefined} playerDevice
     * @memberof geelevel.SetAudioDeviceIndex
     * @instance
     */
    SetAudioDeviceIndex.prototype.playerDevice = null;

    /**
     * Creates a new SetAudioDeviceIndex instance using the specified properties.
     * @function create
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {geelevel.ISetAudioDeviceIndex=} [properties] Properties to set
     * @returns {geelevel.SetAudioDeviceIndex} SetAudioDeviceIndex instance
     */
    SetAudioDeviceIndex.create = function create(properties) {
      return new SetAudioDeviceIndex(properties);
    };

    /**
     * Encodes the specified SetAudioDeviceIndex message. Does not implicitly {@link geelevel.SetAudioDeviceIndex.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {geelevel.ISetAudioDeviceIndex} message SetAudioDeviceIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetAudioDeviceIndex.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.recordDevice != null && Object.hasOwnProperty.call(message, "recordDevice")) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.recordDevice);
      if (message.playerDevice != null && Object.hasOwnProperty.call(message, "playerDevice")) writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.playerDevice);
      return writer;
    };

    /**
     * Encodes the specified SetAudioDeviceIndex message, length delimited. Does not implicitly {@link geelevel.SetAudioDeviceIndex.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {geelevel.ISetAudioDeviceIndex} message SetAudioDeviceIndex message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetAudioDeviceIndex.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetAudioDeviceIndex message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetAudioDeviceIndex} SetAudioDeviceIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetAudioDeviceIndex.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetAudioDeviceIndex();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.recordDevice = reader.int32();
            break;
          }
          case 2: {
            message.playerDevice = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetAudioDeviceIndex message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetAudioDeviceIndex} SetAudioDeviceIndex
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetAudioDeviceIndex.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetAudioDeviceIndex message.
     * @function verify
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetAudioDeviceIndex.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) if (!$util.isInteger(message.recordDevice)) return "recordDevice: integer expected";
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) if (!$util.isInteger(message.playerDevice)) return "playerDevice: integer expected";
      return null;
    };

    /**
     * Creates a SetAudioDeviceIndex message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetAudioDeviceIndex} SetAudioDeviceIndex
     */
    SetAudioDeviceIndex.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetAudioDeviceIndex) return object;
      var message = new $root.geelevel.SetAudioDeviceIndex();
      if (object.recordDevice != null) message.recordDevice = object.recordDevice | 0;
      if (object.playerDevice != null) message.playerDevice = object.playerDevice | 0;
      return message;
    };

    /**
     * Creates a plain object from a SetAudioDeviceIndex message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {geelevel.SetAudioDeviceIndex} message SetAudioDeviceIndex
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetAudioDeviceIndex.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.recordDevice = 0;
        object.playerDevice = 0;
      }
      if (message.recordDevice != null && message.hasOwnProperty("recordDevice")) object.recordDevice = message.recordDevice;
      if (message.playerDevice != null && message.hasOwnProperty("playerDevice")) object.playerDevice = message.playerDevice;
      return object;
    };

    /**
     * Converts this SetAudioDeviceIndex to JSON.
     * @function toJSON
     * @memberof geelevel.SetAudioDeviceIndex
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetAudioDeviceIndex.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetAudioDeviceIndex
     * @function getTypeUrl
     * @memberof geelevel.SetAudioDeviceIndex
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetAudioDeviceIndex.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetAudioDeviceIndex";
    };

    return SetAudioDeviceIndex;
  })();

  geelevel.SetActiveScreen = (function () {
    /**
     * Properties of a SetActiveScreen.
     * @memberof geelevel
     * @interface ISetActiveScreen
     * @property {number|null} [index] SetActiveScreen index
     */

    /**
     * Constructs a new SetActiveScreen.
     * @memberof geelevel
     * @classdesc Represents a SetActiveScreen.
     * @implements ISetActiveScreen
     * @constructor
     * @param {geelevel.ISetActiveScreen=} [properties] Properties to set
     */
    function SetActiveScreen(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetActiveScreen index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetActiveScreen
     * @instance
     */
    SetActiveScreen.prototype.index = null;

    /**
     * Creates a new SetActiveScreen instance using the specified properties.
     * @function create
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {geelevel.ISetActiveScreen=} [properties] Properties to set
     * @returns {geelevel.SetActiveScreen} SetActiveScreen instance
     */
    SetActiveScreen.create = function create(properties) {
      return new SetActiveScreen(properties);
    };

    /**
     * Encodes the specified SetActiveScreen message. Does not implicitly {@link geelevel.SetActiveScreen.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {geelevel.ISetActiveScreen} message SetActiveScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetActiveScreen.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetActiveScreen message, length delimited. Does not implicitly {@link geelevel.SetActiveScreen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {geelevel.ISetActiveScreen} message SetActiveScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetActiveScreen.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetActiveScreen message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetActiveScreen} SetActiveScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetActiveScreen.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetActiveScreen();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetActiveScreen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetActiveScreen} SetActiveScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetActiveScreen.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetActiveScreen message.
     * @function verify
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetActiveScreen.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetActiveScreen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetActiveScreen} SetActiveScreen
     */
    SetActiveScreen.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetActiveScreen) return object;
      var message = new $root.geelevel.SetActiveScreen();
      if (object.index != null) message.index = object.index | 0;
      return message;
    };

    /**
     * Creates a plain object from a SetActiveScreen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {geelevel.SetActiveScreen} message SetActiveScreen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetActiveScreen.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetActiveScreen to JSON.
     * @function toJSON
     * @memberof geelevel.SetActiveScreen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetActiveScreen.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetActiveScreen
     * @function getTypeUrl
     * @memberof geelevel.SetActiveScreen
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetActiveScreen.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetActiveScreen";
    };

    return SetActiveScreen;
  })();

  geelevel.SwitchVideoSource = (function () {
    /**
     * Properties of a SwitchVideoSource.
     * @memberof geelevel
     * @interface ISwitchVideoSource
     * @property {number|null} [index] SwitchVideoSource index
     */

    /**
     * Constructs a new SwitchVideoSource.
     * @memberof geelevel
     * @classdesc Represents a SwitchVideoSource.
     * @implements ISwitchVideoSource
     * @constructor
     * @param {geelevel.ISwitchVideoSource=} [properties] Properties to set
     */
    function SwitchVideoSource(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SwitchVideoSource index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SwitchVideoSource
     * @instance
     */
    SwitchVideoSource.prototype.index = null;

    /**
     * Creates a new SwitchVideoSource instance using the specified properties.
     * @function create
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {geelevel.ISwitchVideoSource=} [properties] Properties to set
     * @returns {geelevel.SwitchVideoSource} SwitchVideoSource instance
     */
    SwitchVideoSource.create = function create(properties) {
      return new SwitchVideoSource(properties);
    };

    /**
     * Encodes the specified SwitchVideoSource message. Does not implicitly {@link geelevel.SwitchVideoSource.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {geelevel.ISwitchVideoSource} message SwitchVideoSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SwitchVideoSource.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SwitchVideoSource message, length delimited. Does not implicitly {@link geelevel.SwitchVideoSource.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {geelevel.ISwitchVideoSource} message SwitchVideoSource message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SwitchVideoSource.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SwitchVideoSource message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SwitchVideoSource} SwitchVideoSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SwitchVideoSource.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SwitchVideoSource();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SwitchVideoSource message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SwitchVideoSource} SwitchVideoSource
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SwitchVideoSource.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SwitchVideoSource message.
     * @function verify
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SwitchVideoSource.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SwitchVideoSource message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SwitchVideoSource} SwitchVideoSource
     */
    SwitchVideoSource.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SwitchVideoSource) return object;
      var message = new $root.geelevel.SwitchVideoSource();
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SwitchVideoSource message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {geelevel.SwitchVideoSource} message SwitchVideoSource
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SwitchVideoSource.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.index = 0;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SwitchVideoSource to JSON.
     * @function toJSON
     * @memberof geelevel.SwitchVideoSource
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SwitchVideoSource.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SwitchVideoSource
     * @function getTypeUrl
     * @memberof geelevel.SwitchVideoSource
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SwitchVideoSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SwitchVideoSource";
    };

    return SwitchVideoSource;
  })();

  geelevel.CaptureImage = (function () {
    /**
     * Properties of a CaptureImage.
     * @memberof geelevel
     * @interface ICaptureImage
     * @property {number|null} [index] CaptureImage index
     * @property {Uint8Array|null} [data] CaptureImage data
     */

    /**
     * Constructs a new CaptureImage.
     * @memberof geelevel
     * @classdesc Represents a CaptureImage.
     * @implements ICaptureImage
     * @constructor
     * @param {geelevel.ICaptureImage=} [properties] Properties to set
     */
    function CaptureImage(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CaptureImage index.
     * @member {number|null|undefined} index
     * @memberof geelevel.CaptureImage
     * @instance
     */
    CaptureImage.prototype.index = null;

    /**
     * CaptureImage data.
     * @member {Uint8Array|null|undefined} data
     * @memberof geelevel.CaptureImage
     * @instance
     */
    CaptureImage.prototype.data = null;

    /**
     * Creates a new CaptureImage instance using the specified properties.
     * @function create
     * @memberof geelevel.CaptureImage
     * @static
     * @param {geelevel.ICaptureImage=} [properties] Properties to set
     * @returns {geelevel.CaptureImage} CaptureImage instance
     */
    CaptureImage.create = function create(properties) {
      return new CaptureImage(properties);
    };

    /**
     * Encodes the specified CaptureImage message. Does not implicitly {@link geelevel.CaptureImage.verify|verify} messages.
     * @function encode
     * @memberof geelevel.CaptureImage
     * @static
     * @param {geelevel.ICaptureImage} message CaptureImage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CaptureImage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.index);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.data);
      return writer;
    };

    /**
     * Encodes the specified CaptureImage message, length delimited. Does not implicitly {@link geelevel.CaptureImage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.CaptureImage
     * @static
     * @param {geelevel.ICaptureImage} message CaptureImage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CaptureImage.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CaptureImage message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.CaptureImage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.CaptureImage} CaptureImage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CaptureImage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.CaptureImage();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.uint32();
            break;
          }
          case 2: {
            message.data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CaptureImage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.CaptureImage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.CaptureImage} CaptureImage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CaptureImage.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CaptureImage message.
     * @function verify
     * @memberof geelevel.CaptureImage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CaptureImage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!((message.data && typeof message.data.length === "number") || $util.isString(message.data))) return "data: buffer expected";
      return null;
    };

    /**
     * Creates a CaptureImage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.CaptureImage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.CaptureImage} CaptureImage
     */
    CaptureImage.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.CaptureImage) return object;
      var message = new $root.geelevel.CaptureImage();
      if (object.index != null) message.index = object.index >>> 0;
      if (object.data != null)
        if (typeof object.data === "string") $util.base64.decode(object.data, (message.data = $util.newBuffer($util.base64.length(object.data))), 0);
        else if (object.data.length >= 0) message.data = object.data;
      return message;
    };

    /**
     * Creates a plain object from a CaptureImage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.CaptureImage
     * @static
     * @param {geelevel.CaptureImage} message CaptureImage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CaptureImage.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.index = 0;
        if (options.bytes === String) object.data = "";
        else {
          object.data = [];
          if (options.bytes !== Array) object.data = $util.newBuffer(object.data);
        }
      }
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      if (message.data != null && message.hasOwnProperty("data"))
        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
      return object;
    };

    /**
     * Converts this CaptureImage to JSON.
     * @function toJSON
     * @memberof geelevel.CaptureImage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CaptureImage.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CaptureImage
     * @function getTypeUrl
     * @memberof geelevel.CaptureImage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CaptureImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.CaptureImage";
    };

    return CaptureImage;
  })();

  geelevel.SetPrivacyScreen = (function () {
    /**
     * Properties of a SetPrivacyScreen.
     * @memberof geelevel
     * @interface ISetPrivacyScreen
     * @property {boolean|null} [enable] SetPrivacyScreen enable
     * @property {number|null} [type] SetPrivacyScreen type
     */

    /**
     * Constructs a new SetPrivacyScreen.
     * @memberof geelevel
     * @classdesc Represents a SetPrivacyScreen.
     * @implements ISetPrivacyScreen
     * @constructor
     * @param {geelevel.ISetPrivacyScreen=} [properties] Properties to set
     */
    function SetPrivacyScreen(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetPrivacyScreen enable.
     * @member {boolean|null|undefined} enable
     * @memberof geelevel.SetPrivacyScreen
     * @instance
     */
    SetPrivacyScreen.prototype.enable = null;

    /**
     * SetPrivacyScreen type.
     * @member {number|null|undefined} type
     * @memberof geelevel.SetPrivacyScreen
     * @instance
     */
    SetPrivacyScreen.prototype.type = null;

    /**
     * Creates a new SetPrivacyScreen instance using the specified properties.
     * @function create
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {geelevel.ISetPrivacyScreen=} [properties] Properties to set
     * @returns {geelevel.SetPrivacyScreen} SetPrivacyScreen instance
     */
    SetPrivacyScreen.create = function create(properties) {
      return new SetPrivacyScreen(properties);
    };

    /**
     * Encodes the specified SetPrivacyScreen message. Does not implicitly {@link geelevel.SetPrivacyScreen.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {geelevel.ISetPrivacyScreen} message SetPrivacyScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetPrivacyScreen.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.enable != null && Object.hasOwnProperty.call(message, "enable")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.enable);
      if (message.type != null && Object.hasOwnProperty.call(message, "type")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.type);
      return writer;
    };

    /**
     * Encodes the specified SetPrivacyScreen message, length delimited. Does not implicitly {@link geelevel.SetPrivacyScreen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {geelevel.ISetPrivacyScreen} message SetPrivacyScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetPrivacyScreen.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetPrivacyScreen message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetPrivacyScreen} SetPrivacyScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetPrivacyScreen.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetPrivacyScreen();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.enable = reader.bool();
            break;
          }
          case 2: {
            message.type = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetPrivacyScreen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetPrivacyScreen} SetPrivacyScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetPrivacyScreen.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetPrivacyScreen message.
     * @function verify
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetPrivacyScreen.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.enable != null && message.hasOwnProperty("enable")) if (typeof message.enable !== "boolean") return "enable: boolean expected";
      if (message.type != null && message.hasOwnProperty("type")) if (!$util.isInteger(message.type)) return "type: integer expected";
      return null;
    };

    /**
     * Creates a SetPrivacyScreen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetPrivacyScreen} SetPrivacyScreen
     */
    SetPrivacyScreen.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetPrivacyScreen) return object;
      var message = new $root.geelevel.SetPrivacyScreen();
      if (object.enable != null) message.enable = Boolean(object.enable);
      if (object.type != null) message.type = object.type >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetPrivacyScreen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {geelevel.SetPrivacyScreen} message SetPrivacyScreen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetPrivacyScreen.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.enable = false;
        object.type = 0;
      }
      if (message.enable != null && message.hasOwnProperty("enable")) object.enable = message.enable;
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      return object;
    };

    /**
     * Converts this SetPrivacyScreen to JSON.
     * @function toJSON
     * @memberof geelevel.SetPrivacyScreen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetPrivacyScreen.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetPrivacyScreen
     * @function getTypeUrl
     * @memberof geelevel.SetPrivacyScreen
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetPrivacyScreen.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetPrivacyScreen";
    };

    return SetPrivacyScreen;
  })();

  geelevel.SyncPrivacyScreen = (function () {
    /**
     * Properties of a SyncPrivacyScreen.
     * @memberof geelevel
     * @interface ISyncPrivacyScreen
     * @property {boolean|null} [enable] SyncPrivacyScreen enable
     * @property {number|null} [type] SyncPrivacyScreen type
     */

    /**
     * Constructs a new SyncPrivacyScreen.
     * @memberof geelevel
     * @classdesc Represents a SyncPrivacyScreen.
     * @implements ISyncPrivacyScreen
     * @constructor
     * @param {geelevel.ISyncPrivacyScreen=} [properties] Properties to set
     */
    function SyncPrivacyScreen(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SyncPrivacyScreen enable.
     * @member {boolean|null|undefined} enable
     * @memberof geelevel.SyncPrivacyScreen
     * @instance
     */
    SyncPrivacyScreen.prototype.enable = null;

    /**
     * SyncPrivacyScreen type.
     * @member {number|null|undefined} type
     * @memberof geelevel.SyncPrivacyScreen
     * @instance
     */
    SyncPrivacyScreen.prototype.type = null;

    /**
     * Creates a new SyncPrivacyScreen instance using the specified properties.
     * @function create
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {geelevel.ISyncPrivacyScreen=} [properties] Properties to set
     * @returns {geelevel.SyncPrivacyScreen} SyncPrivacyScreen instance
     */
    SyncPrivacyScreen.create = function create(properties) {
      return new SyncPrivacyScreen(properties);
    };

    /**
     * Encodes the specified SyncPrivacyScreen message. Does not implicitly {@link geelevel.SyncPrivacyScreen.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {geelevel.ISyncPrivacyScreen} message SyncPrivacyScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SyncPrivacyScreen.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.enable != null && Object.hasOwnProperty.call(message, "enable")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.enable);
      if (message.type != null && Object.hasOwnProperty.call(message, "type")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.type);
      return writer;
    };

    /**
     * Encodes the specified SyncPrivacyScreen message, length delimited. Does not implicitly {@link geelevel.SyncPrivacyScreen.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {geelevel.ISyncPrivacyScreen} message SyncPrivacyScreen message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SyncPrivacyScreen.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SyncPrivacyScreen message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SyncPrivacyScreen} SyncPrivacyScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SyncPrivacyScreen.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SyncPrivacyScreen();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.enable = reader.bool();
            break;
          }
          case 2: {
            message.type = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SyncPrivacyScreen message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SyncPrivacyScreen} SyncPrivacyScreen
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SyncPrivacyScreen.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SyncPrivacyScreen message.
     * @function verify
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SyncPrivacyScreen.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.enable != null && message.hasOwnProperty("enable")) if (typeof message.enable !== "boolean") return "enable: boolean expected";
      if (message.type != null && message.hasOwnProperty("type")) if (!$util.isInteger(message.type)) return "type: integer expected";
      return null;
    };

    /**
     * Creates a SyncPrivacyScreen message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SyncPrivacyScreen} SyncPrivacyScreen
     */
    SyncPrivacyScreen.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SyncPrivacyScreen) return object;
      var message = new $root.geelevel.SyncPrivacyScreen();
      if (object.enable != null) message.enable = Boolean(object.enable);
      if (object.type != null) message.type = object.type >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SyncPrivacyScreen message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {geelevel.SyncPrivacyScreen} message SyncPrivacyScreen
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SyncPrivacyScreen.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.enable = false;
        object.type = 0;
      }
      if (message.enable != null && message.hasOwnProperty("enable")) object.enable = message.enable;
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      return object;
    };

    /**
     * Converts this SyncPrivacyScreen to JSON.
     * @function toJSON
     * @memberof geelevel.SyncPrivacyScreen
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SyncPrivacyScreen.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SyncPrivacyScreen
     * @function getTypeUrl
     * @memberof geelevel.SyncPrivacyScreen
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SyncPrivacyScreen.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SyncPrivacyScreen";
    };

    return SyncPrivacyScreen;
  })();

  geelevel.ReplayResult = (function () {
    /**
     * Properties of a ReplayResult.
     * @memberof geelevel
     * @interface IReplayResult
     * @property {number|null} [code] ReplayResult code
     * @property {number|null} [result] ReplayResult result
     * @property {Uint8Array|null} [data] ReplayResult data
     */

    /**
     * Constructs a new ReplayResult.
     * @memberof geelevel
     * @classdesc Represents a ReplayResult.
     * @implements IReplayResult
     * @constructor
     * @param {geelevel.IReplayResult=} [properties] Properties to set
     */
    function ReplayResult(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReplayResult code.
     * @member {number|null|undefined} code
     * @memberof geelevel.ReplayResult
     * @instance
     */
    ReplayResult.prototype.code = null;

    /**
     * ReplayResult result.
     * @member {number|null|undefined} result
     * @memberof geelevel.ReplayResult
     * @instance
     */
    ReplayResult.prototype.result = null;

    /**
     * ReplayResult data.
     * @member {Uint8Array|null|undefined} data
     * @memberof geelevel.ReplayResult
     * @instance
     */
    ReplayResult.prototype.data = null;

    /**
     * Creates a new ReplayResult instance using the specified properties.
     * @function create
     * @memberof geelevel.ReplayResult
     * @static
     * @param {geelevel.IReplayResult=} [properties] Properties to set
     * @returns {geelevel.ReplayResult} ReplayResult instance
     */
    ReplayResult.create = function create(properties) {
      return new ReplayResult(properties);
    };

    /**
     * Encodes the specified ReplayResult message. Does not implicitly {@link geelevel.ReplayResult.verify|verify} messages.
     * @function encode
     * @memberof geelevel.ReplayResult
     * @static
     * @param {geelevel.IReplayResult} message ReplayResult message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReplayResult.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.code != null && Object.hasOwnProperty.call(message, "code")) writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.code);
      if (message.result != null && Object.hasOwnProperty.call(message, "result")) writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.data);
      return writer;
    };

    /**
     * Encodes the specified ReplayResult message, length delimited. Does not implicitly {@link geelevel.ReplayResult.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.ReplayResult
     * @static
     * @param {geelevel.IReplayResult} message ReplayResult message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReplayResult.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReplayResult message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.ReplayResult
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.ReplayResult} ReplayResult
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReplayResult.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.ReplayResult();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.code = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReplayResult message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.ReplayResult
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.ReplayResult} ReplayResult
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReplayResult.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReplayResult message.
     * @function verify
     * @memberof geelevel.ReplayResult
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReplayResult.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.code != null && message.hasOwnProperty("code")) if (!$util.isInteger(message.code)) return "code: integer expected";
      if (message.result != null && message.hasOwnProperty("result")) if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!((message.data && typeof message.data.length === "number") || $util.isString(message.data))) return "data: buffer expected";
      return null;
    };

    /**
     * Creates a ReplayResult message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.ReplayResult
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.ReplayResult} ReplayResult
     */
    ReplayResult.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.ReplayResult) return object;
      var message = new $root.geelevel.ReplayResult();
      if (object.code != null) message.code = object.code | 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.data != null)
        if (typeof object.data === "string") $util.base64.decode(object.data, (message.data = $util.newBuffer($util.base64.length(object.data))), 0);
        else if (object.data.length >= 0) message.data = object.data;
      return message;
    };

    /**
     * Creates a plain object from a ReplayResult message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.ReplayResult
     * @static
     * @param {geelevel.ReplayResult} message ReplayResult
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReplayResult.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.code = 0;
        object.result = 0;
        if (options.bytes === String) object.data = "";
        else {
          object.data = [];
          if (options.bytes !== Array) object.data = $util.newBuffer(object.data);
        }
      }
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.data != null && message.hasOwnProperty("data"))
        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
      return object;
    };

    /**
     * Converts this ReplayResult to JSON.
     * @function toJSON
     * @memberof geelevel.ReplayResult
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReplayResult.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReplayResult
     * @function getTypeUrl
     * @memberof geelevel.ReplayResult
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReplayResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.ReplayResult";
    };

    return ReplayResult;
  })();

  geelevel.SetShowRemoteCursor = (function () {
    /**
     * Properties of a SetShowRemoteCursor.
     * @memberof geelevel
     * @interface ISetShowRemoteCursor
     * @property {boolean|null} [value] SetShowRemoteCursor value
     * @property {number|null} [index] SetShowRemoteCursor index
     */

    /**
     * Constructs a new SetShowRemoteCursor.
     * @memberof geelevel
     * @classdesc Represents a SetShowRemoteCursor.
     * @implements ISetShowRemoteCursor
     * @constructor
     * @param {geelevel.ISetShowRemoteCursor=} [properties] Properties to set
     */
    function SetShowRemoteCursor(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetShowRemoteCursor value.
     * @member {boolean|null|undefined} value
     * @memberof geelevel.SetShowRemoteCursor
     * @instance
     */
    SetShowRemoteCursor.prototype.value = null;

    /**
     * SetShowRemoteCursor index.
     * @member {number|null|undefined} index
     * @memberof geelevel.SetShowRemoteCursor
     * @instance
     */
    SetShowRemoteCursor.prototype.index = null;

    /**
     * Creates a new SetShowRemoteCursor instance using the specified properties.
     * @function create
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {geelevel.ISetShowRemoteCursor=} [properties] Properties to set
     * @returns {geelevel.SetShowRemoteCursor} SetShowRemoteCursor instance
     */
    SetShowRemoteCursor.create = function create(properties) {
      return new SetShowRemoteCursor(properties);
    };

    /**
     * Encodes the specified SetShowRemoteCursor message. Does not implicitly {@link geelevel.SetShowRemoteCursor.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {geelevel.ISetShowRemoteCursor} message SetShowRemoteCursor message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetShowRemoteCursor.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.value != null && Object.hasOwnProperty.call(message, "value")) writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.value);
      if (message.index != null && Object.hasOwnProperty.call(message, "index")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.index);
      return writer;
    };

    /**
     * Encodes the specified SetShowRemoteCursor message, length delimited. Does not implicitly {@link geelevel.SetShowRemoteCursor.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {geelevel.ISetShowRemoteCursor} message SetShowRemoteCursor message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetShowRemoteCursor.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetShowRemoteCursor message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetShowRemoteCursor} SetShowRemoteCursor
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetShowRemoteCursor.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetShowRemoteCursor();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.value = reader.bool();
            break;
          }
          case 2: {
            message.index = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetShowRemoteCursor message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetShowRemoteCursor} SetShowRemoteCursor
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetShowRemoteCursor.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetShowRemoteCursor message.
     * @function verify
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetShowRemoteCursor.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.value != null && message.hasOwnProperty("value")) if (typeof message.value !== "boolean") return "value: boolean expected";
      if (message.index != null && message.hasOwnProperty("index")) if (!$util.isInteger(message.index)) return "index: integer expected";
      return null;
    };

    /**
     * Creates a SetShowRemoteCursor message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetShowRemoteCursor} SetShowRemoteCursor
     */
    SetShowRemoteCursor.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetShowRemoteCursor) return object;
      var message = new $root.geelevel.SetShowRemoteCursor();
      if (object.value != null) message.value = Boolean(object.value);
      if (object.index != null) message.index = object.index >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetShowRemoteCursor message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {geelevel.SetShowRemoteCursor} message SetShowRemoteCursor
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetShowRemoteCursor.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.value = false;
        object.index = 0;
      }
      if (message.value != null && message.hasOwnProperty("value")) object.value = message.value;
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      return object;
    };

    /**
     * Converts this SetShowRemoteCursor to JSON.
     * @function toJSON
     * @memberof geelevel.SetShowRemoteCursor
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetShowRemoteCursor.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetShowRemoteCursor
     * @function getTypeUrl
     * @memberof geelevel.SetShowRemoteCursor
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetShowRemoteCursor.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetShowRemoteCursor";
    };

    return SetShowRemoteCursor;
  })();

  geelevel.SetClipboardData = (function () {
    /**
     * Properties of a SetClipboardData.
     * @memberof geelevel
     * @interface ISetClipboardData
     * @property {string|null} [type] SetClipboardData type
     * @property {Uint8Array|null} [data] SetClipboardData data
     */

    /**
     * Constructs a new SetClipboardData.
     * @memberof geelevel
     * @classdesc Represents a SetClipboardData.
     * @implements ISetClipboardData
     * @constructor
     * @param {geelevel.ISetClipboardData=} [properties] Properties to set
     */
    function SetClipboardData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetClipboardData type.
     * @member {string|null|undefined} type
     * @memberof geelevel.SetClipboardData
     * @instance
     */
    SetClipboardData.prototype.type = null;

    /**
     * SetClipboardData data.
     * @member {Uint8Array|null|undefined} data
     * @memberof geelevel.SetClipboardData
     * @instance
     */
    SetClipboardData.prototype.data = null;

    /**
     * Creates a new SetClipboardData instance using the specified properties.
     * @function create
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {geelevel.ISetClipboardData=} [properties] Properties to set
     * @returns {geelevel.SetClipboardData} SetClipboardData instance
     */
    SetClipboardData.create = function create(properties) {
      return new SetClipboardData(properties);
    };

    /**
     * Encodes the specified SetClipboardData message. Does not implicitly {@link geelevel.SetClipboardData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {geelevel.ISetClipboardData} message SetClipboardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetClipboardData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.type != null && Object.hasOwnProperty.call(message, "type")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.type);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.data);
      return writer;
    };

    /**
     * Encodes the specified SetClipboardData message, length delimited. Does not implicitly {@link geelevel.SetClipboardData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {geelevel.ISetClipboardData} message SetClipboardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetClipboardData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetClipboardData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetClipboardData} SetClipboardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetClipboardData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetClipboardData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.type = reader.string();
            break;
          }
          case 2: {
            message.data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetClipboardData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetClipboardData} SetClipboardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetClipboardData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetClipboardData message.
     * @function verify
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetClipboardData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.type != null && message.hasOwnProperty("type")) if (!$util.isString(message.type)) return "type: string expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!((message.data && typeof message.data.length === "number") || $util.isString(message.data))) return "data: buffer expected";
      return null;
    };

    /**
     * Creates a SetClipboardData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetClipboardData} SetClipboardData
     */
    SetClipboardData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetClipboardData) return object;
      var message = new $root.geelevel.SetClipboardData();
      if (object.type != null) message.type = String(object.type);
      if (object.data != null)
        if (typeof object.data === "string") $util.base64.decode(object.data, (message.data = $util.newBuffer($util.base64.length(object.data))), 0);
        else if (object.data.length >= 0) message.data = object.data;
      return message;
    };

    /**
     * Creates a plain object from a SetClipboardData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {geelevel.SetClipboardData} message SetClipboardData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetClipboardData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.type = "";
        if (options.bytes === String) object.data = "";
        else {
          object.data = [];
          if (options.bytes !== Array) object.data = $util.newBuffer(object.data);
        }
      }
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      if (message.data != null && message.hasOwnProperty("data"))
        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
      return object;
    };

    /**
     * Converts this SetClipboardData to JSON.
     * @function toJSON
     * @memberof geelevel.SetClipboardData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetClipboardData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetClipboardData
     * @function getTypeUrl
     * @memberof geelevel.SetClipboardData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetClipboardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetClipboardData";
    };

    return SetClipboardData;
  })();

  geelevel.MouseData = (function () {
    /**
     * Properties of a MouseData.
     * @memberof geelevel
     * @interface IMouseData
     * @property {number|null} [event] MouseData event
     * @property {number|null} [x] MouseData x
     * @property {number|null} [y] MouseData y
     * @property {number|null} [buttonState] MouseData buttonState
     * @property {number|null} [keyboardState] MouseData keyboardState
     */

    /**
     * Constructs a new MouseData.
     * @memberof geelevel
     * @classdesc Represents a MouseData.
     * @implements IMouseData
     * @constructor
     * @param {geelevel.IMouseData=} [properties] Properties to set
     */
    function MouseData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MouseData event.
     * @member {number|null|undefined} event
     * @memberof geelevel.MouseData
     * @instance
     */
    MouseData.prototype.event = null;

    /**
     * MouseData x.
     * @member {number|null|undefined} x
     * @memberof geelevel.MouseData
     * @instance
     */
    MouseData.prototype.x = null;

    /**
     * MouseData y.
     * @member {number|null|undefined} y
     * @memberof geelevel.MouseData
     * @instance
     */
    MouseData.prototype.y = null;

    /**
     * MouseData buttonState.
     * @member {number|null|undefined} buttonState
     * @memberof geelevel.MouseData
     * @instance
     */
    MouseData.prototype.buttonState = null;

    /**
     * MouseData keyboardState.
     * @member {number|null|undefined} keyboardState
     * @memberof geelevel.MouseData
     * @instance
     */
    MouseData.prototype.keyboardState = null;

    /**
     * Creates a new MouseData instance using the specified properties.
     * @function create
     * @memberof geelevel.MouseData
     * @static
     * @param {geelevel.IMouseData=} [properties] Properties to set
     * @returns {geelevel.MouseData} MouseData instance
     */
    MouseData.create = function create(properties) {
      return new MouseData(properties);
    };

    /**
     * Encodes the specified MouseData message. Does not implicitly {@link geelevel.MouseData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.MouseData
     * @static
     * @param {geelevel.IMouseData} message MouseData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.event != null && Object.hasOwnProperty.call(message, "event")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.event);
      if (message.x != null && Object.hasOwnProperty.call(message, "x")) writer.uint32(/* id 2, wireType 0 =*/ 16).sint32(message.x);
      if (message.y != null && Object.hasOwnProperty.call(message, "y")) writer.uint32(/* id 3, wireType 0 =*/ 24).sint32(message.y);
      if (message.buttonState != null && Object.hasOwnProperty.call(message, "buttonState")) writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.buttonState);
      if (message.keyboardState != null && Object.hasOwnProperty.call(message, "keyboardState")) writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.keyboardState);
      return writer;
    };

    /**
     * Encodes the specified MouseData message, length delimited. Does not implicitly {@link geelevel.MouseData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.MouseData
     * @static
     * @param {geelevel.IMouseData} message MouseData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MouseData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MouseData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.MouseData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.MouseData} MouseData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.MouseData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.event = reader.uint32();
            break;
          }
          case 2: {
            message.x = reader.sint32();
            break;
          }
          case 3: {
            message.y = reader.sint32();
            break;
          }
          case 4: {
            message.buttonState = reader.uint32();
            break;
          }
          case 5: {
            message.keyboardState = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MouseData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.MouseData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.MouseData} MouseData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MouseData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MouseData message.
     * @function verify
     * @memberof geelevel.MouseData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MouseData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.event != null && message.hasOwnProperty("event")) if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.x != null && message.hasOwnProperty("x")) if (!$util.isInteger(message.x)) return "x: integer expected";
      if (message.y != null && message.hasOwnProperty("y")) if (!$util.isInteger(message.y)) return "y: integer expected";
      if (message.buttonState != null && message.hasOwnProperty("buttonState")) if (!$util.isInteger(message.buttonState)) return "buttonState: integer expected";
      if (message.keyboardState != null && message.hasOwnProperty("keyboardState")) if (!$util.isInteger(message.keyboardState)) return "keyboardState: integer expected";
      return null;
    };

    /**
     * Creates a MouseData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.MouseData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.MouseData} MouseData
     */
    MouseData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.MouseData) return object;
      var message = new $root.geelevel.MouseData();
      if (object.event != null) message.event = object.event >>> 0;
      if (object.x != null) message.x = object.x | 0;
      if (object.y != null) message.y = object.y | 0;
      if (object.buttonState != null) message.buttonState = object.buttonState >>> 0;
      if (object.keyboardState != null) message.keyboardState = object.keyboardState >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a MouseData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.MouseData
     * @static
     * @param {geelevel.MouseData} message MouseData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MouseData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.event = 0;
        object.x = 0;
        object.y = 0;
        object.buttonState = 0;
        object.keyboardState = 0;
      }
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.x != null && message.hasOwnProperty("x")) object.x = message.x;
      if (message.y != null && message.hasOwnProperty("y")) object.y = message.y;
      if (message.buttonState != null && message.hasOwnProperty("buttonState")) object.buttonState = message.buttonState;
      if (message.keyboardState != null && message.hasOwnProperty("keyboardState")) object.keyboardState = message.keyboardState;
      return object;
    };

    /**
     * Converts this MouseData to JSON.
     * @function toJSON
     * @memberof geelevel.MouseData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MouseData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MouseData
     * @function getTypeUrl
     * @memberof geelevel.MouseData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MouseData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.MouseData";
    };

    return MouseData;
  })();

  geelevel.KeyboardData = (function () {
    /**
     * Properties of a KeyboardData.
     * @memberof geelevel
     * @interface IKeyboardData
     * @property {number|null} [event] KeyboardData event
     * @property {number|null} [keyCode] KeyboardData keyCode
     * @property {number|null} [keyboardState] KeyboardData keyboardState
     * @property {number|null} [extra1] KeyboardData extra1
     */

    /**
     * Constructs a new KeyboardData.
     * @memberof geelevel
     * @classdesc Represents a KeyboardData.
     * @implements IKeyboardData
     * @constructor
     * @param {geelevel.IKeyboardData=} [properties] Properties to set
     */
    function KeyboardData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * KeyboardData event.
     * @member {number|null|undefined} event
     * @memberof geelevel.KeyboardData
     * @instance
     */
    KeyboardData.prototype.event = null;

    /**
     * KeyboardData keyCode.
     * @member {number|null|undefined} keyCode
     * @memberof geelevel.KeyboardData
     * @instance
     */
    KeyboardData.prototype.keyCode = null;

    /**
     * KeyboardData keyboardState.
     * @member {number|null|undefined} keyboardState
     * @memberof geelevel.KeyboardData
     * @instance
     */
    KeyboardData.prototype.keyboardState = null;

    /**
     * KeyboardData extra1.
     * @member {number|null|undefined} extra1
     * @memberof geelevel.KeyboardData
     * @instance
     */
    KeyboardData.prototype.extra1 = null;

    /**
     * Creates a new KeyboardData instance using the specified properties.
     * @function create
     * @memberof geelevel.KeyboardData
     * @static
     * @param {geelevel.IKeyboardData=} [properties] Properties to set
     * @returns {geelevel.KeyboardData} KeyboardData instance
     */
    KeyboardData.create = function create(properties) {
      return new KeyboardData(properties);
    };

    /**
     * Encodes the specified KeyboardData message. Does not implicitly {@link geelevel.KeyboardData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.KeyboardData
     * @static
     * @param {geelevel.IKeyboardData} message KeyboardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyboardData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.event != null && Object.hasOwnProperty.call(message, "event")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.event);
      if (message.keyCode != null && Object.hasOwnProperty.call(message, "keyCode")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.keyCode);
      if (message.keyboardState != null && Object.hasOwnProperty.call(message, "keyboardState")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.keyboardState);
      if (message.extra1 != null && Object.hasOwnProperty.call(message, "extra1")) writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.extra1);
      return writer;
    };

    /**
     * Encodes the specified KeyboardData message, length delimited. Does not implicitly {@link geelevel.KeyboardData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.KeyboardData
     * @static
     * @param {geelevel.IKeyboardData} message KeyboardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KeyboardData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KeyboardData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.KeyboardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.KeyboardData} KeyboardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyboardData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.KeyboardData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.event = reader.uint32();
            break;
          }
          case 2: {
            message.keyCode = reader.uint32();
            break;
          }
          case 3: {
            message.keyboardState = reader.uint32();
            break;
          }
          case 4: {
            message.extra1 = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KeyboardData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.KeyboardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.KeyboardData} KeyboardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KeyboardData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KeyboardData message.
     * @function verify
     * @memberof geelevel.KeyboardData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KeyboardData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.event != null && message.hasOwnProperty("event")) if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.keyCode != null && message.hasOwnProperty("keyCode")) if (!$util.isInteger(message.keyCode)) return "keyCode: integer expected";
      if (message.keyboardState != null && message.hasOwnProperty("keyboardState")) if (!$util.isInteger(message.keyboardState)) return "keyboardState: integer expected";
      if (message.extra1 != null && message.hasOwnProperty("extra1")) if (!$util.isInteger(message.extra1)) return "extra1: integer expected";
      return null;
    };

    /**
     * Creates a KeyboardData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.KeyboardData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.KeyboardData} KeyboardData
     */
    KeyboardData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.KeyboardData) return object;
      var message = new $root.geelevel.KeyboardData();
      if (object.event != null) message.event = object.event >>> 0;
      if (object.keyCode != null) message.keyCode = object.keyCode >>> 0;
      if (object.keyboardState != null) message.keyboardState = object.keyboardState >>> 0;
      if (object.extra1 != null) message.extra1 = object.extra1 >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a KeyboardData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.KeyboardData
     * @static
     * @param {geelevel.KeyboardData} message KeyboardData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KeyboardData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.event = 0;
        object.keyCode = 0;
        object.keyboardState = 0;
        object.extra1 = 0;
      }
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.keyCode != null && message.hasOwnProperty("keyCode")) object.keyCode = message.keyCode;
      if (message.keyboardState != null && message.hasOwnProperty("keyboardState")) object.keyboardState = message.keyboardState;
      if (message.extra1 != null && message.hasOwnProperty("extra1")) object.extra1 = message.extra1;
      return object;
    };

    /**
     * Converts this KeyboardData to JSON.
     * @function toJSON
     * @memberof geelevel.KeyboardData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KeyboardData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for KeyboardData
     * @function getTypeUrl
     * @memberof geelevel.KeyboardData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    KeyboardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.KeyboardData";
    };

    return KeyboardData;
  })();

  geelevel.SetCursorData = (function () {
    /**
     * Properties of a SetCursorData.
     * @memberof geelevel
     * @interface ISetCursorData
     * @property {number|null} [id] SetCursorData id
     * @property {number|null} [x] SetCursorData x
     * @property {number|null} [y] SetCursorData y
     * @property {number|null} [width] SetCursorData width
     * @property {number|null} [height] SetCursorData height
     * @property {Uint8Array|null} [data] SetCursorData data
     * @property {boolean|null} [hide] SetCursorData hide
     * @property {geelevel.SetCursorData.CursorType|null} [type] SetCursorData type
     */

    /**
     * Constructs a new SetCursorData.
     * @memberof geelevel
     * @classdesc Represents a SetCursorData.
     * @implements ISetCursorData
     * @constructor
     * @param {geelevel.ISetCursorData=} [properties] Properties to set
     */
    function SetCursorData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetCursorData id.
     * @member {number|null|undefined} id
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.id = null;

    /**
     * SetCursorData x.
     * @member {number|null|undefined} x
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.x = null;

    /**
     * SetCursorData y.
     * @member {number|null|undefined} y
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.y = null;

    /**
     * SetCursorData width.
     * @member {number|null|undefined} width
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.width = null;

    /**
     * SetCursorData height.
     * @member {number|null|undefined} height
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.height = null;

    /**
     * SetCursorData data.
     * @member {Uint8Array|null|undefined} data
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.data = null;

    /**
     * SetCursorData hide.
     * @member {boolean|null|undefined} hide
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.hide = null;

    /**
     * SetCursorData type.
     * @member {geelevel.SetCursorData.CursorType|null|undefined} type
     * @memberof geelevel.SetCursorData
     * @instance
     */
    SetCursorData.prototype.type = null;

    /**
     * Creates a new SetCursorData instance using the specified properties.
     * @function create
     * @memberof geelevel.SetCursorData
     * @static
     * @param {geelevel.ISetCursorData=} [properties] Properties to set
     * @returns {geelevel.SetCursorData} SetCursorData instance
     */
    SetCursorData.create = function create(properties) {
      return new SetCursorData(properties);
    };

    /**
     * Encodes the specified SetCursorData message. Does not implicitly {@link geelevel.SetCursorData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.SetCursorData
     * @static
     * @param {geelevel.ISetCursorData} message SetCursorData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetCursorData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.id);
      if (message.x != null && Object.hasOwnProperty.call(message, "x")) writer.uint32(/* id 2, wireType 0 =*/ 16).sint32(message.x);
      if (message.y != null && Object.hasOwnProperty.call(message, "y")) writer.uint32(/* id 3, wireType 0 =*/ 24).sint32(message.y);
      if (message.width != null && Object.hasOwnProperty.call(message, "width")) writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.width);
      if (message.height != null && Object.hasOwnProperty.call(message, "height")) writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.height);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 6, wireType 2 =*/ 50).bytes(message.data);
      if (message.hide != null && Object.hasOwnProperty.call(message, "hide")) writer.uint32(/* id 7, wireType 0 =*/ 56).bool(message.hide);
      if (message.type != null && Object.hasOwnProperty.call(message, "type")) writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.type);
      return writer;
    };

    /**
     * Encodes the specified SetCursorData message, length delimited. Does not implicitly {@link geelevel.SetCursorData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.SetCursorData
     * @static
     * @param {geelevel.ISetCursorData} message SetCursorData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetCursorData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetCursorData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.SetCursorData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.SetCursorData} SetCursorData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetCursorData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.SetCursorData();
      while (reader.pos < end) {
        var tag = reader.uint32();

        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint32();
            break;
          }
          case 2: {
            message.x = reader.sint32();
            break;
          }
          case 3: {
            message.y = reader.sint32();
            break;
          }
          case 4: {
            message.width = reader.uint32();
            break;
          }
          case 5: {
            message.height = reader.uint32();
            break;
          }
          case 6: {
            message.data = reader.bytes();
            break;
          }
          case 7: {
            message.hide = reader.bool();
            break;
          }
          case 8: {
            message.type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetCursorData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.SetCursorData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.SetCursorData} SetCursorData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetCursorData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetCursorData message.
     * @function verify
     * @memberof geelevel.SetCursorData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetCursorData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id")) if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.x != null && message.hasOwnProperty("x")) if (!$util.isInteger(message.x)) return "x: integer expected";
      if (message.y != null && message.hasOwnProperty("y")) if (!$util.isInteger(message.y)) return "y: integer expected";
      if (message.width != null && message.hasOwnProperty("width")) if (!$util.isInteger(message.width)) return "width: integer expected";
      if (message.height != null && message.hasOwnProperty("height")) if (!$util.isInteger(message.height)) return "height: integer expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!((message.data && typeof message.data.length === "number") || $util.isString(message.data))) return "data: buffer expected";
      if (message.hide != null && message.hasOwnProperty("hide")) if (typeof message.hide !== "boolean") return "hide: boolean expected";
      if (message.type != null && message.hasOwnProperty("type"))
        switch (message.type) {
          default:
            return "type: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            break;
        }
      return null;
    };

    /**
     * Creates a SetCursorData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.SetCursorData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.SetCursorData} SetCursorData
     */
    SetCursorData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.SetCursorData) return object;
      var message = new $root.geelevel.SetCursorData();
      if (object.id != null) message.id = object.id >>> 0;
      if (object.x != null) message.x = object.x | 0;
      if (object.y != null) message.y = object.y | 0;
      if (object.width != null) message.width = object.width >>> 0;
      if (object.height != null) message.height = object.height >>> 0;
      if (object.data != null)
        if (typeof object.data === "string") $util.base64.decode(object.data, (message.data = $util.newBuffer($util.base64.length(object.data))), 0);
        else if (object.data.length >= 0) message.data = object.data;
      if (object.hide != null) message.hide = Boolean(object.hide);
      switch (object.type) {
        default:
          if (typeof object.type === "number") {
            message.type = object.type;
            break;
          }
          break;
        case "NONE":
        case 0:
          message.type = 0;
          break;
        case "ARROW":
        case 1:
          message.type = 1;
          break;
        case "IBEAM":
        case 2:
          message.type = 2;
          break;
        case "WAIT":
        case 3:
          message.type = 3;
          break;
        case "CROSS":
        case 4:
          message.type = 4;
          break;
        case "UPARROW":
        case 5:
          message.type = 5;
          break;
        case "SIZENWSE":
        case 6:
          message.type = 6;
          break;
        case "SIZENESW":
        case 7:
          message.type = 7;
          break;
        case "SIZEWE":
        case 8:
          message.type = 8;
          break;
        case "SIZENS":
        case 9:
          message.type = 9;
          break;
        case "SIZEALL":
        case 10:
          message.type = 10;
          break;
        case "NO":
        case 11:
          message.type = 11;
          break;
        case "HAND":
        case 12:
          message.type = 12;
          break;
        case "APPSTARTING":
        case 13:
          message.type = 13;
          break;
        case "HELP":
        case 14:
          message.type = 14;
          break;
        case "PIN":
        case 15:
          message.type = 15;
          break;
        case "PERSON":
        case 16:
          message.type = 16;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a SetCursorData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.SetCursorData
     * @static
     * @param {geelevel.SetCursorData} message SetCursorData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetCursorData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.x = 0;
        object.y = 0;
        object.width = 0;
        object.height = 0;
        if (options.bytes === String) object.data = "";
        else {
          object.data = [];
          if (options.bytes !== Array) object.data = $util.newBuffer(object.data);
        }
        object.hide = false;
        object.type = options.enums === String ? "NONE" : 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.x != null && message.hasOwnProperty("x")) object.x = message.x;
      if (message.y != null && message.hasOwnProperty("y")) object.y = message.y;
      if (message.width != null && message.hasOwnProperty("width")) object.width = message.width;
      if (message.height != null && message.hasOwnProperty("height")) object.height = message.height;
      if (message.data != null && message.hasOwnProperty("data"))
        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
      if (message.hide != null && message.hasOwnProperty("hide")) object.hide = message.hide;
      if (message.type != null && message.hasOwnProperty("type"))
        object.type =
          options.enums === String ? ($root.geelevel.SetCursorData.CursorType[message.type] === undefined ? message.type : $root.geelevel.SetCursorData.CursorType[message.type]) : message.type;
      return object;
    };

    /**
     * Converts this SetCursorData to JSON.
     * @function toJSON
     * @memberof geelevel.SetCursorData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetCursorData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetCursorData
     * @function getTypeUrl
     * @memberof geelevel.SetCursorData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetCursorData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.SetCursorData";
    };

    /**
     * CursorType enum.
     * @name geelevel.SetCursorData.CursorType
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ARROW=1 ARROW value
     * @property {number} IBEAM=2 IBEAM value
     * @property {number} WAIT=3 WAIT value
     * @property {number} CROSS=4 CROSS value
     * @property {number} UPARROW=5 UPARROW value
     * @property {number} SIZENWSE=6 SIZENWSE value
     * @property {number} SIZENESW=7 SIZENESW value
     * @property {number} SIZEWE=8 SIZEWE value
     * @property {number} SIZENS=9 SIZENS value
     * @property {number} SIZEALL=10 SIZEALL value
     * @property {number} NO=11 NO value
     * @property {number} HAND=12 HAND value
     * @property {number} APPSTARTING=13 APPSTARTING value
     * @property {number} HELP=14 HELP value
     * @property {number} PIN=15 PIN value
     * @property {number} PERSON=16 PERSON value
     */
    SetCursorData.CursorType = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "NONE")] = 0;
      values[(valuesById[1] = "ARROW")] = 1;
      values[(valuesById[2] = "IBEAM")] = 2;
      values[(valuesById[3] = "WAIT")] = 3;
      values[(valuesById[4] = "CROSS")] = 4;
      values[(valuesById[5] = "UPARROW")] = 5;
      values[(valuesById[6] = "SIZENWSE")] = 6;
      values[(valuesById[7] = "SIZENESW")] = 7;
      values[(valuesById[8] = "SIZEWE")] = 8;
      values[(valuesById[9] = "SIZENS")] = 9;
      values[(valuesById[10] = "SIZEALL")] = 10;
      values[(valuesById[11] = "NO")] = 11;
      values[(valuesById[12] = "HAND")] = 12;
      values[(valuesById[13] = "APPSTARTING")] = 13;
      values[(valuesById[14] = "HELP")] = 14;
      values[(valuesById[15] = "PIN")] = 15;
      values[(valuesById[16] = "PERSON")] = 16;
      return values;
    })();

    return SetCursorData;
  })();

  geelevel.CheckGameMode = (function () {
    /**
     * Properties of a CheckGameMode.
     * @memberof geelevel
     * @interface ICheckGameMode
     * @property {number|null} [type] CheckGameMode type
     * @property {number|null} [result] CheckGameMode result
     */

    /**
     * Constructs a new CheckGameMode.
     * @memberof geelevel
     * @classdesc Represents a CheckGameMode.
     * @implements ICheckGameMode
     * @constructor
     * @param {geelevel.ICheckGameMode=} [properties] Properties to set
     */
    function CheckGameMode(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckGameMode type.
     * @member {number|null|undefined} type
     * @memberof geelevel.CheckGameMode
     * @instance
     */
    CheckGameMode.prototype.type = null;

    /**
     * CheckGameMode result.
     * @member {number|null|undefined} result
     * @memberof geelevel.CheckGameMode
     * @instance
     */
    CheckGameMode.prototype.result = null;

    /**
     * Creates a new CheckGameMode instance using the specified properties.
     * @function create
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {geelevel.ICheckGameMode=} [properties] Properties to set
     * @returns {geelevel.CheckGameMode} CheckGameMode instance
     */
    CheckGameMode.create = function create(properties) {
      return new CheckGameMode(properties);
    };

    /**
     * Encodes the specified CheckGameMode message. Does not implicitly {@link geelevel.CheckGameMode.verify|verify} messages.
     * @function encode
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {geelevel.ICheckGameMode} message CheckGameMode message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckGameMode.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.type != null && Object.hasOwnProperty.call(message, "type")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.type);
      if (message.result != null && Object.hasOwnProperty.call(message, "result")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.result);
      return writer;
    };

    /**
     * Encodes the specified CheckGameMode message, length delimited. Does not implicitly {@link geelevel.CheckGameMode.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {geelevel.ICheckGameMode} message CheckGameMode message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckGameMode.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckGameMode message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.CheckGameMode} CheckGameMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckGameMode.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.CheckGameMode();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.type = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CheckGameMode message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.CheckGameMode} CheckGameMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckGameMode.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckGameMode message.
     * @function verify
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckGameMode.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.type != null && message.hasOwnProperty("type")) if (!$util.isInteger(message.type)) return "type: integer expected";
      if (message.result != null && message.hasOwnProperty("result")) if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a CheckGameMode message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.CheckGameMode} CheckGameMode
     */
    CheckGameMode.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.CheckGameMode) return object;
      var message = new $root.geelevel.CheckGameMode();
      if (object.type != null) message.type = object.type >>> 0;
      if (object.result != null) message.result = object.result >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a CheckGameMode message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {geelevel.CheckGameMode} message CheckGameMode
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckGameMode.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.type = 0;
        object.result = 0;
      }
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this CheckGameMode to JSON.
     * @function toJSON
     * @memberof geelevel.CheckGameMode
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckGameMode.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckGameMode
     * @function getTypeUrl
     * @memberof geelevel.CheckGameMode
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckGameMode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.CheckGameMode";
    };

    return CheckGameMode;
  })();

  geelevel.MountHdd = (function () {
    /**
     * Properties of a MountHdd.
     * @memberof geelevel
     * @interface IMountHdd
     * @property {string|null} [path] MountHdd path
     * @property {string|null} [label] MountHdd label
     * @property {number|null} [authority] MountHdd authority
     */

    /**
     * Constructs a new MountHdd.
     * @memberof geelevel
     * @classdesc Represents a MountHdd.
     * @implements IMountHdd
     * @constructor
     * @param {geelevel.IMountHdd=} [properties] Properties to set
     */
    function MountHdd(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MountHdd path.
     * @member {string|null|undefined} path
     * @memberof geelevel.MountHdd
     * @instance
     */
    MountHdd.prototype.path = null;

    /**
     * MountHdd label.
     * @member {string|null|undefined} label
     * @memberof geelevel.MountHdd
     * @instance
     */
    MountHdd.prototype.label = null;

    /**
     * MountHdd authority.
     * @member {number|null|undefined} authority
     * @memberof geelevel.MountHdd
     * @instance
     */
    MountHdd.prototype.authority = null;

    /**
     * Creates a new MountHdd instance using the specified properties.
     * @function create
     * @memberof geelevel.MountHdd
     * @static
     * @param {geelevel.IMountHdd=} [properties] Properties to set
     * @returns {geelevel.MountHdd} MountHdd instance
     */
    MountHdd.create = function create(properties) {
      return new MountHdd(properties);
    };

    /**
     * Encodes the specified MountHdd message. Does not implicitly {@link geelevel.MountHdd.verify|verify} messages.
     * @function encode
     * @memberof geelevel.MountHdd
     * @static
     * @param {geelevel.IMountHdd} message MountHdd message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MountHdd.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.path != null && Object.hasOwnProperty.call(message, "path")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.path);
      if (message.label != null && Object.hasOwnProperty.call(message, "label")) writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.label);
      if (message.authority != null && Object.hasOwnProperty.call(message, "authority")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.authority);
      return writer;
    };

    /**
     * Encodes the specified MountHdd message, length delimited. Does not implicitly {@link geelevel.MountHdd.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.MountHdd
     * @static
     * @param {geelevel.IMountHdd} message MountHdd message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MountHdd.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MountHdd message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.MountHdd
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.MountHdd} MountHdd
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MountHdd.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.MountHdd();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.path = reader.string();
            break;
          }
          case 2: {
            message.label = reader.string();
            break;
          }
          case 3: {
            message.authority = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MountHdd message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.MountHdd
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.MountHdd} MountHdd
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MountHdd.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MountHdd message.
     * @function verify
     * @memberof geelevel.MountHdd
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MountHdd.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.path != null && message.hasOwnProperty("path")) if (!$util.isString(message.path)) return "path: string expected";
      if (message.label != null && message.hasOwnProperty("label")) if (!$util.isString(message.label)) return "label: string expected";
      if (message.authority != null && message.hasOwnProperty("authority")) if (!$util.isInteger(message.authority)) return "authority: integer expected";
      return null;
    };

    /**
     * Creates a MountHdd message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.MountHdd
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.MountHdd} MountHdd
     */
    MountHdd.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.MountHdd) return object;
      var message = new $root.geelevel.MountHdd();
      if (object.path != null) message.path = String(object.path);
      if (object.label != null) message.label = String(object.label);
      if (object.authority != null) message.authority = object.authority >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a MountHdd message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.MountHdd
     * @static
     * @param {geelevel.MountHdd} message MountHdd
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MountHdd.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.path = "";
        object.label = "";
        object.authority = 0;
      }
      if (message.path != null && message.hasOwnProperty("path")) object.path = message.path;
      if (message.label != null && message.hasOwnProperty("label")) object.label = message.label;
      if (message.authority != null && message.hasOwnProperty("authority")) object.authority = message.authority;
      return object;
    };

    /**
     * Converts this MountHdd to JSON.
     * @function toJSON
     * @memberof geelevel.MountHdd
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MountHdd.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MountHdd
     * @function getTypeUrl
     * @memberof geelevel.MountHdd
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MountHdd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.MountHdd";
    };

    return MountHdd;
  })();

  geelevel.FileTransferBegin = (function () {
    /**
     * Properties of a FileTransferBegin.
     * @memberof geelevel
     * @interface IFileTransferBegin
     * @property {number|Long|null} [id] FileTransferBegin id
     * @property {Uint8Array|null} [name] FileTransferBegin name
     * @property {number|Long|null} [size] FileTransferBegin size
     */

    /**
     * Constructs a new FileTransferBegin.
     * @memberof geelevel
     * @classdesc Represents a FileTransferBegin.
     * @implements IFileTransferBegin
     * @constructor
     * @param {geelevel.IFileTransferBegin=} [properties] Properties to set
     */
    function FileTransferBegin(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferBegin id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferBegin
     * @instance
     */
    FileTransferBegin.prototype.id = null;

    /**
     * FileTransferBegin name.
     * @member {Uint8Array|null|undefined} name
     * @memberof geelevel.FileTransferBegin
     * @instance
     */
    FileTransferBegin.prototype.name = null;

    /**
     * FileTransferBegin size.
     * @member {number|Long|null|undefined} size
     * @memberof geelevel.FileTransferBegin
     * @instance
     */
    FileTransferBegin.prototype.size = null;

    /**
     * Creates a new FileTransferBegin instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {geelevel.IFileTransferBegin=} [properties] Properties to set
     * @returns {geelevel.FileTransferBegin} FileTransferBegin instance
     */
    FileTransferBegin.create = function create(properties) {
      return new FileTransferBegin(properties);
    };

    /**
     * Encodes the specified FileTransferBegin message. Does not implicitly {@link geelevel.FileTransferBegin.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {geelevel.IFileTransferBegin} message FileTransferBegin message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferBegin.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.id);
      if (message.name != null && Object.hasOwnProperty.call(message, "name")) writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.name);
      if (message.size != null && Object.hasOwnProperty.call(message, "size")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.size);
      return writer;
    };

    /**
     * Encodes the specified FileTransferBegin message, length delimited. Does not implicitly {@link geelevel.FileTransferBegin.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {geelevel.IFileTransferBegin} message FileTransferBegin message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferBegin.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferBegin message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferBegin} FileTransferBegin
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferBegin.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferBegin();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint64();
            break;
          }
          case 2: {
            message.name = reader.bytes();
            break;
          }
          case 3: {
            message.size = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferBegin message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferBegin} FileTransferBegin
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferBegin.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferBegin message.
     * @function verify
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferBegin.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      if (message.name != null && message.hasOwnProperty("name")) if (!((message.name && typeof message.name.length === "number") || $util.isString(message.name))) return "name: buffer expected";
      if (message.size != null && message.hasOwnProperty("size"))
        if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high))) return "size: integer|Long expected";
      return null;
    };

    /**
     * Creates a FileTransferBegin message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferBegin} FileTransferBegin
     */
    FileTransferBegin.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferBegin) return object;
      var message = new $root.geelevel.FileTransferBegin();
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      if (object.name != null)
        if (typeof object.name === "string") $util.base64.decode(object.name, (message.name = $util.newBuffer($util.base64.length(object.name))), 0);
        else if (object.name.length >= 0) message.name = object.name;
      if (object.size != null)
        if ($util.Long) (message.size = $util.Long.fromValue(object.size)).unsigned = true;
        else if (typeof object.size === "string") message.size = parseInt(object.size, 10);
        else if (typeof object.size === "number") message.size = object.size;
        else if (typeof object.size === "object") message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a FileTransferBegin message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {geelevel.FileTransferBegin} message FileTransferBegin
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferBegin.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
        if (options.bytes === String) object.name = "";
        else {
          object.name = [];
          if (options.bytes !== Array) object.name = $util.newBuffer(object.name);
        }
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.size = options.longs === String ? "0" : 0;
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      if (message.name != null && message.hasOwnProperty("name"))
        object.name = options.bytes === String ? $util.base64.encode(message.name, 0, message.name.length) : options.bytes === Array ? Array.prototype.slice.call(message.name) : message.name;
      if (message.size != null && message.hasOwnProperty("size"))
        if (typeof message.size === "number") object.size = options.longs === String ? String(message.size) : message.size;
        else
          object.size =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.size)
              : options.longs === Number
              ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true)
              : message.size;
      return object;
    };

    /**
     * Converts this FileTransferBegin to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferBegin
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferBegin.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferBegin
     * @function getTypeUrl
     * @memberof geelevel.FileTransferBegin
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferBegin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferBegin";
    };

    return FileTransferBegin;
  })();

  geelevel.FileTransferBeginAck = (function () {
    /**
     * Properties of a FileTransferBeginAck.
     * @memberof geelevel
     * @interface IFileTransferBeginAck
     * @property {number|null} [result] FileTransferBeginAck result
     * @property {number|Long|null} [id] FileTransferBeginAck id
     */

    /**
     * Constructs a new FileTransferBeginAck.
     * @memberof geelevel
     * @classdesc Represents a FileTransferBeginAck.
     * @implements IFileTransferBeginAck
     * @constructor
     * @param {geelevel.IFileTransferBeginAck=} [properties] Properties to set
     */
    function FileTransferBeginAck(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferBeginAck result.
     * @member {number|null|undefined} result
     * @memberof geelevel.FileTransferBeginAck
     * @instance
     */
    FileTransferBeginAck.prototype.result = null;

    /**
     * FileTransferBeginAck id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferBeginAck
     * @instance
     */
    FileTransferBeginAck.prototype.id = null;

    /**
     * Creates a new FileTransferBeginAck instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {geelevel.IFileTransferBeginAck=} [properties] Properties to set
     * @returns {geelevel.FileTransferBeginAck} FileTransferBeginAck instance
     */
    FileTransferBeginAck.create = function create(properties) {
      return new FileTransferBeginAck(properties);
    };

    /**
     * Encodes the specified FileTransferBeginAck message. Does not implicitly {@link geelevel.FileTransferBeginAck.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {geelevel.IFileTransferBeginAck} message FileTransferBeginAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferBeginAck.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result")) writer.uint32(/* id 1, wireType 0 =*/ 8).sint32(message.result);
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.id);
      return writer;
    };

    /**
     * Encodes the specified FileTransferBeginAck message, length delimited. Does not implicitly {@link geelevel.FileTransferBeginAck.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {geelevel.IFileTransferBeginAck} message FileTransferBeginAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferBeginAck.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferBeginAck message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferBeginAck} FileTransferBeginAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferBeginAck.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferBeginAck();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.sint32();
            break;
          }
          case 2: {
            message.id = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferBeginAck message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferBeginAck} FileTransferBeginAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferBeginAck.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferBeginAck message.
     * @function verify
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferBeginAck.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result")) if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      return null;
    };

    /**
     * Creates a FileTransferBeginAck message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferBeginAck} FileTransferBeginAck
     */
    FileTransferBeginAck.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferBeginAck) return object;
      var message = new $root.geelevel.FileTransferBeginAck();
      if (object.result != null) message.result = object.result | 0;
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a FileTransferBeginAck message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {geelevel.FileTransferBeginAck} message FileTransferBeginAck
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferBeginAck.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      return object;
    };

    /**
     * Converts this FileTransferBeginAck to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferBeginAck
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferBeginAck.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferBeginAck
     * @function getTypeUrl
     * @memberof geelevel.FileTransferBeginAck
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferBeginAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferBeginAck";
    };

    return FileTransferBeginAck;
  })();

  geelevel.FileTransferData = (function () {
    /**
     * Properties of a FileTransferData.
     * @memberof geelevel
     * @interface IFileTransferData
     * @property {number|Long|null} [id] FileTransferData id
     * @property {string|null} [md5] FileTransferData md5
     * @property {Uint8Array|null} [data] FileTransferData data
     */

    /**
     * Constructs a new FileTransferData.
     * @memberof geelevel
     * @classdesc Represents a FileTransferData.
     * @implements IFileTransferData
     * @constructor
     * @param {geelevel.IFileTransferData=} [properties] Properties to set
     */
    function FileTransferData(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferData id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferData
     * @instance
     */
    FileTransferData.prototype.id = null;

    /**
     * FileTransferData md5.
     * @member {string|null|undefined} md5
     * @memberof geelevel.FileTransferData
     * @instance
     */
    FileTransferData.prototype.md5 = null;

    /**
     * FileTransferData data.
     * @member {Uint8Array|null|undefined} data
     * @memberof geelevel.FileTransferData
     * @instance
     */
    FileTransferData.prototype.data = null;

    /**
     * Creates a new FileTransferData instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferData
     * @static
     * @param {geelevel.IFileTransferData=} [properties] Properties to set
     * @returns {geelevel.FileTransferData} FileTransferData instance
     */
    FileTransferData.create = function create(properties) {
      return new FileTransferData(properties);
    };

    /**
     * Encodes the specified FileTransferData message. Does not implicitly {@link geelevel.FileTransferData.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferData
     * @static
     * @param {geelevel.IFileTransferData} message FileTransferData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.id);
      if (message.md5 != null && Object.hasOwnProperty.call(message, "md5")) writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.md5);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.data);
      return writer;
    };

    /**
     * Encodes the specified FileTransferData message, length delimited. Does not implicitly {@link geelevel.FileTransferData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferData
     * @static
     * @param {geelevel.IFileTransferData} message FileTransferData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferData message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferData} FileTransferData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint64();
            break;
          }
          case 2: {
            message.md5 = reader.string();
            break;
          }
          case 3: {
            message.data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferData} FileTransferData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferData message.
     * @function verify
     * @memberof geelevel.FileTransferData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      if (message.md5 != null && message.hasOwnProperty("md5")) if (!$util.isString(message.md5)) return "md5: string expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!((message.data && typeof message.data.length === "number") || $util.isString(message.data))) return "data: buffer expected";
      return null;
    };

    /**
     * Creates a FileTransferData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferData} FileTransferData
     */
    FileTransferData.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferData) return object;
      var message = new $root.geelevel.FileTransferData();
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      if (object.md5 != null) message.md5 = String(object.md5);
      if (object.data != null)
        if (typeof object.data === "string") $util.base64.decode(object.data, (message.data = $util.newBuffer($util.base64.length(object.data))), 0);
        else if (object.data.length >= 0) message.data = object.data;
      return message;
    };

    /**
     * Creates a plain object from a FileTransferData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferData
     * @static
     * @param {geelevel.FileTransferData} message FileTransferData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
        object.md5 = "";
        if (options.bytes === String) object.data = "";
        else {
          object.data = [];
          if (options.bytes !== Array) object.data = $util.newBuffer(object.data);
        }
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      if (message.md5 != null && message.hasOwnProperty("md5")) object.md5 = message.md5;
      if (message.data != null && message.hasOwnProperty("data"))
        object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
      return object;
    };

    /**
     * Converts this FileTransferData to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferData
     * @function getTypeUrl
     * @memberof geelevel.FileTransferData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferData";
    };

    return FileTransferData;
  })();

  geelevel.FileTransferDataAck = (function () {
    /**
     * Properties of a FileTransferDataAck.
     * @memberof geelevel
     * @interface IFileTransferDataAck
     * @property {number|null} [result] FileTransferDataAck result
     * @property {number|Long|null} [id] FileTransferDataAck id
     */

    /**
     * Constructs a new FileTransferDataAck.
     * @memberof geelevel
     * @classdesc Represents a FileTransferDataAck.
     * @implements IFileTransferDataAck
     * @constructor
     * @param {geelevel.IFileTransferDataAck=} [properties] Properties to set
     */
    function FileTransferDataAck(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferDataAck result.
     * @member {number|null|undefined} result
     * @memberof geelevel.FileTransferDataAck
     * @instance
     */
    FileTransferDataAck.prototype.result = null;

    /**
     * FileTransferDataAck id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferDataAck
     * @instance
     */
    FileTransferDataAck.prototype.id = null;

    /**
     * Creates a new FileTransferDataAck instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {geelevel.IFileTransferDataAck=} [properties] Properties to set
     * @returns {geelevel.FileTransferDataAck} FileTransferDataAck instance
     */
    FileTransferDataAck.create = function create(properties) {
      return new FileTransferDataAck(properties);
    };

    /**
     * Encodes the specified FileTransferDataAck message. Does not implicitly {@link geelevel.FileTransferDataAck.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {geelevel.IFileTransferDataAck} message FileTransferDataAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferDataAck.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result")) writer.uint32(/* id 1, wireType 0 =*/ 8).sint32(message.result);
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.id);
      return writer;
    };

    /**
     * Encodes the specified FileTransferDataAck message, length delimited. Does not implicitly {@link geelevel.FileTransferDataAck.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {geelevel.IFileTransferDataAck} message FileTransferDataAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferDataAck.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferDataAck message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferDataAck} FileTransferDataAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferDataAck.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferDataAck();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.sint32();
            break;
          }
          case 2: {
            message.id = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferDataAck message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferDataAck} FileTransferDataAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferDataAck.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferDataAck message.
     * @function verify
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferDataAck.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result")) if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      return null;
    };

    /**
     * Creates a FileTransferDataAck message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferDataAck} FileTransferDataAck
     */
    FileTransferDataAck.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferDataAck) return object;
      var message = new $root.geelevel.FileTransferDataAck();
      if (object.result != null) message.result = object.result | 0;
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a FileTransferDataAck message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {geelevel.FileTransferDataAck} message FileTransferDataAck
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferDataAck.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      return object;
    };

    /**
     * Converts this FileTransferDataAck to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferDataAck
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferDataAck.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferDataAck
     * @function getTypeUrl
     * @memberof geelevel.FileTransferDataAck
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferDataAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferDataAck";
    };

    return FileTransferDataAck;
  })();

  geelevel.FileTransferEnd = (function () {
    /**
     * Properties of a FileTransferEnd.
     * @memberof geelevel
     * @interface IFileTransferEnd
     * @property {number|Long|null} [id] FileTransferEnd id
     */

    /**
     * Constructs a new FileTransferEnd.
     * @memberof geelevel
     * @classdesc Represents a FileTransferEnd.
     * @implements IFileTransferEnd
     * @constructor
     * @param {geelevel.IFileTransferEnd=} [properties] Properties to set
     */
    function FileTransferEnd(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferEnd id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferEnd
     * @instance
     */
    FileTransferEnd.prototype.id = null;

    /**
     * Creates a new FileTransferEnd instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {geelevel.IFileTransferEnd=} [properties] Properties to set
     * @returns {geelevel.FileTransferEnd} FileTransferEnd instance
     */
    FileTransferEnd.create = function create(properties) {
      return new FileTransferEnd(properties);
    };

    /**
     * Encodes the specified FileTransferEnd message. Does not implicitly {@link geelevel.FileTransferEnd.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {geelevel.IFileTransferEnd} message FileTransferEnd message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferEnd.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.id);
      return writer;
    };

    /**
     * Encodes the specified FileTransferEnd message, length delimited. Does not implicitly {@link geelevel.FileTransferEnd.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {geelevel.IFileTransferEnd} message FileTransferEnd message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferEnd.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferEnd message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferEnd} FileTransferEnd
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferEnd.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferEnd();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferEnd message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferEnd} FileTransferEnd
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferEnd.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferEnd message.
     * @function verify
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferEnd.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      return null;
    };

    /**
     * Creates a FileTransferEnd message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferEnd} FileTransferEnd
     */
    FileTransferEnd.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferEnd) return object;
      var message = new $root.geelevel.FileTransferEnd();
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a FileTransferEnd message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {geelevel.FileTransferEnd} message FileTransferEnd
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferEnd.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults)
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      return object;
    };

    /**
     * Converts this FileTransferEnd to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferEnd
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferEnd.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferEnd
     * @function getTypeUrl
     * @memberof geelevel.FileTransferEnd
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferEnd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferEnd";
    };

    return FileTransferEnd;
  })();

  geelevel.FileTransferEndAck = (function () {
    /**
     * Properties of a FileTransferEndAck.
     * @memberof geelevel
     * @interface IFileTransferEndAck
     * @property {number|Long|null} [id] FileTransferEndAck id
     * @property {number|null} [result] FileTransferEndAck result
     */

    /**
     * Constructs a new FileTransferEndAck.
     * @memberof geelevel
     * @classdesc Represents a FileTransferEndAck.
     * @implements IFileTransferEndAck
     * @constructor
     * @param {geelevel.IFileTransferEndAck=} [properties] Properties to set
     */
    function FileTransferEndAck(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferEndAck id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferEndAck
     * @instance
     */
    FileTransferEndAck.prototype.id = null;

    /**
     * FileTransferEndAck result.
     * @member {number|null|undefined} result
     * @memberof geelevel.FileTransferEndAck
     * @instance
     */
    FileTransferEndAck.prototype.result = null;

    /**
     * Creates a new FileTransferEndAck instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {geelevel.IFileTransferEndAck=} [properties] Properties to set
     * @returns {geelevel.FileTransferEndAck} FileTransferEndAck instance
     */
    FileTransferEndAck.create = function create(properties) {
      return new FileTransferEndAck(properties);
    };

    /**
     * Encodes the specified FileTransferEndAck message. Does not implicitly {@link geelevel.FileTransferEndAck.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {geelevel.IFileTransferEndAck} message FileTransferEndAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferEndAck.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result")) writer.uint32(/* id 2, wireType 0 =*/ 16).sint32(message.result);
      return writer;
    };

    /**
     * Encodes the specified FileTransferEndAck message, length delimited. Does not implicitly {@link geelevel.FileTransferEndAck.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {geelevel.IFileTransferEndAck} message FileTransferEndAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferEndAck.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferEndAck message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferEndAck} FileTransferEndAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferEndAck.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferEndAck();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint64();
            break;
          }
          case 2: {
            message.result = reader.sint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferEndAck message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferEndAck} FileTransferEndAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferEndAck.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferEndAck message.
     * @function verify
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferEndAck.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      if (message.result != null && message.hasOwnProperty("result")) if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a FileTransferEndAck message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferEndAck} FileTransferEndAck
     */
    FileTransferEndAck.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferEndAck) return object;
      var message = new $root.geelevel.FileTransferEndAck();
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a FileTransferEndAck message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {geelevel.FileTransferEndAck} message FileTransferEndAck
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferEndAck.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
        object.result = 0;
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this FileTransferEndAck to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferEndAck
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferEndAck.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferEndAck
     * @function getTypeUrl
     * @memberof geelevel.FileTransferEndAck
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferEndAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferEndAck";
    };

    return FileTransferEndAck;
  })();

  geelevel.FileTransferControl = (function () {
    /**
     * Properties of a FileTransferControl.
     * @memberof geelevel
     * @interface IFileTransferControl
     * @property {number|Long|null} [id] FileTransferControl id
     * @property {number|null} [action] FileTransferControl action
     */

    /**
     * Constructs a new FileTransferControl.
     * @memberof geelevel
     * @classdesc Represents a FileTransferControl.
     * @implements IFileTransferControl
     * @constructor
     * @param {geelevel.IFileTransferControl=} [properties] Properties to set
     */
    function FileTransferControl(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FileTransferControl id.
     * @member {number|Long|null|undefined} id
     * @memberof geelevel.FileTransferControl
     * @instance
     */
    FileTransferControl.prototype.id = null;

    /**
     * FileTransferControl action.
     * @member {number|null|undefined} action
     * @memberof geelevel.FileTransferControl
     * @instance
     */
    FileTransferControl.prototype.action = null;

    /**
     * Creates a new FileTransferControl instance using the specified properties.
     * @function create
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {geelevel.IFileTransferControl=} [properties] Properties to set
     * @returns {geelevel.FileTransferControl} FileTransferControl instance
     */
    FileTransferControl.create = function create(properties) {
      return new FileTransferControl(properties);
    };

    /**
     * Encodes the specified FileTransferControl message. Does not implicitly {@link geelevel.FileTransferControl.verify|verify} messages.
     * @function encode
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {geelevel.IFileTransferControl} message FileTransferControl message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferControl.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id")) writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.id);
      if (message.action != null && Object.hasOwnProperty.call(message, "action")) writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.action);
      return writer;
    };

    /**
     * Encodes the specified FileTransferControl message, length delimited. Does not implicitly {@link geelevel.FileTransferControl.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {geelevel.IFileTransferControl} message FileTransferControl message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FileTransferControl.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FileTransferControl message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.FileTransferControl} FileTransferControl
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferControl.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.FileTransferControl();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.uint64();
            break;
          }
          case 2: {
            message.action = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FileTransferControl message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.FileTransferControl} FileTransferControl
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FileTransferControl.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FileTransferControl message.
     * @function verify
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FileTransferControl.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))) return "id: integer|Long expected";
      if (message.action != null && message.hasOwnProperty("action")) if (!$util.isInteger(message.action)) return "action: integer expected";
      return null;
    };

    /**
     * Creates a FileTransferControl message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.FileTransferControl} FileTransferControl
     */
    FileTransferControl.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.FileTransferControl) return object;
      var message = new $root.geelevel.FileTransferControl();
      if (object.id != null)
        if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
        else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
        else if (typeof object.id === "number") message.id = object.id;
        else if (typeof object.id === "object") message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
      if (object.action != null) message.action = object.action >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a FileTransferControl message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {geelevel.FileTransferControl} message FileTransferControl
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FileTransferControl.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.id = options.longs === String ? "0" : 0;
        object.action = 0;
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
        else
          object.id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.id)
              : options.longs === Number
              ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
              : message.id;
      if (message.action != null && message.hasOwnProperty("action")) object.action = message.action;
      return object;
    };

    /**
     * Converts this FileTransferControl to JSON.
     * @function toJSON
     * @memberof geelevel.FileTransferControl
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FileTransferControl.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FileTransferControl
     * @function getTypeUrl
     * @memberof geelevel.FileTransferControl
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FileTransferControl.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.FileTransferControl";
    };

    return FileTransferControl;
  })();

  geelevel.ListDirectories = (function () {
    /**
     * Properties of a ListDirectories.
     * @memberof geelevel
     * @interface IListDirectories
     * @property {string|null} [path] ListDirectories path
     */

    /**
     * Constructs a new ListDirectories.
     * @memberof geelevel
     * @classdesc Represents a ListDirectories.
     * @implements IListDirectories
     * @constructor
     * @param {geelevel.IListDirectories=} [properties] Properties to set
     */
    function ListDirectories(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ListDirectories path.
     * @member {string|null|undefined} path
     * @memberof geelevel.ListDirectories
     * @instance
     */
    ListDirectories.prototype.path = null;

    /**
     * Creates a new ListDirectories instance using the specified properties.
     * @function create
     * @memberof geelevel.ListDirectories
     * @static
     * @param {geelevel.IListDirectories=} [properties] Properties to set
     * @returns {geelevel.ListDirectories} ListDirectories instance
     */
    ListDirectories.create = function create(properties) {
      return new ListDirectories(properties);
    };

    /**
     * Encodes the specified ListDirectories message. Does not implicitly {@link geelevel.ListDirectories.verify|verify} messages.
     * @function encode
     * @memberof geelevel.ListDirectories
     * @static
     * @param {geelevel.IListDirectories} message ListDirectories message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ListDirectories.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.path != null && Object.hasOwnProperty.call(message, "path")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.path);
      return writer;
    };

    /**
     * Encodes the specified ListDirectories message, length delimited. Does not implicitly {@link geelevel.ListDirectories.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.ListDirectories
     * @static
     * @param {geelevel.IListDirectories} message ListDirectories message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ListDirectories.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ListDirectories message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.ListDirectories
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.ListDirectories} ListDirectories
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ListDirectories.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.ListDirectories();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.path = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ListDirectories message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.ListDirectories
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.ListDirectories} ListDirectories
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ListDirectories.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ListDirectories message.
     * @function verify
     * @memberof geelevel.ListDirectories
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ListDirectories.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.path != null && message.hasOwnProperty("path")) if (!$util.isString(message.path)) return "path: string expected";
      return null;
    };

    /**
     * Creates a ListDirectories message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.ListDirectories
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.ListDirectories} ListDirectories
     */
    ListDirectories.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.ListDirectories) return object;
      var message = new $root.geelevel.ListDirectories();
      if (object.path != null) message.path = String(object.path);
      return message;
    };

    /**
     * Creates a plain object from a ListDirectories message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.ListDirectories
     * @static
     * @param {geelevel.ListDirectories} message ListDirectories
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ListDirectories.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.path = "";
      if (message.path != null && message.hasOwnProperty("path")) object.path = message.path;
      return object;
    };

    /**
     * Converts this ListDirectories to JSON.
     * @function toJSON
     * @memberof geelevel.ListDirectories
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ListDirectories.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ListDirectories
     * @function getTypeUrl
     * @memberof geelevel.ListDirectories
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ListDirectories.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.ListDirectories";
    };

    return ListDirectories;
  })();

  geelevel.ListDirectoriesAck = (function () {
    /**
     * Properties of a ListDirectoriesAck.
     * @memberof geelevel
     * @interface IListDirectoriesAck
     * @property {string|null} [path] ListDirectoriesAck path
     * @property {string|null} [data] ListDirectoriesAck data
     * @property {number|Long|null} [time] ListDirectoriesAck time
     */

    /**
     * Constructs a new ListDirectoriesAck.
     * @memberof geelevel
     * @classdesc Represents a ListDirectoriesAck.
     * @implements IListDirectoriesAck
     * @constructor
     * @param {geelevel.IListDirectoriesAck=} [properties] Properties to set
     */
    function ListDirectoriesAck(properties) {
      if (properties) for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i) if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ListDirectoriesAck path.
     * @member {string|null|undefined} path
     * @memberof geelevel.ListDirectoriesAck
     * @instance
     */
    ListDirectoriesAck.prototype.path = null;

    /**
     * ListDirectoriesAck data.
     * @member {string|null|undefined} data
     * @memberof geelevel.ListDirectoriesAck
     * @instance
     */
    ListDirectoriesAck.prototype.data = null;

    /**
     * ListDirectoriesAck time.
     * @member {number|Long|null|undefined} time
     * @memberof geelevel.ListDirectoriesAck
     * @instance
     */
    ListDirectoriesAck.prototype.time = null;

    /**
     * Creates a new ListDirectoriesAck instance using the specified properties.
     * @function create
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {geelevel.IListDirectoriesAck=} [properties] Properties to set
     * @returns {geelevel.ListDirectoriesAck} ListDirectoriesAck instance
     */
    ListDirectoriesAck.create = function create(properties) {
      return new ListDirectoriesAck(properties);
    };

    /**
     * Encodes the specified ListDirectoriesAck message. Does not implicitly {@link geelevel.ListDirectoriesAck.verify|verify} messages.
     * @function encode
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {geelevel.IListDirectoriesAck} message ListDirectoriesAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ListDirectoriesAck.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.path != null && Object.hasOwnProperty.call(message, "path")) writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.path);
      if (message.data != null && Object.hasOwnProperty.call(message, "data")) writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.data);
      if (message.time != null && Object.hasOwnProperty.call(message, "time")) writer.uint32(/* id 3, wireType 0 =*/ 24).uint64(message.time);
      return writer;
    };

    /**
     * Encodes the specified ListDirectoriesAck message, length delimited. Does not implicitly {@link geelevel.ListDirectoriesAck.verify|verify} messages.
     * @function encodeDelimited
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {geelevel.IListDirectoriesAck} message ListDirectoriesAck message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ListDirectoriesAck.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ListDirectoriesAck message from the specified reader or buffer.
     * @function decode
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {geelevel.ListDirectoriesAck} ListDirectoriesAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ListDirectoriesAck.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.geelevel.ListDirectoriesAck();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.path = reader.string();
            break;
          }
          case 2: {
            message.data = reader.string();
            break;
          }
          case 3: {
            message.time = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ListDirectoriesAck message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {geelevel.ListDirectoriesAck} ListDirectoriesAck
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ListDirectoriesAck.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ListDirectoriesAck message.
     * @function verify
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ListDirectoriesAck.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.path != null && message.hasOwnProperty("path")) if (!$util.isString(message.path)) return "path: string expected";
      if (message.data != null && message.hasOwnProperty("data")) if (!$util.isString(message.data)) return "data: string expected";
      if (message.time != null && message.hasOwnProperty("time"))
        if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high))) return "time: integer|Long expected";
      return null;
    };

    /**
     * Creates a ListDirectoriesAck message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {geelevel.ListDirectoriesAck} ListDirectoriesAck
     */
    ListDirectoriesAck.fromObject = function fromObject(object) {
      if (object instanceof $root.geelevel.ListDirectoriesAck) return object;
      var message = new $root.geelevel.ListDirectoriesAck();
      if (object.path != null) message.path = String(object.path);
      if (object.data != null) message.data = String(object.data);
      if (object.time != null)
        if ($util.Long) (message.time = $util.Long.fromValue(object.time)).unsigned = true;
        else if (typeof object.time === "string") message.time = parseInt(object.time, 10);
        else if (typeof object.time === "number") message.time = object.time;
        else if (typeof object.time === "object") message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a ListDirectoriesAck message. Also converts values to other types if specified.
     * @function toObject
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {geelevel.ListDirectoriesAck} message ListDirectoriesAck
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ListDirectoriesAck.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.path = "";
        object.data = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.time = options.longs === String ? "0" : 0;
      }
      if (message.path != null && message.hasOwnProperty("path")) object.path = message.path;
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      if (message.time != null && message.hasOwnProperty("time"))
        if (typeof message.time === "number") object.time = options.longs === String ? String(message.time) : message.time;
        else
          object.time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.time)
              : options.longs === Number
              ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true)
              : message.time;
      return object;
    };

    /**
     * Converts this ListDirectoriesAck to JSON.
     * @function toJSON
     * @memberof geelevel.ListDirectoriesAck
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ListDirectoriesAck.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ListDirectoriesAck
     * @function getTypeUrl
     * @memberof geelevel.ListDirectoriesAck
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ListDirectoriesAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/geelevel.ListDirectoriesAck";
    };

    return ListDirectoriesAck;
  })();

  return geelevel;
})();

module.exports = $root;
