import AtKit from "@/lib/AtKit/AtKit";
import realTimeInstance from "@/lib/AtKit/real-time-connection/real-time-connection";
import permissionsInstance from "@/lib/AtKit/permissions/permissions";
import gameInstance from "@/lib/AtKit/game/game";
class Heartbeat {
  constructor(interval = 1) {
    if (Heartbeat.instance) {
      return Heartbeat.instance;
    }
    this.interval = interval || 1; //心跳时间
    this.timerId = null;
    this.accidentalTimeout = null; //无操作定时器
    this.accidentalTime = 5; //无操作定时器的时间默认五分钟
    Heartbeat.instance = this;
  }
  setInterval = (interval) => {
    if (interval > 0) {
      this.interval = interval;
      return;
    }
    this.interval = 1;
  };
  //设置时间
  setNoInputTimeout = (time) => {
    if (time) {
      this.accidentalTime = time;
    }
  };
  //重置无操作定时器
  resetInputTimer = (time) => {
    this.delayedLogger(time);
  };
  /**
   * 例如五分钟没有发送dataChannel给对方就断开
   * @param {*} time 多久没法送数据没操作就断开 默认300 = 5分钟
   */
  delayedLogger = () => {
    // 设置一个新的定时器，在 5 分钟后执行
    if (this.accidentalTimeout) {
      clearInterval(this.accidentalTimeout);
    }
    this.accidentalTimeout = setInterval(() => {
      console.log("无操作定时器执行");
      AtKit.onSceneChanged("stop", {
        reason: "noOperation",
        message: `无操作超时`,
        cId: AtKit.getPlayToken().playToken,
      });
      gameInstance.stopGame(0, true, "无操作超时");
    }, this.accidentalTime * 60 * 1000); // 5 分钟，单位为秒
  };
  cleatAccidental = () => {
    clearInterval(this.accidentalTimeout);
    clearInterval(this.timerId);
  };

  //停止心跳定时器
  stopHeartbeatTimer = () => {
    if (this.timerId) {
      console.log("执行停止心跳");
      clearInterval(this.timerId);
      this.timerId = null;
    }
  };
  //心跳定时器
  startHeartbeatTimer = () => {
    // 先清除现有的定时器
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }

    // 确保 interval 是一个有效的数字
    const intervalMinutes = parseFloat(this.interval);
    if (isNaN(intervalMinutes) || intervalMinutes <= 0) {
      console.error("Invalid interval value:", this.interval);
      return;
    }

    // 计算时间间隔（毫秒）
    const intervalMs = intervalMinutes * 60 * 1000;

    // 启动新的定时器
    this.timerId = setInterval(() => {
      realTimeInstance.sendCloudPc("cardiacEvent", {});
    }, intervalMs);
  };
}
// 直接实例化并导出
const heartbeatInstance = new Heartbeat();
export default heartbeatInstance;
