/**
 * socket.io 文件
 */
import { EventEmitter } from "eventemitter3";
// import { io } from "socket.io-client";
import logCollector from "@/utils/report-log/LogCollector.js";
import AtKit from "@/lib/AtKit/AtKit";
import gameInstance from "../game/game";

export default class Socket extends EventEmitter {
  constructor(host, id) {
    super();
    this.uid = id;
    this.host = host;
    this._socket = null;
  }
  connect(peerId) {
    this._socket.emit("status", peerId);
  }
  //
  stopSocketConnect(peerId) {
    this._socket.emit("stop", peerId);
  }
  // 启动一些基本回调的注册
  start() {
    console.log("start:", 111);
    this._socket = null;
    return new Promise((resolve, reject) => {
      this._socket = io(this.host, {
        // withCredentials: true,
        extraHeaders: {
          device_id: this.uid,
        },
      });

      this._socket.on("connect_error", (error) => {
        console.error(`连接socket失败 ${this.uid}`);
        logCollector.log({
          type: "error",
          eventType: 11,
          eventName: "SDK State",
          details: `连接socket失败 ${this.uid}`,
        });
        reject(new Error(`连接socket失败 ${this.uid}`));
      });

      this._socket.on("logined", () => {
        console.log(`连接socket成功 ${this.uid}`);
        logCollector.log({
          eventType: 11,
          eventName: "SDK State",
          details: `连接socket成功 ${this.uid}`,
        });
        resolve();
      });

      // 注册本机上线 发送给信令
      this._socket.emit("login", this.uid);

      // 检查指定主机是否在线的回调
      this._socket.on("status", (message) => {
        if (message.online !== true) {
          AtKit._emit("error", {
            sceneld: "stop",
            reason: "instanceError",
            message: "对方ID不存在或不在线",
            aID: message.room,
          });
          //调用接口报告
          return;
        }
        this.emit("start", message);
      });

      // 如果有连接本机的消息
      this._socket.on("message", async (message) => {
        this.emit("message", message);
      });
      //队列通知
      this._socket.on("server_notify_update", async (server) => {
        let data = JSON.parse(server.data);
        console.log("收到信令信息:", data);
        //如果没有
        if (!data) {
          return;
        }
        //不是自已
        if ((data.device_id || data?.deviceID) !== gameInstance.localDeviceId) {
          return;
        }
        let data2 = data.data;
        switch (data.type) {
          //队列更新
          case "7":
            if (data.op === "updateQueue") {
              this.emit("queue_information", data2);
              break;
            }
          //停止
          case "5":
            if (data.op === "stop") {
              this.emit("stop", data2);
            }
            break;
          //权限
          case "8":
            this.emit("update_permissions", data2);
            break;
          //支付
          case "9":
            //江湖客栈data.form === "jianghukezhan"
            AtKit.onSceneChanged("paymentNotification", data2.payInfo);
            break;
        }
        logCollector.log({
          eventType: 3,
          eventName: "Signaling State Change",
          details: `收到信令服务通知:${server.data}`,
        });
      });
      this._socket.on("error", (error) => {
        console.log("sokect error: ", error);
      });
    });
  }
  send(data) {
    if (data) {
      this._socket.emit("message", data);
    } else {
      console.log(`error send not find data`);
    }
  }
  close = (remoteID) => {
    this._socket.emit("logout", this.uid);
    // 通知对方主机， 我要关了
    this._socket.emit("close", remoteID);
    this._socket.disconnect();
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `告诉对方,主动关闭socket`,
    });
  };
}
