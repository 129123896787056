import AtKit from "@/lib/AtKit/AtKit";
const setPlayingtime = (playingtime) => {
  const hours = Math.floor(playingtime / 3600);
  const minutes = Math.floor((playingtime % 3600) / 60);
  const remainingSeconds = playingtime % 60;
  AtKit.onSceneChanged("remainingTime", `剩余游戏可玩时长:${hours}时${minutes}分${remainingSeconds}秒`);
  //return { hours, minutes, remainingSeconds };
};

export { setPlayingtime };
