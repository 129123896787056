import AtKit from "@/lib/AtKit/AtKit";
import realTimeInstance from "@/lib/AtKit/real-time-connection/real-time-connection";
import logCollector from "@/utils/report-log/LogCollector.js";
import { toProtobuf } from "@/toolbox";
import gameInstance from "@/lib/AtKit/game/game";
import videoPlayerInstance from "@/utils/player/player.js";
class MouseCursor {
  constructor() {
    if (MouseCursor.instance) {
      return MouseCursor.instance;
    }
    //1.滑鼠 PC鼠标模式
    //2.触控 手机触屏点击 点那里就是那里 有300毫秒延迟判断是否误触攻击
    //4:触屏不响应单击 或者触屏攻击 只能滑动
    this.mouseType = 1; //鼠标模式
    this.initialMouseSpeed = 8; //初始鼠标速度
    this.onReceiveMouseKeyboardMessage = 9; // 9相对位置 1绝对位置
    //鼠标xy
    this.posCursor = {
      x: 0,
      y: 0,
    };
    this.localStorageUser = window.localStorageUser;
    this.isKeyboardVisible = false; //当前键盘状态
    this.cursorType = 1; // 2是输入光标状态
    this.mouseModule = null; //mouseModule 游戏详情里面配置的 强制使用相对/绝对鼠标位置 , 例如植物大战僵尸光标是自已画的采集不到
    MouseCursor.instance = this;
  }
  /**
   * 获取鼠标移动速度
   */
  getMouseMoveRatio = () => {
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `获取鼠标移动速度:${this.initialMouseSpeed}`,
    });
    if (localStorageUser && typeof localStorageUser.get === "function") {
      return localStorageUser.get("mouseSpeed") || this.initialMouseSpeed;
    }
    return this.initialMouseSpeed;
  };
  /**
   * 设置鼠标速度
   * 由SDK内部接收 , 发送相对坐标时由 鼠标偏移量 * 设置的鼠标速度
   */
  setMouseMoveRatio = (number) => {
    window.localStorageUser.set("mouseSpeed", Number(number));
    this.initialMouseSpeed = Number(number);
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `设置鼠标移动速度:${this.initialMouseSpeed}`,
    });
  };
  /**
   * 目前只用到触控,禁用sdk自已实现
   * @param {*} type 类型 0:禁用
   * 1.滑鼠 PC鼠标模式 2.触控 手机触屏点击 4:触屏不响应单击 或者触屏攻击 只能滑动 5 在移动屏幕的时候产生touchStart 会进行鼠标左键
   */
  setMouseMode = (type) => {
    switch (type) {
      case 0:
        //卸载 播放器元素的鼠标的事件
        this.mouseType = 0;
        videoPlayerInstance.mouseEventHandlers.cleanupMouseEventHandlers();
        break;
      case 1:
        this.mouseType = 1;
        break;
      //跟1场景类似 有300毫秒延迟判断是否误触攻击
      case 2:
        this.mouseType = 2;
        break;
      case 4:
        this.mouseType = 4;
        break;
      case 5:
        this.mouseType = 5;
        break;
      default:
        break;
    }
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `设置鼠标模式:${type}`,
    });
  };
  //获取鼠标模式
  getMouseMode = () => {
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `获取鼠标模式:${this.mouseType}`,
    });
    return this.mouseType;
  };
  /**
   * 返回鼠标数据
   */
  getMouseCoordinate = () => {
    return {
      posCursor: this.posCursor,
      posMouse: {
        x: 0,
        y: 0,
      },
    };
  };
  //处理不同类型的光标模式
  _cursorModeType = () => {
    try {
      if (!realTimeInstance.isFirstFrame) return;
      switch (this.cursorType) {
        case 2:
          //需要后台接口配置 , 编辑状态弹出键盘
          if (gameInstance.gameDetails && gameInstance.gameDetails.attr.vKeyboard === "Y") {
            // 延迟一秒设置键盘状态
            setTimeout(() => {
              if (this.cursorType === 2) {
                AtKit.setFullKeyboardState(true);
                if (!videoPlayerInstance.mouseEventHandlers) return;
                //同时也要发送一个点击过去 不然只是编辑状态但是 但是没有触摸焦点 需要判断当前鼠标模式
                videoPlayerInstance.mouseEventHandlers.shootingGameTouchStart(4);
                setTimeout(() => {
                  videoPlayerInstance.mouseEventHandlers.shootingGameTouchStart(3);
                }, 200);
              }
            }, 1000);
          }
          break;
        default:
          //其他的状态的话如果 自动显示键盘 并且此时键盘在 就隐藏键盘
          break;
      }
    } catch (error) {
      console.log("_cursorModeType:error", error);
    }
  };
  // 测试古墓丽影用的函数 x 1144 y 607
  autoClickCloudPc = () => {
    // 定义一个函数来生成并发送 MouseData
    const sendMouseData = (type, state) => {
      let cmd = {
        event: type,
        x: 1144,
        y: 607,
        buttonState: state,
        keyboardState: 0,
      };
      let buf = toProtobuf("MouseData", cmd, 2001);
      realTimeInstance.sendDataChannel("channel-mouse", buf);
    };
    // 发送 event: 2
    sendMouseData(2, 0);
    // 发送 event: 4
    sendMouseData(4, 1);
    sendMouseData(3, 1);
  };
  //是否处于编辑状态
  isEditingShape = (object) => {
    if (object && typeof object.type !== "undefined") {
      this.cursorType = object.type;
      this._cursorModeType(this.cursorType);
    }
    //console.log(`鼠标数据:`, object);
    AtKit.onSceneChanged("cursorInfo", {
      showOrHide: object.hasOwnProperty("hide") && object.hide === true,
      gameCursorData: {
        data: object.data,
        type: object.type,
      },
      cursorWidth: object.width,
      cursorHeight: object.height,
      cursorPos: {
        x: object.x || 0,
        y: object.y || 0,
      },
    });
    this.posCursor.x = object.x || 0;
    this.posCursor.y = object.y || 0;
  };
}

// 导出单例实例
const mouseCursorInstance = new MouseCursor();
export default mouseCursorInstance;
