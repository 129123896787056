//游戏类相关的参数
import { gameStartApi, gameLeaveApi, gameArchiveApi, powerJoinApi, gamePlayInfoApi, gameInfoApi, powerCodeApi } from "@/utils/api/api-request-list.js";
import { setPlayingtime } from "@/utils/atkit-utils/tools.js";
import executionTimer from "@/utils/time-consuming/timer.js";
import { setIgnoreResizeEvent, removeVideoAudioTrack } from "@/toolbox.js";
import realTimeInstance from "../real-time-connection/real-time-connection";
import atKitCofingInstance from "../config/config.js"; //AtKit SDK的单例配置类
import logCollector from "@/utils/report-log/LogCollector";
import heartbeatInstance from "@/utils/heartbeat/heartbeat.js"; //心跳
import videoPlayerInstance from "@/utils/player/player.js";
import AtKit from "../AtKit";
import permissionsInstance from "@/lib/AtKit/permissions/permissions.js";
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor";
class Game {
  constructor() {
    if (Game.instance) {
      return Game.instance;
    }
    this.gameDetails = null; //游戏详情
    this.gameParameters = null; //申请游戏的参数
    this.localDeviceId = null; //自已的设备id
    this.remoteID = null; //远程iD
    Game.instance = this;
  }
  get(key) {
    return this[key];
  }
  // 设置实例上的任意属性
  set(key, value) {
    this[key] = value;
  }
  /**
   * 开始排队进入游戏
   * @param {s} domId dom id 创建播放器
   * @param {*} options 参数
   */
  startGame = async (domId, options) => {
    executionTimer.start("gameStartTime");
    try {
      let containerId = document.getElementById(domId);
      if (!containerId) {
        console.error(`传入dom元素id有误不存在:${domId}`);
        AtKit.onSceneChanged("warning", { message: `传入dom元素id有误不存在:${domId}` });
        return;
      }
      let { userInfo, pkgName, appChannel, cToken, playingtime, configInfo, isPortait } = options;
      if (userInfo === undefined && cToken === undefined && playingtime === undefined && pkgName === undefined && appChannel === undefined && configInfo === undefined && isPortait === undefined) {
        AtKit.onSceneChanged("warning", { message: `申请游戏参数错误!请检查必填参数${JSON.stringify(options)}` });
        return;
      }
      this.gameParameters = options;
      //如果存在无操作时间传入下来
      if (this.gameParameters.hasOwnProperty("noInputTimeout") && this.gameParameters.noInputTimeout) {
        heartbeatInstance.setNoInputTimeout(this.gameParameters.noInputTimeout);
      }
      this.gameParameters.domId = domId;
      this.gameParameters.accessKeyId = AtKit.args.accessKeyId;
      //存入返回的值
      window.localStorageUser.set("start_game", this.gameParameters);
      //设置 游戏竖屏 横屏
      setIgnoreResizeEvent(domId, options.isPortrait);
      //如果参数存在playToken 和 pinCode代表是多人或者接力玩
      if (options.hasOwnProperty("pinCode") && options.pinCode && options.hasOwnProperty("playToken") && options.playToken) {
        let { pinCode, playToken } = options;
        let userID = options.userInfo.userID;
        if (userID && pinCode && playToken) {
          window.localStorageUser.updateConnectionInfo("current_connection_info", {
            playToken: playToken,
          });
          //1.取租户信息 根据用户的isp取的运营商信息
          await atKitCofingInstance.getGameConfigApi(); //获取租户配置
          await this.partiesGame({ userID, pinCode });
        }
        return;
      }
      //进入排队
      await this.enterQueue();
    } catch (error) {
      console.error("开始游戏报错:", error);
      AtKit.onSceneChanged("warning", { message: `[startGame]开始游戏报错${error}` });
    }
  };
  /**
   * 云游为游戏中调用
   * @param {*} agentDeviceID agent的id
   * @param {*} data 开始游戏接口的参数
   */
  inGame = (agentDeviceID) => {
    this.remoteID = Number(agentDeviceID);
    realTimeInstance.connect();
  };
  /**
   * 开始游戏--进入排队开始调度
   * @returns
   */
  enterQueue = async () => {
    let userInfo = {
      userID: this.gameParameters.userInfo.userID,
      userToken: this.gameParameters.userInfo.userToken,
      flag: this.gameParameters.userInfo.flag,
    };

    let extendMap = {
      packageName: this.gameParameters.pkgName,
      accessKey: AtKit.args.accessKeyId,
      accessChannel: this.gameParameters.appChannel,
      userID: this.gameParameters.userInfo.userID,
      userToken: this.gameParameters.userInfo.userToken,
    };
    let data = {
      appID: AtKit.args.accessKeyId,
      userInfo: JSON.stringify(userInfo),
      gameID: this.gameParameters.pkgName,
      sign: this.gameParameters.sign,
      timeStamp: this.gameParameters.timeStamp,
      signType: this.gameParameters?.signType || "hm",
      extendMap: JSON.stringify(extendMap),
      clientType: "3",
    };
    //signType : at , hm 算法类型 hm的传hm 自已测试可以传 at
    if (this.gameParameters?.signType === "at") {
      delete data.extendMap;
    }
    //判断业务方是否需要透传参数
    if (this.gameParameters.protoData) {
      data.protoData = this.gameParameters.protoData;
    }
    // extendMap; JSON 对象字符串 用户id 用户token 游戏包名 AccKey AccChannel
    try {
      executionTimer.start("gameProcess");
      let res = await gameStartApi(data);
      //开始游戏 会返回用户的ipConf 记录用户的地址和运营商
      executionTimer.start("queueStartTime");
      if (res.code !== 200) {
        logCollector.log({
          type: "error",
          eventType: 11,
          eventName: "API Request Response Change",
          details: `请求游戏结果ERROR:${JSON.stringify(res)}`,
        });
        AtKit.onSceneChanged("stop", {
          reason: "gameError",
          message: "进入游戏出错",
          error: "error",
          cId: this.getPlayToken().playToken,
        });
        AtKit.onSceneChanged("warning", { message: `请求游戏结果error:${JSON.stringify(res)}` });
        return false;
      }
      //用户配置
      let { ipConf, status, deviceID } = res.data;
      if (ipConf) {
        atKitCofingInstance.setUserConf(ipConf);
      }
      //用户上一次的资源还没有释放
      if (status === 2) {
        logCollector.log({
          type: "error",
          eventType: 11,
          eventName: "SDK State",
          details: `用户上一次资源还未释放 status: ${status}`,
        });
        AtKit.onSceneChanged("stop", {
          reason: "instanceLimit",
          message: "上一次游戏还未释放",
          error: "error",
          cId: this.getPlayToken().playToken,
        });
      }
      window.localStorageUser.updateConnectionInfo("current_connection_info", res.data);
      await atKitCofingInstance.getGameConfigApi(); //获取租户配置
      realTimeInstance.createStream(); // 创建媒体轨道
      this.localDeviceId = deviceID.toString();
      //连接信令
      await realTimeInstance.createServerConnection(this.localDeviceId);
      await this._cloudTravel(res.data); //获取云游
      return true;
    } catch (error) {
      AtKit.onSceneChanged("warning", { message: `请求游戏结果ERROR: ${error}` });
    }
  };
  _cloudTravel = async (data) => {
    //获取当前云游状态
    let travelingAround = await this.getCurrentUserGameStateApi();
    let { no, deviceID, verCode, status } = travelingAround.data;
    await this.getGameDetailsApi(); //获取游戏详情
    if (travelingAround.code === 200) {
      switch (status) {
        case 0:
          //排队中
          //回调排队信息
          AtKit.onSceneChanged("queueInfo", {
            reason: "showQueueInfo", //显示当前排队信息
            message: `排队中当前正在第${no}位`,
            waitingPeople: no, //等待人数
            waitingTotalNum: "", //等待总人数
            waitingTim: "", //预计时间 单位秒})
          });

          break;
        case 1:
          //游戏中
          this.inGame(deviceID, data);
          executionTimer.end("gameStartTime"); //开始游戏耗时end
          break;
        case -1:
          //待核销
          let info = {
            message: "加载游戏",
            ranking: 0,
            data: {
              deviceID: deviceID,
              verCode: verCode,
            },
          };
          this.confirmGame(info);
          break;
        case 2:
          //已结束
          console.log("本次云游戏已结束");
          break;
        default:
          break;
      }
    }
  };
  /**
   * 退出排队
   */
  endQueue = async () => {
    try {
      //如果此时有了对端的ID则不需要调用退出排队
      if (this.remoteID) {
          //此时有可能已经连上了agent 并且校验完了只是画面可能还没有第一帧 为了做保底也需要通知agent检查一下
          await this.stopGame(0, true, "用户主动退出排队");
          return true;
      
      }
      let r = await gameLeaveApi();
      if (r.code === 200) {
        logCollector.log({
          eventType: 11,
          eventName: "API Request Response Change",
          details: `退出排队成功`,
        });
      }
      logCollector.log({
        type: "error",
        eventType: 11,
        eventName: "API Request Response Change",
        details: `退出排队失败`,
      });
      AtKit.onSceneChanged("warning", { message: `[API Request Response Change]退出排队失败` });
      return false;
    } catch (error) {
      logCollector.log({
        type: "error",
        eventType: 11,
        eventName: "API Request Response Change",
        details: `退出排队异常:${JSON.stringify(error)}`,
      });
      AtKit.onSceneChanged("warning", { message: `[endQueue]退出排队异常: ${error}` });
      return false;
    }
  };
  /**
   * 插队
   * @param {*} flag 值必须>1 小于等于50
   * @returns true 成功 false 失败
   */
  jumpQueue = async (flag) => {
    // if (flag < 1 || flag > 50) {
    //   logCollector.log({
    //     type: "error",
    //     eventType: 11,
    //     eventName: "API Request Response Change",
    //     details: `插队参数错误:${flag}`,
    //   });
    //   return false;
    // }
    let res = await gameJump({ flag: flag });
    if (res.code === 200) {
      //插队成功
      AtKit.onSceneChanged("queueInfo", {
        reason: "jumpQueue",
        message: "插队成功",
      });
      logCollector.log({
        eventType: 11,
        eventName: "API Request Response Change",
        details: `插队成功`,
      });
      return true;
    }
    AtKit.onSceneChanged("queueInfo", {
      reason: "jumpQueue",
      message: "插队失败",
    });
    logCollector.log({
      type: "error",
      eventType: 11,
      eventName: "API Request Response Change",
      details: `插队失败`,
    });
    return false;
  };
  /**
   * 查询游戏是否存在存档
   * @param {*} options
   */
  gameArchived = async () => {
    try {
      let res = await gameArchiveApi();
      let result = {
        code: -1,
        errorMessage: "",
        hasArchive: false,
      };
      if (res.code === 200) {
        result.code = 0;
        if (res.list.length > 0) {
          result.hasArchive = true;
        }
        logCollector.log({
          eventType: 11,
          eventName: "API Request Response Change",
          details: `查询游戏是否存在存档:${result}`,
        });
        return result;
      }
      result.errorMessage = res.msg;
      return result;
    } catch (error) {
      logCollector.log({
        type: "error",
        eventType: 11,
        eventName: "API Request Response Change",
        details: "查询游戏是否存档出错:",
        error,
      });
      AtKit.onSceneChanged("warning", { message: `[gameArchived]查询游戏是否存档出错: ${error}` });
    }
  };

  isVisible = async () => {
    let res = await this.getCurrentUserGameStateApi();
    //游戏已结束
    if (res.status === 2) {
      window.localStorageUser.clear(); //清空本地浏览器所有缓存
      return;
    }
    //浏览器可见时的操作
    if (realTimeInstance.connPeer && this.getPlayToken()?.playToken) {
      //重新连接webrtc
      realTimeInstance.connPeer.connect();
      realTimeInstance.connPeer.initPeer();
      AtKit._initOnEvent();
    }
  };

  //暂停游戏
  pauseGame = async (type) => {
    try {
      switch (type) {
        case "visible":
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: `[pauseGame visible]浏览器可见`,
          });
          await this.isVisible();
          break;
        case "hidden":
          await this.isVisible();
          //浏览器当前不可见
          if (realTimeInstance.connPeer) {
            logCollector.log({
              eventType: 22,
              eventName: "SDK State",
              details: `[pauseGame hidden]浏览器不可见`,
            });
            this.stopGame(1001, false, "浏览器当前不可见");
            AtKit.eventEmitter.removeAllListeners();
            //推送所有日志
            logCollector.uploadLogsToServer();
            realTimeInstance.isScreenCapture = false; //置清是否收到采集屏幕
          }
          break;
        default:
          break;
      }
    } catch (error) {}
  };
  /**
   *
   * @param {*} code 0 立即停止 1001 保活
   * @param {*} manualClick 是否主动停止
   * @returns
   */
  stopGame = async (code = 0, manualClick = true, msg = "用户主动结束游戏") => {
    try {
      heartbeatInstance.cleatAccidental();
      removeVideoAudioTrack(); //清空轨道视频流
      if (realTimeInstance.connPeer) {
        //setPeerClose(2, code);
        realTimeInstance.connPeer.stopWebRtcInfo();
        realTimeInstance.connPeer.closeConnect(); //关闭peer连接
      }
      await realTimeInstance.notifyUpdate(code, msg);
      if (manualClick) {
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `关闭游戏stopGame,是否主动停止:${manualClick},code:${code}`,
        });
        if (realTimeInstance.socket) {
          realTimeInstance.socket.close(this.remoteID);
        }
        executionTimer.end("gameEndTimeElapsed");
        executionTimer.end("queueToGameEnd");
        executionTimer.end("gameProcess");
        window.localStorageUser.clear(); //清空本地浏览器所有缓存
        videoPlayerInstance.deleteElementById("example");
      }
      logCollector.stopLogTimer();
      return true;
    } catch (error) {
      console.log("[stopGame] Error: " + error);
      return false;
    }
  };
  /**
   * 获取游戏详情
   */
  getGameDetailsApi = async () => {
    try {
      let res = await gameInfoApi();
      //游戏配置
      if (res.code === 200) {
        this.gameDetails = res.data;
        if (this.gameDetails.attr?.mouseModule) {
          //如果游戏详情配置了强制鼠标模式则使用
          mouseCursorInstance.mouseModule = this.gameDetails.attr?.mouseModule;
        }
        //如果后台有配置无操作时间 并且sdk开始游戏没有传无操作时间才用
        if (res.data?.activeTime && !this.gameParameters?.noInputTimeout) {
          heartbeatInstance.setNoInputTimeout(res.data.activeTime);
        }
      }
    } catch (error) {
      console.log("获取游戏详情失败");
      AtKit.onSceneChanged("warning", { message: `[getGameDetailsApi]获取游戏详情失败: ${error}` });
    }
  };
  //封装一个排到队进入游戏的函数 防止 -1未核销的情况
  confirmGame = (info) => {
    //排队回调信息
    let sendWaitInfo = {
      reason: "showQueueInfo", //显示当前排队信息
      message: info.message,
      waitingPeople: info?.waitingPeople, //等待人数
      waitingTotalNum: info.waitingTotalNum, //等待总人数
      waitingTim: info?.avgTime, //预计时间 单位秒
    };
    //排到队了
    if (info.ranking === 0) {
      //whetherToQueue 没明白海马这个逻辑 文档中给的是这个之后调用进入排队,但其实开始游戏后就已经进入排队了
      sendWaitInfo.reason = "applyGame"; //排队完成正在进入游戏
      //排到队开始创建播放器以及连接agent
      this.remoteID = Number(info.data.deviceID);

      //把remoteID 包括 本身uid 存在 current_connection_info
      window.localStorageUser.updateConnectionInfo("current_connection_info", {
        remoteID: this.remoteID,
      });
      realTimeInstance.connect();
      //记录排到队的时间戳
      window.localStorageUser.set("start_game_time", Date.now());
      executionTimer.end("queueStartTime");
      executionTimer.start("queueToVideoFirstFrame");
      executionTimer.start("queueToGameEnd");
      //本次可玩的游戏时长
      setPlayingtime(this.gameParameters.playingtime);
    }
    AtKit.onSceneChanged("queueInfo", sendWaitInfo);
  };
  /**
   * 获取当前用户游戏状态
   * 用来防止 手动清除浏览器缓存导致的问题
   * @param {*} options
   */
  getCurrentUserGameStateApi = async () => {
    let rankInfo = await gamePlayInfoApi();
    logCollector.log({
      eventType: 11,
      eventName: "API Request Response Change",
      details: `获取云游状态:${JSON.stringify(rankInfo)}`,
    });
    return rankInfo;
  };
  //获取pinCode
  getPinCode = async () => {
    let res;
    let pinCodeObj = {
      result: -1,
      playToken: "",
      pinCode: "",
      validityRemaining: "",
      errMsg: "",
    };
    try {
      res = await powerCodeApi();
      if (res.code !== 200) {
        AtKit.onSceneChanged("warning", { message: `[getPinCode]获取pinCode出错` });
        pinCodeObj.errMsg = res.msg;
        AtKit.onSceneChanged("applyPinCode", pinCodeObj);
        return pinCodeObj;
      }
      pinCodeObj.result = 0;
      pinCodeObj.playToken = this.getPlayToken().playToken;
      pinCodeObj.pinCode = res.data.code;
      pinCodeObj.validityRemaining = res.data.ttl;
      window.localStorageUser.updateConnectionInfo("current_connection_info", {
        pinCode: res.data.code,
      });
      AtKit.onSceneChanged("applyPinCode", pinCodeObj);
      return pinCodeObj;
    } catch (error) {
      pinCodeObj.errMsg = error;
      AtKit.onSceneChanged("applyPinCode", pinCodeObj);
      return pinCodeObj;
    }
  };
  //派对吧模式 进入游戏
  partiesGame = async (args) => {
    //如果浏览器存储已经有了 remoteID 就不需要后面的加入玩家了
    // let connectionInfo = window.localStorageUser?.get("current_connection_info");
    // if (!connectionInfo?.remoteID) {
    let data = {
      code: args.pinCode,
      userID: args.userID,
    };

    let res = await powerJoinApi(data);
    if (res.code !== 200) {
      AtKit.onSceneChanged("warning", { message: `[partiesGame]派对游戏模式出错:${res.msg}` });
      return;
    }
    //主要是把本次的playToken 替换成自已的 playerToken
    //res.data.playToken = res.data.playerToken;
    window.localStorageUser.updateConnectionInfo("current_connection_info", res.data);
    await atKitCofingInstance.getGameConfigApi();
    //成功存储信息直接连接云主机
    let { remoteID, deviceID, ipConf } = res.data;
    this.remoteID = remoteID; //对端id
    this.localDeviceId = deviceID; //自已的设备id
    window.localStorageUser.updateConnectionInfo("current_connection_info", { localDeviceId: this.localDeviceId });
    atKitCofingInstance.setUserConf(ipConf);
    // } else {
    //   this.remoteID = connectionInfo.remoteID; //对端id
    //   this.localDeviceId = connectionInfo.deviceID; //自已的设备id
    // }
    permissionsInstance.isPlayer = true;
    try {
      realTimeInstance.createStream(); // 创建媒体轨道
      //2.连接socket
      await realTimeInstance.createServerConnection(this.localDeviceId);
      //3.连接Rtc
      realTimeInstance.connect();
      //
    } catch (error) {
      AtKit.onSceneChanged("warning", { message: `[partiesGame]派对游戏模式异常:${JSON.stringify(error)}` });
    }
  };
  //设置虚拟键位布局 xbox 手柄 keyboard 自定义键鼠 NONE 不显示
  setVirtualKeys = (type, options) => {
    if (typeof type !== "string" && type === "") {
      AtKit.onSceneChanged("warning", { message: `[setVirtualKeys]参数错误 ${error}` });
      return;
    }
    switch (type) {
      //显示xbox布局
      case "xbox":
        break;
      case "keyboard":
        // const handler = new GameGta5padHandler(options);
        break;
      case "NONE":
        break;
      default:
        AtKit.onSceneChanged("warning", { message: `[setVirtualKeys]参数必须为xbox | keyboard | NONE 实际参数: = ${type}` });
        break;
    }
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `设置虚拟键位布局:${type}`,
    });
  };
  //返回当前键盘是否展示隐藏
  getFullKeyboardState = () => {
    try {
      const element = document.querySelector(".at-vir-keyboard-box");
      return element && element.style.display === "block";
    } catch (error) {
      return false;
    }
  };
  setFullKeyboardState = (isEnabled) => {
    //未正常初始化的调用
    // if (!this.videoPlayer) return "Not initialized properly";
    //显示键盘
    AtKit.onSceneChanged("keyBoardState", {
      result: isEnabled,
    });
    videoPlayerInstance.keyboardfolding(isEnabled);
  };
  //取浏览器的 playToken
  getPlayToken = () => {
    try {
      let playToken = window.localStorageUser?.get("current_connection_info")?.playToken;
      return { playToken };
    } catch (error) {
      AtKit.onSceneChanged("warning", { message: `[getPlayToken]获取playToken出错: ${error}` });
      return null;
    }
  };
  //取浏览器的玩家token
  getPlayerToken = () => {
    try {
      let playerToken = window.localStorageUser?.get("current_connection_info")?.playerToken;
      return { playerToken };
    } catch (error) {
      AtKit.onSceneChanged("warning", { message: `[playerToken]获取playerToken出错: ${error}` });
      return null;
    }
  };
}

// 导出单例实例
const gameInstance = new Game();
export default gameInstance;
