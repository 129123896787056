// 定义映射关系的对象
const labelMappings = {
  iceExchangeTime: "ICE连接耗时",
  videoFirstFrame: "视频第一帧耗时",
  gameEndTimeElapsed: "开始游戏到结束游戏耗时",
  queueStartTime: "排队到排队成功耗时",
  gameStartTime: "开始游戏耗时",
  authSuccess: "认证开始到结束耗时",
  startRemoteVideoCapture: "发送采集到回来耗时",
  gameProcess: "从申请游戏到结束游戏耗时",
  queueToGameEnd: "从排队成功到结束游戏耗时",
  queueToVideoFirstFrame: "从排队成功到视频第一帧耗时",
};


// 函数根据key获取value的描述
export const getDescriptionByKey = (key) => {
  // 如果key存在于映射关系中，则返回对应的value
  if (key in labelMappings) {
    return labelMappings[key];
  } else {
    // 如果key不存在于映射关系中，可以返回一个默认值或者空字符串，视情况而定
    return key;
  }
};
