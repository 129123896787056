class CodeMappings {
  constructor() {
    if (CodeMappings.instance) {
      return CodeMappings.instance;
    }
    this.constantToChineseMappings = {
      LIVE_STREAM_STARTED: "开始直播成功",
      LIVE_STREAM_START_FAILED: "开始直播失败",
      LIVE_STREAM_STOPPED_SUCCESSFULLY: "关闭直播成功",
      LIVE_STREAM_STOP_FAILED: "关闭直播失败",
    };
  }

  getDescriptionByKey(key) {
    if (key in this.constantToChineseMappings) {
      return this.constantToChineseMappings[key];
    } else {
      return key;
    }
  }
}
// 导出单例实例
const codeMappingsInstance = new CodeMappings();
export default codeMappingsInstance;
