import proto from "./geelevelproto.js";
import CryptoJS from "crypto-js";
export function ab2str(buf) {
  return new TextDecoder().decode(buf);
}
export function convertArrayBufferToNumber(buffer) {
  const bytes = new Uint8Array(buffer);
  const dv = new DataView(bytes.buffer);
  return dv.getUint16(0, true);
}
export function mergeArrayBuffer(...arrayBuffers) {
  let totalLength = 0;
  arrayBuffers.forEach((item) => {
    totalLength += item.byteLength;
  });
  const result = new Int8Array(totalLength);
  let offset = 0;
  arrayBuffers.forEach((item) => {
    result.set(new Int8Array(item), offset);

    offset += item.byteLength;
  });

  return result.buffer;
}
export function toProtobuf(messageType, messageData, messageCode) {
  let codeBuffer = new ArrayBuffer(2);
  let view1 = new Uint16Array(codeBuffer);
  view1[0] = messageCode;

  let MessageProto = proto.geelevel[messageType];
  let msg = MessageProto.create(messageData);
  let encodedData = MessageProto.encode(msg).finish();

  return mergeArrayBuffer(codeBuffer, encodedData);
}
export function toProtoBufCode(messageCode) {
  let codeBuffer = new ArrayBuffer(2);
  let view1 = new Uint16Array(codeBuffer);
  view1[0] = messageCode;
  return mergeArrayBuffer(codeBuffer);
}

export function fromProtobuf(messageType, messageData) {
  let MessageProto = proto.geelevel[messageType];
  let udata = new Uint8Array(messageData);
  let message = MessageProto.decode(udata);
  return MessageProto.toObject(message);
}
export function generatePeerReqConn() {
  let codeBuffer = new ArrayBuffer(2);
  let view1 = new Uint16Array(codeBuffer);
  view1[0] = 1503;
  let passwordTag = new ArrayBuffer(1);
  let view2 = new Uint8Array(passwordTag);
  view2[1] = 0;
  let Buffer = mergeArrayBuffer(codeBuffer, passwordTag);
  return Buffer;
}
export function parseH264ProfileLevelId(profileLevelIdStr) {
  // 将十六进制字符串转换为数字
  const profileLevelId = parseInt(profileLevelIdStr, 16);

  // 从 profileLevelId 中提取 profile_idc, profile_iop 和 level_idc
  const profile_idc = profileLevelId >> 16;
  let profile_iop = (profileLevelId >> 8) & 0xff;
  const level_idc = profileLevelId & 0xff;

  // 确定配置文件
  let profile;
  switch (profile_idc) {
    case 66:
      profile = "Baseline";
      break;
    case 77:
      profile = "Main";
      break;
    case 88:
      profile = "Extended";
      break;
    case 100:
      if ((profile_iop & 0x10) === 0) {
        profile = "High";
      } else {
        profile = "Constrained Baseline";
      }
      break;
    case 110:
      profile = "High 10";
      if ((profile_iop & 0x10) === 0) {
        profile += " Intra";
      }
      break;
    case 122:
      profile = "High 4:2:2";
      if ((profile_iop & 0x10) === 0) {
        profile += " Intra";
      }
      break;
    case 244:
      profile = "High 4:4:4";
      if ((profile_iop & 0x10) === 0) {
        profile += " Intra";
      }
      break;
    default:
      profile = "Unknown";
  }

  // 确定级别
  let level = (level_idc / 10).toFixed(1);
  if (level_idc % 10 === 0) {
    level = parseFloat(level).toFixed(0);
  }

  return [profile, level];
}
/**
 * 记录流量信息
 * @param {Object} localCandidate 本地候选对信息
 * @param {Object} remoteCandidate 远程候选对信息
 */
export function logTraffic(localCandidate, remoteCandidate) {
  if (remoteCandidate?.candidateType === "relay") {
    console.log(
      "流量正在通过TURN服务器转发 ",
      localCandidate?.candidateType + " " + localCandidate.ip + " " + remoteCandidate?.candidateType + "" + remoteCandidate?.ip,
      " protocl: ",
      remoteCandidate?.protocol
    );
  } else {
    console.log(
      "流量正在通过直接连接",
      localCandidate?.candidateType + " " + localCandidate.ip + " " + remoteCandidate?.candidateType + "" + remoteCandidate?.ip,
      " protocl: ",
      remoteCandidate?.protocol
    );
  }
}
/**
 * 用于在 SDP（Session Description Protocol）中切换特定编解码器的使用。
 * @param {*} sdp 表示要进行处理的 SDP 字符串
 * @param {*} Code 表示要切换的编解码器的编码名称。
 * @param {*} sdpTransform 表示要进行处理的 SDP 对象。
 * @returns
 */
export const switchCoder = (sdp, Code, sdpTransform) => {
  try {
    const sdpObj = sdpTransform.parse(sdp); //使用 sdpTransform.parse() 方法解析传入的 SDP 字符串，将其转换为一个对象 sdpObj，便于后续处理
    if (!sdpObj.media) return sdp;
    //不存在 media 属性，或者 media 数组为空，则直接返回原始的 SDP 字符串，不做任何处理
    sdpObj.media.forEach((item) => {
      if (item.type !== "video" || !item.rtp) {
        return;
      }
      let preferredPayloads = [];
      let otherPayloads = [];
      /**
       * 对于满足条件的条目，遍历其中的 rtp 数组，对每个 RTP 项进行处理：
          如果当前 RTP 项的编解码器等于给定的 Code，则继续检查其对应的 fmtp 配置，并根据配置判断是否为首选编解码器。如果是首选编解码器，则将其 payload 添加到 preferredPayloads 数组中，否则添加到 otherPayloads 数组中。
          如果当前 RTP 项的编解码器不等于给定的 Code，则将其 payload 添加到 otherPayloads 数组中。
       */
      item.rtp.forEach((rtpItem) => {
        if (rtpItem.codec === Code) {
          // Check fmtp for this payload
          const fmtp = item.fmtp.find((f) => f.payload === rtpItem.payload);
          if (fmtp && fmtp.config) {
            const params = sdpTransform.parseParams(fmtp.config);
            let profile, level;
            if (params["profile-level-id"]) {
              [profile, level] = parseH264ProfileLevelId(params["profile-level-id"]);
            }
            if (params["packetization-mode"] == "1" && profile == "Main") {
              preferredPayloads.push(rtpItem.payload.toString());
            } else {
              otherPayloads.push(rtpItem.payload.toString());
            }
          }
        } else {
          otherPayloads.push(rtpItem.payload.toString());
        }
      });
      if (preferredPayloads.length > 0 || otherPayloads.length > 0) {
        item.payloads = [...preferredPayloads, ...otherPayloads].join(" ");
      }
    });
    return sdpTransform.write(sdpObj);
  } catch (error) {
    console.error("switchCoderError", error);
  }
};
/**
 * 创建用于获取设备信息的数据包
 * @param {*} proto Protocol Buffers
 * @returns  ArrayBuffer
 */
export function createGetDeviceInfoData(proto, myID) {
  // 指令代码
  let msgCodeBuffer = new ArrayBuffer(2);
  let view1 = new Uint16Array(msgCodeBuffer);
  view1[0] = 1501;

  // let deviceInfo = geelevelProto.proto.DeviceInfo;
  // console.log(deviceInfo);
  //let msg = deviceInfo.create({data:{t:"t"}});
  // let data = deviceInfo.encode(msg).finish();
  //{"display":[{"height":1080,"is_online":true,"is_primary":true,"name":"","width":1920}],"id":"37368929","system":{"platform":"OSX"},"type":"device-info"}
  //let msg = proto.geelevel.DeviceInfo.create({"version":1, "mode": "ShareScreen", "id": this.myID });
  let msg = proto.geelevel.DeviceInfo.create({
    data: JSON.stringify({ version: 1, mode: "ShareScreen", id: myID }),
  });
  let data = proto.geelevel.DeviceInfo.encode(msg).finish();

  let Buffer = mergeArrayBuffer(msgCodeBuffer, data);
  return Buffer;
}
/**
 * 处理 ICE 候选事件。函数接收一个事件对象 event，该对象包含了 ICE 候选信息。
 * @param {*} event ice
 * @returns
 */
export function onIceData(event) {
  console.log("onIceData: ", event.candidate);
  if (event.candidate) {
    let candidateData = JSON.stringify({
      type: "candidate", //候选类型，这里固定为 "candidate"。
      sdpMLineIndex: event.candidate.sdpMLineIndex, //候选对应的 SDP 行索引。
      sdpMid: event.candidate.sdpMid, //候选对应的 SDP 标识符。
      candidate: event.candidate.candidate, //候选的描述信息
    });
    //返回组装好的候选数据字符串 candidateData。
    return candidateData;
  }
}
/**
 *
 * @param {*} remoteID 远程id
 * @param {*} password 密码
 * @param {*} pwdType 密码类型
 * @param {*} authority 权限 authority 0 没有权限 1鼠标 2 键盘 4 手柄 全要需要 || 起来
 * @returns
 */
export function createPasswordCallData(remoteID, password, pwdType = 3, authority) {
  let saltPassword = remoteID + password;
  CryptoJS.MD5(saltPassword);
  //1密码校验 2屏幕共享密码校验 3 token校验
  return toProtobuf("Call", { password: password, pwdType: pwdType, authority: authority }, 1504);
}

/**
   * 用户对端信息
   * 函数的主要功能是解析 info 中的二进制数据，获取其中的设备信息。这段二进制数据包含了一个自定义的结构，其包含三部分：
     头部（4个字节）：用于标记后面内容的长度。
     protobuf 结构：存储了设备信息的数据。这部分是一个 protobuf 结构，其中存储了一个 JSON 对象。
     解析 protobuf 结构中存储的 JSON 数据。
     函数首先从 info 中读取出头部的长度信息，然后根据这个长度截取出后面的数据，即 protobuf 结构的部分。接着，利用一个名为 fromProtobuf 的方法解析出 protobuf 中的 JSON 数据，并将其解析为 JavaScript 对象。
    解析出的设备信息包含了视频的宽度和高度等信息，将这些信息保存到函数中的一些属性中（videoWidth、videoHeight、displays、peerData），并打印出设备信息到控制台上。
   * @param {*} info 
   */
export function parsePeerDeviceInfoAck(info) {
  // DeviceInfo 结构
  // 1. 4 字节: 头, 用于标记后面的内容长度, 后面结构为 potobuff
  // 2. potobuff 结构 {data: json结构}
  // 3. 解析 potobuff 中 data 所存储的 json
  try {
    convertArrayBufferToNumber(info.slice(0, 4));
    let data = info.slice(4);
    let object = fromProtobuf("DeviceInfo", data);
    return JSON.parse(object.data);
  } catch (error) {
    console.error(`parsePeerDeviceInfoAck Error: ${error}`);
  }
}

/**
 * 错误应答信息处理
 */
// 处理连接失败的情况
export function handleConnectionFailure(reason) {
  switch (reason) {
    case 0:
      // 用户拒绝连接
      console.error("用户拒绝连接:", reason);
      break;
    case 1:
      // 密码错误
      console.error("密码错误弹窗");
      break;
    default:
      console.error("未知的连接失败原因:", reason);
  }
}
