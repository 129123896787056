// 集成sokect webrtc实时连接的处理 ,让入口js文件不处理
import AtKit from "../AtKit";
import logCollector from "@/utils/report-log/LogCollector";
import atKitCofingInstance from "../config/config";
import Peer from "../peer/peer";
import Socket from "../sokect/sokect";
import executionTimer from "@/utils/time-consuming/timer.js";
import codeInstance from "@/utils/code-msg/code-msg.js"; //根据交互的code取对应意思
import gameConfigInstance from "@/utils/game-settings-config/config.js";
import gameInstance from "../game/game";
import videoPlayerInstance from "@/utils/player/player.js";
import heartbeatInstance from "@/utils/heartbeat/heartbeat.js";
import permissionsInstance from "@/lib/AtKit/permissions/permissions.js";
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor.js"; //鼠标键盘类
import { sendSignAPi } from "@/utils/api/api-request-list";
import {
  setOpenPeerCursor,
  toProtobuf,
  fromProtobuf,
  sendCustomJson,
  setPeerClose,
  setRemoteVideoResolution,
  parsePeerDeviceInfoAck,
  displayAudioTrack,
  displayVideoTrack,
  getCustomData,
  createPasswordCallData,
  setPeerEnableAudio,
  moveMousePosition,
  setLocalCursorData,
  switchBitrate,
  setVideoContent,
  setPeerVideoFps,
  keyQuality,
  sendSdkKeyboardEvent,
  processSdkMouseEvent,
} from "@/toolbox";
class RealTimeConnection {
  constructor() {
    if (RealTimeConnection.instance) {
      return RealTimeConnection.instance;
    }
    this.connPeerTimerId = null; //连接webrtc的定时器保存
    this.xBoxList = {}; //存储xbox发送的值
    this.displays = {}; //对端设备信息
    this.accessKeyId = ""; //accessKeyId
    this.stream = null; //流
    this.connPeer = null; //rtc返回的引用
    this.socket = null; //socket 应用
    this.isFirstFrame = false; //视频第一帧
    this.authority = 0; //权限 0 没有权限
    this.resolutionList = [
      { id: 1, name: "超清", bitRate: 12000, resolution: "1920x1080" },
      { id: 2, name: "高清", bitRate: 8000, resolution: "1920x1080" },
      { id: 3, name: "标清", bitRate: 5000, resolution: "1280x720" },
      { id: 4, name: "流畅", bitRate: 3000, resolution: "1280x720" },
    ]; //可设置的清晰度
    this.setHistroyHash = {}; // 处理fps等设置的值 结果后加上值 结果 例如fps 60 , 返回 设置fps 60 true
    RealTimeConnection.instance = this;
  }
  get(key) {
    return this[key];
  }
  // 设置实例上的任意属性
  set(key, value) {
    this[key] = value;
  }
  //创建媒体轨道
  createStream = () => {
    if (!this.stream) {
      this.stream = new MediaStream();
      console.log("AtKit stream :", this.stream.getTracks());
      logCollector.log({
        eventType: 22,
        eventName: "SDK State",
        details: `创建媒体轨道`,
      });
    }
  };
  // new WebRTC连接
  _createPeerConnection = () => {
    //根据信息找出对应的运营商
    let results = atKitCofingInstance.getOperatorByInfo();
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `[_createPeerConnection] 本次连接的运营商信息 ${JSON.stringify(results)}`,
    });
    //要连接的远程
    let pc = new Peer({
      uid: gameInstance.localDeviceId,
      remoteID: gameInstance.remoteID,
      accessKeyId: AtKit.args.accessKeyId,
      relay: results,
    });
    pc.on("audioTrack", (audioTrack) => {
      displayAudioTrack(audioTrack, "AtKitAudio", this.stream);
    });
    //远程发送视频信息过来就会触发 videoTrack事件是表示视频流的视频轨道已经被添加或者开始接收，而不是开始采集。
    pc.on("videoTrack", (videoTrack) => {
      displayVideoTrack(videoTrack, "AtKitVideo", this.stream);
    });
    pc.on("ice", (candidateData) => {
      this.socket.send({
        room: gameInstance.remoteID,
        src: gameInstance.localDeviceId,
        master: true,
        data: candidateData,
      });
    });
    /**
     * 建立连接之前需要交换信息
     * room: 表示房间号，值为 this.remoteID。
     * src: 表示消息的源，值为 this.uid。
     * master: 表示是否为主节点，值为 true。
     * data: 表示 offer 的 JSON 数据，值为 offerjson。
     * type: 表示消息类型，值为 0。
     * version: 表示协议版本，值为 2。
     */
    pc.on("offer", (offerjson) => {
      this.socket.send({
        room: gameInstance.remoteID.toString(),
        src: gameInstance.localDeviceId.toString(),
        master: true,
        data: offerjson,
        type: 0,
        version: 3,
      });
    });
    pc.on("operate", async (code, data) => {
      switch (code) {
        case 1501:
          logCollector.log({
            eventType: 4,
            eventName: "Data Channel State Change",
            details: `发送获取对等设备信息`,
          });
          break;
        case 1502:
          //获取设备信息
          let deviceInfo = parsePeerDeviceInfoAck(data);
          //回调视频流宽高给上层 width:宽 height:高
          AtKit.onSceneChanged("gameScreenInfo", {
            width: deviceInfo.display[0].width,
            height: deviceInfo.display[0].height,
          });
          AtKit.onSceneChanged("gameScreenList", deviceInfo.display[0].settings);
          AtKit.onSceneChanged("resolutionList", {
            list: this.resolutionList,
            selected: gameInstance.gameParameters.resolutionId || 4,
          });
          let storedVideoInfo = {
            DeviceWidth: deviceInfo.display[0].width,
            DeviceHeight: deviceInfo.display[0].height,
          };
          //存在浏览器内存里面 用途于videoInfo 等鼠标 以及视频计算
          window.localStorageUser.set("video_info", storedVideoInfo);
          //加载播放器
          videoPlayerInstance.loadPlayer(gameInstance.gameParameters.domId);
          logCollector.log({
            eventType: 4,
            eventName: "Data Channel State Change",
            details: `获取设备信息deviceInfo:${JSON.stringify(deviceInfo)},`,
          });
          //  --- agent 校验
          let playToken;
          //24-7-11新增权限等级 authority 0 没有权限 1鼠标 2 键盘 4 手柄 全要需要 | 起来
          // let authority = 1 | 2 | 4;
          //如果是true代表玩家
          let authority = 0;
          if (permissionsInstance.isPlayer) {
            authority = 0;
            playToken = gameInstance.getPlayerToken().playerToken;
          } else {
            authority = 1 | 2 | 4;
            playToken = gameInstance.getPlayToken().playToken;
          }

          let buf = createPasswordCallData(gameInstance.remoteID, playToken, 3, authority);
          this.sendDataChannel("custom-message", buf);
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: `让对端校验playtoken`,
          });
          executionTimer.start("authSuccess"); //记录采集发送采集时间
          break;
        case 1505:
          await this.parsePeerConnACK(data);
          break;
        case 1506:
          //挂断
          break;
        case 1515:
          //收到对方发的JSON DATA数据
          let obj = getCustomData(data);
          //
          this.processingCustomJson(obj);
          break;
        case 1508:
          console.log("对方分辨率改变:", data);
          //this.setLocalResolution(data);
          break;
        default:
          let unknownData = fromProtobuf("ReplayResult", data);
          this.replayResult(unknownData);
      }
    });
    /**
     * 收到鼠标相关的事件
     */
    pc.on("peerMouse", (code, data) => {
      switch (code) {
        case (2016, 2015):
          //远程光标位置
          setLocalCursorData(code, data);
          break;
        case 2014:
          //远程光标ID
          moveMousePosition(data);
          break;
        default:
          console.info("peerMouse未处理的键鼠事件:", code);
      }
    });
    pc.on("videoStreamInfo", (data) => {
      AtKit.onSceneChanged("videoStreamInfo", data);
      //组装发送一个 keyQuality 回调
      AtKit.onSceneChanged("keyQuality", keyQuality(data));
    });
    pc.on("error", (options) => {
      console.log("收到ice断开事件");
      let { code, message, error } = options;
      switch (code) {
        // ice断开
        case "5001":
          break;
        default:
          break;
      }
    });
    return pc;
  };
  // new Socket连接
  createServerConnection = async (uid) => {
    if (this.socket) {
      return;
    }
    try {
      console.log(atKitCofingInstance.config.signal.addr);
      this.socket = null;
      this.socket = new Socket(atKitCofingInstance.config.signal.addr, String(uid));
      console.log(`sokect进行连接:, ${JSON.stringify(this.socket)}}`);
    } catch (error) {
      console.log("new Sokect error :", error);
      AtKit.onSceneChanged("warning", { message: `[createServerConnection]sokect连接: ${error}` });
    }
    // 开始一个新的连接的回调
    this.socket.on("start", async (message) => {
      console.log("sokect start :", message);
      //每次连接信令会处理让agent stop 在连不然会连不上
      if (this.connPeerTimerId) {
        clearTimeout(this.connPeerTimerId);
        this.connPeerTimerId = null;
      }
      this.connPeerTimerId = setTimeout(() => {
        this.connPeer.connect();
        this.connPeer.initPeer();
      }, 1500);
      logCollector.log({
        eventType: 3,
        eventName: "Signaling State Change",
        details: `sokect向对端建立连接,对端设备号[${message.room}]`,
      });
    });
    // 连接的 sdp 信息的交换
    this.socket.on("message", (message) => {
      try {
        this.connPeer.handleSDPInformation(message);
      } catch (error) {
        logCollector.log({
          eventType: 3,
          eventName: "Signaling State Change",
          details: `sokect message error: ${error}`,
        });
        AtKit.onSceneChanged("warning", { message: `[sokect]sokect连接: ${error}` });
      }
    });
    this.socket.on("stop", async (data) => {});
    //监听排队信息
    this.socket.on("queue_information", (info) => {
      try {
        //队列信息来了 不一定是排到队
        if (info) {
          // relayConf 运营商的转发[{\"isp\":\"中国电信\",\"relayURL\":\"r4.geelevel.com\",\"relayAuthType\":\"user\",\"relayUser\":\"geelevel\",\"relayPwd\":\"geelevel\",\"relayForce\":\"Y\"},{\"isp\":\"中国联通\",\"relayURL\":\"r4.geelevel.com\",\"relayAuthType\":\"user\",\"relayUser\":\"geelevel\",\"relayPwd\":\"geelevel\",\"relayForce\":\"Y\"},{\"isp\":\"中国移动\",\"relayURL\":\"r4.geelevel.com\",\"relayAuthType\":\"user\",\"relayUser\":\"geelevel\",\"relayPwd\":\"geelevel\",\"relayForce\":\"Y\"}]
          if (info?.relayConf) {
            let relayConf = JSON.parse(info.relayConf);
            atKitCofingInstance.setRelayConf(relayConf); //更新转发
          }
          //开始游戏
          gameInstance.confirmGame(info);
        }
      } catch (error) {
        console.error(`排队信息出错:${error}`);
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `排队信息出错:${error}`,
        });
        AtKit.onSceneChanged("warning", { message: `[createServerConnection]排队信息出错: ${error}` });
      }
    });
    await this.socket.start(); //连接
  };
  //设置fps等日志
  setHistroy = (key, value) => {
    this.setHistroyHash[key] = value;
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `设置ID: ${key} ,value: ${value}`,
    });
  };
  //获取fps等日志
  getHistory = (key) => {
    return this.setHistroyHash[key];
  };
  /**
   * 解析对等连接的应答消息
   * @param {*} data
   */
  parsePeerConnACK = async (data) => {
    const object = fromProtobuf("CallAck", data);
    //认证完成
    //认证通过没有action是 authority
    // 根据收到的动作类型进行处理
    if (object.action) {
      switch (object.action) {
        // 连接成功，但认证失败
        case 1:
          AtKit.onSceneChanged("stop", {
            reason: "tokenFailed", //token鉴权失败
            message: "认证失败",
            errorCode: object.reason,
            cId: gameInstance.remoteID,
          });
          logCollector.log({
            type: "error",
            eventType: 22,
            eventName: "SDK State",
            details: `token认证失败:errorCode:${JSON.stringify(object.reason)}`,
          });
          //认证失败 --- 清空浏览器所有缓存
          gameInstance.stopGame(0, true, "token认证失败");
          executionTimer.end("authSuccess");
          break;
        default:
          console.error("未知的应答信息:", object);
          break;
      }
    } else {
      //发送认证
      executionTimer.end("authSuccess");
      //发送要声音
      setPeerEnableAudio();
      //非玩家 房主才允许发过去
      if (!permissionsInstance.isPlayer) {
        let sendUserInfo = {
          userID: gameInstance.gameParameters.userInfo.userID,
          gameID: gameInstance.gameParameters.pkgName,
          bId: AtKit.args.accessKeyId,
          gamePkg: "",
          cId: gameInstance.getPlayToken().playToken, //
          isArchive: gameInstance.gameParameters.isArchive, //true是否存档
          extraData: gameInstance.gameParameters.extraData, //h5透传去agent的内容
        };
        this.sendCloudPc("sdkUserInfo", sendUserInfo);
        this.sendCloudPc("userInfo", sendUserInfo);
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `SDK发送的userInfo:${JSON.stringify(sendUserInfo)}`,
        });
        //设置对方分辨率
        if (gameInstance.gameParameters.viewResolutionWidth && gameInstance.gameParameters.viewResolutionHidth) {
          setRemoteVideoResolution(gameInstance.gameParameters.viewResolutionWidth, gameInstance.gameParameters.viewResolutionHidth);
        }
      }

      logCollector.log({
        eventType: 22,
        eventName: "SDK State",
        details: `token认证成功:发送用户信息,发送要声音事件给agent`,
      });
      executionTimer.start("startRemoteVideoCapture");
    }
  };
  /**
   *
   * @param {*} options 根据options 有没有posCuror属性来区分是鼠标还是键盘
   * @returns
   *
   * nputOp对应 buttonState
   * 512 左键 = 1
   * 513 中键 = 4
   * 514 右键 = 2
   * 515 滚轮 = 6 // event buttons 没有状态
   * 516 移动 = 2
   * inputState 1默认 2 按下 3抬起 对应 moveType 4 按下 3抬起
   * value 对应 滚轮 0默认 1 鼠标向上滚 -1 向下滚 对应 6 wheel
   *  posCursor 对应 x y
   */
  cmdToCloud = (options) => {
    if (typeof options !== "object") {
      AtKit.onSceneChanged("warning", { message: `cmdToCloud 参数错误` }); //参数错误
      return;
    }
    //携带posCursor就认为是鼠标
    if (options && options.hasOwnProperty("posCursor")) {
      // inputOp: 512 inputState: 3 posCursor: {x: 83, y: 83} posMouse: {x: 960, y: 540} value: 0
      try {
        processSdkMouseEvent(options);
      } catch (error) {
        AtKit.onSceneChanged("warning", { message: `[cmdToCloud] 未知error错误:${error}` }); //参数错误
      }
    } else {
      //自定义键盘
      if (options && options.inputState !== 1) {
        //目前兼容3A F12碰到特定值打开准心
        gameConfigInstance.configureExtraGameSettings(options);
        //键盘
        // {inputOp: 83; 发送的值 inputState: 2; 2按下 3 抬起 4大写关闭 5 大写打开  value: 0; 默认值
        let obj = {
          inputOp: options.inputOp,
          inputState: options.inputState,
          value: options.value || 0,
        };
        sendSdkKeyboardEvent(obj);
        console.log("obj:", obj);
        //xbox 键盘
      } else {
        options.inputOp = Number(options.inputOp);
        switch (options.inputOp) {
          case 1024:
            //xBoxList
            this.xBoxList.buttons = options.value;
            break;
          case 1025:
            this.xBoxList.leftTrigger = options.value;
            break;
          case 1026:
            this.xBoxList.rightTrigger = options.value;
            break;
          case 1027:
            this.xBoxList.thumbLX = options.value;
            break;
          case 1028:
            this.xBoxList.thumbLY = options.value;
            break;
          case 1029:
            this.xBoxList.thumbRX = options.value;
            break;
          case 1030:
            this.xBoxList.thumbRY = options.value;
            break;
          default:
            break;
        }
        sendXboxGame(this.xBoxList.buttons, this.xBoxList.leftTrigger, this.xBoxList.rightTrigger, this.xBoxList.thumbLX, this.xBoxList.thumbLY, this.xBoxList.thumbRX, this.xBoxList.thumbRY);
      }
    }
  };
  /**
   * 开启声音开关
   * @param {*} enabled true 开启 false
   */
  enableAudio = (enabled) => {
    const audio = document.getElementById("AtKitAudio");
    if (enabled) {
      audio.muted = true;
    } else {
      audio.muted = false;
    }
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `声音开关:${enabled}`,
    });
  };
  /**
   * 连接云主机
   */
  connect = () => {
    this.connPeer = this._createPeerConnection();
    this.socket.connect(gameInstance.remoteID);
  };
  /**
   *
   * @param {*} lable 事件名
   * @param {*} buffer 数据缓冲区
   * 将给定的数据缓冲区（buffer）通过数据通道（DataChannel）发送给连接的对等方
   */
  sendDataChannel = (lable, buffer) => {
    try {
      if (this.connPeer !== undefined) {
        this.connPeer.sendDataChannel(lable, buffer);
      }
    } catch (error) {
      console.error("sendDataChannel error", error);
    }
  };
  /**
   * 开始采集屏幕
   * @param {*}
   */
  startScreenCapture = () => {
    let buf = toProtobuf(
      "StartScreenCapture",
      {
        index: 0,
      },
      1518
    );
    //发送对端开始采集视频
    this.sendDataChannel("custom-message", buf);
    executionTimer.end("startRemoteVideoCapture");
    executionTimer.start("videoFirstFrame");
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `发送对端开始采集视频`,
    });
    console.log("发送对端开始采集视频:startScreenCapture");
    this.isScreenCapture = true; //
  };
  /**
   * 视频第一帧后 发现 发送 fps 码率
   */
  sendFpsAndBitrateAfterFirstFrame = () => {
    executionTimer.end("queueToVideoFirstFrame");
    //码率 不传默认4 3mb
    if (gameInstance.gameParameters.resolutionId) {
      this.setVideoBitrate(gameInstance.gameParameters.resolutionId);
    } else {
      this.setVideoBitrate(4);
    }
    //发古墓丽影模拟点击
    if (gameInstance.gameParameters.pkgName === "666112dc466d41f5854ea9ee") {
      mouseCursorInstance.autoClickCloudPc();
      setTimeout(() => {
        mouseCursorInstance.autoClickCloudPc();
      }, 1000);
    }
    //桌面模式
    setVideoContent(1);
    this.setHistroy("1514", "桌面速度模式 速度优先");
    //fps
    setPeerVideoFps(0, 60);
    this.setHistroy("1513", "设置帧率 60fps index 0 ");
    //发送是否显示远程光标
    setOpenPeerCursor({ value: 0, index: 1 });
    this.setHistroy("1524", "设置光标显示状态为 0 ");
    this.connPeer.getWebRtcStats(); //获取webrtc状态
    //定时sdk驱动agent发心跳 如果非房主的情况下就不发这个了
    if (!permissionsInstance.isPlayer) {
      heartbeatInstance.startHeartbeatTimer();
      //记录无操作定时器
      heartbeatInstance.delayedLogger(gameInstance.gameParameters.noInputTimeout);
    }
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `
        1.设置桌面模式
        2.设置fps:value 60
        3.设置是否显示远程光标:value 0
        `,
    });
  };
  // 设置分辨率
  setVideoStreamDimensions = (id) => {
    //
    const result = this.resolutionList.find((item) => item.id === id);
    //根据设置清晰度默认去修改分辨率
    let dimensions = result.resolution.split("x");
    let width = parseInt(dimensions[0]);
    let height = parseInt(dimensions[1]);
    setRemoteVideoResolution(width, height, 1);
  };
  // 设置清晰度
  setVideoBitrate = (id) => {
    try {
      const result = this.resolutionList.find((item) => item.id === id);
      switchBitrate(result.bitRate);
      logCollector.log({
        eventType: 22,
        eventName: "SDK State",
        details: `设置比特率:${result.bitRate}`,
      });
      this.setHistroy("1509", `设置比特率:${result.bitRate}`);
      this.setVideoStreamDimensions(id);
      logCollector.log({
        eventType: 22,
        eventName: "SDK State",
        details: `设置分辨率:${result.resolution}`,
      });
      this.setHistroy("1517", `设置分辨率:${result.resolution}`);
    } catch (error) {
      console.log("设置码率有误:", error);
      logCollector.log({
        type: "error",
        eventType: 22,
        eventName: "SDK State",
        details: `设置码率有误:${error}`,
      });
      AtKit.onSceneChanged("warning", { message: `[initSDK]setVideoBitrate: ${error}` });
    }
  };
  //notifyUpdate 防止重连不上 发送给信令 信令会通知agent
  notifyUpdate = async (code, msg) => {
    let info = window.localStorageUser.get("current_connection_info");
    if (!info || (!info.remoteID && !info.deviceID)) {
      return;
    }
    let playToken = gameInstance.getPlayToken().playToken;
    // 目标 URL
    // const url = "https://signal.everylinks.com/notifyUpdate";
    // 请求体 (根据实际需求填写)
    const payload = {
      type: 5,
      op: "stop",
      deviceID: [gameInstance.remoteID],
      data: {
        fromDevice: gameInstance.localDeviceId,
        reason: code,
        playToken: playToken,
        msg: msg,
      },
    };
    console.log("payload:",payload);
    

    await sendSignAPi({ data: JSON.stringify(payload) });
    // // 请求选项
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(payload),
    // };
    // // 发送 POST 请求
    // fetch(url, requestOptions)
    //   .then((response) => {
    //     if (!response.ok) {
    //       // throw new Error("请求失败，状态码: " + response.status);
    //       return response.status;
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     console.log("请求成功!", data);
    //   })
    //   .catch((error) => {
    //     console.error("请求出错:", error);
    //     //请求出错就通过dataChannle去修改
    //     setPeerClose(2, code);
    //   });
    logCollector.log({
      eventType: 3,
      eventName: "Signaling State Change",
      details: `调用后台接口通知对端结束是:${gameInstance.remoteID}`,
    });
    // logCollector.log({
    //   eventType: 3,
    //   eventName: "Signaling State Change",
    //   details: `调用信令的notifyUpdate接口,对端是:${gameInstance.remoteID}`,
    // });
  };
  //向agent发送截图的意思
  captureGameImage = (options) => {
    let data = {
      msg_id: 2, // 必填
      type: "captureGameImage",
      data: options,
    };
    sendCustomJson(data);
  };
  //发信息给agent
  sendCloudPc = (type, data) => {
    //发送用户信息过去
    let info = {
      msg_id: 2, // 必填
      type: type,
      data: data,
    };
    sendCustomJson(info);
  };
  /**
   * 处理自定义JSON
   */
  processingCustomJson = (obj) => {
    switch (obj.type) {
      case "agent_video_play":
        //isScreenCapture 是为了agent错误的发送多次采集
        if (this.isScreenCapture) return;
        this.startScreenCapture();
        break;
      case "agent_cardiacEvent":
        this.sendCloudPc("cardiacEvent", {});
        break;
      case "agent_startLiveState":
        //开始直播
        console.log({
          result: obj.result || false,
          msg: codeInstance.getDescriptionByKey(obj.msg) || "",
        });
        AtKit.onSceneChanged("startLiveState", {
          result: obj.result || false,
          msg: codeInstance.getDescriptionByKey(obj.msg) || "",
        });
        break;
      case "agent_endLiveState":
        console.log({
          result: obj.result || false,
          msg: codeInstance.getDescriptionByKey(obj.msg) || "",
        });
        //关闭直播
        AtKit.onSceneChanged("endLiveState", {
          result: obj.result || false,
          msg: codeInstance.getDescriptionByKey(obj.msg) || "",
        });
        break;
      case "agent_permission":
        //agent设置的权限回调
        AtKit.onSceneChanged("gameControl", {
          state: obj.position === 0 ? "loses" : "got",
          playerToken: gameInstance.getPlayerToken().playerToken,
        });
        break;
    }
  };
  /**
   * 处理设置fps等结果
   */
  replayResult = (unknownData) => {
    if (unknownData) {
      console.log("自定义信息:", unknownData);
      switch (unknownData.code) {
        case 1518:
          //开始屏幕采集回来了 需要从新发offer 并且type = 0
          this.connPeer.createOffer(0);
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: `开始屏幕采集回来了 需要从新发 offer`,
          });
          break;
        case 1509:
          //设置码流结果 -1失败
          AtKit.onSceneChanged("onStreamSetupResult", {
            result: !(unknownData?.result === -1),
          });
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: this.getHistory("1509") + `设置结果: ${!(unknownData?.result === -1)}`,
          });
          break;
        case 1514:
          //设置桌面模式
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: `设置桌面模式结果: ${!(unknownData?.result === -1)}`,
          });
          break;
        case 1513:
          //onFPSSetupResult
          AtKit.onSceneChanged("onFpsSetupResult", {
            result: !(unknownData?.result === -1),
          });
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: this.getHistory("1513") + "设置结果" + !(unknownData?.result === -1),
          });
          break;
        case 1517:
          AtKit.onSceneChanged("onResolutionSet", {
            result: !(unknownData?.result === -1),
          });
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: this.getHistory("1517") + `设置结果:${!(unknownData?.result === -1)}`,
          });
          break;
        case 1524:
          logCollector.log({
            eventType: 22,
            eventName: "SDK State",
            details: this.getHistory("1524") + `获取对端光标信息${!(unknownData?.result === -1)}`,
          });
          break;
        default:
          logCollector.log({
            eventType: 4,
            eventName: "Data Channel State Change",
            details: `"收到未知 DadaChannel 自定义信息: code: "${unknownData.code}`,
          });
          break;
      }
    }
  };
}

// 导出单例实例
const realTimeInstance = new RealTimeConnection();
export default realTimeInstance;
