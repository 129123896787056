// 封装fetch的基本配置
// import AtKit from "@/lib/AtKit/AtKit";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    // 在这里可以添加其他默认的请求头
  },
};

// 实际的网络请求函数
async function fetchWrapper(url, options = {}) {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  try {
    const response = await fetch(`${BASE_URL}${url}`, mergedOptions);
    if (!response.ok) {
      //throw new Error("Network response was not ok");
      return "Network response was not ok";
    }

    return response.json();
  } catch (error) {
    // AtKit.onSceneChanged("warning", { message:`[getPlayToken]获取playToken出错: ${error}`});
    return `Fetch error: ${error.message}`;
    //throw new Error(`Fetch error: ${error.message}`);
  }
}

// 封装常用的GET请求
export async function get(url, options = {}) {
  return fetchWrapper(url, { method: "GET", ...options });
}

// 封装常用的POST请求
export async function post(url, body, options = {}, xtoken = false, signId = null) {
  if (xtoken) {
    let playToken = window.localStorageUser?.get("current_connection_info")?.playToken || window.localStorageUser?.get("current_connection_info")?.playerToken;
    defaultOptions.headers = {
      ...defaultOptions.headers,
      "x-token": playToken,
    };
  } else {
    defaultOptions.headers = {
      "Content-Type": "application/json",
      // 在这里可以添加其他默认的请求头
    };
  }

  if (signId) {
    defaultOptions.headers = {
      ...defaultOptions.headers,
      "x-sign-id": signId,
    };
  }

  return fetchWrapper(url, {
    method: "POST",
    body: JSON.stringify(body),
    ...options,
  });
}
// export async function postEs(body) {
//   let playToken = window.localStorageUser?.get("current_connection_info")?.playToken;
//   defaultOptions.headers = {
//     ...defaultOptions.headers,
//     "x-token": playToken,
//   };
//   return fetchWrapper(BASE_ES_URL, {
//     method: "POST",
//     body: JSON.stringify(body),
//   });
// }
