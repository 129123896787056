import CryptoJS from "crypto-js";

class LogCollector {
  constructor() {
    if (LogCollector.instance) {
      return LogCollector.instance;
    }
    this.logs = []; //日志数组
    this.logTime = 60; // 单位时间秒
    this.logTool = 60; // 日志条数
    this.timer = null;
    this.cachedLogs = [];
    LogCollector.instance = this;
  }

  static getInstance() {
    if (!LogCollector.instance) {
      new LogCollector();
    }
    return LogCollector.instance;
  }

  log(options, type, bulk = false) {
    // 获取浏览器的 playToken
    let currentConnectionInfo = window.localStorageUser.get("current_connection_info");
    if (!currentConnectionInfo || !currentConnectionInfo.playToken) {
      // 将日志暂存到缓存中
      this.cachedLogs = this.cachedLogs || [];
      this.cachedLogs.push({ options, type, bulk });
      return;
    }
    let { playToken } = currentConnectionInfo;
    // 获取 start_game 的 accessKeyId
    let startGameInfo = window.localStorageUser.get("start_game");
    if (!startGameInfo || !startGameInfo.accessKeyId) {
      console.error("accessKeyId not found in start_game");
      return;
    }
    let { accessKeyId, userInfo } = startGameInfo;
    options.userId = userInfo.userID || ""; //用户的userID
    options.uuid = playToken; // uuid
    options.timestamp = new Date().getTime(); // 时间戳
    options.accessKeyld = accessKeyId;
    options.eventName = options.eventName || "Sdk State";
    options.eventType = options.eventType || 22;
    options.type = options.type || "info";
    options.deviceType = "SDK";
    // 检查并上传缓存的日志
    if (this.cachedLogs && this.cachedLogs.length > 0) {
      for (let log of this.cachedLogs) {
        log.options.userId = options.userId;
        log.options.uuid = options.uuid;
        log.options.timestamp = new Date().getTime();
        log.options.accessKeyld = options.accessKeyld;
        this.uploadSingleLogToServer(log.options, log.type);
      }
      this.cachedLogs = []; // 清空缓存
    }
    //单条的直接提交不记录日志内
    if (!bulk) {
      this.uploadSingleLogToServer(options, type);
      return;
    }
    //如果是要批量提交的 要追加

    this.logs.push(options);

    // 到达条数阀值时上传
    if (this.logs.length >= this.logTool) {
      this.uploadLogsToServer("switch_status_history");
    }
  }
  post(options, type, bulk = true) {
    this.uploadSingleLogToServer(options, type);
  }

  async uploadSingleLogToServer(log = this.logs[0], type = "switch_status_history") {
    const logsToSend = JSON.stringify(log);
    const username = "elastic";
    const password = "geelevel@es";
    const headers = {
      Authorization: "Basic " + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(username + ":" + password)),
      "Content-Type": "application/json",
    };

    const url = "https://es.everylinks.com/" + type + "/_doc/";

    const options = {
      method: "POST",
      headers: headers,
      body: logsToSend,
    };
    try {
      await fetch(url, options);
      this.clearLogs();
    } catch (error) {
      console.log("提交状态改变日志error:", error);
    }
  }

  async uploadLogsToServer(type = "switch_status_history") {
    if (this.logs.length === 0) {
      console.log("No logs available to upload.");
      return;
    }
    let bulkData = "";
    //console.log("this.logs.length = " + this.logs.length);
    this.logs.forEach((item) => {
      bulkData += JSON.stringify({ index: { _type: "_doc" } }) + "\n";
      bulkData += JSON.stringify(item) + "\n";
    });
    this.clearLogs();
    const username = "elastic";
    const password = "geelevel@es";
    let headers = {
      Authorization: "Basic " + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(username + ":" + password)),
      "Content-Type": "application/x-ndjson",
    };
    let url = "https://es.everylinks.com/" + type + "/_bulk/";
    const options = {
      method: "POST",
      headers: headers,
      body: bulkData,
    };
    try {
      await fetch(url, options);
    } catch (error) {
      console.log("error:", error);
    }
  }
  //清空日志
  clearLogs() {
    this.logs.splice(0, this.logs.length);
  }
  //开启日志定时器

  startLogTimer = () => {
    if (this.timer) {
      this.stopLogTimer();
    }
    this.timer = setInterval(() => {
      console.log("setInterval:setInterval");
      if (this.logs.length > 0) {
        this.uploadLogsToServer();
      }
    }, this.logTime * 1000);
  };
  //停止定时器
  stopLogTimer = () => {
    clearInterval(this.timer);
    this.timer = null;
  };
}
let instance = null;

const getInstance = () => {
  if (!instance) {
    instance = new LogCollector();
  }
  return instance;
};
// 使用单例类
const logCollector = getInstance();
export default logCollector;
