import AtKit from "@/lib/AtKit/AtKit";
import { powerQueryApi, powerChangeApi } from "@/utils/api/api-request-list";
//用于控制多人玩 接力玩的权限类
class Permission {
  constructor() {
    if (Permission.instance) {
      return Permission.instance;
    }
    this.permissions = new Map(); // 使用 Map 存储玩家信息 包括权限
    this.isPlayer = false; //是否玩家
    Permission.instance = this;
  }

  //分配控制权--设置控制权
  controlPowerDistribute = async (options, callback) => {
    let { playToken, controlInfos } = options;
    if (!playToken && !controlInfos) {
      AtKit.onSceneChanged("warning", { message: `[controlPowerDistribute]参数错误 ${JSON.stringify(options)}` });
      return "[controlPowerDistribute]参数错误";
    }
    this.permissions.clear(); // 清空原来的permissions 重新开始分配
    let players = [];
    let newPlayers = []; //用来添加新的数组用来发送 gameControl 回调
    for (let player of controlInfos) {
      // 构造每个玩家的数据对象
      let playerData = {
        playerToken: player.playerToken,
        position: player.position,
      };
      // if (this.permissions.has(player.playerToken)) {
      //   const oldValue = this.permissions.get(player.playerToken).position;
      //   const newValue = player.position;
      //   //如果不等于说明有改变
      //   if (oldValue !== newValue) {
      //     newPlayers.push(playerData);
      //   }
      // }
      // 将玩家数据对象添加到 players 数组中
      players.push(playerData);
      this.permissions.set(player.playerToken, player.position);
    }
    //1 先调用后台api接口成功了 在执行对应的权限设置
    let res = await powerChangeApi({ players: players });
    let resule = {
      code: 0,
      errorMsg: res.msg,
      controlInfos: this.permissions.entries(),
    };
    if (res.code !== 200) {
      resule.code = -1;
      this.permissionsCallback(resule, callback);
      return;
    }
    //如果成功了 遍历一下 依次发送 gameControl 回调 | hm 房主以及玩家都可以收到回调
    //this.forNewPlayers(newPlayers);
    this.permissionsCallback(resule, callback);
  };
  //控制权查询
  controlPowerQuery = async (options, callback) => {
    if (options && options.playToken) {
      //这里的更新主要是为了调用接口时的API
      window.localStorageUser.updateConnectionInfo("current_connection_info", {
        playToken: options.playToken,
      });
    }
    let res = await powerQueryApi();
    let result = {
      code: -1,
      errMsg: res.msg,
      controlInfos: res.data.players,
    };
    if (res.code === 200) {
      this.permissionsCallback(result, callback);
      return;
    }
    result.code = 0;
    this.permissionsCallback(result, callback);
  };
  // 检查自已的权限
  checkPermission = (playerId) => {
    if (!this.permissions.has(playerId)) {
      return false;
    }
    const hasPermission = this.permissions.get(playerId);
    return hasPermission;
  };
  //权限类的callback
  permissionsCallback = (options, callback) => {
    if (callback && typeof callback === "function") {
      callback(options);
    }
  };
  //遍历 newPlayers 依次发送回调
  forNewPlayers = (newPlayers) => {
    for (const item of newPlayers) {
      AtKit.onSceneChanged("gameControl", {
        state: item.position === 0 ? "loses" : "got",
        playerToken: item.playerToken,
      });
    }
  };
}
const permissionsInstance = new Permission();
export default permissionsInstance;
