/**
 * 游戏开始不止做游戏的操作,需要根据传入进来的domId 生成对应的播放器dom结构
 * 封装一层 用途于播放器 播放器的id 貌似都是随机生成的
 * view
 *  canvas
 *  video
 *  audio
 *  div 键盘蒙层
 *      div 键盘
 */
import { virtualKeyboard, sendKeyboardEvent, mouseEvent, sendTouchMove } from "@/utils/keyboard-mouse/Input-device-utils";
import { calculateDisplaySize, getExampleDimensions } from "@/utils/utilit-ties/tools";
import { playerBox } from "./player-html";
import { isScreenOrientationVertical, getCursorStatus } from "@/toolbox";
import AtKit from "@/lib/AtKit/AtKit";
import logCollector from "@/utils/report-log/LogCollector.js";
import MouseEvents from "./video-mouse";
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor.js";
import executionTimer from "@/utils/time-consuming/timer.js";
import realTimeInstance from "@/lib/AtKit/real-time-connection/real-time-connection";
class VideoPlayer {
  constructor() {
    if (VideoPlayer.instance) {
      return VideoPlayer.instance;
    }
    this.mouseEventHandlers = null;
    // this.initEscEvent();
    this.cursorTag = null; //存储鼠标dom结构
    this.customPlay = null; //存储播放器的信息
  }
  //加载播放器
  loadPlayer = (containerId) => {
    try {
      let container = document.getElementById(containerId);
      if (!isScreenOrientationVertical()) {
        container.style.width = window.innerWidth + "px";
        container.style.height = window.innerHeight + "px";
      } else {
        //竖屏
        container.style.width = window.innerHeight + "px";
        container.style.height = window.innerWidth + "px";
      }
      container.innerHTML = playerBox;
      // 获取视频元素
      const video = document.getElementById("AtKitVideo");
      this.cursorTag = document.getElementById("AtKitCursorTag");
      //设置视频的宽高
      this.setWidthAndHeight();
      //视频第一帧
      video.addEventListener("loadeddata", () => {
        realTimeInstance.isFirstFrame = true;
        AtKit.onSceneChanged("play", {
          message: "",
        });
        AtKit.onSceneChanged("loadeddata", {
          message: "视频第一帧来了",
        });
        //发送 fps 码率 等
        realTimeInstance.sendFpsAndBitrateAfterFirstFrame();
        executionTimer.end("videoFirstFrame");
        executionTimer.start("gameEndTimeElapsed");
        this.mouseEventHandlers = new MouseEvents();
        if (mouseCursorInstance.mouseSendPositionType === 1) {
          this.mouseEventHandlers.setCursorCenter();
        }
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `视频第一帧来了`,
        });
      });
      //当视频因需要缓冲数据而暂停播放时触发。
      video.addEventListener("waiting", function () {
        console.log("Video is waiting for more data (buffering)...");
      });
      //当浏览器无法取回媒体数据时触发。
      video.addEventListener("stalled", function () {
        console.log("Video has stalled...");
      });
      //当浏览器已取得媒体数据但停止加载更多数据时触发。
      video.addEventListener("suspend", function (err) {
        console.log("suspend:", new Date().getTime());
      });
      video.addEventListener("error", (e) => {
        console.error("Video error:", e);
        console.error("Error details:", error);
      });

      window.addEventListener("resize", () => {
        this.setWidthAndHeight();
      });
      //调用注册键盘事件
      this.addKeyBoard();
      this.initEscEvent();
      AtKit.onSceneChanged("loadPlayer", true);
      logCollector.log({
        eventType: 22,
        eventName: "SDK State",
        details: `加载播放器,初始化虚拟键盘操作等`,
      });
    } catch (error) {
      AtKit.onSceneChanged("loadPlayer", false);
      logCollector.log({
        type: "error",
        eventType: 22,
        eventName: "SDK State",
        details: `加载播放器失败,初始化虚拟键盘操作等:${error}`,
      });
      AtKit.onSceneChanged("warning", { message: `[loadPlayer]创建player播放器失败:: ${error}` });
    }
  };
  //注册键盘相关事件 -- keyboard
  addKeyBoard = () => {
    // 缓存 DOM 元素
    const toggleButton = document.querySelector(".i_button[data-value='switch']");
    const letterPanel = document.querySelector(".panel.letter");
    const symbolPanel = document.querySelector(".panel.symbol");
    const backButton = document.querySelector(".i_button[data-value='back']");
    const capsLockButton = document.querySelector(".capsLock");
    letterPanel.classList.add("panel-active");
    symbolPanel.classList.remove("panel-active");
    // 切换面板
    toggleButton.addEventListener("click", (event) => {
      event.stopPropagation();
      letterPanel.classList.toggle("panel-active");
      symbolPanel.classList.toggle("panel-active");
    });

    // 返回按钮功能
    backButton.addEventListener("click", (event) => {
      event.stopPropagation();
      letterPanel.classList.add("panel-active");
      symbolPanel.classList.remove("panel-active");
    });
    // 按键点击事件委托
    const keys = document.querySelector(".at-vir-keyboard");
    keys.addEventListener("click", (event) => {
      event.stopPropagation();
      const target = event.target.closest(".i_button");
      if (!target) return;
      const keyValue = target.dataset.value;
      let state = target.dataset.state || 0;
      let needShift = target.dataset.needshift === "true";

      // 如果需要 Shift，则触发虚拟键盘事件
      if (needShift) {
        virtualKeyboard("Shift", 2, 0);
      }

      // 触发虚拟键盘事件
      virtualKeyboard(keyValue, state, 0);

      // 模拟 keyup 事件
      target.classList.add("active");
      setTimeout(() => {
        virtualKeyboard(keyValue, state, 1);
        if (needShift) {
          virtualKeyboard("Shift", 2, 1);
        }
        target.classList.remove("active");
      }, 0);
    });

    // 处理大写锁定
    capsLockButton.addEventListener("click", (event) => {
      event.stopPropagation();
      toggleCapsLock();
    });

    function toggleCapsLock() {
      const buttons = document.querySelectorAll(".i_button_letter");
      const isCapsLockActive = capsLockButton.textContent === "大写";
      capsLockButton.textContent = isCapsLockActive ? "小写" : "大写";
      capsLockButton.setAttribute("data-label", isCapsLockActive ? "小写" : "大写");

      buttons.forEach((button) => {
        const label = button.dataset.label;
        if (isCapsLockActive) {
          button.textContent = label.toUpperCase();
          button.setAttribute("data-state", "8");
        } else {
          button.textContent = label.toLowerCase();
          button.removeAttribute("data-state");
        }
      });
    }
  };
  //监听键盘ESC事件 特殊处理需要获取当前光标信息
  initEscEvent = () => {
    //播放器初始化一个按钮事件用来判断是否是esc
    document.addEventListener("keydown", function (event) {
      //获取云主机光标信息
      if (event.key === "Escape") {
        getCursorStatus();
      }
    });
  };
  //设置播放器类宽高
  setWidthAndHeight = () => {
    let customPlayers = document.getElementById("custom-player");
    this.customPlay = customPlayers;
    let videoInfo = null;
    if (customPlayers) {
      // 获取之前保存的 videoInfo 对象
      let storedVideoInfo = window.localStorageUser.get("video_info");
      //在这里设置视频 计算实际上应该放的视频宽高 取document.getElementById(containerId); example

      let { width, height } = getExampleDimensions();

      videoInfo = calculateDisplaySize(storedVideoInfo.DeviceWidth, storedVideoInfo.DeviceHeight, width, height);
      // 合并之前的值和新的值
      let mergedVideoInfo = Object.assign({}, storedVideoInfo, videoInfo);
      window.localStorageUser.set("video_info", mergedVideoInfo);
    }
    if (this.cursorTag === null) {
      this.cursorTag = document.getElementById("AtKitCursorTag");
    }
    //需要拿到缩放的值 给我鼠标dom结构 以方便后续设置缩放

    if (this.cursorTag) {
      this.cursorTag.setAttribute("scale", videoInfo.scaleWidth);
      this.cursorTag.style.display = "block"; //设置鼠标可见
    } else {
      console.error("未找到具有ID 'cursorTag' 的DOM元素。");
    }
    customPlayers.style.width = videoInfo.displayWidth + "px";
    customPlayers.style.height = videoInfo.displayHeight + "px";
    customPlayers.style.left = videoInfo.leftX + "px";
    customPlayers.style.top = videoInfo.topY + "px";
  };
  playVideo() {
    let video = document.getElementById("AtKitVideo");
    video.play();
  }
  /**
   * 传入dom元素id继续删除
   * @param {*} elementId
   */
  deleteElementById(elementId) {
    var element = document.getElementById(elementId);
    if (element) {
      // element.parentNode.removeChild(element);
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    } else {
      return false;
    }
  }

  /**
   * 注册外面键盘图片的事件
   */
  keyboardfolding = (isEnabled) => {
    //控制键盘的展示和隐藏
    try {
      let keyboardBox = document.getElementsByClassName("at-vir-keyboard-box")[0];
      if (!keyboardBox) return;
      if (isEnabled) {
        keyboardBox.style.display = "block";
        mouseCursorInstance.isKeyboardVisible = true;
        // 延迟添加点击事件监听器
        document.addEventListener("click", this.hideKeyboardOnClickOutside);
      } else {
        keyboardBox.style.display = "none";
        mouseCursorInstance.isKeyboardVisible = false;
      }
    } catch (error) {
      console.log("虚拟键盘错误:", error);
      logCollector.log({
        type: "error",
        eventType: 22,
        eventName: "SDK State",
        details: `虚拟键盘异常:${error}`,
      });
      AtKit.onSceneChanged("warning", { message: `[keyboardfolding]虚拟键盘异常:: ${error}` });
    }
  };
  //判断当前点击位置是否在虚拟键盘元素内
  hideKeyboardOnClickOutside = (event) => {
    event.stopPropagation(); // 阻止事件冒泡
    const keyboardRect = document.querySelector(".at-vir-keyboard").getBoundingClientRect();
    //判断是否在键盘元素外面点击 如果是就消失自定义键盘
    const isOutside = !(event.clientX >= keyboardRect.left && event.clientX <= keyboardRect.right && event.clientY >= keyboardRect.top && event.clientY <= keyboardRect.bottom);
    if (isOutside) {
      this.hideKeyboard();
    }
  };
  //隐藏虚拟键盘
  hideKeyboard = () => {
    var keyboardBox = document.querySelector(".at-vir-keyboard-box");
    keyboardBox.style.display = "none";
    AtKit.onSceneChanged("keyBoardState", {
      result: false,
    });
    mouseCursorInstance.isKeyboardVisible = false;
    // 移除点击事件监听器
    document.removeEventListener("click", this.hideKeyboardOnClickOutside);
  };
  //显示准心
  openCrosshair = (isOpen) => {
    let crosshair = document.getElementById("AtKitCrosshair");
    // 切换准心图片的显示状态
    if (crosshair.style.display === "none" || crosshair.style.display === "") {
      crosshair.style.display = "block";
    } else {
      crosshair.style.display = "none";
    }
  };
}
// 导出单例实例
const videoPlayerInstance = new VideoPlayer();
export default videoPlayerInstance;
