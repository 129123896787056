import logCollector from "@/utils/report-log/LogCollector.js";
/**
 * 创建事件数据对象
 * @param {string} deviceID - 设备ID，可选，独立ID的天个数，如果是云游戏，不记录接收端的这个ID
 * @param {string} uuid - 连接ID，必选，用于记录连接了多少次，按天的个数 -- 对应sdk本次游戏过程的token
 * @param {number} timestamp - 时间戳，必选，Unix 时间戳
 * @param {string} accessKeyld - 访问 key，必选，用于区分租户
 * @param {number} eventType - 事件类型，必选，见如下 3.2
 * @param {string} eventName - 事件名称，必选，见如下 3.2
 * @param {string} details - 改变的详细内容，必选，见如下 3.2
 * @param {string} userID - 用户ID，可选，用户ID
 * @param {number} startConnectTime - 开始时间，可选，Unix 时间戳
 * @param {number} endConnectTime - 结束时间，可选，Unix 时间戳
 * @param {number} duration - 过程用时，可选，Unix 时间戳
 * @returns {object} - 返回包含事件数据的对象
 */
function createEventData(deviceID, uuid, timestamp, accessKeyld, eventType, eventName, details, userID, startConnectTime, endConnectTime, duration) {
  // 构建事件数据对象
  const eventData = {
    deviceID: deviceID || "", // 默认为空字符串
    uuid: uuid || "", //
    timestamp: timestamp || Math.floor(Date.now() / 1000), // 默认为当前时间的 Unix 时间戳
    accessKeyld: accessKeyld || "", // 默认为空字符串
    eventType: eventType || 0, // 默认为0 1. 10 以内保留给 webrtc 使用。     2. 10-20 保留给标准事情使用。 3. 20 以上给业务使用。
    eventName: eventName || "", // 默认为空字符串
    details: details || "", // 默认为空字符串
    userID: userID || "", // 默认为空字符串
    startConnectTime: startConnectTime || null, // 默认为null
    endConnectTime: endConnectTime || null, // 默认为null
    duration: duration || null, // 默认为null
  };
}
export { createEventData };

/**
 * 每次启动调用
 * @param {*} options
 * @returns
 */
export function analyzeStartup(options) {
  let { timestamp, version, channel, accessKeyld, isFirstUse, ip, userID, language, platform, osVersion, country, deviceID, machineName, type } = options;
  // 创建包含所有字段的对象
  const analysisData = {
    timestamp: timestamp, // 以后每个数据的提交时的标记时间 (必选) 1000
    version: version, // 软件版本 (必选) 1.0.0
    channel: channel, // 渠道 ID (必选)
    accessKeyld: accessKeyld, // 访问 key (必选), 用于区分租户
    isFirstUse: isFirstUse, // 首次使用 (必选), 是否第一次使用
    ip: ip, // 用户IP (可选), 公网IP
    userID: userID, // 用户ID (可选)
    language: language, // 语言 (可选)window.navigator.language,
    platform: platform, // 操作系统平台 (必选) //window.navigator.platform
    osVersion: osVersion, // 操作系统版本 (可选)
    country: country, // 城市 (可选)
    deviceID: deviceID, // 设备码 (可选)
    machineName: machineName, // 机器名 (可选)
    type: type, // 记录的类型 (可选), base
  };
  logCollector.post(analysisData, "app_channel", false);
  // logToEs(analysisData, "app_channel", false);
}