import { VirtualKey, toProtobuf, fromProtobuf, kcToVirtualKey, toProtoBufCode } from "@/toolbox";
import { getOperatingSystem, getOperatingSystemName } from "@/utils/utilit-ties/tools";
import AtKit from "@/lib/AtKit/AtKit";
import realTimeInstance from "@/lib/AtKit/real-time-connection/real-time-connection";
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor.js";
/**
 * 判断鼠标模式
 */
const mouseMode = (elm, object) => {
  if (!object) return;
  console.log("mouseMode:", object);
  const shouldHide = object.hasOwnProperty("hide") && object.hide === true;
  // const noId = object.hasOwnProperty("id") && object.id !== 0;
  // const noType = object.hasOwnProperty("type") && object.type !== 0;

  if (shouldHide) {
    elm.style.display = "none";
    mouseCursorInstance.mouseSendPositionType = 9; //相对位置
  } else {
    elm.style.display = "block";
    mouseCursorInstance.mouseSendPositionType = 1; //绝对位置
  }
  // console.log("mouseCursorInstance.mouseModule:",mouseCursorInstance.mouseModule);
  //CSGO的情况下隐藏的话完全收不到hide type字段
  if (!object.hasOwnProperty("type") && !object.hasOwnProperty("hide")) {
    elm.style.display = "none";
    mouseCursorInstance.mouseSendPositionType = 9; //相对位置
  }
  if (mouseCursorInstance.mouseModule !== null) {
    if (mouseCursorInstance.mouseModule == 1) {
      elm.style.display = "block";
      mouseCursorInstance.mouseSendPositionType = 1; //绝对位置
    }
    if (mouseCursorInstance.mouseModule == 9) {
      elm.style.display = "none";
      mouseCursorInstance.mouseSendPositionType = 9; //相对位置
    }
  }
  console.log("mouseCursorInstance.mouseSendPositionType:", mouseCursorInstance.mouseSendPositionType);

  // elm.style.display = "none";
  // mouseCursorInstance.mouseSendPositionType = 9; //相对位置
  //console.log("最后修改的鼠标模式:", mouseCursorInstance.mouseSendPositionType);
};
/**
 *
 * @param {*} key 要发送的键值
 * @returns
 */

const sendSpecKey = (key) => {
  let keyCode = VirtualKey.get(key);
  if (keyCode === undefined) {
    return;
  }
  let cmd = {
    event: 3, // 为安卓的键盘事件
    keyCode: keyCode,
    keyboardState: 0,
  };
  let buf = toProtobuf("KeyboardData", cmd, 2002);
  realTimeInstance.sendDataChannel("channel-keyboard", buf);
};

/**
 * 处理键盘事件
 * @param {KeyboardEvent} e 键盘事件
 * @param {string} eventType 事件类型 === e.type
 */
const sendKeyboardEvent = (e, eventType) => {
  const keyboardState = calculateKeyboardState(e);
  const keyCode = VirtualKey.get(e.code);
  if (keyCode === undefined) {
    console.log("keyCode is undefined");
    return;
  }
  if (eventType === null || eventType === undefined) {
    eventType = e.type;
  }
  const event = determineEvent(eventType);
  if (e.code === "CapsLock") {
    sendData(keyCode, keyboardState, 0);
    // 如果是 CapsLock 按键，额外发送一个 up 事件
    setTimeout(() => {
      sendData(107, 65536, 1);
    }, 10);
  } else {
    sendData(keyCode, keyboardState, event);
  }
};
/**
 * 提供给上层SDK键盘发送
 * @param {*} obj
 */
const sendSdkKeyboardEvent = (obj) => {
  let keyCode = kcToVirtualKey[obj.inputOp]; //实际上发送的keyCode
  let keyboardState = calculateInputOpKeyboardState(keyCode);
  if (obj.inputState !== 0) {
    switch (obj.inputState) {
      case 2:
        //= 0 按下
        sendData(keyCode, keyboardState, 0);
        //esc 按下
        if (keyCode === 114) {
          getCursorStatus();
        }
        //假设是枪战类型游戏 目前兼容CSGO 去开启辅助准心
        break;
      case 3:
        //=1;抬起
        sendData(keyCode, keyboardState, 1);
        break;
      //大写关闭
      case 4:
        sendData(keyCode, keyboardState, 0);
        // 如果是 CapsLock 按键，额外发送一个 up 事件
        setTimeout(() => {
          sendData(107, 65536, 1);
        }, 10);
        break;
      //大写打开
      case 5:
        sendData(keyCode, keyboardState, 0);
        // 如果是 CapsLock 按键，额外发送一个 up 事件
        setTimeout(() => {
          sendData(107, 65536, 1);
        }, 10);
        break;
      default:
        break;
    }
  }
};
/**
 * 计算键盘状态
 * @param {KeyboardEvent} e 键盘事件
 * @returns {number} 键盘状态
 */
const calculateKeyboardState = (e) => {
  let keyboardState = 0;
  if (e.code === "CapsLock") {
    keyboardState |= 0x8;
  }
  if (e.ctrlKey) {
    keyboardState |= 0x1;
  } else if (e.shiftKey) {
    keyboardState |= 0x2;
  } else if (e.altKey) {
    keyboardState |= 0x4;
  }
  return keyboardState;
};

//根据inputOp推算
const calculateInputOpKeyboardState = (inputOp) => {
  let keyboardState = 0;
  //shift
  if (inputOp === 96 || inputOp === 94) {
    keyboardState |= 0x2;
  }
  //ctrl
  if (inputOp === 99 || inputOp === 97) {
    keyboardState |= 0x1;
  }
  //alt
  if (inputOp === 92 || inputOp === 93) {
    keyboardState |= 0x4;
  }
  //capsLock
  if (inputOp === 107) {
    keyboardState |= 0x8;
  }
  return keyboardState;
};

/**
 * 确定事件类型
 * @param {string} type 事件类型
 * @returns {number} 事件类型代码
 */
const determineEvent = (type) => {
  switch (type) {
    case "keydown":
      return 0;
    case "keyup":
      return 1;
    case "keypress":
      return 3;
    default:
      return 0;
  }
};
/**
 * 发送数据
 * @param {number} keyCode 按键码
 * @param {number} keyboardState 键盘状态
 * @param {number} event 事件类型
 * @param {number} osInfo 操作系统信息 浏览器不需要
 */
const sendData = (keyCode, keyboardState, event) => {
  const cmd = {
    event,
    keyCode,
    keyboardState,
    //keyboardState: keyboardState | (0x1 << 16),
  };
  console.log("按键:", cmd);

  const buf = toProtobuf("KeyboardData", cmd, 2002);
  realTimeInstance.sendDataChannel("channel-keyboard", buf);
};

/**
 * 虚拟键盘
 * @param {*} code 按键名
 */

const virtualKeyboard = (code, keyboard_state, event) => {
  let keyboardState = 0;
  keyboardState |= Number(keyboard_state);
  let evt = event || 0; //0 keydown按下 1 keyup 弹起
  console.log("code:", code);
  switch (code) {
    case "CapsLock":
      keyboardState |= 0x8; //当前如果是大写就发8
      break;
    case "Shift":
      keyboardState |= 0x2;
      break;
    case "Alt":
      keyboardState |= 0x4;
      break;
    case "Control":
      keyboardState |= 0x1;
      break;
    case "Enter": //换行 需要加上shift keyboard_state = 2;
      keyboardState |= 0x2;
      break;
    default:
      break;
  }
  let keyCode = VirtualKey.get(code);
  if (code === "CapsLock") {
    sendData(keyCode, keyboardState, 0);
    // 如果是 CapsLock 按键，额外发送一个 up 事件
    setTimeout(() => {
      sendData(107, 65536, 1);
    }, 10);
  } else {
    sendData(keyCode, keyboardState, evt);
  }
};
/**
 *
 * @param {*} buttons 按键的合计值
 * @param {*} leftTrigger 是lt扳机键
 * @param {*} rightTrigger 是rt扳机键
 * @param {*} thumbLX 左摇杆X
 * @param {*} thumbLY 左摇杆Y
 * @param {*} thumbRX 右摇杆X
 * @param {*} thumbRY 右摇杆Y
 */
const sendXboxGame = (buttons, leftTrigger, rightTrigger, thumbLX, thumbLY, thumbRX, thumbRY) => {
  // 设置wButtons字段（16位）
  let keyCode = buttons & 0x0000ffff;

  // 设置左右触发器（各8位）
  let triggers = ((leftTrigger & 0x00ff) | ((rightTrigger & 0x00ff) << 8)) << 16;
  keyCode |= triggers;

  // 设置左右拇指摇杆（各32位）
  let keyboard_state = (thumbLY << 16) | (thumbLX & 0x0000ffff);
  //右摇杆
  let extra1 = (thumbRY << 16) | (thumbRX & 0x0000ffff);

  let cmd = {
    event: 128, // 手柄 = 128
    keyCode: keyCode,
    keyboardState: keyboard_state,
    extra1: extra1,
  };
  console.log("发送的xbox值:", cmd);
  let buf = toProtobuf("KeyboardData", cmd, 2002);
  realTimeInstance.sendDataChannel("channel-keyboard", buf);
};
// 获取键盘状态
const getKeyboardState = (e) => {
  let keyboard_state = 0;
  if (e.ctrlKey) {
    keyboard_state |= 1;
  } else if (e.shiftKey) {
    keyboard_state |= 2;
  } else if (e.altKey) {
    keyboard_state |= 4;
  }
  return keyboard_state;
};
/**
 *
 * @param {*} index 索引
 * @param {*} fps fps值
 */
const setPeerVideoFps = (index = 0, fps = 60) => {
  let buf = toProtobuf(
    "SetVideoFps",
    {
      index: index,
      fps: fps,
    },
    1513
  );
  realTimeInstance.sendDataChannel("custom-message", buf);
};
/**
 *
 * @param {*} enable 是否启用音频
 * @param {*} remote 是否远程音频
 */
const setPeerEnableAudio = () => {
  let buf = toProtobuf(
    "EnableAudio",
    {
      enable: true, //表示是否启用音频
      remote: true, //表示是否为远程音频
      echoCancellation: false, //表示是否启用回声消除
      recordDevice: 0, //录音设备的编号
      playerDevice: 0, //播放设备的编号
    },
    1550
  );
  console.log("发送申请声音:", buf);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
//移动鼠标位置
const moveMousePosition = (data) => {
  const elm = document.getElementById("AtKitCursorTag");
  const object = fromProtobuf("SetCursorData", data);
  //console.log("moveMousePosition", object);
  updateCursorImage(elm, object);
  mouseCursorInstance.isEditingShape(object);
};
//设置本地光标数据
const setLocalCursorData = (code, data) => {
  const object = fromProtobuf("SetCursorData", data);
  //console.log("object.type);", object);
  const elm = document.getElementById("AtKitCursorTag");
  // 设置光标图像
  if (code === 2015) {
    updateCursorImage(elm, object);
  }
  // 设置光标位置 2016鼠标动的时候传回来的数据
  if (code === 2016) {
    updateCursorPosition(elm, object);
  }
  //编辑形状
  mouseCursorInstance.isEditingShape(object);
};
// 更新光标图像
const canvas = document.createElement("canvas");
const updateCursorImage = (elm, object) => {
  mouseMode(elm, object);
  let videoInfo = window.localStorageUser.get("video_info");
  const typedArray = new Uint8Array(object.data);
  let u8array = new Uint8ClampedArray(typedArray);
  canvas.width = object.width;
  canvas.height = object.height;
  const context = canvas.getContext("2d");
  const imagData = new ImageData(u8array, object.width, object.height);
  context.putImageData(imagData, 0, 0);

  //如果 object 有 hide === true 不能显示本地鼠标
  elm.style.width = object.width * videoInfo.scaleWidth + "px";
  elm.style.height = object.height * videoInfo.scaleWidth + "px";
  elm.src = canvas.toDataURL();
};
// 更新光标位置
const updateCursorPosition = (elm, object) => {
  mouseMode(elm, object);
  //console.log("修改鼠标类型:mouseCursorInstance.mouseSendPositionType ", mouseCursorInstance.mouseSendPositionType);
  let videoInfo = window.localStorageUser.get("video_info");
  try {
    // 计算移动的鼠标的 x 和 y 坐标
    const mouseX = object.x * videoInfo.scaleWidth + videoInfo.leftX;
    const mouseY = object.y * videoInfo.scaleHeight + videoInfo.topY;
    elm.style.left = mouseX + "px";
    elm.style.top = mouseY + "px";
    //console.log(`光标数据 移动到 ${mouseX} ${mouseY}`);
  } catch (error) {
    //console.error("更新光标位置出错:error ===>", error);
    AtKit.onSceneChanged("warning", { message: `[updateCursorPosition]更新光标位置出错: ${error}` });
  }
};
/**
 * 用于设置对等端（peer）的剪贴板数据
 * @param {*} type 表示剪贴板数据的类型
 * @param {*} data 表示要设置的剪贴板数据内容
 */
const setPeerClipboardData = (type, data) => {
  var arraybufData = new TextEncoder("utf-8").encode(data);
  let buf = toProtobuf("SetClipboardData", { type: type, data: arraybufData }, 1512);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
/**
 * 对应海马的是
 * @param {*} value 该参数用于设置视频比特率（bitrate）的质量
 * 使用 toProtobuf() 方法生成一个 protobuf 格式的消息，用于描述设置视频比特率的操作。这个方法的具体实现不在你提供的代码中，
 * 但它很可能是根据一定的规则生成一个满足你的通信协议要求的二进制数据
 * 生成的 protobuf 消息包含了设置视频比特率的信息，其中 BitrateType 和 index 是消息的两个属性，用于描述设置的类型和索引。
 * 通过 this.sendDataChannel("custom-message", buf) 方法发送生成的二进制数据给对端，以便对端根据协议解析该消息，并根据 BitrateType 和 index 设置视频比特率。
 */
//设置码流
const switchBitrate = (value) => {
  let buf = toProtobuf("SetVideoBitrate", { value: value, index: 0 }, 1509);
  realTimeInstance.sendDataChannel("custom-message", buf);
};

const setRemoteVideoResolution = (width, height, scale = 1) => {
  //修改分辨率 1517
  let buf = toProtobuf("SetResolution", { width: width, height: height, scale: scale }, 1517);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
//获取光标按钮状态
const getCursorStatus = () => {
  let buf = toProtoBufCode(1524);
  realTimeInstance.sendDataChannel("custom-message", buf);
};

//设置桌面模式
const setVideoContent = (value) => {
  let buf = toProtobuf("SetVideoContent", { value: value, index: 0 }, 1514);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
//是否显示远程光标
const setOpenPeerCursor = (options) => {
  let buf = toProtobuf("SetShowRemoteCursor", { value: options.value, index: options.index }, 1511);
  console.log("发送是否显示远程光标:", buf);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
//设置活动屏幕
const setPeerActiveScreen = (index) => {
  let buf = toProtobuf("SetActiveScreen", { value: index }, 1510);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
const setPeerClose = (value, reason = 2) => {
  let buf = toProtobuf("EndCall", { action: value, reason: reason }, 1506);
  realTimeInstance.sendDataChannel("custom-message", buf);
};
// 发送JsonData给对方
const sendCustomJson = (value) => {
  try {
    let data = JSON.stringify(value);
    let buf = toProtobuf("JsonData", { data: data }, 1515);
    realTimeInstance.sendDataChannel("custom-message", buf);
  } catch (error) {
    console.log("JsonData error: " + error);
    AtKit.onSceneChanged("warning", { message: `[sendCustomJson]发送JsonData给对方出错: ${error}` });
  }
};

/**
 * 解析自定义数据
 */
const getCustomData = (data) => {
  try {
    let obj = fromProtobuf("JsonData", data);
    return JSON.parse(obj.data);
  } catch (error) {
    console.error("解析自定义数据出错", error, data);
    AtKit.onSceneChanged("warning", { message: `[getCustomData]解析自定义数据出错: ${error}` });
  }
};
/**
 * 鼠标移动发送数据
 */
// 需要处理, 从浏览器取得鼠标与特殊键盘的状态
// button_state 左键:1 右键:2 中键: 4
// keyboard_state control: 1  shift: 2  alt: 4
const mouseEvent = (e, coordinate) => {
  // 定义名为 mouseEvent 的函数，接收两个参数：e 是鼠标事件对象
  const moveTypeMap = {
    // 创建对象 moveTypeMap，用于将事件类型映射到移动类型
    mouseup: 3, // 鼠标抬起事件对应移动类型 3
    mousedown: 4, // 鼠标按下事件对应移动类型 4
    mousedclick: 5, // 鼠标双击事件对应移动类型 5
    wheel: 6, // 鼠标滚轮滚动事件对应移动类型 6
  };
  let moveType = moveTypeMap[e.type] || 2; // 获取当前事件的移动类型，如果没有对应的移动类型则默认为 2
  if (moveType === 2) return;
  /**
  0: 没有按键或者是没有初始化
  1: 鼠标左键
  2: 鼠标右键
  4: 鼠标滚轮或者是中键
  8: 第四按键 (通常是“浏览器后退”按键)
  16 : 第五按键 (通常是“浏览器前进”)
   */
  // 需要处理, 从浏览器取得鼠标与特殊键盘的状态
  // button_state 左键:1  右键:2  中键: 4
  let buttonState = e.buttons; // 获取鼠标按钮状态

  if (e.type === "wheel") {
    //浏览器采用 wheelDeltaX wheelDeltaY 区分正反滚动
    buttonState = -e.wheelDeltaX | (-e.wheelDeltaY & 0xffff);
  }
  let keyboardState = getKeyboardState(e);
  let { x, y } = coordinate; //获取鼠标相对于触发事件元素的偏移坐标
  //抬起需要跟踪 按下的键
  if (moveTypeMap[e.type] === 3) {
    //等于 0 左键释放
    //2 右键释放
    switch (e.button) {
      case 0:
        buttonState = 1;
        break;
      case 2:
        buttonState = 2;
        break;
      default:
        break;
    }
  }
  let cmd = {
    // 构造鼠标事件信息命令对象
    event: moveType, // 事件类型
    x, // 鼠标 x 坐标
    y, // 鼠标 y 坐标
    buttonState,
    keyboardState,
  };
  let buf = toProtobuf("MouseData", cmd, 2001); // 将命令对象转换为协议缓冲区格式
  realTimeInstance.sendDataChannel("channel-mouse", buf); // 发送鼠标数据到指定通道
};
/**
 * 提供给上层sendMessageToRom事件的鼠标操作
 * 不写在mouseEvent里防止逻辑过乱
 * @param {*} obj inputOp: 512 inputState: 2 posCursor: {x: 83, y: 83}posMouse: {x: 5283, y: 1197} value: 0
 inputOp 512 左键 513 中键 514 右键 515 滚轮 516 移动
 inputState 1 默认 2 按下 3 抬起
 value 0 默认值 1 鼠标滚轮向上滚动 -1 鼠标滚轮向下滚动
 posCursor x x轴光标位置 y y轴光标位置
 posMouse x x轴物理位置 y y轴物理位置
 */
const processSdkMouseEvent = (obj) => {
  try {
    let moveType = 0; //事件类型
    let buttonState = 0; // 左键1 右键2 中键4
    let wheelDeltaY = 0;
    let keyboardState = 0;
    if (obj.inputOp) {
      switch (obj.inputOp) {
        case 512:
          buttonState = 1;
          break;
        case 513:
          buttonState = 4;
          break;
        case 514:
          buttonState = 2;
          break;
        case 515:
          moveType = 6;
          break;
        case 516:
          moveType = 2;
          break;
        default:
          break;
      }
    }
    if (obj.inputState !== 1) {
      switch (obj.inputState) {
        case 2:
          //按下
          moveType = 4;
          break;
        case 3:
          //抬起
          moveType = 3;
          break;
        default:
          break;
      }
    }
    if (obj.value !== 0) {
      switch (obj.value) {
        case 1:
          //正数 120
          wheelDeltaY = 120;
          buttonState = -wheelDeltaY & 0xffff;
          break;
        case -2:
          //负数 -120
          wheelDeltaY = -120;
          buttonState = -wheelDeltaY & 0xffff;
          break;
        default:
          break;
      }
    }
    let { posCursor } = obj;
    let cmd = {
      // 构造鼠标事件信息命令对象
      event: moveType,
      // x: posCursor.x,
      // y: posCursor.y,
      x: 0,
      y: 0,
      buttonState,
      keyboardState,
    };
    console.log("鼠标事件:", cmd);
    let buf = toProtobuf("MouseData", cmd, 2001);
    realTimeInstance.sendDataChannel("channel-mouse", buf);
  } catch (error) {
    console.error("processSdkMouseEvent:", error);
  }
};

/**
 * 手机用这个这个函数
 * @param {*} e
 * @param {*} coordinate
 *
 * button_state 左键:1 右键:2 中键: 4
 */
const sendTouchMove = (e, coordinate, type) => {
  const moveTypeMap = {
    touchend: 3, // 触摸结束事件
    touchstart: 4, // 触摸点击 -- 鼠标单击 5
    touchmove: 2, // 触摸移动事件2
  };
  let moveType = moveTypeMap[e.type] || 2; // 获取当前事件的移动类型，如果没有对应的移动类型则默认为 5
  // 此时这样为按下 也就是点击单击 = 1
  let buttonState = 0;
  if (e.touches && e.touches.length > 0) {
    buttonState = 1;
  } else {
    buttonState = 0;
  }
  let keyboardState = 0; // 触摸事件无键盘状态，设置为 0
  let { x, y } = coordinate; // 获取第一个触摸点的页面坐标
  if (moveTypeMap[e.type] === 2) {
    buttonState = 0;
  } else if (moveTypeMap[e.type] === 3) {
    buttonState = 1;
  } else if (moveTypeMap[e.type] === 4) {
    //console.trace("调用栈:");
    //touchstart 事件 先发送一个移动过去这样点击才会响应坐标位置
    if (mouseCursorInstance.mouseType === 2) {
      let cmd = {
        event: 2,
        x: x,
        y: y,
        buttonState: 0,
        keyboardState,
      };
      let buf = toProtobuf("MouseData", cmd, 2001);
      console.log("[sendTouchMove]MouseData", cmd);
      realTimeInstance.sendDataChannel("channel-mouse", buf);
    }
  }
  //游戏鼠标模式发送的是 相对位置
  if (type === 9) {
    moveType = 9;
  }
  let cmd = {
    event: moveType, // 事件类型
    x: x,
    y: y,
    buttonState, // 触摸按钮状态
    keyboardState, // 键盘状态，触摸事件无键盘状态
  };
  console.log("MouseData : ", cmd);
  let buf = toProtobuf("MouseData", cmd, 2001); // 将命令对象转换为协议缓冲区格式
  realTimeInstance.sendDataChannel("channel-mouse", buf); // 发送触摸数据到指定通道

  //如果类型是3 并且 光标是2 并且配置了自动键盘
  if (moveType === 3 && mouseCursorInstance.cursorType === 2) {
    mouseCursorInstance._cursorModeType();
  }
};
/**
 * 用图自定义发鼠标
 */
const customMouseData = (options) => {
  var keyboardState = 0;
  if (options.wheel) {
    keyboardState = -0 | (-options.wheel & 0xffff);
  }
  let { x, y } = options.posCursor;
  let cmd = {
    event: options.buttonState,
    x,
    y,
    buttonState: options.buttonState,
    keyboardState,
  };
  console.log("发送自定义鼠标的值:", cmd);
  let buf = toProtobuf("MouseData", cmd, 2001); // 将命令对象转换为协议缓冲区格式
  realTimeInstance.sendDataChannel("channel-mouse", buf); // 发送鼠标数据到指定通道
};

export {
  getCursorStatus,
  setRemoteVideoResolution,
  setVideoContent,
  sendSdkKeyboardEvent,
  processSdkMouseEvent,
  customMouseData,
  sendSpecKey,
  sendKeyboardEvent,
  sendXboxGame,
  mouseEvent,
  setPeerVideoFps,
  setPeerEnableAudio,
  setLocalCursorData,
  setPeerClipboardData,
  switchBitrate,
  setOpenPeerCursor,
  setPeerActiveScreen,
  setPeerClose,
  sendCustomJson,
  virtualKeyboard,
  moveMousePosition,
  sendTouchMove,
  getCustomData,
};
