import CryptoJS from "crypto-js";
const getTimestampToDate = () => {
  let timestamp = new Date().getTime();
  // 创建一个新的 Date 对象，将时间戳作为参数传入
  let date = new Date(timestamp);

  // 获取年、月、日
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，所以要加1，并保证两位数格式
  let day = ("0" + date.getDate()).slice(-2); // 获取日期，并保证两位数格式
  // 返回年月日
  return `${year}-${month}-${day}`;
};
// 本函数只能在主函数内执行
export const postDataToES = async (rtcData, type = "connect_records", bulk = false) => {
  const username = "elastic";
  const password = "geelevel@es";
  let headers = {
    Authorization: "Basic " + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(username + ":" + password)),
    "Content-Type": "application/json",
  };

  let url = "https://es.everylinks.com/" + `${type}-${getTimestampToDate()}` + "/_doc/";
  // 批量提交， 换接口
  if (bulk) {
    headers["Content-Type"] = "application/x-ndjson";
    url = `https://es.everylinks.com/speed_history-${getTimestampToDate()}/_bulk/`;
  }
  const options = {
    method: "POST",
    headers: headers,
    body: rtcData,
  };
  try {
    const res = await fetch(url, options);
    if (res.status != "201" && res.status != "200") {
      let text = res.text();
      console.log(`Failed to ${url}  the code:`, res.status, " the res:", text);
    }
  } catch (error) {
    console.error("Failed to report data to JSON - Error Type: " + error.name + " with message: " + error.message);
  }
};
