import crosshairSrc from "./crosshair.svg";
export const playerBox = `
<div class="custom-player" id="custom-player">
  <canvas id="canvas" class="at-vir-canvas"></canvas>
  <video id="AtKitVideo" class="video"
    autoplay
    disablePictureInPicture 
    controlslist="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
    playsinline
    webkit-playsinline
    x5-playsinline
    x5-video-player-type="h5"
    x5-video-orientation="landscape"
    x-webkit-airplay="allow"
    preload
    style="width: 100%; height: 100%; background: none; display: block;object-fit:fill">
  </video>
  <audio
    id="AtKitAudio"          
    autoplay
    playsinline
    webkit-playsinline
    style="display: none"
    >
  </audio>
  <img
  scale=1
  class="player-canvas-cursor" 
  id="AtKitCursorTag"
  alt=""
  style="z-index:6666;position:absolute;border:none;display:none"
  ></img>
<img
  scale="1"
  id="AtKitCrosshair"
  class="AtKitCrosshair"
  alt=""
  style="z-index: 6666;width:2.2rem;height:2.2rem;position: absolute;border: none;display: none;left: 50%;top: 50%;transform: translate(-50%, -50%);"
  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwcHgiIGhlaWdodD0iMTUwcHgiIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPue8lue7hCAyPC90aXRsZT4KICAgIDxnIGlkPSLnq5bniYgiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSLnvJbnu4QtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMiwgMikiPgogICAgICAgICAgICA8cGF0aCBkPSJNNzMuMjUsMCBMNzMuMjUsMTIgTTcyLjc1LDEzNCBMNzIuNzUsMTQ2IE0xNDYsNzMuMjUgTDEzNCw3My4yNSBNMTIsNzIuNzUgTDAsNzIuNzUiIGlkPSLlvaLnirbnu5PlkIgiIHN0cm9rZT0iIzAwNGQwMCIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj48L3BhdGg+CiAgICAgICAgICAgIDxjaXJjbGUgaWQ9IuakreWchuW9oiIgZmlsbD0iIzAwNGQwMCIgY3g9IjczIiBjeT0iNzMiIHI9IjMiPjwvY2lyY2xlPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"
/>
  <div class="at-vir-layout">
  <div class="at-vir-keyboard-box">
    <div class="at-vir-keyboard-mask"></div>
    <div class="at-vir-keyboard">
      <div class="panel letter">
      <table id="virtual-keyboard" align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
          <tr>
              <td colspan="13"></td>
              <td colspan="1"><div class="i_button ctrl" data-type="button" data-value="PageUp" data-label="前翻">前翻</div></td>
              <td colspan="1"><div class="i_button ctrl" data-type="button" data-value="PageDown" data-label="后翻">后翻</div></td>
          </tr>
          <tr>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad1" data-label="1">1</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad2" data-label="2">2</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad3" data-label="3">3</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad4" data-label="4">4</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad5" data-label="5">5</div></td>
              <td colspan="5"></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad6" data-label="6">6</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad7" data-label="7">7</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad8" data-label="8">8</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad9" data-label="9">9</div></td>
              <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad0" data-label="0">0</div></td>
          </tr>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyQ" data-label="q">q</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyW" data-label="w">w</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyE" data-label="e">e</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyR" data-label="r">r</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyT" data-label="t">t</div></td>
          <td colspan="5"></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyY" data-label="y">y</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyU" data-label="u">u</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyI" data-label="i">i</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyO" data-label="o">o</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyP" data-label="p">p</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button ctrl" data-type="button" data-value="Enter" data-label="换行">换行</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyA" data-label="a">a</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyS" data-label="s">s</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyD" data-label="d">d</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyF" data-label="f">f</div></td>
          <td colspan="5"></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyG" data-label="g">g</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyH" data-label="h">h</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyJ" data-label="j">j</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyK" data-label="k">k</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyL" data-label="l">l</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button ctrl capsLock i_button_button" data-type="button" data-value="CapsLock" data-label="大写">大写</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyZ" data-label="z">z</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyX" data-label="x">x</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyC" data-label="c">c</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyV" data-label="v">v</div></td>
          <td colspan="5"></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyB" data-label="b">b</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyN" data-label="n">n</div></td>
          <td colspan="1"><div class="i_button i_button_letter" data-type="letter" data-value="KeyM" data-label="m">m</div></td>
          <td colspan="2"><div class="i_button ctrl" data-type="button" data-value="Backspace" data-label="删除">删除</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button ctrl i_button_button" data-type="button" data-value="switch" data-label="数/符">数/符</div></td>
          <td colspan="1"><div class="i_button ctrl i_button_button" data-type="button" data-value="Shift" data-label="中/英">中/英</div></td>
          <td colspan="1"><div class="i_button"  data-value="Comma" data-label=",">,</div></td>
          <td colspan="1"><div class="i_button" data-value="Period" data-label=".">.</div></td>
          <td colspan="7"><div class="i_button"  data-value="Space" data-label="空格">空格</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-needshift="true" data-state="2" data-type="symbol" data-value="Digit1" data-label="!">!</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-needshift="true" data-type="symbol" data-state="2" data-value="Digit2" data-label="@">@</div></td>
          <td colspan="2"><div class="i_button ctrl" data-type="button" data-value="Enter" data-label="确定">确定</div></td>
        </tr>
      </tbody>
  </table>
      </div>
      <div class="panel symbol">
      <table align="center" width="100%" border="0" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td colspan="14"></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit9" data-needshift="true" data-label="(">(</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit0" data-needshift="true" data-label=")">)</div></td>
          <td colspan="1"><div class="i_button "  data-value="BracketLeft" data-label="[">[</div></td>
          <td colspan="1"><div class="i_button "  data-value="BracketRight" data-label="]">]</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="BracketLeft" data-needshift="true" data-label="{">{</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="BracketRight" data-needshift="true" data-label="}">}</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit2" data-needshift="true" data-label="@">@</div></td>
          <td colspan="3"></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad1" data-label="1">1</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad2" data-label="2">2</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad3" data-label="3">3</div></td>
          <td colspan="1"><div class="i_button" data-value="Comma" data-label=",">,</div></td>
          <td colspan="1"><div class="i_button" data-value="Period" data-label=".">.</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit8" data-needshift="true" data-label="*">*</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit3" data-needshift="true" data-label="#">#</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit5" data-needshift="true" data-label="%">%</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit4" data-needshift="true" data-label="$">$</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit7" data-needshift="true" data-label="&amp;">&amp;</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Minus" data-needshift="true"  data-label="_">_</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit6" data-needshift="true" data-label="^">^</div></td>
          <td colspan="3"></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpa4" data-label="4">4</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad5" data-label="5">5</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad6" data-label="6">6</div></td>
          <td colspan="2"><div class="i_button ctrl i_button_letter" data-type="letter" data-value="Delete" data-label="删除">删除</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button"  data-value="Slash" data-label="/">/</div></td>
          <td colspan="1"><div class="i_button" data-value="Backslash" data-label="\">\\</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-needshift="true" data-value="Bar" data-label="|">|</div></td>
          <td colspan="1"><div class="i_button" data-value="Quote" data-label="\'">\'</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-value="Semicolon" data-label="&quot;">"</div></td>
          <td colspan="1"><div class="i_button"  data-value="Backquote" data-label="\`">\`</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Backquote" data-label="~">~</div></td>
          <td colspan="3"></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad7" data-label="7">7</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad8" data-label="8">8</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad9" data-label="9">9</div></td>
          <td colspan="2"><div class="i_button ctrl" data-value="Enter" data-label="换行">换行</div></td>
        </tr>
        <tr>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Semicolon" data-needshift="true" data-label=":">:</div></td>
          <td colspan="1"><div class="i_button" data-value="Semicolon" data-label=";">;</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Plus" data-needshift="true" data-label="+">+</div></td>
          <td colspan="1"><div class="i_button" data-value="Minus" data-label="-">-</div></td>
          <td colspan="1"><div class="i_button" data-value="Equal" data-label="=">=</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Comma" data-needshift="true" data-label="<">&lt;</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Period" data-needshift="true" data-label=">">&gt;</div></td>
          <td colspan="3"></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Slash" data-needshift="true" data-label="?">?</div></td>
          <td colspan="1"><div class="i_button i_button_symbol" data-type="symbol" data-state="2" data-value="Digit1" data-needshift="true" data-label="!">!</div></td>
          <td colspan="1"><div class="i_button i_button_num" data-type="num" data-value="Numpad0" data-label="0">0</div></td>
          <td colspan="2"><div class="i_button ctrl i_button_button" data-type="button" data-value="back" data-label="返回">返回</div></td>
        </tr>
        <tr>
          <td colspan="14"></td>
        </tr>
      </tbody>
    </table>
      </div>
    </div>
  </div>
  </div>
</div>
`;
