import { postDataToES } from "./RTCReport.js";
import { post } from "@/services/request/fetch.js";

export default class RTCReceivedStats {
  THRESHOLD = 60;
  oldInbound = {}; // 内部都根据 id: {} 来分别存对应的数据
  statsInfo = {}; // 每一个 id 的状态的合集
  keyQuality = {}; // 用于显示的关键数据
  lastTransport = {};

  recvQualityHistory = {}; // 接收质量数据的 id 合集
  sendQualityHistory = {}; // 发送质量的数据的 id 合集

  maxDelay = {}; // 最大的延时
  maxDecodeTime = {}; // 最大的解码时间
  maxLostRate = {}; // 最大的 lostrate
  maxJitterBufferDelay = {};
  maxMbps = {};
  avgMbps = {};
  avgDelay = {}; // 最大的延时
  avgDecodeTime = {}; // 最大的解码时间
  avgLostRate = {}; // 最大的 lostrate
  avgJitterBufferDelay = {};
  decodeTimeOver20 = {}; // 解码时间超过 20ms 的秒数
  delayTimeOver40 = {}; // 超过 40ms 的 ttl 的秒数
  lostRateTimeOver15 = {}; // 超过 1% 的 lostrate 的秒数

  startTime = {};
  lastPostTime = new Date().getTime(); // 提交间隔
  initialTime = new Date().getTime(); // 提次的初始时间

  constructor() {
    this.isSciter = typeof Window !== "undefined" && Window.share;
    if (this.isSciter) {
      if (!Window.share.AppServices) {
        Window.share.AppServices = {};
      }
      if (!Window.share.AppServices.rtcRecvStatsManager) {
        Window.share.AppServices.rtcRecvStatsManager = this;
      } else {
        return Window.share.AppServices.rtcRecvStatsManager;
      }
    } else {
      if (!window.share) {
        window.share = {};
      }
      if (!window.share.AppServices) {
        window.share.AppServices = {};
      }
      if (!window.share.AppServices.rtcRecvStatsManager) {
        window.share.AppServices.rtcRecvStatsManager = this;
      }
      return window.share.AppServices.rtcRecvStatsManager;
    }
  }
  initializeStatsInfo(id) {
    if (!this.statsInfo[id]) {
      this.statsInfo[id] = {}; // 当前计算出来的值
      this.oldInbound[id] = {}; // 上次计算的值
      this.recvQualityHistory[id] = []; // 历史接收用的质量数据
      this.sendQualityHistory[id] = []; // 历史发送用的质量数据

      this.maxDelay[id] = 0;
      this.maxDecodeTime[id] = 0;
      this.maxLostRate[id] = 0;
      this.maxJitterBufferDelay[id] = 0;
      this.maxMbps[id] = 0;
      this.avgMbps[id] = 0;
      this.avgDelay[id] = 0;
      this.avgDecodeTime[id] = 0;
      this.avgLostRate[id] = 0;
      this.avgJitterBufferDelay[id] = 0;
      this.delayTimeOver40[id] = 0;
      this.decodeTimeOver20[id] = 0;
      this.lostRateTimeOver15[id] = 0;

      this.startTime[id] = new Date().getTime();
    }

    // 不放到 constructor 是因为 sciter app 的原因
    if (!this.myDeviceId || !this.uuid) {
      if (this.isSciter) {
        this.myDeviceId = app.backend.myDeviceId();
        this.uuid = app.connect.get(id, "uuid");
      } else {
        let { deviceID, playToken } = window.localStorageUser.get("current_connection_info") || "";
        this.myDeviceId = deviceID;
        this.uuid = playToken;
      }
    }

    // 因为有可能在 getStatsByID 被其它的程序先创建, 所以这个需要单独判断
    if (!this.keyQuality[id] && this.isSciter) {
      this.keyQuality[id] = {};
    }
  }
  delete(id) {
    // this.keyQuality[id].dispose();
    delete this.keyQuality[id];
    delete this.oldInbound[id];
    delete this.statsInfo[id];
    delete this.keyQuality[id];
    delete this.recvQualityHistory[id];
    delete this.sendQualityHistory[id];
    delete this.avgMbps[id];
    delete this.maxMbps[id];
    delete this.maxDelay[id];
    delete this.maxDecodeTime[id];
    delete this.maxLostRate[id];
    delete this.maxJitterBufferDelay[id];
    delete this.avgDelay[id];
    delete this.avgDecodeTime[id];
    delete this.avgLostRate[id];
    delete this.avgJitterBufferDelay[id];
    delete this.delayTimeOver40[id];
    delete this.decodeTimeOver20[id];
    delete this.lostRateTimeOver15[id];
    delete this.startTime[id];
  }

  // 通过 ID 取对应的显示的信息
  getStatsByID(id) {
    if (!this.keyQuality[id]) {
      if (!this.isSciter) {
        return {};
      }
      // 这个需要给界面显示，所以用 signal 存的每一个 id
      this.keyQuality[id] = {};
    }
    return this.keyQuality[id];
  }

  parseStatsInfo(id, info) {
    let object = null;
    if (this.isSciter) {
      object = this.parseJSON(info);
      if (!object) return;
    } else {
      object = info;
    }

    // 确保有 id 的一系列对象，下面的计算都需要用到它
    this.initializeStatsInfo(id);

    // 第一步， 计算网络的 ice 的变化, 找出对应的 pairid
    const candidates = this.extractCandidates(object); // 需要先计算出来这个参数才能做其它的
    let selectedPairId;
    object.forEach((item) => {
      if (item.type === "transport" && item.selectedCandidatePairId) {
        selectedPairId = item.selectedCandidatePairId;
      }
    });

    // 第二步， 存基本的信息
    object.forEach((item) => {
      switch (item.type) {
        case "inbound-rtp":
          this.handleInboundRTP(id, item);
          break;
        case "codec":
          this.handleCodec(id, item);
          break;
        default:
          break;
      }
    });
    // 第三步， 计算质量和关键数据并提交
    object.forEach((item) => {
      switch (item.type) {
        case "candidate-pair":
          if (item.id === selectedPairId) {
            this.handleCandidatePair(id, item, candidates);
            this.statsInfo[id].candidatePair = { ...item }; // 保存 candidatePair 历史数据
          }
          break;
        case "transport":
          // 计算分析质量数据
          this.recordQualityHistory(id, item); // todo 放到最后计算
          this.lastTransport[id] = { ...item };
          break;
        default:
          break;
      }
    });
    // 第四步，保存历史数据
    object.forEach((item) => {
      switch (item.type) {
        case "inbound-rtp":
          this.oldInbound[id] = { ...item }; // 防止引用
          break;
        default:
          break;
      }
    });
  }
  recordQualityHistory(id, transport) {
    // 保证有上次的数据才开始计算这些值
    if (!this.oldInbound[id]) {
      return;
    }
    // 计算需要用的时间
    let totalTime = new Date().getTime() - this.startTime[id];
    let timeDiff = this.getTimeDiff(transport, this.lastTransport[id]);

    // 计算速度
    let kbps = this.getKbps(id, timeDiff, totalTime, transport, this.lastTransport[id]);

    // 计算质量数据, 需要前面的二个数据才行
    let keyQuality = this.getKeyQuality(id, timeDiff, totalTime, this.statsInfo[id].inboundRTP, this.statsInfo[id].candidatePair);
    if (this.isSciter) {
      // 更新用于显示的数据
      this.keyQuality[id].value = {
        ...keyQuality,
        kbps: kbps,
        decodeTimeAvg: this.avgDecodeTime[id],
        avgDelay: this.avgDelay[id],
      };
    } else {
      // 更新用于显示的数据
      this.keyQuality[id] = {
        ...keyQuality,
        kbps: kbps,
      };
    }

    // 更新历史质量信息
    try {
      let myDeviceId, uuid;
      if (this.isSciter) {
        myDeviceId = app.backend.myDeviceId();
        uuid = app.connect.get(id, "uuid");
      } else {
        let { deviceID, playToken } = window.localStorageUser.get("current_connection_info") || "";
        myDeviceId = deviceID;
        uuid = playToken;
      }
      this.recvQualityHistory[id].push({
        ...keyQuality,
        kbps: kbps,
        uuid: uuid,
        timestamp: new Date().getTime(),
        deviceID: myDeviceId,
      });
      this.reportQualityHistory(id);
    } catch (error) {
      console.log(`recordQualityHistory error`, error, this);
    }
  }

  getKeyQuality(id, timeDiff, totalTime, inboundRTP, candidatePair) {
    if (timeDiff <= 0) return;

    return {
      width: inboundRTP.frameWidth || -1,
      height: inboundRTP.frameHeight || -1,
      framesReceived: inboundRTP.framesReceived || -1,
      bytesReceived: inboundRTP.bytesReceived || -1,
      protocol: this.statsInfo[id].protocol,
      natType: this.statsInfo[id].natType, // 这个的计算是 candidatepair 中计算，所以要复制过来
      mimeType: this.statsInfo[id].mimeType || "video/H264",
      fps: inboundRTP.framesPerSecond ?? 0,
      jitter: parseInt(inboundRTP.jitter * 1000),
      currentRoundTripTime: this.getTTL(id, candidatePair),
      jitterBufferDelayChange: this.getLastJitterBufferDelay(id, timeDiff, inboundRTP, this.oldInbound[id]),
      processingDelayChange: this.getLastProcessingDelay(inboundRTP, this.oldInbound[id]),
      firCountChange: this.getLastFirCount(inboundRTP, this.oldInbound[id]),
      pliCountChange: this.getLastPliCount(inboundRTP, this.oldInbound[id]),
      nackCountChange: this.getLastNackCount(inboundRTP, this.oldInbound[id]),
      packetsLostRateChange: this.getPacketLostRate(id, timeDiff, inboundRTP, this.oldInbound[id]),
      framesDroppedChange: this.getFramesDroppedChange(inboundRTP, this.oldInbound[id]),
      framesDecodedChange: this.getLastFramesDecoded(inboundRTP, this.oldInbound[id]),
      framesReceivedChange: this.getFramesReceivedChange(inboundRTP, this.oldInbound[id]),
      decodedTimeChange: this.getDecodedTime(id, timeDiff, inboundRTP, this.oldInbound[id]),
    };
  }
  postPlayData(id) {
    let { deviceID, playToken } = window.localStorageUser.get("current_connection_info") || "";
    let playDate = {
      data: JSON.stringify({
        maxMbps: this.maxMbps[id],
        maxDelay: this.maxDelay[id],
        maxDecodeTime: this.maxDecodeTime[id],
        maxLostRate: this.maxLostRate[id],
        maxJitterBufferDelay: this.maxJitterBufferDelay[id],
        avgMbps: this.avgMbps[id],
        avgDelay: this.avgDelay[id],
        avgDecodeTime: this.avgDecodeTime[id],
        avgLostRate: this.avgLostRate[id],
        avgJitterBufferDelay: this.avgJitterBufferDelay[id],
        delayTimeOver40: this.delayTimeOver40[id],
        decodeTimeOver20: this.decodeTimeOver20[id],
        lostRateTimeOver15: this.lostRateTimeOver15[id],
        playToken: playToken,
        deviceID: deviceID,
        timestamp: new Date().getTime(),
        date: new Date().toLocaleString(),
        duration: (new Date().getTime() - this.startTime[id]) / 1000,
      }),
    };
    post("/game/stats", playDate, {}, true);
  }

  getTTL(id, candidatePair) {
    // 计算最大的RoundTripTime
    let delayTTL = candidatePair.currentRoundTripTime * 1000;
    this.maxDelay[id] = Math.max(this.maxDelay[id], delayTTL);

    this.avgDelay[id] = parseInt((candidatePair.totalRoundTripTime / candidatePair.responsesReceived) * 1000); //totltime is ms,so * 1000

    if (delayTTL >= 40) this.delayTimeOver40[id] += 1; // 时间加 1s
    return parseInt(delayTTL);
  }

  reportQualityHistory(id, threshold = 60) {
    const now = Date.now();
    // 如果还未到5分钟或者已过5分钟但时间差达到5分钟的倍数
    if (now - this.lastPostTime >= 1 * 60 * 1000 && (now - this.initialTime <= 5 * 60 * 1000 || (now - this.lastPostTime) % (5 * 60 * 1000) === 0)) {
      console.log(`last time : ${this.lastPostTime / 100}`);
      console.log(
        `----- max maxDelay ${this.maxDelay[id]} maxDecodeTime ${this.maxDecodeTime[id]}  maxLostRate ${this.maxLostRate[id]} maxJitterBufferDelay ${this.maxJitterBufferDelay[id]} maxMbps ${this.maxMbps[id]}`
      );
      console.log(
        `----- avg delay    ${this.avgDelay[id]} avgDecodeTime ${this.avgDecodeTime[id]} avgLostRate ${this.avgLostRate[id]} avgJitterBufferDelay ${this.avgJitterBufferDelay[id]} avgMbps ${this.avgMbps[id]}`
      );
      console.log(`----- Over delayTime ${this.delayTimeOver40[id]} decodeTime ${this.decodeTimeOver20[id]} lostRate ${this.lostRateTimeOver15[id]} `);
      this.postPlayData(id);
      this.lastPostTime = new Date().getTime();
    }
    if (this.recvQualityHistory[id].length >= threshold) {
      // 准备批量上传的数据
      let bulkData = "";
      this.recvQualityHistory[id].forEach((item) => {
        bulkData += JSON.stringify({ index: { _type: "_doc" } }) + "\n";
        bulkData += JSON.stringify(item) + "\n";
      });
      postDataToES(bulkData, "speed_history", true);
      this.recvQualityHistory[id].splice(0, this.recvQualityHistory[id].length);
    }
  }

  handleCandidatePair(id, item, candidates) {
    // 检查 candidate 是否变化
    if (this.statsInfo[id].lastCandidatePairId !== item.id) {
      this.statsInfo[id].lastCandidatePairId = item.id;
      let localCandidate = candidates[item.localCandidateId];
      let remoteCandidate = candidates[item.remoteCandidateId];
      this.checkCandidateChange(id, item, localCandidate, remoteCandidate);
    }
  }

  handleInboundRTP(id, item) {
    if (item.kind != "video") {
      return;
    }
    this.statsInfo[id].decoder = item.decoderImplementation;
    this.statsInfo[id].framesDropped = item.framesDropped;
    this.statsInfo[id].codecId = item.codecId;
    this.statsInfo[id].inboundRTP = item;
  }
  handleCodec(id, item) {
    if (this.statsInfo[id]?.codecId && item.id == this.statsInfo[id].codecId) {
      this.statsInfo[id].mimeType = item.mimeType ?? "codeInfo";
    }
  }
  // 用于计算网络条件变化
  checkCandidateChange(id, item, localCandidate, remoteCandidate) {
    if (!this.statsInfo[id]?.candidatePairsHistory) {
      this.statsInfo[id].candidatePairsHistory = []; // 如果不存在，先创建一个空数组
    }
    if (this.statsInfo[id]?.remoteAddr != remoteCandidate?.ip || this.statsInfo[id]?.localAddr != localCandidate?.ip) {
      this.statsInfo[id].candidatePairsHistory.push({
        id: item.id,
        timestamp: Math.floor(Date.now() / 1000), // todo
        local: {
          ip: localCandidate.ip,
          port: localCandidate.port,
          type: localCandidate.candidateType,
          protocol: localCandidate.protocol,
        },
        remote: {
          ip: remoteCandidate.ip,
          port: remoteCandidate.port,
          type: remoteCandidate.candidateType,
          protocol: remoteCandidate.protocol,
        },
      });
      remoteCandidate?.candidateType === "relay" ? (this.statsInfo[id].natType = "加速网络") : (this.statsInfo[id].natType = "直接连接");
      this.statsInfo[id].protocol = remoteCandidate?.protocol;
      this.statsInfo[id].remoteAddr = remoteCandidate.ip;
      this.statsInfo[id].localAddr = localCandidate.ip;
      //mark
      // console.log(
      //   `流量正在通过 ${this.statsInfo[id].natType}`,
      //   localCandidate?.candidateType + " " + localCandidate.ip + " " + remoteCandidate?.candidateType + "" + remoteCandidate?.ip,
      //   " protocl: ",
      //   remoteCandidate?.protocol
      // );
    }
  }
  extractCandidates(object) {
    let candidates = {};
    object.forEach((report) => {
      if (["local-candidate", "remote-candidate"].includes(report.type)) {
        candidates[report.id] = report;
      }
    });
    return candidates;
  }

  getLastFramesDecoded(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return inbound.framesDecoded - oldInbound.framesDecoded;
  }
  getFramesDroppedChange(inbound, oldInbound) {
    if (!oldInbound || inbound.framesDropped == 0) return 0;
    return inbound.framesDropped - oldInbound.framesDropped;
  }
  getFramesReceivedChange(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return inbound.framesReceived - oldInbound.framesReceived;
  }
  getDecodedTime(id, timeDiff, inbound, oldInbound) {
    if (!oldInbound || !oldInbound.totalDecodeTime || !oldInbound.framesDecoded) return 0;
    const decodedTime = parseInt((((inbound.totalDecodeTime - oldInbound.totalDecodeTime) / (inbound.framesDecoded - oldInbound.framesDecoded)) * 1000) / timeDiff);

    if (isNaN(decodedTime)) {
      return -1;
    }
    // 计算最大的解码时间
    this.maxDecodeTime[id] = Math.max(this.maxDecodeTime[id], decodedTime);
    this.avgDecodeTime[id] = parseInt((inbound.totalDecodeTime / inbound.framesDecoded) * 1000);
    if (decodedTime > 20) this.decodeTimeOver20[id] += 1; // 解码高的时间加 1s

    // 检查是否为 NaN，如果是，则返回 -1
    return decodedTime;
  }

  getLastJitterBufferDelay(id, timeDiff, inbound, oldInbound) {
    if (!oldInbound) return 0;
    let jitterBufferDelay = parseInt((((inbound.jitterBufferDelay - oldInbound.jitterBufferDelay) / (inbound.jitterBufferEmittedCount - oldInbound.jitterBufferEmittedCount)) * 1000) / timeDiff);
    if (isNaN(jitterBufferDelay)) {
      return -1;
    }
    this.avgJitterBufferDelay[id] = parseInt((inbound.jitterBufferDelay / inbound.jitterBufferEmittedCount) * 1000);
    this.maxJitterBufferDelay[id] = Math.max(this.maxJitterBufferDelay[id], jitterBufferDelay);
    return jitterBufferDelay;
  }
  getLastProcessingDelay(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return parseInt(((inbound.totalProcessingDelay - oldInbound.totalProcessingDelay) / (inbound.framesDecoded - oldInbound.framesDecoded)) * 1000);
  }

  getLastJitterBufferTargetDelay(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return parseInt(((inbound.jitterBufferTargetDelay - oldInbound.jitterBufferTargetDelay) / (inbound.jitterBufferEmittedCount - oldInbound.jitterBufferEmittedCount)) * 1000);
  }

  getLastJitterBufferMinimumDelay(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return parseInt(((inbound.jitterBufferMinimumDelay - oldInbound.jitterBufferMinimumDelay) / (inbound.jitterBufferEmittedCount - oldInbound.jitterBufferEmittedCount)) * 1000);
  }
  getLastFirCount(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return inbound.firCount - oldInbound.firCount;
  }
  getLastPliCount(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return inbound.pliCount - oldInbound.pliCount;
  }
  getLastNackCount(inbound, oldInbound) {
    if (!oldInbound) return 0;
    return inbound.nackCount - oldInbound.nackCount;
  }
  getPacketLostRate(id, timeDiff, inbound, oldInbound) {
    if (!oldInbound || !oldInbound.packetsLost || !oldInbound.packetsReceived) return 0;
    let packetsLostChange = inbound.packetsLost - oldInbound.packetsLost;
    let packetsReceivedChange = inbound.packetsReceived - oldInbound.packetsReceived;
    var packetsLostRate = (((packetsLostChange / (packetsReceivedChange + packetsLostChange)) * 100) / timeDiff).toFixed(2);

    if (isNaN(packetsLostRate)) {
      return 0;
    }
    // 计算最大的LostRate
    this.maxLostRate[id] = Math.max(this.maxLostRate[id], packetsLostRate);
    this.avgLostRate[id] = ((inbound.packetsLost / inbound.packetsReceived) * 100).toFixed(2);
    if (packetsLostRate > 0.2) this.lostRateTimeOver15[id] += 1; // 时间加 1s
    return packetsLostRate;
  }
  getTimeDiff(selectedPair, oldPair) {
    if (!oldPair) return 0;
    const timeDiff = (selectedPair.timestamp - oldPair.timestamp) / 1000; // 时间差，单位为毫秒, 变成秒
    if (timeDiff <= 0) {
      return 0;
    }
    return timeDiff;
  }
  getKbps(id, timeDiff, totalTime, selectedPair, oldPair) {
    if (timeDiff == 0) return 0;
    const bytesDiff = selectedPair.bytesReceived - oldPair.bytesReceived; // 字节差
    let kbps = parseFloat((((bytesDiff / timeDiff) * 8) / 1000).toFixed(2));
    this.avgMbps[id] = (((selectedPair.bytesReceived / totalTime) * 8) / 1000).toFixed(2);
    this.maxMbps[id] = Math.max(this.maxMbps[id], (kbps / 1000).toFixed(2));
    return kbps; // 转成网络流量需要 *8 然后转成 k
  }
  // Utility methods
  parseJSON(info) {
    try {
      return JSON.parse(info);
    } catch (err) {
      console.error(`Error parsing rtc post JSON: ${err}, "${info}"`);
      return null;
    }
  }
}
