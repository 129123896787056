import logCollector from "@/utils/report-log/LogCollector.js";
import { getDescriptionByKey } from "@/utils/state-descriptions/duration";
class ExecutionTimer {
  constructor() {
    if (!ExecutionTimer.instance) {
      this.timers = {};
      ExecutionTimer.instance = this;
    }

    return ExecutionTimer.instance;
  }

  start(label) {
    this.timers[label] = Date.now();
  }

  end(label) {
    const timeNow = Date.now();
    if (label in this.timers) {
      const timeTaken = timeNow - this.timers[label];
      delete this.timers[label];
      console.log(`耗时:${label} : ${timeTaken}`);
      logCollector.log({
        type: "time",
        eventType: 24,
        eventName: "Operation Duration",
        details: `${getDescriptionByKey(label)}耗时: : ${timeTaken}`,
      });
      return timeTaken;
    }

    return null;
  }
}

const executionTimer = new ExecutionTimer();
export default executionTimer;
