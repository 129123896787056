import logCollector from "@/utils/report-log/LogCollector.js";
import executionTimer from "@/utils/time-consuming/timer.js";
import AtKit from "@/lib/AtKit/AtKit";
/**
 * 打印ice状态各种信息
 */
let esData = {
  uuid: "",
  type: "info",
  timestamp: "",
  accessKeyld: "",
  eventType: 2,
  eventName: "ICE Connection State Change",
  details: "",
};
function logIceGatheringState(event, accessKeyld) {
  if (!esData.uuid) {
    let playToken = window.localStorageUser?.get("current_connection_info")?.playToken;

    esData.uuid = playToken;
  }
  if (!esData.accessKeyld) {
    esData.accessKeyld = accessKeyld;
  }
  esData.timestamp = Date.now().valueOf();
  esData.details = "iceGatheringState: ";
  
  switch (event.target.iceGatheringState) {
    case "new":
      esData.details += "ICE 正在新建连接";
      break;
    case "gathering":
      esData.details += "ICE 正在尝试收集本地网络接口的候选项";
      executionTimer.start("iceExchangeTime");
      break;
    case "complete":
      esData.details += "ICE 代理已经完成了对网络的探测";
      break;
    default:
      esData.details += "无";
      break;
  }
  esData.details = " connectionState: ";
  if (event.target.connectionState) {
    switch (event.target.connectionState) {
      case "new":
        esData.details += "新建连接.";
        break;
      case "connecting":
        esData.details += "正在建立连接";
        break;
      case "connected":
        esData.details += "连接成功";
        break;
      case "disconnected":
        esData.details += "连接断开";
        break;
      case "failed":
        esData.details += "连接失败";
        break;
      case "closed":
        esData.details += "连接关闭";
        break;
      default:
        esData.details += "无";
        break;
    }
  }
  esData.details = " iceConnectionState: ";
  if (event.target.iceConnectionState) {
    switch (event.target.iceConnectionState) {
      case "new":
        esData.details += "ICE新建连接";
        break;
      case "checking":
        esData.details += "ICE正在进行连接检查";
        break;
      case "connected":
        esData.details += "ICE连接成功";
        console.log(event)
        executionTimer.end("iceExchangeTime");
        break;
      case "disconnected":
        esData.details += "ICE连接断开";
        break;
        esData.details += "ICE连接失败";
        break;
      case "closed":
        esData.details += "ICE连接关闭";
        break;
      default:
        esData.details += "无";
        break;
    }
  }
  console.log(esData.details);
  logCollector.log(esData, "switch_status_history");
}
export { logIceGatheringState };
