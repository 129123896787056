import { post } from "@/services/request/fetch";

//获取租户配置
export const gameConfigApi = async () => {
  return await post("/game/config", {}, {}, true);
};
//开始游戏
export const gameStartApi = async (data) => {
  return await post("/game/start", data, {}, false);
};
//退出排队
export const gameLeaveApi = async () => {
  return await post("/game/leave", {}, {}, true, null);
};
//查询用户在这个游戏是否有存档
export const gameArchiveApi = async () => {
  return await post("/game/archive", null, {}, true);
};
//插队
export const gameJump = async (data) => {
  return await post("/game/jump", data, {}, true);
};
//获取游戏详情
export const gameInfoApi = async () => {
  return await post("/game/info", null, {}, true);
};
//获取云游状态
export const gamePlayInfoApi = async () => {
  return await post("/game/playinfo", null, {}, true);
};
//获取pinCode
export const powerCodeApi = async () => {
  return await post("/power/code", null, {}, true);
};
//派对吧模式 玩家加入游戏
export const powerJoinApi = async (data) => {
  return await post("/power/join", data, {}, true);
};
//查询权限
export const powerQueryApi = async (data) => {
  return await post("/power/query", {}, {}, true);
};
//切换权限
export const powerChangeApi = async (data) => {
  return await post("/power/change", data, {}, true);
};
//发送信令信息代理
export const sendSignAPi = async (data) => {
  return await post("/game/signProxy", data, {}, true);
};
//取用户的ip 是什么运营商
// export const getUserOperator = async () => {
//   return await post("/ip/city");
// };

//通知信令
export const notifyUpdate = async (code, msg) => {};
