/**
 * 创建webrtc类
 */
import { mergeArrayBuffer, convertArrayBufferToNumber, parseH264ProfileLevelId, logTraffic, switchCoder, createGetDeviceInfoData, onIceData } from "@/toolbox.js";
import heartbeatInstance from "@/utils/heartbeat/heartbeat.js";
import proto from "@/utils/peer/geelevelproto.js";
import * as sdpTransform from "sdp-transform";
import { getVideoQualityData } from "@/utils/utilit-ties/tools.js";
import { logIceGatheringState } from "@/utils/ice-log/ice-status-logger.js";
import { EventEmitter } from "eventemitter3";
import RTCReceivedStats from "@/services/RTC/RTCReceivedStats";
import logCollector from "@/utils/report-log/LogCollector.js";
import AtKit from "@/lib/AtKit/AtKit";
export default class Peer extends EventEmitter {
  channel = new Map();
  gatheredCandidates = [];
  constructor(args) {
    super();
    this.pcConfig = {
      iceServers: [
        {
          urls: args.relay.relayURL ? `turn:${args.relay.relayURL}:3478?transport=udp` : "turn:r4.geelevel.com:3478?transport=udp",
          credential: args.relay.relayPwd || "geelevel", //访问 ICE 服务器时所需的凭据
          username: args.relay.relayUser || "geelevel", //访问 ICE 服务器时所需的用户名
        },
      ],
      iceTransportPolicy: "relay", // 这个设置强制只使用 relay 类型的候选（即通过 TURN 服务器）
      continualGatheringPolicy: "gatherContinually", //设置为 "gatherContinually"，表示 ICE 代理应该持续收集候选地址
    };
    this.props = args;
    this.remoteID = args.remoteID;
    this.myID = args.uid;
    this.accessKeyId = args.accessKeyId; //
    this.statsInterval = null; // 定时器变量，用于清理
    this.rtcLog = new RTCReceivedStats();
    this.tempData = null; //处理protobuf分包数据
    this.byteLength = 0;
    //兼容sciter语法
    if (!window.share.Global) {
      window.share.Global = {};
      window.share.Global.selfId = args.uid;
    }
  }
  connect = () => {
    //new RTC 实时通信对等连接
    if (this.pc) {
      this.pc.close();
      this.pc = null;
    }
    this.pc = new RTCPeerConnection(this.pcConfig);
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `建立webrtc连接`,
    });
    // this.pc.signalingState会在连接过程中随着信令交换的进行而发生变化，它的值通常会在以下几种状态中切换：
    // "stable": 表示连接处于正常状态，没有进行任何信令交换或者交换已经完成。
    // "have-local-offer": 表示本地端已经创建了一个Offer，并等待远端应答。
    // "have-remote-offer": 表示远端已经创建了一个Offer，并等待本地端应答。
    // "have-local-pranswer": 表示本地端已经创建了一个Pranswer（部分应答），等待远端应答。
    // "have-remote-pranswer": 表示远端已经创建了一个Pranswer（部分应答），等待本地端应答。
    // "closed": 表示连接已经关闭，无法进行信令交换。
  };
  /**
   * webRtc获取所有统计信息
   * @returns
   */
  getWebRtcStats = async () => {
    const updateStats = async () => {
      let statsInfo = {
        bytesReceived: 0,
        timestamp: 0,
        recv: {},
      };
      let lastCandidatePairId = null;
      let candidatePairsHistory = [];
      let webrtcInfo = null;
      try {
        webrtcInfo = await this.pc.getStats(null);
        return { statsInfo, webrtcInfo };
      } catch (error) {
        console.error("Error while getting stats:", error);
        return null;
      }
    };
    this.statsInterval = setInterval(async () => {
      if (this.pc === null) {
        clearInterval(this.statsInterval);
        return;
      }
      let { statsInfo, webrtcInfo } = await updateStats();
      if (statsInfo) {
        this.rtcLog.parseStatsInfo(this.remoteID, webrtcInfo);
        this.emit("videoStreamInfo", getVideoQualityData(this.rtcLog.getStatsByID(this.remoteID)));
      }
    }, 1000);
  };

  //SDP 信息通常用于描述会话的相关信息，例如媒体流的配置参数、编解码器支持等
  handleSDPInformation = async (message) => {
    //不存在信息
    if (message.data === null || message.data === undefined) {
      console.error("the message is invalid!" + message.data.type);
      return;
    }
    //解析成JSON
    let data = JSON.parse(message.data);
    switch (data.type) {
      case "offer":
        break;
      case "answer":
        if (this.pc.signalingState && this.pc.signalingState === "have-local-offer") {
          await this.handleAnswer(data);
        }
        logCollector.log({
          eventType: 1,
          eventName: "SDP Change",
          details: `收到answer signalingState:${this.pc.signalingState}`,
        });
        break;
      case "candidate":
        await this.handleCandidate(data);
        break;
      case "sdp":
        break;
      default:
        break;
    }
  };
  /**
   * 用于表示对另一端发起的 "offer" 类型消息的响应。
   * 在 WebRTC 中，建立点对点连接的过程通常涉及两个对等方（peer），一个作为"offer"方，另一个作为"answer"方。
   * @param {*} data sdp信息
   */
  async handleAnswer(data) {
    try {
      sdpTransform.parse(data.sdp);
      await this.pc.setRemoteDescription(new RTCSessionDescription(data));
      const transceivers = this.pc.getTransceivers();
      for (const transceiver of transceivers) {
        const track = transceiver.receiver.track;
        if (track) {
          this.emit(track.kind + "Track", track);
        }
      }
    } catch (error) {
      console.error(`handleAnswer error:${error}`);
    }
  }
  /**
   * 处理ice候选信息
   * @param {*} data
   */
  async handleCandidate(data) {
    if (this.pc === null) {
      console.error("RTCPeerConnection 对象为空，无法处理 ice 候选信息。");
      logCollector.log({
        type: "error",
        eventType: 5,
        eventName: "Candidate Pairs State Change",
        details: `RTCPeerConnection 对象为空，无法处理 ice 候选信息。`,
      });
      return;
    }
    try {
      const candidate = new RTCIceCandidate({
        sdpMLineIndex: data.sdpMLineIndex,
        candidate: data.candidate,
      });
      if (candidate.type === "relay") {
        await this.pc.addIceCandidate(candidate);
        console.log("添加了ICE候选者对象 :", candidate);
        logCollector.log({
          eventType: 5,
          eventName: "Candidate Pairs State Change",
          details: `添加了ICE候选者对象 :${JSON.stringify(candidate)}`,
        });
      }
    } catch (error) {
      logCollector.log({
        type: "error",
        eventType: 5,
        eventName: "Candidate Pairs State Change",
        details: `处理ice候选信息出错:${error}`,
      });
    }
  }
  /**
   * 初始化 PeerConnection
   * @param {string} message - 消息
   */
  initPeer() {
    // 设置 dataChannel
    this.initPeerDataChannel();
    // 当连接状态发生变化时触发该回调。
    // - new：新建连接
    // - connecting：正在建立连接
    // - connected：连接成功
    // - disconnected：连接断开
    // - failed：连接失败
    // - closed：连接关闭
    this.pc.onconnectionstatechange = (event) => {
      logIceGatheringState(event, this.accessKeyId);
    };

    // 当ICE收集过程中遭遇错误时触发该回调
    this.pc.onicecandidateerror = (event) => {
      console.error("ICE收集过程中遭遇错误:", event.errorText);
      this.emit("error", {
        code: "5001", //代表ice断开
        error: "ICE收集过程中遭遇错误:",
        message: `ice 断开`,
        cId: this.remoteID,
      });
    };

    // 当ICE连接状态发生变化时触发该回调。
    // - new：新建连接
    // - checking：正在进行连接检查
    // - connected：ICE连接成功
    // - disconnected：ICE连接断开
    // - failed：ICE连接失败
    // - closed：ICE连接关闭
    this.pc.oniceconnectionstatechange = (event) => {
      if (event.target.iceConnectionState === "disconnected") {
        console.log("发送ice断开");
        this.emit("error", {
          code: "5001", //代表ice断开
          error: "ice disconnected",
          message: `ice 断开`,
          cId: this.remoteID,
        });
        //断开需要摧毁webrtc信息打印
        clearInterval(this.statsInterval);
      }
      logIceGatheringState(event, this.accessKeyId);
    };

    // 当需要重新协商连接时触发该回调
    // this.pc.onnegotiationneeded = (event) => {
    //   console.log("需要重新协商连接");
    // };

    // 收集到ice候选信息并发送给远程对等方
    this.pc.onicecandidate = (event) => {
      let candidateData = onIceData(event);
      if (candidateData) {
        this.emit("ice", candidateData);
      }
      if (event.candidate) {
        this.gatheredCandidates.push(event.candidate);
        logCollector.log({
          eventType: 5,
          eventName: "Candidate Pairs State Change",
          details: `收集到ice候选信息并发送给远程对等方${JSON.stringify(event.candidate)}`,
        });
      }
    };

    // 当ICE候选项收集状态发生变化时触发该回调。
    // - new：新建连接
    // - gathering：正在收集候选项
    // - complete：候选项收集完成
    // 通过该回调函数，您可以处理ICE候选项收集状态的变化，如通知用户候选项收集完成等。
    this.pc.onicegatheringstatechange = (event) => {
      logIceGatheringState(event, this.accessKeyId);
    };

    // 响应对方的datachannel的创建
    // 主控并不执行
    this.pc.ondatachannel = (event) => {
      console.log("Receive Data Channel Callback", event);
    };
    this.createOffer();
  }
  /**
   * 创建并发送 offer 给对方
   * @param {*} media_type
   */
  createOffer(media_type) {
    let offerOptions = {
      offerToReceiveAudio: false, //接收音频
      offerToReceiveVideo: false, //接收视频
      iceRestart: false, //是否启用 ICE
    };
    //media_type === 0 才要视频 上面给false 是不希望多一次接收到其他的音视频轨道信息
    if (media_type === 0) {
      offerOptions.offerToReceiveAudio = true;
      offerOptions.offerToReceiveVideo = true;
      offerOptions.iceRestart = true;
    }
    // 对 offer 中的 SDP 进行编码转换，通常用于修改 SDP 中的编解码参数或协商媒体流的类型
    this.pc.createOffer(offerOptions).then((offerdesc) => {
      //h264
      let sdp = switchCoder(offerdesc.sdp, "H264", sdpTransform);
      this.pc
        .setLocalDescription(offerdesc)
        .then(() => {
          let jsonData = {
            // media_type: 0, //-1默认不会有视频 要连接上 1video 2 audio 3 有video and audio
            // video_type: 0, //设置了这个的话不用手动播放video标签了
            sdp: sdp,
            type: offerdesc.type,
            forceRelay: 0, //让agent不管转发
            // 让对端走这个转发
            relay: [
              {
                addr: this.props.relay.relayURL || "r4.geelevel.com",
                user: this.props.relay.relayUser || "geelevel",
                pwd: this.props.relay.relayPwd || "geelevel",
              },
            ],
          };
          let offerjson = JSON.stringify(jsonData);
          this.emit("offer", offerjson);
          logCollector.log({
            eventType: 1,
            eventName: "SDP Change",
            details: "创建offer并且发送给对方",
          });
        })
        .catch((error) => {
          logCollector.log({
            type: "error",
            eventType: 1,
            eventName: "SDP Change",
            details: `设置offer出错:${error}`,
          });
        });
    });
  }

  /**
   * 初始化数据通道的方法
   */
  initPeerDataChannel() {
    // 创建键盘数据通道
    let keyboardChannel = this.pc.createDataChannel("channel-keyboard");
    keyboardChannel.binaryType = "arraybuffer";
    this.channel.set("channel-keyboard", keyboardChannel);
    keyboardChannel.onopen = (event) => {
      logCollector.log({
        eventType: 4,
        eventName: "Data Channel State Change",
        details: "打开键盘通道",
      });
    };

    keyboardChannel.onmessage = (event) => {
      // 接收到键盘消息
      this.onReceiveKeyboardMessage(event);
    };

    // 创建鼠标数据通道
    let mouseChannel = this.pc.createDataChannel("channel-mouse");
    mouseChannel.binaryType = "arraybuffer";
    this.channel.set("channel-mouse", mouseChannel);

    mouseChannel.onopen = (event) => {
      // 鼠标通道已打开
      logCollector.log({
        eventType: 4,
        eventName: "Data Channel State Change",
        details: "打开鼠标通道",
      });
    };

    mouseChannel.onmessage = (event) => {
      // 接收到鼠标消息
      this.onReceiveMouseMessage(event);
    };

    // 创建自定义消息通道
    let customMessageChannel = this.pc.createDataChannel("custom-message");
    customMessageChannel.binaryType = "arraybuffer";
    this.channel.set("custom-message", customMessageChannel);

    customMessageChannel.addEventListener("open", () => {
      // 自定义消息通道已打开
      let data = createGetDeviceInfoData(proto, this.myID);
      this.sendDataChannel("custom-message", data);
      logCollector.log({
        eventType: 4,
        eventName: "Data Channel State Change",
        details: "打开自定义通道",
      });
      //open
      //this.emit("dataChannelOpen")
    });

    customMessageChannel.addEventListener("message", (event) => {
      // 接收到自定义消息
      this.onReceiveCustomMessage(event);
    });
  }

  /**
   * 关闭Peer
   *
   */
  closeConnect = () => {
    logCollector.log({
      eventType: 2,
      eventName: "SDK State",
      details: "关闭Peer,销毁连接",
    });
    if (!this.pc) {
      return;
    }
    clearInterval(this.statsInterval);
    try {
      this.pc.close();
      console.log("this.pc close --- ", this.pc);
      this.pc.ontrack = null;
      this.pc.onremovetrack = null;
      this.pc.onremovestream = null;
      this.pc.onicecandidate = null;
      this.pc.oniceconnectionstatechange = null;
      this.pc.onsignalingstatechange = null;
      this.pc.onicegatheringstatechange = null;
      this.pc.onnegotiationneeded = null;
      this.pc = null;
      console.log("RTCPeerConnection已设置为null:", this.pc);
      this.channel.get("channel-keyboard").close();
      this.channel.get("channel-mouse").close();
      this.channel.get("custom-message").close();
    } catch (error) {
      console.error(`关闭连接error: ${JSON.stringify(error)}`);
    }
  };
  /**
   * 用于向指定的数据通道发送数据
   * @param {string} label 表示数据通道的标签
   * @param {ArrayBuffer} data 表示要发送的数据
   */
  sendDataChannel(label, data) {
    try {
      if (!this.pc) {
        console.error(`还未存在通道: ${JSON.stringify(this.pc)}`);
        return;
      }

      if (!this.channel) {
        console.error("错误：此通道未连接");
        return;
      }
      let dataChannel = this.channel.get(label);
      if (!dataChannel || dataChannel.readyState !== "open") {
        console.error("错误：此通道未打开");
        return;
      }
      // 标记：
      // 0 - 开始
      // 1 - 中间
      // 2 - 结束
      // 3 - 开始和结束
      let flagBuffer = new ArrayBuffer(1);
      let view = new Uint8Array(flagBuffer);
      view[0] = 3;
      let buffer = mergeArrayBuffer(flagBuffer, data);
      // 发送合并后的数据
      dataChannel.send(buffer);
      //需要判断是按键 和 屏幕点击 判断是否需要超时问题
      if (label === "custom-message") {
        return;
      }
      heartbeatInstance.delayedLogger();
    } catch (error) {}
  }
  /**
   * 处理接收到的自定义消息
   * @param {MessageEvent} e 接收到的消息事件
   */
  onReceiveCustomMessage(e) {
    try {
      var msg = e.data;
      if (msg) {
        // 解析消息格式：
        // 1. 包头位置
        // 2. 集令代码
        // 3. 数据
        let tag = msg.slice(0, 1);
        let code = convertArrayBufferToNumber(msg.slice(1, 3));
        let data = msg.slice(3);
        //console.log("msg:", data);
        this.emit("operate", code, data);
      } else {
        console.error("错误：接收到的消息为空");
      }
    } catch (error) {
      console.log("解析自定义信息出错:", error);
      AtKit.onSceneChanged("warning", { message: `[onReceiveCustomMessage]解析自定义信息出错: ${error}` });
    }
  }
  //处理鼠标数据
  onReceiveKeyboardMessage(e) {
    let msg = e.data;
    console.log("msg:", msg);

    if (msg) {
      // 0是第一个 1是中间包 2是结束 3是一次性发完的
      let flag = this.detectFlag(msg);
      console.log(flag);

      let t = msg.slice(1);
      this.parseAndHandle(t);
    }
  }
  /**
   * 处理接收到的键鼠消息
   * @param {MessageEvent} e 接收到的消息事件
   */
  onReceiveMouseMessage(e) {
    let msg = e.data;
    console.log("msg:", msg);

    if (msg) {
      // 解析消息格式：1. 包头位置 2. 集令代码 3. 数据
      // 0是第一个 1是中间包 2是结束 3是一次性发完的
      let flag = this.detectFlag(msg);
      console.log(flag);

      let t = msg.slice(1);
      switch (flag) {
        case 3:
          this.parseAndHandle(t);
          break;
        case 0:
          //0的话要处理这是分包了
          this.tempData = t;
          break;
        case 1: // 如果是中间的包
          this.tempData = this.appendData(this.tempData, t);
          break;
        case 2: // 如果是最后一个包
          this.tempData = this.appendData(this.tempData, t);

          this.parseAndHandle(this.tempData);
          this.tempData = null;
          break;
        default:
          console.log("t : ", t);

          // console.error("解析出错");
          break;
      }
    } else {
      console.error("错误：接收到的键鼠消息为空");
    }
  }
  // 追加二进制数据
  appendData(existingData, newData) {
    if (!existingData) {
      return newData; // 如果不存在旧数据，直接返回新数据
    }

    let combinedData = new Uint8Array(existingData.byteLength + newData.byteLength);
    combinedData.set(new Uint8Array(existingData), 0);
    combinedData.set(new Uint8Array(newData), existingData.byteLength);

    console.log("combinedData:", combinedData);
    return combinedData.buffer; // 返回合并后的 ArrayBuffer
  }
  parseAndHandle(msg) {
    let code = convertArrayBufferToNumber(msg.slice(0, 2));
    let data = msg.slice(2);
    console.log("code:", code, "  Data:", data, " msg:", msg);
    this.emit("peerMouse", code, data);
  }
  //解析第一个包
  detectFlag(msg) {
    // 假设标识位在每个数据包的第一个字节
    let flagByte = msg.slice(0, 1);
    // 将标识位从字节数组转换为数字
    let flag = new Uint8Array(flagByte)[0];

    return flag;
  }
  //生成一个函数摧毁webrtc的定时器
  stopWebRtcInfo() {
    clearInterval(this.clearInterval);
  }
}
