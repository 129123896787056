export default class LocalStorageManager {
  constructor(namespace) {
    if (LocalStorageManager.instance) {
      return LocalStorageManager.instance;
    }
    this.namespace = namespace;
    LocalStorageManager.instance = this;
  }

  // 存储数据
  set(key, value) {
    try {
      localStorage.setItem(`${this.namespace}_${key}`, JSON.stringify(value));
      return true;
    } catch (error) {
      console.info("LocalStorage Error:", error);
      return false;
    }
  }
  // 获取数据
  get(key, defaultValue = null) {
    try {
      const storedValue = localStorage.getItem(`${this.namespace}_${key}`);
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.info("LocalStorage Error:", error);
      return defaultValue;
    }
  }

  // 删除数据
  remove(key) {
    try {
      localStorage.removeItem(`${this.namespace}_${key}`);
      return true;
    } catch (error) {
      console.info("LocalStorage Error:", error);
      return false;
    }
  }

  // 清除命名空间下的所有数据
  clear() {
    try {
      const keysToRemove = Object.keys(localStorage).filter((key) => key.startsWith(`${this.namespace}_`));
      keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
      });
      return true;
    } catch (error) {
      console.info("LocalStorage Error:", error);
      return false;
    }
  }
  // 更新连接信息
  updateConnectionInfo(storageName, newConnectionInfo) {
    try {
      const currentConnectionInfo = this.get(storageName, {});
      const updatedConnectionInfo = { ...currentConnectionInfo, ...newConnectionInfo };
      this.set(storageName, updatedConnectionInfo);
      return true;
    } catch (error) {
      console.info("LocalStorage Error:", error);
      return false;
    }
  }
}
