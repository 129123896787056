import { sendCustomJson } from "@/toolbox.js";
//直播类
class AtKitLive {
  constructor() {
    if (AtKitLive.instance) {
      return AtKitLive.instance;
    }
    AtKitLive.instance = this;
  }
  /**
   * 开始直播
   * @param {*} roomId 房间号 (暂时没用)
   * @param {*} pushStreamAddress 推流地址
   * @param {*} args {
   * 可配置选项 
   *    width: 1920,//视频宽
        height: 1080,//视频高
        bitrate: 2000,//码率2mb
        maxBitrate: 4000,//最大码率4mb
   * }
   */
  startLiving = (roomId, pushStreamAddress) => {
    //发送参数给agent
    let data = {
      msg_id: 2, // 必填
      type: "startLive",
      data: {
        pushStreamAddress: pushStreamAddress,
      },
    };
    sendCustomJson(data);
  };
  /**
   * 停止直播
   */
  endLiving = () => {
    //发送参数给agent
    let data = {
      msg_id: 2, // 必填
      type: "stopLive",
      data: {},
    };
    sendCustomJson(data);
  };
}

// 导出单例实例
const atKitLiveInstance = new AtKitLive();
export default atKitLiveInstance;
