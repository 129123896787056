import gameInstance from "@/lib/AtKit/game/game";
import videoPlayerInstance from "@/utils/player/player.js";
import logCollector from "@/utils/report-log/LogCollector.js";
class GameConfig {
  constructor() {
    if (GameConfig.instance) {
      return GameConfig.instance;
    }
    this.shootingGameMode = false; //点击射击游戏模式
    GameConfig.instance = this;
  }
  //针对一些游戏的额外配置
  configureExtraGameSettings = (options) => {
    try {
      if (!options) {
        return;
      }
      //CSGO 针对3A的准心
      if (options.inputOp == 123 && gameInstance.gameParameters.pkgName === "211211dee44ac6736713a8841563a4f5" && options.inputState === 2) {
        videoPlayerInstance.openCrosshair();
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `F12触发,开启或关闭准心`,
        });
        return;
      }
      //F11 开启新的鼠标点击射击游戏模式
      if (options.inputOp == 122 && gameInstance.gameParameters.pkgName === "211211dee44ac6736713a8841563a4f5" && options.inputState === 2) {
        this.shootingGameMode = !this.shootingGameMode;
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: `F11开启或关闭特殊游戏模式`,
        });
        return;
      }

      // if (gameInstance.gameParameters.pkgName === "666112dc466d41f5854ea9ee") {
      //   mouseCursorInstance.autoClickCloudPc();
      //   setTimeout(() => {
      //     mouseCursorInstance.autoClickCloudPc();
      //   }, 1000);
      // }
    } catch (error) {
      console.error("[configureExtraGameSettings] error:", error);
    }
  };
}
// 直接实例化并导出
const gameConfigInstance = new GameConfig();
export default gameConfigInstance;
