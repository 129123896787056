//租户的配置 信令等
import AtKit from "@/lib/AtKit/AtKit";
import logCollector from "@/utils/report-log/LogCollector.js";
import heartbeatInstance from "@/utils/heartbeat/heartbeat.js"; //心跳
import { gameConfigApi } from "@/utils/api/api-request-list.js";
import { getOperatingSystemName, analyzeStartup } from "@/toolbox.js";

class AtKitConfig {
  constructor() {
    if (AtKitConfig.instance) {
      return AtKitConfig.instance;
    }
    this.config = {
      signal: {
        addr: process.env.REACT_APP_SIGNALING_ADDRESS, // 信令地址 默认值
      }, //信令地址
      relay: [], //转发
      other: {}, //其它配置例如心跳保活时间
    };
    this.userConf = {}; //用户的ipc信息
    this.SDK_VERSION = "AtKit-1.1.0"; //版本号
    AtKitConfig.instance = this;
  }
  // 获取实例上的任意属性
  get(key) {
    return this[key];
  }
  // 设置实例上的任意属性
  set(key, value) {
    this[key] = value;
  }
  //获取租户配置信息
  getGameConfigApi = async () => {
    let res = await gameConfigApi();
    if (res.code !== 200) {
      console.log("获取租户配置失败");
      logCollector.log({
        eventType: 11,
        eventName: "API Request Response Change",
        details: `获取租户配置失败 ${JSON.stringify(res)}`,
      });
      AtKit.onSceneChanged("warning", { message: `[getGameConfigApi]获取租户配置失败 ${JSON.stringify(res)}` });
      return;
    }
    //other 心跳时间
    if (res.data.other) {
      heartbeatInstance.setInterval(Number(res.data.other.queryRate)); //查询费用时间 -- 赋值心跳定时器
    }
    this.config = res.data;
    //如果没有信令配置 用默认的
    if (!res.data.signal.addr) {
      this.config.signal.addr = process.env.REACT_APP_SIGNALING_ADDRESS;
    }
    this.config.relay = JSON.parse('[{"relayURL":"211.95.135.125","isp":"中国联通"},{"relayURL":"120.241.224.85","isp":"中国移动"},{"relayURL":"113.108.13.105","isp":"中国电信"}]');
    window.localStorageUser.set("config", this.config);
  };
  //取config信息
  getConfig = () => {
    try {
      if (!this.config) {
        this.config = window.localStorageUser.get("config");
      }
    } catch (error) {
      AtKit.onSceneChanged("warning", { message: `[getConfig]获取租户配置失败 ${JSON.stringify(error)}` });
      return false;
    }
    this.config.relay = JSON.parse('[{"relayURL":"211.95.135.125","isp":"中国联通"},{"relayURL":"120.241.224.85","isp":"中国移动"},{"relayURL":"113.108.13.105","isp":"中国电信"}]');
    return this.config;
  };
  //设置转发
  setRelayConf = (relayConf) => {
    //this.config.relay = relayConf;
    this.config.relay = JSON.parse('[{"relayURL":"211.95.135.125","isp":"中国联通"},{"relayURL":"120.241.224.85","isp":"中国移动"},{"relayURL":"113.108.13.105","isp":"中国电信"}]');
    window.localStorageUser.updateConnectionInfo("config", this.config);
  };
  //单独设置userConf
  setUserConf = (ipConf) => {
    this.userConf = ipConf;
    logCollector.log({
      eventType: 22,
      eventName: "SDK State",
      details: `[setUserConf] 本次用户ips信息 ${JSON.stringify(ipConf)}`,
    });
  };
  //根据用户ip信息 取对应运营商信息
  getOperatorByInfo = () => {
    //根据信息找出对应的运营商
    let config = this.getConfig();
    let relayConf = config.relay;
    let results = null;
    if (relayConf) {
      results = relayConf.filter((telecom) => {
        return telecom.isp.includes(this.userConf.isp);
      });
    }
    return results[0] || relayConf[0];
  };
  initSendLog = (args) => {
    //mock 发送日志
    let logInfo = {
      timestamp: new Date().getTime(),
      version: this.SDK_VERSION,
      channel: args.channelld,
      accessKeyId: args.accessKeyId,
      isFirstUse: 1,
      language: window.navigator.language,
      platform: getOperatingSystemName(),
    };
    analyzeStartup(logInfo);
  };
  getVersion = () => {
    return this.SDK_VERSION;
  };
}

// 导出单例实例
const atKitCofingInstance = new AtKitConfig();
export default atKitCofingInstance;
