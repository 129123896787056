import LocalStorageManager from "@/utils/storage/local-storage-manager.js";

import { EventEmitter } from "eventemitter3";
import { setIgnoreResizeEvent, registerCustomEvents, removeVideoAudioTrack,getCursorStatus } from "@/toolbox.js";
import logCollector from "@/utils/report-log/LogCollector.js";
import heartbeatInstance from "@/utils/heartbeat/heartbeat.js"; //心跳
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor.js";
import atKitCofingInstance from "./config/config.js"; //AtKit SDK的单例配置类
import atKitLiveInstance from "./live/live"; // 直播类
import gameInstance from "./game/game.js"; //游戏类
import realTimeInstance from "./real-time-connection/real-time-connection.js"; //集成实时连接类
import permissionsInstance from "./permissions/permissions.js";
class AtKit {
  constructor() {
    //initSDK传进来的参数
    this.args = {
      accessKeyId: "",
      channelId: "",
      isIgnoreResizeEvent: false,
      onSceneChanged: (sceneId, extraInfo) => {},
      MessageHandler: (message) => {},
    };
    //sdkOptions
    this.eventEmitter = new EventEmitter(); //emit 事件实例化的引用
  }
  //映射暴露方法给window对象
  _mappingAtKit = () => {
    if (typeof window !== "undefined") {
      window.AtKit = {
        initSDK: this.initSDK,
        startGame: this.startGame,
        enterQueue: this.enterQueue,
        endQueue: this.endQueue,
        cmdToCloud: this.cmdToCloud,
        setVirtualKeys: this.setVirtualKeys,
        enableAudio: this.enableAudio,
        gameArchived: this.gameArchived,
        stopGame: this.stopGame,
        getFullKeyboardState: this.getFullKeyboardState,
        jumpQueue: this.jumpQueue,
        getVersion: this.getVersion,
        setVideoBitrate: this.setVideoBitrate,
        // sendDataChannel: this.sendDataChannel,
        getPlayToken: this.getPlayToken,
        setVideoStreamDimensions: this.setVideoStreamDimensions,
        emit: this._emit,
      };
    }
  };
  /**
   * 回调给上层事件
   * @param {*} sceneId 对应sceneId
   * @param {*} options
   */
  onSceneChanged = (sceneId, options) => {
    try {
      this.args.onSceneChanged(sceneId, options);
      if (sceneId === "warning") {
        logCollector.log({
          eventType: 22,
          eventName: "SDK State",
          details: options?.message,
        });
      }
    } catch (error) {
      console.error("[onSceneChanged]回调信息出错:", error);
    }
  };
  /**
   * 初始化自已项目的手柄事件
   */
  _initOnEvent = () => {
    //现在这里注册 例如GTA5 手柄托盘事件 W S A D
    if (this.eventEmitter.listeners("GameOperation").length <= 0) {
      //gta5
      this.eventEmitter.on("GameOperation", (data) => {
        this.cmdToCloud(data);
      });
    }
    //xbox 手柄
    if (this.eventEmitter.listeners("GameOperation-Xbox").length <= 0) {
      this.eventEmitter.on("GameOperation-Xbox", (data) => {
        this.cmdToCloud(data);
      });
    }
    //错误事件
    this.eventEmitter.on("error", (option) => {
      if (!option.hasOwnProperty("sceneld")) return;
      switch (option.sceneld) {
        case "stop":
          console.log("stop:", option);
          break;
        default:
          break;
      }
    });
  };
  //抛出的emit全局共享事件
  _emit = (type, args) => {
    this.eventEmitter.emit(type, args);
  };
  /**
   * 初始化先自已连接信令
   * @param {*} args 参数
   */
  initSDK = (args) => {
    this.args = { ...args };
    window.localStorageUser = new LocalStorageManager("AtKit");
    //新增映射
    if (!args.accessKeyId || !args.channelId || !args.onSceneChanged || !args.MessageHandler) {
      this.onSceneChanged("warning", { message: `[initSDK]必填项缺失，请确保传入accessKeyId、channelld、onSceneChanged、MessageHandler参数` });
      return "必填项缺失，请确保传入accessKeyId、channelld、onSceneChanged、MessageHandler参数";
    }
    //this.mappingAtKit();
    try {
      removeVideoAudioTrack(); //初始化先删除一遍轨道 防止存在上一次的导致黑屏
      logCollector.startLogTimer();
      //注册一些事件
      registerCustomEvents(this);
      //设置横竖屏
      setIgnoreResizeEvent("example", args.isIgnoreResizeEvent);
      //发送日志
      atKitCofingInstance.initSendLog(args);
      //初始化回调
      this.onSceneChanged("init", { message: true });
      //初始化事件
      this._initOnEvent();
      //开启日志收集
    } catch (error) {
      this.onSceneChanged("init", { message: false });
      this.onSceneChanged("warning", { message: `[initSDK]初始化SDK: ${error}` });
    }
  };
  //开始游戏
  startGame = async (domId, options) => {
    await gameInstance.startGame(domId, options);
  };
  //进入排队 --- 真正开始游戏
  enterQueue = async () => {
    await gameInstance.enterQueue();
  };
  //退出排队
  endQueue = async () => {
    await gameInstance.endQueue();
  };
  //设置虚拟键位布局 xbox 手柄 keyboard 自定义键鼠 NONE 不显示
  setVirtualKeys = (type, options) => {
    gameInstance.setVirtualKeys();
  };
  /**
   * 开启声音开关
   * @param {*} enabled true 开启 false
   */
  enableAudio = (enabled) => {
    realTimeInstance.enableAudio(enabled);
  };
  //返回当前键盘是否展示隐藏
  getFullKeyboardState = () => {
    gameInstance.getFullKeyboardState();
  };
  //插队
  jumpQueue = async (flag) => {
    await gameInstance.jumpQueue(flag);
  };
  //获取版本号
  getVersion = () => {
    return atKitCofingInstance.getVersion();
  };
  // 设置分辨率
  setVideoStreamDimensions = (id) => {
    realTimeInstance.setVideoStreamDimensions(id);
  };
  // 设置清晰度
  setVideoBitrate = (id) => {
    realTimeInstance.setVideoBitrate(id);
  };
  //取浏览器的 playToken
  getPlayToken = () => {
    return gameInstance.getPlayToken();
  };
  //暂停游戏
  pauseGame = (type) => {
    gameInstance.pauseGame(type);
  };
  // 需要给提供给上层sdk的函数
  getMouseMode = () => {
    return mouseCursorInstance.getMouseMode();
  };
  //获取鼠标速度
  getMouseMoveRatio = () => {
    return mouseCursorInstance.getMouseMoveRatio();
  };
  //设置鼠标速度
  setMouseMoveRatio = (number) => {
    return mouseCursorInstance.setMouseMoveRatio(number);
  };
  //设置鼠标模式
  setMouseMode = (type) => {
    return mouseCursorInstance.setMouseMode(type);
  };
  //获取鼠标数据
  getMouseCoordinate = () => {
    return mouseCursorInstance.getMouseCoordinate();
  };
  //是否支持直播
  isSupportLiving = () => {
    return true;
  };
  //开始直播
  startLiving = (roomId, pushStreamAddress) => {
    atKitLiveInstance.startLiving(roomId, pushStreamAddress);
  };
  //关闭直播
  endLiving = () => {
    atKitLiveInstance.endLiving();
  };
  //重置无操作定时器
  resetInputTimer = (time) => {
    heartbeatInstance.resetInputTimer(time);
  };
  //设置键盘
  setFullKeyboardState = (isEnabled) => {
    gameInstance.setFullKeyboardState(isEnabled);
  };
  //结束游戏
  stopGame = async () => {
    await gameInstance.stopGame();
  };
  //获取是否存在档案
  gameArchived = async () => {
    await gameInstance.gameArchived();
  };
  //发送数据给对端
  cmdToCloud = async (data) => {
    realTimeInstance.cmdToCloud(data);
  };
  //------------------------------控制权----------------------------------
  //获取pinCode
  getPinCode = async () => {
    await gameInstance.getPinCode();
  };
  //分配权限
  controlPowerDistribute = async (options, callback) => {
    await permissionsInstance.controlPowerDistribute(options, callback);
  };
  //控制权查询
  controlPowerQuery = async (options, callback) => {
    await permissionsInstance.controlPowerQuery(options, callback);
  };
  getPlayerToken = () => {
    gameInstance.getPlayerToken();
  };
  getCursorStatus = ()=>{
    getCursorStatus();
  }
}

let instance = null;

const getInstance = () => {
  if (!instance) {
    instance = new AtKit();
  }
  return instance;
};
export default getInstance();
