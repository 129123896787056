/**
 * 抽取封装的播放器的鼠标发送数据操作
 */
import { sendKeyboardEvent, mouseEvent, sendTouchMove } from "@/utils/keyboard-mouse/Input-device-utils";
import { calculateDisplaySize, restoreOriginalCoordinatesPC, getOperatingSystem, getExampleDimensions } from "@/utils/utilit-ties/tools";
import mouseCursorInstance from "@/utils/mouse-cursor/mouse-cursor.js"; //鼠标键盘类
import { splitCoordinates, portraitOrientationCoordinates } from "./video-utils";
import { toProtobuf } from "@/toolbox";
import AtKit from "@/lib/AtKit/AtKit";
import gameConfigInstance from "@/utils/game-settings-config/config.js";
import realTimeInstance from "@/lib/AtKit/real-time-connection/real-time-connection";
export default class MouseEvents {
  constructor() {
    if (!MouseEvents.instance) {
      // 初始化单例模式所需的属性
      this.timeoutId = null; //touchStart事件定时器 用于不同触控类型
      this.lastTouch = null; // 上一次保存的触摸点坐标
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches; // 判断是否竖屏
      this.mouseConfig = {
        downDelay: 200, //默认配置定时器时间200
        downMoveOffset: 5, //移动xy加起来不超过5不算移动
        slideMode2DownDuration: 1000, //在平滑模式下（假设你的屏幕是可以滚动的），如果你长时间按住鼠标不放，每秒都会发送一次你的鼠标信息。
        slideMode2DownMoveOffset: 5, //在平滑模式下，如果你按住鼠标后移动了超过5个像素，就不再周期性地发送鼠标信息。
      };
      this.customPlay = null; // 视频播放器 DOM 元素
      this.cursorTag = null; // 光标标签
      this.addMouseDom = null; //自定义播放器的类
      this.scaleXY = null; // 缩放比例

      this.movingTouchId = null; // 视角控制触摸点id
      this.touchPoints = []; //保存触摸点
      this.fireGunTimeouts = {}; //touchStart定时器的保存
      this.touchEndTimeouts = {}; //touchEnd定时器的保存

      // 注册事件
      this.registerEvents();
      MouseEvents.instance = this; // 保存单例实例
    }

    return MouseEvents.instance;
  }
  /**
   * 注册全局键盘事件 PC操控也能完全没问题
   * @returns
   */
  registerEvents = () => {
    try {
      // 注册键盘事件，支持 h5 和浏览器模拟
      document.addEventListener("keydown", (event) => {
        sendKeyboardEvent(event, "keydown");
      });

      document.addEventListener("keyup", (event) => {
        sendKeyboardEvent(event, "keyup");
      });

      // 监听屏幕方向变化事件
      window.addEventListener("orientationchange", () => {
        setTimeout(() => {
          this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
        }, this.mouseConfig.downDelay); // 延迟200毫秒以确保方向已更新
      });

      // 获取自定义播放器的 DOM 元素
      const addMouseDom = document.getElementById("custom-player");
      if (!addMouseDom) return;

      this.customPlay = addMouseDom;
      const videoInfo = window.localStorageUser.get("video_info"); // 获取视频信息
      const os = getOperatingSystem(); // 获取操作系统信息
      this.bodyRect = getExampleDimensions();

      console.log("os 是否是手机模式:", os);
      if (os) {
        // 手机模式下注册事件
        this.setupMobileHandlers(addMouseDom, videoInfo);
      } else {
        // 浏览器模式下注册事件
        this.setupBrowserHandlers(addMouseDom, videoInfo);
      }
    } catch (error) {
      console.log("MouseEvents error: ", error);
    }
  };

  // 手机模式下的事件处理程序注册
  setupMobileHandlers = (addMouseDom, videoInfo) => {
    const { DeviceWidth, displayWidth } = videoInfo;
    this.scaleXY = DeviceWidth / displayWidth;
    const eventHandlers = {
      touchstart: (event) => this.touchStart(event),
      touchmove: (event) => this.touchMove(event),
      touchend: (event) => this.touchEnd(event),
    };
    //设置手机事件
    const setupMouseEventHandlers = (addMouseDom) => {
      Object.keys(eventHandlers).forEach((eventName) => {
        addMouseDom.addEventListener(eventName, eventHandlers[eventName], { passive: false });
      });
    };

    const cleanupMouseEventHandlers = () => {
      Object.keys(eventHandlers).forEach((eventName) => {
        addMouseDom.removeEventListener(eventName, eventHandlers[eventName], { passive: false });
      });
    };
    //默认设置手机事件
    setupMouseEventHandlers(addMouseDom);
    return { cleanupMouseEventHandlers, setupMouseEventHandlers };
  };

  // 浏览器模式下的事件处理程序注册
  setupBrowserHandlers = (addMouseDom, videoInfo) => {
    const eventHandlers = {
      mousedown: (event) => this.updataXY(event),
      mousemove: (event) => this.updataXY(event),
      mouseup: (event) => this.updataXY(event),
      wheel: (event) => this.updataXY(event),
    };

    const setupMouseEventHandlers = (addMouseDom) => {
      Object.keys(eventHandlers).forEach((eventName) => {
        addMouseDom.addEventListener(eventName, eventHandlers[eventName], { passive: true });
      });
    };

    const cleanupMouseEventHandlers = () => {
      Object.keys(eventHandlers).forEach((eventName) => {
        addMouseDom.removeEventListener(eventName, eventHandlers[eventName]);
      });
    };

    setupMouseEventHandlers(addMouseDom);
    return { cleanupMouseEventHandlers, setupMouseEventHandlers };
  };
  /**
   * 是否是自已元素上的touch事件
   * @param {*} className
   * @returns true 是 false 不是
   */
  isSeftElement = (className) => {
    if (className !== "at-vir-layout" && className !== "player-canvas-cursor" && className !== "AtKitCrosshair") {
      return false;
    }
    return true;
  };
  //用于阻止默认行为和停止事件传播
  preventEventDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  //需要遍历屏幕上的触摸点
  touchStart = (event) => {
    try {
      if (mouseCursorInstance.isKeyboardVisible) return; //当前键盘是展开的
      this.preventEventDefaults(event);
      console.log("touchStart: ", event.targetTouches, "   event.targetTouches.length", event.targetTouches.length);
      //遍历屏幕上自已的所有元素
      for (let i = 0; i < event.targetTouches.length; i++) {
        let { clientX, clientY, identifier, target } = event.targetTouches[i];
        const className = target.className; //获取当前事件的元素
        //true执行这是自已事件的元素
        if (!this.isSeftElement(className)) {
          return;
        }
        //处理横竖屏的XY切换
        let touchXY = this.getTrueCoordinates(clientX, clientY);
        console.log(" this.movingTouchId 赋值前=", this.movingTouchId);
        // 如果当前没有移动视角的触点，则设置为当前触点
        if (this.movingTouchId === null) {
          this.movingTouchId = identifier;
          this.lastTouch = touchXY;
          // console.log("this.lastTouch  11");
          // console.log("[touchStart]当前新的视角id:", this.movingTouchId, " this.lastTouch:", this.lastTouch);
        }
        console.log(" this.movingTouchId 赋值后=", this.movingTouchId);
        // console.log("touchStart :", identifier, JSON.stringify(this.touchPoints));
        // 检查触点是否已经存在于touchPoints数组中
        let existingTouchPoint = this.touchPoints.find((point) => point.identifier === identifier);
        //console.log("是否已经存在了触摸点:", existingTouchPoint);
        if (existingTouchPoint) continue; //有的话 跳过
        this.touchPoints.push({
          clientX: touchXY.clientX, //X
          clientY: touchXY.clientY, //Y
          identifier, //当前触摸点
          isMoved: false, //是否touchMove
        });
        //console.log(" this.touchPoints : ", JSON.stringify(this.touchPoints));
        this.mouseType(event, identifier, touchXY.clientX, touchXY.clientY, 4);
      }
    } catch (error) {
      console.log("[touchStart] error: " + error);
    }
  };
  touchMove = (event) => {
    console.log("[touchMove]: mouseCursorInstance.isKeyboardVisible ", mouseCursorInstance.isKeyboardVisible);
    try {
      if (mouseCursorInstance.isKeyboardVisible) return;
      this.preventEventDefaults(event);
      for (let i = 0; i < event.targetTouches.length; i++) {
        let { clientX, clientY, identifier } = event.targetTouches[i];
        console.log("touchMove:", clientX, clientY, identifier);
        const point = this.touchPoints.find((point) => point.identifier === identifier);
        if (!point) continue; // 如果point不存在，跳过当前循环
        // 标记触摸点已经移动过
        //如果第一组触摸点存在 touchStart定时器就清除掉 此时是想移动 非点击
        if (this.fireGunTimeouts[identifier]) {
          clearTimeout(this.fireGunTimeouts[identifier]); // 仅清除当前触点的定时器
          delete this.fireGunTimeouts[identifier];
        }
        // 当触摸点 和保存的触摸点一致时响应自已的位移
        console.log("identifier:", identifier, "this.movingTouchId :", this.movingTouchId);
        if (identifier === this.movingTouchId) {
          point.isMoved = true;
          console.log("touchMove:", "clientX:   ", clientX, "  clientY", clientY);
          let touchXY = this.getTrueCoordinates(clientX, clientY);
          //更新保存的触摸点XY
          point.clientX = touchXY.clientX;
          point.clientY = touchXY.clientY;
          console.log("touchMove touchXY:", "touchXY.clientX:   ", touchXY.clientX, "  touchXY.clientY", touchXY.clientY);
          //发送相对位置
          if (mouseCursorInstance.mouseSendPositionType === 9) {
            if (!this.lastTouch) {
              this.lastTouch = { clientX: 0, clientY: 0 };
            }
            this.updateCursorPosition(event, point.clientX, point.clientY, 9);
          } else {
            //发送绝对位置
            this.updateCursorPosition(event, point.clientX, point.clientY, 2);
          }
        }
      }
    } catch (error) {
      console.log("[touchMove] error : ", error);
    }
  };
  touchEnd = (event) => {
    try {
      console.log("[touchEnd]:", mouseCursorInstance.isKeyboardVisible);
      if (mouseCursorInstance.isKeyboardVisible) {
        this.touchPoints = [];
        this.movingTouchId = null;
        this.lastTouch = { clientX: 0, clientY: 0 };
        return;
      }
      this.preventEventDefaults(event);
      for (let i = 0; i < event.changedTouches.length; i++) {
        let { clientX, clientY, identifier } = event.changedTouches[i];
        //查找touchEnd触摸点的信息
        const index = this.touchPoints.findIndex((point) => point.identifier === identifier);
        // 如果未找到对应的触摸点，直接跳过
        if (index === -1) continue;
        const point = this.touchPoints[index];
        // 如果触摸结束的是移动视角的触摸点，如果存在找一个新的触摸点来移动视角
        if (point.isMoved) {
          // console.log("是视角控制触摸点");
          if (this.touchPoints.length > 1) {
            //console.log("视角控制触摸点删除");
            this.touchPoints.splice(index, 1);
            //console.log("剩下的触摸点信息:", this.touchPoints);
            this.movingTouchId = this.touchPoints[0].identifier;
            this.touchPoints[0].isMoved = true;
            this.lastTouch = { clientX: this.touchPoints[0].clientX, clientY: this.touchPoints[0].clientY };
            // console.log("this.lastTouch  44");
            // console.log("转移过后的触摸点信息:", this.touchPoints);
            return;
          } else {
            this.touchPoints = [];
            this.movingTouchId = null;
            this.lastTouch = { clientX: 0, clientY: 0 };
            this.mouseType(event, identifier, 0, 0, 3, index);
            return;
          }
        }
        //如果有多个触摸点
        console.log("触摸点:", this.touchPoints.length);
        if (this.touchPoints.length) {
          //需要删除触摸点
          this.touchPoints.splice(index, 1);
          if (!this.touchPoints.length) {
            this.touchPoints = [];
            this.movingTouchId = null;
            this.lastTouch = { clientX: 0, clientY: 0 };
          }
        }
        //存在这个触摸点
        if (index > -1) {
          let touchXY = this.getTrueCoordinates(clientX, clientY);
          this.mouseType(event, identifier, touchXY.clientX, touchXY.clientY, 3, index);
        }
      }
    } catch (error) {
      console.log("[touchEnd] error : ", error);
    }
  };

  //处理多个鼠标模式
  mouseType = (event, identifier, clientX, clientY, type, index) => {
    if (gameConfigInstance.shootingGameMode) {
      //鼠标单击
      if (type === 4) {
        // 判断是否有多个触摸点
        if (this.touchPoints.length > 1) {
          this.shootingGameTouchStart(4);
          return;
        }
        if (this.fireGunTimeouts[identifier]) return;
        this.fireGunTimeouts[identifier] = setTimeout(() => {
          this.specialGameMove(clientX, clientY);
        }, 100);
        return;
      }
      //鼠标释放
      if (type === 3) {
        // 判断是否有多个触摸点
        if (this.touchPoints.length > 1) {
          this.shootingGameTouchStart(3);
          return;
        }
        this.touchEndTimeouts[identifier] = setTimeout(() => {
          this.shootingGameTouchStart(3);
          delete this.touchEndTimeouts[identifier];
          delete this.fireGunTimeouts[identifier];
          // console.log("当前开始定时器:", JSON.stringify(this.fireGunTimeouts));
          // console.log("当前停止定时器:", JSON.stringify(this.touchEndTimeouts));
          // console.log("当前触摸点信息:", JSON.stringify(this.touchPoints));
        }, 100);
        //console.log("mouseType : ", identifier, clientX, clientY, type);
      }
      return;
    }
    switch (mouseCursorInstance.mouseType) {
      case 0:
        //卸载全部鼠标事件
        this.cleanupMouseEventHandlers();
        break;
      case 1:
      case 2:
        //延迟3判断是否有move 没有的话响应touchStart touchEnd
        //如果存在视角移动触摸点不需要延迟射击
        if (mouseCursorInstance.mouseSendPositionType === 9) {
          //鼠标单击
          if (type === 4) {
            // 判断是否有多个触摸点
            if (this.touchPoints.length > 1) {
              this.shootingGameTouchStart(4);
              return;
            }
            if (this.fireGunTimeouts[identifier]) return;
            this.fireGunTimeouts[identifier] = setTimeout(() => {
              this.shootingGameTouchStart(4);
            }, this.mouseConfig.downDelay);
            return;
          }
          //鼠标释放
          if (type === 3) {
            // 判断是否有多个触摸点
            if (this.touchPoints.length > 1) {
              this.shootingGameTouchStart(3);
              return;
            }
            this.touchEndTimeouts[identifier] = setTimeout(() => {
              this.shootingGameTouchStart(3);
              delete this.touchEndTimeouts[identifier];
              delete this.fireGunTimeouts[identifier];
              // console.log("当前开始定时器:", JSON.stringify(this.fireGunTimeouts));
              // console.log("当前停止定时器:", JSON.stringify(this.touchEndTimeouts));
              // console.log("当前触摸点信息:", JSON.stringify(this.touchPoints));
            }, this.mouseConfig.downDelay);
            //console.log("mouseType : ", identifier, clientX, clientY, type);
          }
        }
        if (mouseCursorInstance.mouseSendPositionType === 1) {
          //鼠标单击
          if (type === 4) {
            // 判断是否有多个触摸点
            if (this.touchPoints.length > 1) {
              this.shootingGameTouchStart(4);
              return;
            }
            if (this.fireGunTimeouts[identifier]) return;

            this.fireGunTimeouts[identifier] = setTimeout(() => {
              if (mouseCursorInstance.mouseType === 2) {
                this.mouseType2(event, type, clientX, clientY);
              } else {
                this.shootingGameTouchStart(4);
              }
            }, this.mouseConfig.downDelay);
            return;
          }
          //鼠标释放
          if (type === 3) {
            // 判断是否有多个触摸点
            if (this.touchPoints.length > 1) {
              this.shootingGameTouchStart(3);
              return;
            }
            this.touchEndTimeouts[identifier] = setTimeout(() => {
              this.shootingGameTouchStart(3);
              delete this.touchEndTimeouts[identifier];
              delete this.fireGunTimeouts[identifier];
              // console.log("当前开始定时器:", JSON.stringify(this.fireGunTimeouts));
              // console.log("当前停止定时器:", JSON.stringify(this.touchEndTimeouts));
              // console.log("当前触摸点信息:", JSON.stringify(this.touchPoints));
            }, this.mouseConfig.downDelay);
            //console.log("mouseType : ", identifier, clientX, clientY, type);
          }
        }
        break;
      case 5:
        break;
    }
  };
  //用于其它不同类型 点击屏幕某个点 直接移动过去
  specialGameMove = (clientX, clientY) => {
    // 获取屏幕绝对中间的X和Y坐标
    let positionX = window.innerWidth / 2;
    let positionY = window.innerHeight / 2;
    // 计算相对坐标
    let centerXY = this.getTrueCoordinates(positionX, positionY);

    // 计算相对移动的X和Y坐标
    let x = clientX - centerXY.clientX;
    let y = clientY - centerXY.clientY;
    // x = x * 2.5;
    // y = y * 2.5;
    x = x * mouseCursorInstance.initialMouseSpeed;
    y = y * mouseCursorInstance.initialMouseSpeed;

    let monseArr = splitCoordinates(x, y);
    for (let i = 0; i < monseArr.length; i++) {
      let item = monseArr[i];
      console.log("x: " + item.x + ", y: " + item.y);
      sendTouchMove(
        {
          type: "touchmove",
        },
        {
          x: Math.round(item.x),
          y: Math.round(item.y),
        },
        9
      );
    }
    //移动完了开枪
    this.shootingGameTouchStart(4);
  };

  // 更新光标位置
  updateCursorPosition = (event, clientX, clientY, type) => {
    //鼠标模式 9 发相对位置
    if (mouseCursorInstance.mouseSendPositionType === 9) {
      const deltaX = clientX - this.lastTouch.clientX;
      const deltaY = clientY - this.lastTouch.clientY;

      const roundedDeltaX = deltaX * mouseCursorInstance.initialMouseSpeed;
      const roundedDeltaY = deltaY * mouseCursorInstance.initialMouseSpeed;

      if (Math.abs(roundedDeltaY) < 5 && Math.abs(roundedDeltaX) < 5) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
      let monseArr = splitCoordinates(roundedDeltaX, roundedDeltaY);
      monseArr.forEach((item, index) => {
        sendTouchMove(
          event,
          {
            x: Math.round(item.x),
            y: Math.round(item.y),
          },
          9
        );
      });
      this.lastTouch = { clientX, clientY };
    } else {
      if (this.cursorTag === null) {
        this.cursorTag = document.getElementById("AtKitCursorTag");
      }

      if (mouseCursorInstance.mouseType === 2) {
        this.mouseType2(event, type, clientX, clientY);
      }
      //如果鼠标的模式也是 1 应该发送相对的路径操作 然后把本次差异的xy追加到鼠标上去
      if (mouseCursorInstance.mouseType === 1) {
        this.mouseType1(event, type, clientX, clientY);
      }
    }
  };
  //要发绝对位置的鼠标模式 1
  mouseType1 = (event, type, clientX, clientY) => {
    console.log("mouseType1 : ", type);
    try {
      //如果是按下和放开的时候 只发当前光标的位置 不进行计算
      if (type === 4 || type === 3) {
        // 更新 left 和 top 值
        const currentLeft = parseFloat(this.cursorTag.style.left) || 0;
        const currentTop = parseFloat(this.cursorTag.style.top) || 0;
        sendTouchMove(
          event,
          {
            x: currentLeft * this.scaleXY,
            y: currentTop * this.scaleXY,
          },
          type
        );
        return;
      }
      const { displayHeight, displayWidth } = window.localStorageUser.get("video_info");
      console.log("displayHeight", displayHeight, " displayWidth", displayWidth);
      if (!displayHeight || !displayWidth) {
        const customPlayer = document.getElementById("custom-player");
        if (customPlayer) {
          const rect = customPlayer.getBoundingClientRect();
          const playerHeight = rect.height;
          const playerWidth = rect.width;
          // 将获取到的宽度和高度存储回本地存储
          window.localStorageUser.set("video_info", { displayHeight: playerHeight, displayWidth: playerWidth });
        }
      }
      const deltaX = clientX - this.lastTouch.clientX;
      const deltaY = clientY - this.lastTouch.clientY;
      // console.log("deltaX:", deltaX);
      // console.log("deltaY:", deltaY);
      const currentLeft = parseFloat(this.cursorTag.style.left) || 0;
      const currentTop = parseFloat(this.cursorTag.style.top) || 0;
      //console.log("currentLeft:", currentLeft, "currentTop", currentTop);
      let mX = currentLeft + deltaX;
      let mY = currentTop + deltaY;
      console.log("mX:", mX, "mY", mY);
      // 确保光标不会移出显示区域 还需要减去本身本地鼠标的宽高
      if (mX < 0) mX = 0;
      if (mX > displayWidth) mX = displayWidth;
      if (mY < 0) mY = 0;
      if (mY > displayHeight) mY = displayHeight;
      // 更新 left 和 top 值
      this.cursorTag.style.left = `${mX}px`;
      this.cursorTag.style.top = `${mY}px`;
      window.localStorageUser.set("cursorTag", { x: mX, y: mY });
      sendTouchMove(
        event,
        {
          x: mX * this.scaleXY,
          y: mY * this.scaleXY,
        },
        type
      );
      this.lastTouch = { clientX, clientY };
    } catch (error) {
      console.log("mouseCursorInstance.mouseType === 1 error:", error);
    }
  };
  //要发绝对位置的鼠标模式 2
  mouseType2 = (event, type, clientX, clientY) => {
    this.cursorTag.style.left = clientX.toFixed(2) + "px";
    this.cursorTag.style.top = clientY.toFixed(2) + "px";
    const deltaX = clientX * this.scaleXY;
    const deltaY = clientY * this.scaleXY;
    window.localStorageUser.set("cursorTag", { x: clientX, y: clientY });
    sendTouchMove(
      event,
      {
        x: deltaX,
        y: deltaY,
      },
      type
    );
  };
  // 更新鼠标事件的 XY 坐标
  updataXY = (event) => {
    let latestEvent = event;
    let animationFrameId;
    const updateCoordinates = () => {
      const { DeviceWidth, displayWidth } = window.localStorageUser.get("video_info");
      const elementRect = this.customPlay.getBoundingClientRect();
      const offsetX = latestEvent.clientX - elementRect.left;
      const offsetY = latestEvent.clientY - elementRect.top;
      let coordinate = restoreOriginalCoordinatesPC(offsetX, offsetY, DeviceWidth, displayWidth);
      if (this.cursorTag === null) {
        this.cursorTag = document.getElementById("AtKitCursorTag");
      }
      if (this.cursorTag) {
        this.cursorTag.style.left = coordinate.x;
        this.cursorTag.style.top = coordinate.y;
      }
      mouseEvent(latestEvent, coordinate);
      animationFrameId = null;
    };
    if (!animationFrameId) {
      animationFrameId = requestAnimationFrame(updateCoordinates);
    }
  };
  /**
   * 获取播放器的黑边距
   * @param {*} x
   * @param {*} y
   * @param {*} isPortrait 是否竖屏
   * @returns
   */
  getTrueCoordinates = (x, y) => {
    try {
      if (this.customPlay === null) {
        this.customPlay = document.getElementById("custom-player").getBoundingClientRect();
      }
      const playerRect = this.customPlay.getBoundingClientRect();
      let clientX, clientY;
      if (this.isPortrait) {
        const temp = x;
        x = y;
        y = this.bodyRect.height - temp;
        // 在竖屏时，调整计算顺序
        clientY = y - playerRect.left;
        clientX = x - playerRect.top;
      } else {
        // 在横屏时，保持原有计算顺序
        clientX = x - playerRect.left;
        clientY = y - playerRect.top;
      }
      return { clientX, clientY };
    } catch (error) {
      console.log("getCustomPlayer出错:", error);
      AtKit.onSceneChanged("warning", { message: `[getTrueCoordinates]getCustomPlayer出错: ${error}` });
      return { clientX: 0, clientY: 0 }; // 或者返回适当的默认值或错误处理方式
    }
  };
  //设置光标在屏幕中间
  setCursorCenter = () => {
    if (this.cursorTag === null) {
      this.cursorTag = document.getElementById("AtKitCursorTag");
    }
    const customPlayer = document.getElementById("custom-player");

    //取浏览器有没有  window.localStorageUser.set("cursorTag", {x:clientX,y:clientY});数据缓冲区
    let curorTagDispley = window.localStorageUser.get("cursorTag");

    if (customPlayer) {
      // 获取 custom-player 的宽度和高度
      const playerWidth = customPlayer.offsetWidth;
      const playerHeight = customPlayer.offsetHeight;

      // 计算中心位置
      let centerX = playerWidth / 2;
      let centerY = playerHeight / 2;
      if (curorTagDispley) {
        centerX = curorTagDispley.x;
        centerY = curorTagDispley.y;
      }
      // 设置光标的位置
      this.cursorTag.style.position = "absolute"; // 确保光标使用绝对定位
      this.cursorTag.style.left = `${centerX}px`;
      this.cursorTag.style.top = `${centerY}px`;
    } else {
      console.error("Element with id 'custom-player' not found.");
    }
  };
  //封装一个鼠标类型5 move的过程有了touchStart 发送鼠标点击
  shootingGameTouchStart = (eventType) => {
    let cmd = {
      // 构造鼠标事件信息命令对象
      event: eventType,
      x: 0,
      y: 0,
      buttonState: 1,
      keyboardState: 0,
    };
    console.log("[shootingGameTouchStart]: 发送鼠标", cmd);
    let buf = toProtobuf("MouseData", cmd, 2001);
    realTimeInstance.sendDataChannel("channel-mouse", buf);
  };
}
